import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfViewerProps {
  documents: Array<{
    uri: string;
    fileName?: string;
  }>;
  filteredDoc?: {
    uri: string;
    fileName?: string;
  } | null;
  activeHighlight?: { content: string } | null;
  pdfVerticalScrollByDefault?: boolean;
  defaultZoom?: number;
  singlePageViewDefault?: boolean;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  documents,
  filteredDoc,
  activeHighlight,
  pdfVerticalScrollByDefault = true,
  defaultZoom = 1.1,
  singlePageViewDefault = false,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState(defaultZoom);
  const [currentPage, setCurrentPage] = useState(1);
  const [singlePageView, setSinglePageView] = useState(singlePageViewDefault);
  const containerRef = useRef<HTMLDivElement>(null);
  const [renderedPages, setRenderedPages] = useState<Set<number>>(new Set());
  const [isSearching, setIsSearching] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setRenderedPages(new Set());
  };

  const onPageLoadSuccess = (pageNumber: number) => {
    setRenderedPages(prev => new Set([...prev, pageNumber]));
  };

  const highlightText = async (searchText: string) => {
    console.log('Highlighting text:', searchText);
    if (!containerRef.current || isSearching) return;
    setIsSearching(true);

    // Wait a bit for the text layer to be fully rendered
    await new Promise(resolve => setTimeout(resolve, 500));

    const textLayers = containerRef.current.querySelectorAll('.react-pdf__Page__textContent');
    console.log('Found text layers:', textLayers.length);

    let bestParagraph: HTMLElement[] | null = null;
    let bestScore = 0;

    textLayers.forEach((textLayer, idx) => {
      // Get all text spans within the text layer
      const textElements = textLayer.querySelectorAll('span');
      console.log(`Text layer ${idx} has ${textElements.length} text elements`);

      // Group nearby text elements into paragraphs
      let currentParagraph: HTMLElement[] = [];
      let lastY = -1;
      const paragraphs: HTMLElement[][] = [];

      textElements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        // If this element is significantly below the last one, start a new paragraph
        if (lastY !== -1 && rect.top - lastY > 20) {
          if (currentParagraph.length > 0) {
            paragraphs.push([...currentParagraph]);
            currentParagraph = [];
          }
        }
        currentParagraph.push(el as HTMLElement);
        lastY = rect.bottom;
      });

      // Add the last paragraph if any
      if (currentParagraph.length > 0) {
        paragraphs.push(currentParagraph);
      }

      console.log(`Grouped into ${paragraphs.length} paragraphs`);

      // Score each paragraph
      paragraphs.forEach((paragraph) => {
        const paragraphText = paragraph.map(el => el.textContent || '').join(' ');
        const score = calculateMatchScore(paragraphText.toLowerCase(), searchText.toLowerCase());

        if (score > bestScore) {
          bestScore = score;
          bestParagraph = paragraph;
        }
      });
    });

    // Clear any existing highlights
    const existingHighlights = containerRef.current.querySelectorAll('[data-highlight="true"]');
    existingHighlights.forEach((el) => {
      (el as HTMLElement).style.backgroundColor = '';
      (el as HTMLElement).removeAttribute('data-highlight');
    });


    if (bestParagraph) {

      (bestParagraph as HTMLElement[]).forEach((element: HTMLElement) => {
        element.style.backgroundColor = 'rgba(255, 215, 0, 0.3)'; // semi-transparent gold
        element.style.transition = 'background-color 0.3s ease';
        element.style.pointerEvents = 'none'; // ensure it doesn't block interaction
        element.style.mixBlendMode = 'multiply'; // better blending with text
        element.setAttribute('data-highlight', 'true');
      });
      // Scroll to the first element
      (bestParagraph[0] as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    setIsSearching(false);
  };

  // Helper function to calculate match score
  const calculateMatchScore = (text: string, search: string): number => {
    let score = 0;

    // Clean up and normalize the text and search string
    const normalizeText = (str: string): string => {
      return str
        .toLowerCase()
        // Replace all non-alphanumeric chars with empty string
        .replace(/[^a-z0-9]/g, '')
        .trim();
    };

    const normalizedText = normalizeText(text);
    const normalizedSearch = normalizeText(search);

    // If the stripped paragraph contains the stripped search text
    if (normalizedText.includes(normalizedSearch)) {
      score += 150; // Highest priority match
      return score;
    }

    // Check for partial matches of the stripped text
    // If at least 80% of the search text is found continuously in the text
    const minMatchLength = Math.floor(normalizedSearch.length * 0.8);
    for (let i = 0; i <= normalizedSearch.length - minMatchLength; i++) {
      const searchSubstring = normalizedSearch.substring(i, i + minMatchLength);
      if (normalizedText.includes(searchSubstring)) {
        score += 120; // High priority but not perfect match
        break;
      }
    }

    // If we found a good stripped match, return early
    if (score > 0) {
      return score;
    }

    // Fall back to word-based matching for lower scores
    text = text.replace(/\s+/g, ' ').trim();
    search = search.replace(/\s+/g, ' ').trim();

    // Split into words and check for word matches
    const searchWords = search.split(/\s+/).filter(word => word.length > 3);
    const textWords = text.split(/\s+/);

    let matchedWords = 0;
    let consecutiveMatches = 0;
    let maxConsecutive = 0;

    searchWords.forEach(word => {
      const normalizedWord = normalizeText(word);
      const found = textWords.some(textWord =>
        normalizeText(textWord).includes(normalizedWord)
      );

      if (found) {
        matchedWords++;
        consecutiveMatches++;
        maxConsecutive = Math.max(maxConsecutive, consecutiveMatches);
      } else {
        consecutiveMatches = 0;
      }
    });

    // Score based on percentage of matched words
    score += (matchedWords / searchWords.length) * 60;

    // Bonus for consecutive matches
    score += (maxConsecutive / searchWords.length) * 20;

    return score;
  };

  useEffect(() => {
    if (activeHighlight?.content && containerRef.current) {
      highlightText(activeHighlight.content);
    }
  }, [activeHighlight, renderedPages]);

  const handleZoomIn = () => {
    setScale(prevScale => Math.min(prevScale + 0.2, 3));
  };

  const handleZoomOut = () => {
    setScale(prevScale => Math.max(prevScale - 0.2, 0.5));
  };

  const handleNextPage = () => {
    if (numPages && currentPage < numPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const toggleViewMode = () => {
    setSinglePageView(prev => !prev);
  };

  // Check if we have any documents to display
  if (!documents || documents.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">No document available</p>
      </div>
    );
  }

  const activeDocument = filteredDoc || documents[0];

  // Verify that activeDocument has a valid URI
  if (!activeDocument?.uri) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">Invalid document source</p>
      </div>
    );
  }

  return (
    <div className="relative w-full h-[90%]">
      <div className="sticky top-0 z-10 bg-white border-b p-2 flex justify-between items-center">
        <div className="flex space-x-2">
          <button
            onClick={toggleViewMode}
            className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200"
          >
            {singlePageView ? 'All Pages' : 'Single Page'}
          </button>
          {singlePageView && (
            <div className="flex items-center space-x-2">
              <button
                onClick={handlePrevPage}
                disabled={currentPage <= 1}
                className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200 disabled:opacity-50"
              >
                ←
              </button>
              <span className="text-sm">
                Page {currentPage} of {numPages || '?'}
              </span>
              <button
                onClick={handleNextPage}
                disabled={!numPages || currentPage >= numPages}
                className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200 disabled:opacity-50"
              >
                →
              </button>
            </div>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleZoomOut}
            className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200"
          >
            -
          </button>
          <span className="text-sm">{Math.round(scale * 100)}%</span>
          <button
            onClick={handleZoomIn}
            className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200"
          >
            +
          </button>
        </div>
      </div>

      <div
        ref={containerRef}
        className="pdf-container overflow-auto"
        style={{
          height: 'calc(100% - 48px)',
          direction: pdfVerticalScrollByDefault ? 'ltr' : 'rtl'
        }}
      >
        <div style={{ direction: 'ltr' }}>
          <Document
            file={activeDocument.uri}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<div className="text-center py-4">Loading PDF...</div>}
            error={<div className="text-center py-4 text-red-500">Error loading PDF</div>}
          >
            {singlePageView ? (
              <Page
                pageNumber={currentPage}
                scale={scale}
                onLoadSuccess={() => onPageLoadSuccess(currentPage)}
                loading={<div className="text-center py-2">Loading page...</div>}
                className="mb-4"
              />
            ) : (
              Array.from(new Array(numPages), (_, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={scale}
                  onLoadSuccess={() => onPageLoadSuccess(index + 1)}
                  loading={<div className="text-center py-2">Loading page...</div>}
                  className="mb-4"
                />
              ))
            )}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;