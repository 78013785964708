import Footer from "../../components/Footer";
import "./Terms.css";

function Terms() {
  return (
    <>
      <div className="terms-wrapper">
        <div className="termsContent">
          <div className="termsContent-h1">Terms and Conditions</div>
          <p>
            These terms and conditions outline the rules and regulations for the use of AnswerThis.io's Website, located at AnswerThis.io.
          </p>

          <div className="termsContent-div">Intellectual Property Rights</div>
          <p>
            Under these Terms, AnswerThis.io and/or its licensors own all the intellectual property rights and materials contained in this Website except for the content explicitly generated by users. You are granted a license to use, for personal or non-commercial purposes, the content explicitly generated and provided by AnswerThis.io.
          </p>

          <div className="termsContent-div">Restrictions</div>
          <p>You are specifically restricted from all of the following, except as explicitly permitted by these Terms:</p>
          <ul>
            <li>Selling, sublicensing, and/or otherwise commercializing any Website material not owned by you.</li>
            <li>Publicly performing and/or showing any Website material not owned by you.</li>
            <li>Using this Website in any way that is, or may be, damaging to this Website.</li>
            <li>Using this Website in any way that impacts user access to this Website.</li>
          </ul>

          <div className="termsContent-div">Your Content</div>
          <p>
            "Your Content" shall mean any audio, video, text, images, or other material you choose to display on this Website. By displaying Your Content, you grant AnswerThis.io a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.
          </p>

          <div className="termsContent-div">No Warranties</div>
          <p>
            This Website and its content are provided "as is," with all faults, and AnswerThis.io expresses no representations or warranties of any kind related to this Website or the materials contained on this Website.
          </p>

          <div className="termsContent-div">Limitation of Liability</div>
          <p>
            AnswerThis.io, its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website or the content provided herein. You assume full responsibility for the consequences of your use of any content and agree to indemnify and hold harmless AnswerThis.io from any and all claims, liabilities, damages, losses, and expenses arising from such use.
          </p>

          <div className="termsContent-div">Amendments to Terms</div>
          <p>
            AnswerThis.io is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis to ensure you understand all terms and conditions governing the use of this Website.
          </p>

          <div className="termsContent-div">Entire Agreement</div>
          <p>
            These Terms constitute the entire agreement between AnswerThis.io and you in relation to your use of this Website and supersede all prior agreements and understandings.
          </p>

          <div className="termsContent-div">Governing Law & Jurisdiction</div>
          <p>
            These Terms will be governed by and interpreted in accordance with the laws of the State of Country, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Country for the resolution of any disputes.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Terms;
