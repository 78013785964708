import React from 'react';
import { Filters } from "../../authService";

interface AnswerThisFiltersComponentProps {
    answerFilters: Filters;
    setAnswerFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const AnswerThisFiltersComponent: React.FC<AnswerThisFiltersComponentProps> = ({
    answerFilters,
    setAnswerFilters,
}) => {
    const resetFilter = (filterKey: string) => {
        setAnswerFilters((prevFilters: Filters) => {
            const newFilters: Filters = { ...prevFilters };

            switch (filterKey) {
                case "minCitationCount":
                case "numberOfAbstracts":
                    newFilters[filterKey] = 0;
                    break;
                case "fieldsOfStudy":
                case "database":
                    newFilters[filterKey] = [];
                    break;
                case "startDate":
                case "endDate":
                    newFilters[filterKey] = "";
                    break;
                case "isFastMode":
                    newFilters[filterKey] = false;
                    break;
                default:
                    break;
            }



            return newFilters;
        });
    };

    const displayActiveFilters = () => {
        if (answerFilters) {
            return Object.entries(answerFilters)
                .flatMap(([key, value]) => {
                    if (
                        (Array.isArray(value) && value.length > 0) ||
                        (typeof value === "number" && value !== 0) ||
                        (typeof value === "string" && value !== "") ||
                        (typeof value === "boolean" && value === true)
                    ) {
                        let contents = [];
                        switch (key) {
                            case "minCitationCount":
                                contents.push(`Minimum numbers of citations: ${value}`);
                                break;
                            case "fieldsOfStudy":
                                contents.push(`Fields of study: ${(value as string[]).join(", ")}`);
                                break;
                            case "startDate":
                                contents.push(`Start date: ${value}`);
                                break;
                            case "endDate":
                                contents.push(`End date: ${value}`);
                                break;
                            case "database":
                                contents = (value as string[]).map(db => `Database: ${db}`);
                                break;
                            case "isFastMode":
                                if (value === true) {
                                    contents.push("Turbo Mode: On");
                                }
                                break;
                            default:
                                return null;
                        }

                        return contents.map((content, index) => (
                            <div
                                key={`${key}-${index}`}
                                className="flex items-center border border-[#A53E5A] max-w-[200px] px-2 py-0.5 text-sm rounded font-bold gap-4"
                            >
                                <p className="text-[#A53E5A] leading-5">{content}</p>
                                <span
                                    onClick={() => resetFilter(key)}
                                    className="text-[#A53E5A] font-sans w-6 flex justify-center cursor-pointer hover:opacity-50"
                                >
                                    &#10005;
                                </span>
                            </div>
                        ));
                    }
                    return null;
                })
                .filter(Boolean);
        }
    };

    return (
        <div className="flex flex-wrap gap-2 mt-2 items-start">
            {displayActiveFilters()}
        </div>
    );
};

export default AnswerThisFiltersComponent;