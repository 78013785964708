import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import SlicedText from '../slicedText';

interface ExpandableSectionProps {
    children: React.ReactNode;
    title: string;
    initiallyExpanded?: boolean;
    isExpandedForce?: boolean | null;
    onExpand?: () => void;
    titleClass?: string
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({ children, title, titleClass = '', isExpandedForce = null, initiallyExpanded = false, onExpand }) => {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

    useEffect(() => {
        if (typeof isExpandedForce === 'boolean') {
            setIsExpanded(isExpandedForce);

        }
    }, [isExpandedForce]);

    const handleToggle = () => {
        // Only toggle if isExpandedForce is not controlling the state

        const newExpandedState = !isExpanded;
        setIsExpanded(newExpandedState);
        if (newExpandedState && onExpand) {
            onExpand();
        }

    };

    return (
        <div className=" rounded-lg shadow-sm">
            <div
                className={titleClass != '' ? titleClass : "w-full flex items-center justify-between p-4 text-left font-semibold text-brandMaroon"}
                onClick={handleToggle}
            >
                <SlicedText text={title} />
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
            {isExpanded && <div>{children}</div>}
        </div >
    );
};

export default ExpandableSection;