import React, { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { handleRedirectMainPage, redirectToOriginalPage } from "../../authService";
import { toast, Toaster } from "sonner";
import GoogleLoginComponent from "../../components/GoogleLogin";
import { AuthLayout } from './authCommon';

const Login = () => {
  const [email, setEmail] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [pwValue, setPwValue] = useState({
    password: "",
    showPassword: false,
  });

  const handleLoginClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (email.length === 0 || pwValue.password.length === 0) {
      toast.error("Email or password can't be blank!");
      return;
    }
    try {
      await login(email, pwValue.password);
      toast.success("Log in successful");
      if (location.state && location.state.from) {
        await redirectToOriginalPage(navigate, location.state);
      } else {
        await handleRedirectMainPage(navigate);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Toaster closeButton expand={false} richColors position="top-center" />
      <AuthLayout 
        title="Welcome back!"
        subtitle="Sign in to use AnswerThis features"
      >
        <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Email</label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="border-gray-200 focus:border-brandMaroon focus:ring-brandMaroon"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Password</label>
            <div className="relative">
              <Input
                type={pwValue.showPassword ? "text" : "password"}
                value={pwValue.password}
                onChange={(e) => setPwValue({ ...pwValue, password: e.target.value })}
                placeholder="Enter password"
                className="border-gray-200 focus:border-brandMaroon focus:ring-brandMaroon pr-10"
              />
              <button
                type="button"
                onClick={() => setPwValue({ ...pwValue, showPassword: !pwValue.showPassword })}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                {pwValue.showPassword ?
                  <EyeOffIcon className="h-4 w-4" /> :
                  <EyeIcon className="h-4 w-4" />
                }
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between text-sm">
            <label className="flex items-center space-x-2">
              <input type="checkbox" className="rounded border-gray-300" />
              <span className="text-gray-600">Remember me</span>
            </label>
            <a href="/forgotPassword" className="text-brandMaroon hover:text-darkMaroon">
              Forgot password?
            </a>
          </div>

          <Button
            onClick={handleLoginClick}
            className="w-full bg-brandMaroon hover:bg-darkMaroon text-white transition-colors"
          >
            Sign In
          </Button>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>

          <GoogleLoginComponent />

          <p className="text-center text-gray-600 pt-4">
            New to AnswerThis?{' '}
            <a href="/signup" className="text-brandMaroon hover:text-darkMaroon">
              Create an account
            </a>
          </p>
        </form>
      </AuthLayout>
    </>
  );
};

export default Login;