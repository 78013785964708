// answerConversion.ts
import { AnswerObject, Filters, Section, SourceInfo } from '@/authService';
import { citationStyles, formatCitation, getInTextCitation } from '@/pages/Answer/citationUtils';
import { Editor } from '@tiptap/core';

function findSourceByChunkId(papersArraySorted: SourceInfo[], chunkId: string): SourceInfo | null {
    return papersArraySorted.find(source =>
        source.sections.some(section => section.id === chunkId)
    ) || null;
}

export async function convertAnswerToEditorFormat(
    answer: {
        formattedAnswer: string;
        answerObject: AnswerObject;
        papersArraySorted: SourceInfo[];
    },
    citationStyle?: citationStyles
): Promise<string> {

    // Create a temporary DOM element to parse the HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(answer.formattedAnswer, 'text/html');

    // Find all citation links
    const citations = doc.querySelectorAll('a[href^="#chunk_"]');
    citations.forEach((citation) => {
        const chunkId = citation.getAttribute('href')?.slice(1); // Remove the #
        if (chunkId) {
            const sourceInfo = findSourceByChunkId(answer.papersArraySorted, chunkId);
            if (sourceInfo) {
                // Find the specific section for this chunk
                const citedSection = sourceInfo.sections.find(section => section.id === chunkId);
                if (citedSection) {
                    // Create the new citation span
                    const citationSpan = doc.createElement('span');
                    citationSpan.className = 'citation inline-block bg-blue-100 px-2 py-0.5 rounded cursor-pointer hover:bg-blue-200';
                    citationSpan.setAttribute('data-citation', encodeURIComponent(JSON.stringify(sourceInfo)));
                    citationSpan.setAttribute('data-source-id', sourceInfo.id);
                    citationSpan.setAttribute('data-citation-number', citation.textContent?.replace(/[[\]]/g, '') || '1');
                    citationSpan.setAttribute('data-citation-style', citationStyle ? citationStyle : 'apa');
                    citationSpan.setAttribute('data-chunk-id', chunkId);
                    citationSpan.setAttribute('data-section-content', citedSection.content);
                    citationSpan.textContent = citation.textContent || '[1]';
                    // Replace the anchor with the span
                    citation.replaceWith(citationSpan);
                }
            }
        }
    });

    // Clean up the HTML
    return doc.body.innerHTML.replace(/\n\s*\n/g, '\n').trim();
}


function generateChunkId(sourceId: string, sectionContent: string): string {
    // Generate a deterministic ID based on the source and content
    const contentHash = btoa(encodeURIComponent(sectionContent + sourceId))
        .replace(/[^a-zA-Z0-9]/g, '')
        .slice(0, 8);
    return `chunk_${contentHash}`;
}
export function convertEditorToAnswer(
    editor: Editor,
    options: {
        query?: string;
        filters?: Filters;
    } = {}
): AnswerObject {
    const html = editor.getHTML();
    console.log('Raw editor HTML:', html);

    const sourcesMap = new Map<string, SourceInfo>();
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Track unique sections by their content
    const uniqueSections = new Map<string, Section>();

    // Track citations for each source
    const sourceSections = new Map<string, Set<string>>();

    // Find all citations and build source info
    const citations = doc.querySelectorAll('span.citation');
    console.log('Found citations:', citations.length);

    citations.forEach((citation, index) => {
        const citationData = citation.getAttribute('data-citation');
        if (citationData) {
            try {
                const sourceInfo = JSON.parse(decodeURIComponent(citationData)) as SourceInfo;
                const sourceId = sourceInfo.id;
                console.log(`Processing citation ${index + 1} for source ${sourceId}`);

                // Create or get sections set for this source
                if (!sourceSections.has(sourceId)) {
                    sourceSections.set(sourceId, new Set());
                }

                const sectionContent = citation.getAttribute('data-section-content') ||
                    sourceInfo.abstract ||
                    "";

                const contentKey = `${sourceId}:${sectionContent}`;

                let chunkId: string;
                if (uniqueSections.has(contentKey)) {
                    // Reuse existing chunk ID
                    chunkId = uniqueSections.get(contentKey)!.id;
                } else {
                    // Generate new chunk ID
                    chunkId = generateChunkId(sourceId, sectionContent);
                    uniqueSections.set(contentKey, {
                        id: chunkId,
                        content: sectionContent,
                        name: sourceInfo.title,
                        databaseId: sourceInfo.id
                    });
                    sourceSections.get(sourceId)!.add(chunkId);
                }

                // Store source info
                if (!sourcesMap.has(sourceId)) {
                    sourcesMap.set(sourceId, {
                        ...sourceInfo,
                        sections: [] // We'll fill this later
                    });
                }

                // Create the citation text node
                const citationText = `[${chunkId}]`;
                console.log('Adding citation:', citationText);

                // Replace citation span with text node
                const textNode = doc.createTextNode(citationText);
                citation.replaceWith(textNode);
            } catch (e) {
                console.error('Error processing citation:', e);
            }
        }
    });

    // Add unique sections to each source
    sourcesMap.forEach((sourceInfo, sourceId) => {
        const sections = Array.from(uniqueSections.entries())
            .filter(([key]) => key.startsWith(`${sourceId}:`))
            .map(([, section]) => section);
        for (const section of sections) {
            section.databaseId = sourceInfo.id;
        }
        sourceInfo.sections = sections;
    });

    // Process the HTML content carefully
    const processedHtml = doc.body.innerHTML;
    console.log('Initial processed HTML:', processedHtml);

    // Clean up the HTML while preserving the text and citations
    const cleanedText = processedHtml
        .replace(/&nbsp;/g, ' ')
        .replace(/<br\s*\/?>/g, '\n')
        .replace(/<\/p>\s*<p[^>]*>/g, '\n\n')
        .replace(/<p[^>]*>/g, '')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<(?!\/?(?:span|br|p))[^>]+>/g, '')
        .replace(/\n{3,}/g, '\n\n')
        .trim();

    console.log('Final cleaned text:', cleanedText);
    console.log('Sources map:', Object.fromEntries(sourcesMap));

    // Create the answer object
    const answerObject: AnswerObject = {
        sources: {
            result: Object.fromEntries(sourcesMap)
        },
        unused_sources: {
            result: {}
        },
        query: options.query || '',
        answer: cleanedText,
        filters: options.filters || {
            answerStyle: 'literature_review',
            database: ['your library'],
            doubleCheckCitations: false,
            endDate: new Date().toISOString().split('T')[0],
            fieldsOfStudy: [],
            isFastMode: true,
            journalQuality: ['Q1', 'Q2', 'Q3', 'Q4'],
            minCitationCount: 0,
            numberOfAbstracts: 0,
            researchPapersFilter: [],
            startDate: '1900-01-01'
        },
        getPapers: true
    };

    return answerObject;
}

export function convertCitationsToChunkIds(htmlContent: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const citations = doc.querySelectorAll('span.citation');

    citations.forEach((citation) => {
        const citationData = citation.getAttribute('data-citation');
        if (citationData) {
            try {
                const sourceInfo = JSON.parse(decodeURIComponent(citationData));
                const sourceId = sourceInfo.id;
                const sectionContent = sourceInfo.abstract || sourceInfo.title;

                // Generate the chunk ID based on source and section content
                const chunkId = generateChunkId(sourceId, sectionContent);

                // Replace the citation span with [chunk_id]
                const textNode = document.createTextNode(`[${chunkId}]`);
                citation.replaceWith(textNode);
            } catch (error) {
                console.error('Error converting citation to chunk ID:', error);
            }
        }
    });

    return doc.body.innerHTML.replace(/&nbsp;/g, ' ')
        .replace(/<br\s*\/?>/g, '\n')
        .replace(/<\/p>\s*<p[^>]*>/g, '\n\n')  // Handle paragraphs with any attributes
        .replace(/<p[^>]*>/g, '')  // Remove opening p tag with any attributes
        .replace(/<\/p>/g, '\n')
        .replace(/<div[^>]*>/g, '')  // Remove div with any attributes
        .replace(/<\/div>/g, '\n')
        .replace(/<[^>]+>/g, '')  // Remove all other HTML tags
        .replace(/\n{3,}/g, '\n\n')
        .trim();
}

export function getEditorAnswerText(editor: Editor): string {
    if (!editor) return '';

    const html = editor.getHTML();
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Track citations and sources in order of appearance
    const citationsMap = new Map<string, number>(); // sourceId -> citation number
    const citationOrder: string[] = []; // sourceIds in order of appearance
    const uniqueSources = new Map<string, SourceInfo>();
    let currentCitationNumber = 0; // Start from 1 instead of 0

    // Process citations
    const citations = doc.querySelectorAll('span.citation');
    citations.forEach((citation) => {
        const citationData = citation.getAttribute('data-citation');
        const citationStyle = citation.getAttribute('data-citation-style') as citationStyles || 'apa';

        if (citationData) {
            try {
                const sourceInfo = JSON.parse(decodeURIComponent(citationData));
                const sourceId = sourceInfo.id;

                // Assign citation number if not already assigned
                if (!citationsMap.has(sourceId)) {
                    citationsMap.set(sourceId, currentCitationNumber++);
                    citationOrder.push(sourceId);
                    uniqueSources.set(sourceId, sourceInfo);
                }

                const citationNumber = citationsMap.get(sourceId);
                // Format citation according to the current style and number
                const inlineCitation = getInTextCitation(sourceInfo, citationStyle, citationNumber!);
                const textNode = doc.createTextNode(inlineCitation);
                citation.replaceWith(textNode);
            } catch (e) {
                console.error('Error processing citation:', e);
            }
        }
    });

    // Clean up the HTML while preserving important formatting
    let cleanedText = doc.body.innerHTML
        .replace(/&nbsp;/g, ' ')
        .replace(/<br\s*\/?>/g, '\n')
        .replace(/<\/p>\s*<p[^>]*>/g, '\n\n')
        .replace(/<p[^>]*>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<div[^>]*>/g, '')
        .replace(/<\/div>/g, '\n')
        // Preserve bold, italic, and underline formatting
        .replace(/<(\/?)strong>/g, '*$1*')
        .replace(/<(\/?)b>/g, '*$1*')
        .replace(/<(\/?)em>/g, '_$1_')
        .replace(/<(\/?)i>/g, '_$1_')
        .replace(/<(\/?)u>/g, '__$1__')
        // Remove all other HTML tags
        .replace(/<(?!\/?(strong|b|em|i|u))[^>]+>/g, '')
        .replace(/\n{3,}/g, '\n\n')
        //replace any list elements with a new line
        .replace(/<ul[^>]*>/g, '\n')
        .replace(/<li[^>]*>/g, '\n- ')
        .replace(/<\/li>/g, '')
        .replace(/<\/ul>/g, '')
        .trim();

    // Add References section if there are citations
    if (uniqueSources.size > 0) {
        cleanedText += '\n\nReferences:\n';

        // Add references in order of first citation
        citationOrder.forEach((sourceId) => {
            const source = uniqueSources.get(sourceId);
            const citationStyle = doc.querySelector(`span.citation[data-source-id="${sourceId}"]`)?.getAttribute('data-citation-style') as citationStyles || 'apa';
            if (source) {
                const citationNumber = citationsMap.get(sourceId);
                const formattedReference = formatCitation(source, citationStyle, citationNumber!);
                cleanedText += `\n\n${formattedReference}`;
            }
        });
    }

    return cleanedText;
}
