import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Blog } from "../../authService";
import Spinner from "../Spinner";
import blogData from '../../blogData.json';
import logo from "/img/answerthis_mobile_logo.png"

export const fetchAllBlog = async (): Promise<Blog[]> => {
  return blogData;
};



interface CarouselProps { }

const Carousel: FC<CarouselProps> = () => {
  const [allBlogs, setAllBlogs] = useState<Blog[]>();

  useEffect(() => {
    const loadAllBlogs = async () => {
      const res = await fetchAllBlog();
      setAllBlogs(res);
    };
    loadAllBlogs();
  }, []);
  return (
    <>

      {allBlogs ? (
        <div className="bg-white py-10 sm:py-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">

            <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {allBlogs?.map((post) => (
                <article
                  key={post.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                >
                  <img src={post.coverImage} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                    <time dateTime={post.createdAt} className="mr-8">
                      {post.createdAt}
                    </time>
                    <div className="-ml-4 flex items-center gap-x-4">
                      <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="flex gap-x-2.5">
                        <img src={logo} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                        Ayush Garg
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <Link to={`/blog/${post.url}`}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </Link>
                  </h3>
                </article>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Carousel;


