import React, { useState } from 'react';
import { SourceInfo, openPdf, checkIfParamAvailable, Section, OpenPaperInfo, editPaperInProject, CustomButton } from '../../authService';
import { Trash2, Edit2, MessagesSquare, Library } from 'lucide-react';
import parse from 'html-react-parser';
import SlicedText from '../slicedText';
import { toast } from 'sonner';
import { Input } from "@/components/ui/input"
import CustomLabel from "@/components/CustomLabel"
import { Button } from "@/components/ui/button"
import GlobalCopyCitation from '@/contexts/citationContext';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"

interface PaperItemProps {
    paper: SourceInfo;
    isLibrary: boolean;
    handleChat: (id: string) => void;
    handleSave?: (id: string) => void;
    handleDelete: (id: string) => void;
    selectedSourceIds: string[];
    handleCheckboxChange: (id: string) => void;
    customColumns: string[];
    customColumnData: { [key: string]: { [key: string]: string } };
    loadingColumns: { [key: string]: boolean };
    markedSummary?: string;
    handleTitleClick?: (sourceId: string) => void;
    projectId: string;
    onPaperUpdate?: (updatedPaper: SourceInfo) => void;
    customButtons?: CustomButton[]; // New prop for custom buttons
    showCheckbox?: boolean;
    compactUi?: boolean;
}

const PaperItem: React.FC<PaperItemProps> = ({
    paper,
    isLibrary,
    handleChat,
    handleSave,
    handleDelete,
    selectedSourceIds,
    handleCheckboxChange,
    customColumns,
    customColumnData,
    loadingColumns,
    markedSummary,
    handleTitleClick,
    projectId,
    onPaperUpdate,
    customButtons = [], // Default to empty array
    showCheckbox = true,
    compactUi = false,
}) => {
    const { navigateToPaperInfo } = OpenPaperInfo();
    const [isEditing, setIsEditing] = useState(false);
    const [editedPaper, setEditedPaper] = useState<SourceInfo>(paper);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleTitleClickWrapper = (sourceId: string) => {
        if (handleTitleClick) {
            handleTitleClick(sourceId);
        } else {
            navigateToPaperInfo(sourceId);
        }
    };
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedPaper(prev => ({ ...prev, date_of_publication: e.target.value }));
    };
    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsEditing(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEditedPaper(prev => ({ ...prev, [name]: value }));
    };

    const handleSaveEdit = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            toast.loading("Updating paper...");
            // Only pass the file if it exists, otherwise it will be undefined
            await editPaperInProject(
                projectId,
                paper.id,
                editedPaper,
                selectedFile || undefined
            );
            toast.dismiss();
            toast.success("Paper updated successfully");
            window.location.reload();
            setIsEditing(false);
            if (onPaperUpdate) {
                onPaperUpdate(editedPaper);
            }
            toast.success("Paper updated successfully");
            setSelectedFile(null); // Reset file selection
        } catch (error) {
            console.error("Error updating paper:", error);
            toast.error("Failed to update paper");
        }
    };

    const handleCancelEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsEditing(false);
        setEditedPaper(paper);
    };
    const renderCustomButtons = () => (
        <>
            {customButtons.map((button, index) => (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <button
                            key={`${button.name}-${index}`}
                            className="hover:bg-white font-medium hover:text-brandMaroon border-brandMaroon border-2 py-1 px-4 rounded-md bg-brandMaroon text-white transition duration-300 flex items-center"
                            onClick={() => button.onClick(paper.id)}
                        >
                            {(!compactUi || !button.icon) ? button.name : button.icon}
                        </button>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-sm">
                        <p className="text-sm">{button.name}</p>
                    </TooltipContent>
                </Tooltip>
            ))}
        </>
    );
    const renderEditableField = (fieldName: keyof SourceInfo, label: string) => {
        if (fieldName === 'sections') {
            // Handle sections separately
            return (
                <div className="mb-4">
                    <CustomLabel htmlFor={fieldName} className="text-sm font-medium text-gray-700">{label}</CustomLabel>
                    <textarea
                        id={fieldName}
                        name={fieldName}
                        value={JSON.stringify(editedPaper[fieldName], null, 2)}
                        onChange={(e) => {
                            try {
                                const parsedSections = JSON.parse(e.target.value);
                                setEditedPaper(prev => ({ ...prev, [fieldName]: parsedSections }));
                            } catch (error) {
                                console.error("Invalid JSON for sections");
                            }
                        }}
                        className="mt-1 block w-full h-32 resize-y"
                    />
                </div>
            );
        }
        if (fieldName === 'date_of_publication') {
            return (
                <div className="mb-4">
                    <CustomLabel htmlFor={fieldName} className="text-sm font-medium text-gray-700">{label}</CustomLabel>
                    <Input
                        type="date"
                        id={fieldName}
                        name={fieldName}
                        value={editedPaper[fieldName] || ''}
                        onChange={handleDateChange}
                        className="mt-1 block w-full"
                    />
                </div>
            );
        }
        if (fieldName === 'pdf_path') {
            return (
                <div className="mb-4">
                    <CustomLabel htmlFor="pdf-upload" className="text-sm font-medium text-gray-700">
                        Upload PDF
                    </CustomLabel>
                    <Input
                        type="file"
                        id="pdf-upload"
                        accept=".pdf"
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                setSelectedFile(e.target.files[0]);
                            }
                        }}
                        className="mt-1 block w-full"
                    />
                </div>
            );
        }

        return (
            <div className="mb-4">
                <CustomLabel htmlFor={fieldName} className="text-sm font-medium text-gray-700">{label}</CustomLabel>
                <Input
                    type="text"
                    id={fieldName}
                    name={fieldName}
                    value={editedPaper[fieldName]?.toString() || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full"
                />
            </div>
        );
    };

    return (
        <div className='break-all'>
            <div className="w-full mx-auto border-t-[1.5px] border-black"></div>
            <div className={`duration-300 border-black hover:bg-lightMaroon py-4 px-4 paper-section`}>
                <div className="flex hover:cursor-pointer">
                    {showCheckbox && <div className="max-w-max">
                        <input
                            type="checkbox"
                            checked={selectedSourceIds.includes(paper.id)}
                            onChange={() => handleCheckboxChange(paper.id)}
                            className="cursor-pointer w-[15px] h-[15px] mr-2"
                        />
                    </div>}

                    <div className="flex justify-between w-full" onClick={() => !isEditing && handleTitleClickWrapper(paper.id!)}>
                        {isEditing ? (
                            <div className="w-full bg-white p-4 rounded-lg shadow">
                                {renderEditableField('title', 'Title')}
                                {renderEditableField('author', 'Authors')}
                                {renderEditableField('journal', 'Journal')}
                                {renderEditableField('date_of_publication', 'Publication Date')}

                                {renderEditableField('doi', 'DOI')}
                                {renderEditableField('citations', 'Citations')}
                                {renderEditableField('pdf_path', 'PDF Upload')}

                                <div className="mt-4 flex justify-end space-x-2">
                                    <Button onClick={handleSaveEdit} className="bg-green-500 text-white hover:bg-green-600">
                                        Save
                                    </Button>
                                    <Button onClick={handleCancelEdit} variant="outline">
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <span className={`flex w-[95%] ${compactUi ? 'font-normal text-[15px]' : 'font-medium text-[18px]'}`}>
                                    {!compactUi &&
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg>
                                    }
                                    <div className="w-[90%]">{paper.title}</div>
                                </span>
                                <div className="flex">
                                    {onPaperUpdate && (
                                        <button
                                            onClick={handleEditClick}
                                            className="text-blue-500 hover:text-blue-700 mr-2"
                                        >
                                            <Edit2 size={20} />
                                        </button>
                                    )}
                                    {!handleSave &&
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(paper.id);
                                            }}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <Trash2 size={20} />
                                        </button>}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!isEditing && (
                    <div className="transition-max-height flex flex-col gap-2 ml-6">
                        <div className={`flex text-sm ${!compactUi ? 'mt-3' : 'mt-0'}`}>
                            {!compactUi &&
                                <div className="w-[10%] md:w-[5%]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.7" stroke="currentColor" className="w-5 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                    </svg>
                                </div>
                            }
                            {paper.author && (
                                <div className="w-[90%] md:w-[95%]">
                                    <SlicedText text={paper.author} maxLength={400} />
                                </div>
                            )}
                        </div>
                        {paper.journal && paper.journal != 'None' && paper.journal != '' && (
                            <div className="flex text-sm">
                                {!compactUi &&
                                    <div className="w-[10%] md:w-[5%]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.7" stroke="currentColor" className="w-5 mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 3h9a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 1v22" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7h5M8 11h5" />
                                        </svg>
                                    </div>
                                }
                                <div className="w-[90%] md:w-[95%]">
                                    {paper.journal}
                                </div>
                            </div>
                        )}

                        {!compactUi && ((paper.sections && paper.sections.length > 0) ? paper.sections.map((detail: Section, detailIndex: number) => (
                            <p key={detailIndex} id={detail.id} className="extracted-source text-sm">
                                <mark>
                                    <br />
                                    <SlicedText text={detail.content} maxLength={400} />
                                </mark>
                            </p>
                        )) : (paper.abstract && <SlicedText text={paper.abstract} maxLength={400} />))}

                        {markedSummary && (
                            <div className="mt-3 text-sm">
                                <h4 className="font-semibold mb-2">Insight Related to Question:</h4>
                                {parse(markedSummary) as string}

                            </div>
                        )}

                        {!compactUi && (
                            <div className="flex gap-6 mt-4 lg:mt-2 flex-wrap md:flex-row lg:gap-10">
                                {checkIfParamAvailable(paper.pdf_path) ? (
                                    <div className="flex items-center hover:cursor-pointer" onClick={() => {
                                        openPdf(paper);
                                    }}>
                                        <div className="h-4 w-4 bg-green-500 rounded-full border-black border mr-2"></div>
                                        PDF Available
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <div className="h-4 w-4 bg-red-500 rounded-full border-black border mr-2"></div>
                                        PDF not Available
                                    </div>
                                )}
                                {paper.whole_content && paper.whole_content !== "None" && paper.whole_content !== "" && (
                                    <div className="flex items-center">
                                        <div className="h-4 w-4 bg-blue-500 rounded-full border-black border mr-2"></div>
                                        Precise Search Activated
                                    </div>
                                )}
                                {
                                    paper.doi && paper.doi != "" && paper.doi != "None" && paper.doi != "0" && (
                                        <span className='text-blue-500 break-all'>
                                            DOI: {paper.doi.startsWith('http') ? <a className='text-blue-500 break-words' href={paper.doi} target="_blank" rel="noreferrer">{paper.doi}</a> : <a className='text-blue-500 break-words' href={"https://doi.org/" + paper.doi} target="_blank" rel="noreferrer">{"https://doi.org/" + paper.doi}</a>}
                                        </span>
                                    )
                                }
                                {paper.citations > 0 && (
                                    <span>
                                        Number of citations: {paper.citations}
                                    </span>
                                )}
                                {paper.date_of_publication && paper.date_of_publication != 'None' && paper.date_of_publication != '' &&
                                    <span>
                                        Date Of Publication: {paper.date_of_publication}
                                    </span>
                                }
                            </div>
                        )}

                        {!compactUi && customColumns.length > 0 && (
                            <div className="mt-4">
                                <h4 className="font-semibold mb-2">Custom Information:</h4>
                                {customColumns.map((columnName) => (
                                    <div key={columnName} className="mb-2">
                                        <span className="font-medium">{columnName}: </span>
                                        {loadingColumns[columnName] ? (
                                            <span className="text-gray-500">Loading...</span>
                                        ) : (
                                            <span>{customColumnData[columnName]?.[paper.id] || 'N/A'}</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        <GlobalCopyCitation source={paper} />
                        <div className={`flex ${!compactUi ? 'md:justify-end' : 'md:justify-start'} mt-2 gap-4 items-center flex-wrap md:flex-nowrap justify-start`}>
                            <TooltipProvider delayDuration={300}>
                                {paper.pdf_path == null || paper.pdf_path == "" || paper.pdf_path == "None" ? <></> : (
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <button
                                                className="hover:bg-white font-medium hover:text-brandMaroon border-brandMaroon border-2 py-1 px-4 rounded-md bg-brandMaroon text-white transition duration-300 flex items-center"
                                                onClick={() => {
                                                    handleCheckboxChange(paper.id);
                                                    handleChat(paper.id);
                                                }}
                                            >
                                                {!compactUi ? 'Chat with paper' : (
                                                    <MessagesSquare size={14} />
                                                )}
                                            </button>
                                        </TooltipTrigger>
                                        <TooltipContent className="max-w-sm">
                                            <p className="text-sm">{'Chat with paper'}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                )}
                                {!isLibrary && handleSave && (
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <button
                                                className="hover:bg-white font-medium hover:text-brandMaroon border-brandMaroon border-2 py-1 px-4 rounded-md bg-brandMaroon text-white transition duration-300 flex items-center"
                                                onClick={() =>
                                                    handleSave(paper.id)
                                                }
                                            >
                                                {!compactUi ? 'Save to library' : (
                                                    <Library size={14} />
                                                )}
                                            </button>
                                        </TooltipTrigger>
                                        <TooltipContent className="max-w-sm">
                                            <p className="text-sm">{'Save to library'}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                )}
                                {customButtons.length > 0 && renderCustomButtons()}
                            </TooltipProvider>
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
};

export default PaperItem;

