import React, { useState } from 'react';
import { Search, Check, FileCode2, } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { Card } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DIAGRAM_CATEGORIES, DIAGRAM_EXAMPLES, DIAGRAM_TYPES } from './DIAGRAM_CATEGORIES';

interface DiagramTypeSelectorProps {
  selectedType: string;
  onTypeChange: (type: string) => void;
  onCustomTypeChange: (type: string) => void;
}


export const DiagramTypeSelector: React.FC<DiagramTypeSelectorProps> = ({
  selectedType,
  onTypeChange,
  onCustomTypeChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('All');
  const [isCustomType, setIsCustomType] = useState(false);
  const [failedImages, setFailedImages] = useState<Set<string>>(new Set());

  const filteredTypes = Object.entries(DIAGRAM_TYPES).filter(([_, value]) =>
    value.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    value.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleImageError = (type: string) => {
    setFailedImages(prev => new Set(prev).add(type));
  };


  return (
    <div className="space-y-4">

      {/* show current diagram type */}
      <div className="flex items-center space-x-4">
        <div className="relative flex-1">
          <span className="text-sm text-muted-foreground">Current Diagram Type: </span>
          <span className="text-sm font-bold">{selectedType}</span>
        </div>
      </div>
      <div className="flex gap-2">
        <Button
          variant={isCustomType ? "outline" : "secondary"}
          size="sm"
          onClick={() => {
            setIsCustomType(false);
            onCustomTypeChange('');
          }}
          className="flex-1"
        >
          Predefined Types
        </Button>
        <Button
          variant={isCustomType ? "secondary" : "outline"}
          size="sm"
          onClick={() => {
            setIsCustomType(true);
            onCustomTypeChange('');
          }}
          className="flex-1 relative"
          disabled
        >
          Graphs
          <Badge variant="destructive" className="absolute -top-2 -right-2 text-xs">
            coming soon
          </Badge>
        </Button>
      </div>

      {!isCustomType && (
        <>
          <div className="flex items-center space-x-4">
            <div className="relative flex-1">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search diagram types..."
                className="pl-8"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <Tabs value={activeCategory} onValueChange={setActiveCategory}>
            <TabsList className="w-full justify-start overflow-x-auto">
              {Object.keys(DIAGRAM_CATEGORIES).map((category) => (
                <TabsTrigger
                  key={category}
                  value={category}
                  className="relative"
                >
                  {category}
                  {(DIAGRAM_CATEGORIES as Record<string, string[]>)[category].includes(selectedType) && (
                    <div className="absolute -right-1 -top-1">
                      <div className="bg-primary text-primary-foreground rounded-full p-0.5">
                        <Check className="h-3 w-3" />
                      </div>
                    </div>
                  )}
                </TabsTrigger>
              ))}
            </TabsList>

            {Object.entries(DIAGRAM_CATEGORIES).map(([category, types]) => (
              <TabsContent key={category} value={category}>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                  {types.map((type) => {
                    const diagramType = DIAGRAM_TYPES[type];
                    const example = (DIAGRAM_EXAMPLES as Record<string, { code: string }>)[type];
                    if (!diagramType || !example) return null;
                    if (searchTerm && !filteredTypes.some(([key]) => key === type)) return null;

                    return (
                      <Card
                        key={type}
                        className={cn(
                          "relative p-4 cursor-pointer hover:border-primary transition-all",
                          selectedType === type ? "ring-2 ring-primary border-primary bg-secondary/20" : "hover:shadow-md"
                        )}
                        onClick={() => onTypeChange(type)}
                      >
                        {selectedType === type && (
                          <div className="absolute -right-1 -top-1">
                            <div className="bg-primary text-primary-foreground rounded-full p-1">
                              <Check className="h-4 w-4" />
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col h-full">
                          <div className="mb-1">
                            <h3 className="text-sm font-medium">{diagramType.title}</h3>
                            <p className="text-xs text-muted-foreground">
                              {diagramType.description}
                            </p>
                          </div>
                          <div className="relative aspect-video mb-3 bg-muted rounded-md overflow-hidden">
                            {!failedImages.has(type) ? (
                              <img
                                src={`/diagrams/${type}-preview.svg`}
                                alt={`${diagramType.title} example`}
                                className="object-contain w-full h-full p-2"
                                onError={() => handleImageError(type)}
                              />
                            ) : (
                              <div className="absolute inset-0 flex flex-col items-center justify-center p-2 text-muted-foreground">
                                <FileCode2 className="h-8 w-8 mb-1" />
                                <pre className="text-xs text-center overflow-hidden">
                                  {example.code.split('\n').slice(0, 4).join('\n')}
                                </pre>
                              </div>
                            )}
                          </div>
                          <div className="mt-auto">
                            <div className="flex flex-wrap gap-2">
                              {type === selectedType && (
                                <span className="inline-flex items-center text-xs bg-primary/10 text-primary px-2 rounded-full">
                                  Selected
                                </span>
                              )}

                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </TabsContent>
            ))}
          </Tabs></>
      )}
    </div>
  );
};
