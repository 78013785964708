import React, { useState } from 'react';
import { X, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { createCheckoutSession } from '../../authService';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'sonner';
import ExitIntentModal from './ExitIntentModal';

interface FloatingPricingPopupProps {
    isOpen: boolean;
    onClose: () => void;
    projectId: string;
}

const FloatingPricingPopup: React.FC<FloatingPricingPopupProps> = ({ isOpen, onClose, projectId }) => {
    const navigate = useNavigate();
    const [billPlan, setBillPlan] = useState<'monthly' | 'annually'>('annually');
    const [showExitModal, setShowExitModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { loggedIn } = useAuth();

    if (!isOpen) return null;

    const handleSeeMore = () => {
        navigate(`/${projectId}/pricing`);
        onClose();
    };

    const handleCloseAttempt = () => {
        setShowExitModal(true);
    };

    const handleConfirmClose = () => {
        setShowExitModal(false);
        onClose();
    };

    const handleUpgradeNow = async () => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }

        setIsLoading(true);
        try {
            const result = await createCheckoutSession(
                billPlan === 'monthly' ? 'monthly' : 'annually'
            );

            if (result.url) {
                window.location.href = result.url;
            } else {
                toast.error("Failed to create checkout session");
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
            toast.error("An error occurred while processing your request");
        } finally {
            setIsLoading(false);
        }
    };

    const prices = {
        annually: {
            regular: 35,
            discounted: 12,
            savePercentage: 65,
        },
        monthly: {
            regular: 35,
        }
    };

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[60]">
                <div className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 overflow-hidden font-Manrope">

                    <div className="relative p-6">
                        <button
                            onClick={handleCloseAttempt}
                            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                            aria-label="Close"
                        >
                            <X size={20} />
                        </button>

                        <h2 className="text-2xl font-bold mb-2">Upgrade to Unlimited</h2>
                        <p className="text-gray-600 mb-6">Make your research faster and easier</p>



                        <div className="space-y-3 mb-8">
                            {[
                                'Unlimited references',
                                'Unlimited literature reviews',
                                'Unlimited AI Generations',
                                'Unlimited Library Storage',

                            ].map((feature, index) => (
                                <div key={index} className="flex items-start">
                                    <div className="h-5 w-5 rounded-full bg-brandMaroon flex items-center justify-center mt-0.5 flex-shrink-0">
                                        <Check size={12} className="text-white" />
                                    </div>
                                    <span className="ml-3 text-gray-700 text-sm">{feature}</span>
                                </div>
                            ))}
                        </div>

                        {/* Pricing options with radio buttons */}
                        <div className="space-y-3 mb-6 w-full">
                            <label className={`flex items-center border rounded-lg p-3 cursor-pointer transition-colors w-full ${billPlan === 'annually' ? 'border-brandMaroon bg-rose-50' : ''}`}>
                                <input
                                    type="radio"
                                    name="pricing-plan"
                                    className="h-4 w-4 text-brandMaroon"
                                    checked={billPlan === 'annually'}
                                    onChange={() => setBillPlan('annually')}
                                />
                                <div className="ml-3 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <span className="font-medium">Annual</span>
                                        <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded">Save {prices.annually.savePercentage}%</span>
                                    </div>
                                    <div className="flex items-baseline">
                                        <span className="text-xl font-bold">${prices.annually.discounted}</span>
                                        <span className="text-gray-500 text-sm">/mo</span>
                                    </div>
                                </div>
                            </label>

                            <label className={`flex items-center border rounded-lg p-3 cursor-pointer transition-colors w-full ${billPlan === 'monthly' ? 'border-brandMaroon bg-rose-50' : ''}`}>
                                <input
                                    type="radio"
                                    name="pricing-plan"
                                    className="h-4 w-4 text-brandMaroon"
                                    checked={billPlan === 'monthly'}
                                    onChange={() => setBillPlan('monthly')}
                                />
                                <div className="ml-3 flex-grow">
                                    <div className="flex justify-between items-center">
                                        <span className="font-medium">Monthly</span>
                                    </div>
                                    <div className="flex items-baseline">
                                        <span className="text-xl font-bold">${prices.monthly.regular}</span>
                                        <span className="text-gray-500 text-sm">/mo</span>
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div className="mb-4 text-center text-sm text-gray-600">
                            No strings attached, cancel anytime
                        </div>

                        <button
                            onClick={handleUpgradeNow}
                            className="w-full bg-brandMaroon text-white py-3 rounded-lg font-medium hover:bg-opacity-90 transition-colors"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Processing...' : 'Upgrade Now'}
                        </button>

                        <div className="mt-4 text-center">
                            <button
                                onClick={handleSeeMore}
                                className="text-brandMaroon hover:underline text-sm font-medium"
                            >
                                See more details
                            </button>
                        </div>

                        <div className="flex items-center justify-center mt-6 space-x-1 text-sm text-gray-500">
                            <div className="flex -space-x-1">
                                <img src="https://randomuser.me/api/portraits/women/17.jpg" className="w-6 h-6 rounded-full border-2 border-white" alt="Researcher" />
                                <img src="https://randomuser.me/api/portraits/men/4.jpg" className="w-6 h-6 rounded-full border-2 border-white" alt="Researcher" />
                                <img src="https://randomuser.me/api/portraits/women/3.jpg" className="w-6 h-6 rounded-full border-2 border-white" alt="Researcher" />
                            </div>
                            <span>Join 75,000+ researchers revolutionizing their work</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Exit Intent Modal */}
            <ExitIntentModal
                isOpen={showExitModal}
                onClose={handleConfirmClose}
                onUpgrade={handleUpgradeNow}
            />
        </>
    );
};

export default FloatingPricingPopup; 