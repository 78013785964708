import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postDataChargebee, SubscriptionDetail, ServerResponse, handleRedirectMainPage } from '../../authService';
import { toast } from 'sonner';
import SeoComp from '../../components/SeoComp';

const PricingRedirect: React.FC = () => {
    const location = useLocation();
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetail | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const subscriptionId = queryParams.get('sub_id');
        const invoiceId = queryParams.get('invoice_id');

        const handleSuccess = (data: ServerResponse) => {
            setSubscriptionDetails(data.subscription);
            toast.success('Plan Activated');
            handleRedirectMainPage(navigate);
        };

        const handleError = (error: string) => {
            toast.error(error);
        };

        if (subscriptionId && invoiceId) {
            postDataChargebee(handleSuccess, handleError, subscriptionId, invoiceId);
        }
    }, [location.search]);

    if (!subscriptionDetails) return <div className="mx-auto spinner"></div>;

    return (
        <div>
            <SeoComp />
            <section className="py-24 relative">
                <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
                    <h2 className="font-Manrope font-bold text-4xl leading-10 text-black text-center">
                        Payment Successful
                    </h2>
                    <p className="mt-4 font-normal text-lg leading-8 text-gray-500 mb-11 text-center">
                        Thanks for upgrading to AnswerThis Pro
                    </p>

                </div>
            </section>
        </div>
    );
};

export default PricingRedirect;
