import { Paper } from './types';
import PaperItemWithOrigin from './PaperItemWithOrigin';

interface OriginPapersProps {
    handleCheckboxChange: (paperId: string) => void;
    handleRemoveOrigin: (paperId: string) => void;
    handleSavePaper: (paperId: string) => Promise<void>;
    isAddingPaper: { [key: string]: boolean };
    originPapers: Paper[];
    projectId: string;
    selectedPapersToSave: Set<string>;
}

const OriginPapers = ({
    handleCheckboxChange,
    handleRemoveOrigin,
    handleSavePaper,
    isAddingPaper,
    originPapers,
    projectId,
    selectedPapersToSave
}: OriginPapersProps) => {
    return (
        <div className="p-4 space-y-4">
            <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Origin Papers</h3>
                <div className="text-sm text-gray-500">
                    {originPapers.length} {originPapers.length === 1 ? 'paper' : 'papers'}
                </div>
            </div>

            {originPapers.length === 0 ? (
                <div className="text-center py-8 text-gray-500">
                    <p>No origin papers yet.</p>
                    <p className="text-sm mt-2">Search for papers and click the target icon to add them as origins.</p>
                </div>
            ) : (
                <div className="space-y-4">
                    {originPapers.map(paper => (
                        <PaperItemWithOrigin
                            key={paper.paperId}
                            paper={paper}
                            isOriginPaper={true}
                            onRemoveOrigin={handleRemoveOrigin}
                            handleCheckboxChange={handleCheckboxChange}
                            handleSavePaper={handleSavePaper}
                            isAddingPaper={isAddingPaper}
                            selectedPapersToSave={selectedPapersToSave}
                            projectId={projectId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default OriginPapers;