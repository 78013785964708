export const buttonClass = " rounded-lg py-1 px-3 flex font-medium items-center       text-brandMaroon bg-white border border-brandMaroon hover:bg-gray-50 ";
export const buttonClassBig = "border-[1.5px] border-black bg-brandMaroon py-2 px-4 rounded-lg font-medium text-white flex items-center justify-center min-w-[120px] h-10";
export const buttonClassWhiteBig = `
  text-brandMaroon
  rounded-lg
  py-2
  px-3
  flex
  hover:text-white
  hover:bg-brandMaroon
  font-medium
  items-center
  relative
  before:content-['']
  before:absolute
  before:inset-[1.5px]
  before:border-[1.5px]
  before:border-brandMaroon
  before:rounded-[6px]
  hover:before:border-white
`;