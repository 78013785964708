// api.ts

import axios from 'axios';
import { Paper } from './types';

export const serverUrl = import.meta.env.PROD
  ? (import.meta.env.VITE_SERVER_URL_PROD as string)
  : (import.meta.env.VITE_SERVER_URL_DEV as string);

const handleResponse = async (response: any) => {
  if (response.status !== 200) {
    const error = response.data?.error || 'API request failed';
    throw new Error(error);
  }
  return response.data;
};

export const fetchPaperDetails = async (paperId: string): Promise<Paper> => {
  try {
    const response = await axios.post(`${serverUrl}/paper/details`, { paperId }, {
      withCredentials: true
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching paper details:', error);
    throw new Error('Failed to fetch paper details');
  }
};

export const fetchPaperCitations = async (paperId: string): Promise<Paper[]> => {
  try {
    const response = await axios.post(`${serverUrl}/citations`, {
      paperId,
      fields: 'title,abstract,year,venue,authors,citationCount,references,citations'
    }, {
      withCredentials: true
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching paper citations:', error);
    throw new Error('Failed to fetch paper citations');
  }
};

export const fetchPaperReferences = async (paperId: string): Promise<Paper[]> => {
  try {
    const response = await axios.post(`${serverUrl}/references`, {
      paperId,
      fields: 'title,abstract,year,venue,authors,citationCount,references,citations'
    }, {
      withCredentials: true
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching paper references:', error);
    throw new Error('Failed to fetch paper references');
  }
};

export const fetchPaperNetwork = async (paperId: string): Promise<{
  citations: Paper[];
  references: Paper[];
  secondLevelCitations: Paper[];
  secondLevelReferences: Paper[];
}> => {
  try {
    const response = await axios.post(`${serverUrl}/paper/network`, {
      paperId,
      fields: 'title,abstract,year,venue,authors,citationCount,references,citations'
    }, {
      withCredentials: true
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching paper network:', error);
    throw new Error('Failed to fetch paper network');
  }
};

export const searchPapers = async (query: string): Promise<Paper[]> => {
  try {
    const response = await axios.post(`${serverUrl}/search`, {
      query,
      fields: 'title,abstract,year,venue,authors,citationCount,references,citations'
    }, {
      withCredentials: true
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error searching papers:', error);
    throw new Error('Failed to search papers');
  }
};

export const fetchPaperByDoi = async (doi: string): Promise<Paper> => {
  try {
    const response = await axios.post(`${serverUrl}/paper/doi`, {
      doi,
      fields: 'title,abstract,year,venue,authors,citationCount,references,citations'
    }, {
      withCredentials: true
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching paper by DOI:', error);
    throw new Error('Failed to fetch paper by DOI');
  }
};