import React, { useState } from "react";
import LoadingSkeleton from "../PrePageLoad/LoadingSkeleton";
import {
    PageParamsProvider,
    PlasmicComponent,
    ComponentRenderData
} from '@plasmicapp/loader-react';

import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { PLASMIC } from "@/components/plasmicLoader";
import NotFound from "../PrePageLoad/NotFound";
export const CatchAllPage: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState<ComponentRenderData | null>(null);
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        async function load() {
            const pageData = await PLASMIC.maybeFetchComponentData(location.pathname);
            setPageData(pageData);
            setLoading(false);
        }
        load();
    }, [location.pathname]);

    if (loading) {
        return <LoadingSkeleton />;
    }

    if (!pageData) {
        return <NotFound />;
    }

    return (
        <PageParamsProvider
            route={location.pathname}
            query={Object.fromEntries(searchParams.entries())}
        >
            <PlasmicComponent component={location.pathname} />
        </PageParamsProvider>
    );
};