import React from 'react';
import { citationStyles } from './citationUtils';

interface CitationStyleSelectorProps {
    currentStyle: citationStyles;
    onStyleChange: (style: citationStyles) => void;
}

const CitationStyleSelector: React.FC<CitationStyleSelectorProps> = ({ currentStyle, onStyleChange }) => {
    const handleStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onStyleChange(e.target.value as citationStyles);
    };

    return (
        <div className="border border-gray" >

            <select
                id="citationStyle"
                value={currentStyle}
                onChange={handleStyleChange}
                className="block w-full py-0 px-3 text-s bg-white rounded-md shadow-sm "
            >
                <option value="apa">APA</option>
                <option value="mla">MLA</option>
                <option value="chicago">Chicago</option>
                <option value="harvard">Harvard</option>
            </select>
        </div>
    );
};

export default CitationStyleSelector;