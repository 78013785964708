import React, { useState } from 'react';
import { toast } from 'sonner';
import { getCustomColumnData } from '../../authService';
import { CreateColumnComponent } from './tableColumnBar';
import { useProcessing } from '@/contexts/processingContext';
interface CustomColumnsManagerProps {
    sourceIds: string[];
    onColumnsUpdate: (columns: string[], data: { [key: string]: { [key: string]: string } }, loadingColumns: { [key: string]: boolean }) => void;
}

export const CustomColumnsManager: React.FC<CustomColumnsManagerProps> = ({
    sourceIds,
    onColumnsUpdate,
}) => {
    // We only need the state setters, as we're using functional updates
    const [, setCustomColumns] = useState<string[]>([]);
    const [, setCustomColumnData] = useState<{ [key: string]: { [key: string]: string } }>({});
    const [, setLoadingColumns] = useState<{ [key: string]: boolean }>({});
    const { addTask, removeTask } = useProcessing();

    const handleAddColumn = async (columnName: string): Promise<void> => {
        // Update local state and notify parent of the new column
        setCustomColumns(prevColumns => {
            const updatedColumns = [...prevColumns, columnName];

            // Update loading state in a separate state update to ensure consistency
            setLoadingColumns(prevLoadingState => {
                const updatedLoadingState = { ...prevLoadingState, [columnName]: true };

                // Get current data to preserve existing columns' data
                setCustomColumnData(prevData => {
                    // Notify parent with the updated states while preserving existing data
                    onColumnsUpdate(
                        updatedColumns,
                        prevData, // Preserve existing data
                        updatedLoadingState
                    );
                    return prevData; // No change to data at this point
                });

                return updatedLoadingState;
            });

            return updatedColumns;
        });

        try {
            const taskId = addTask(`Fetching data for column: ${columnName}`);
            const response = await getCustomColumnData(sourceIds, columnName);

            // Preserve existing data while adding new column data
            const columnResults = Object.fromEntries(
                Object.entries(response).map(([paperId, data]) => [paperId, data.answer])
            );

            removeTask(taskId);

            // Update data and loading states using functional updates to get latest state
            setCustomColumnData(prevData => {
                const newColumnData = {
                    ...prevData,
                    [columnName]: columnResults
                };

                // Update loading state in a separate state update
                setLoadingColumns(prevLoadingState => {
                    const finalLoadingState = { ...prevLoadingState, [columnName]: false };

                    // Get the latest columns state to ensure consistency
                    setCustomColumns(latestColumns => {
                        // Notify parent with all the latest states
                        onColumnsUpdate(latestColumns, newColumnData, finalLoadingState);
                        return latestColumns; // No change to columns
                    });

                    return finalLoadingState;
                });

                return newColumnData;
            });

        } catch (error) {
            console.error("Error fetching custom column data:", error);
            toast.error("Failed to fetch data for the new column");

            // Update loading state using functional update to get latest state
            setLoadingColumns(prevLoadingState => {
                const finalLoadingState = { ...prevLoadingState, [columnName]: false };

                // Get the latest columns and data states
                setCustomColumns(latestColumns => {
                    setCustomColumnData(latestData => {
                        // Notify parent with all the latest states
                        onColumnsUpdate(latestColumns, latestData, finalLoadingState);
                        return latestData; // No change to data
                    });
                    return latestColumns; // No change to columns
                });

                return finalLoadingState;
            });
        }
    };

    return (
        <CreateColumnComponent onAddColumn={handleAddColumn} />
    );
};

export const useCustomColumns = () => {
    const [customColumns, setCustomColumns] = useState<string[]>([]);
    const [customColumnData, setCustomColumnData] = useState<{ [key: string]: { [key: string]: string } }>({});
    const [loadingColumns, setLoadingColumns] = useState<{ [key: string]: boolean }>({});

    const updateColumnsAndData = (
        columns: string[],
        data: { [key: string]: { [key: string]: string } },
        loading: { [key: string]: boolean }
    ) => {
        setCustomColumns(columns);
        setCustomColumnData(data);
        setLoadingColumns(loading);
    };

    return {
        customColumns,
        customColumnData,
        loadingColumns,
        updateColumnsAndData,
    };
};

