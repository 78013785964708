import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { SourceInfo } from '../../authService';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { citationStyles } from './citationUtils';
import GlobalCopyCitation, { GlobalCitationStyleControl } from '@/contexts/citationContext';

interface CitationComponentProps {
    sources: SourceInfo[];
    onCitationOrderChange: (newOrder: SourceInfo[]) => void;
    currentStyle: citationStyles;
}

const CitationComponent: React.FC<CitationComponentProps> = React.memo(({
    sources,
    onCitationOrderChange,
    currentStyle
}) => {
    const [citationOrder, setCitationOrder] = useState<SourceInfo[]>(sources);
    const [draggedItem, setDraggedItem] = useState<number | null>(null);
    useEffect(() => {
        setCitationOrder(sources);
    }, [sources]);

    const handleDragStart = useCallback((e: React.DragEvent<HTMLLIElement>, index: number) => {
        e.dataTransfer.setData('text/plain', index.toString());
        setDraggedItem(index);
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLLIElement>) => {
        e.preventDefault();
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';
        e.currentTarget.style.transform = 'scale(1.02)';
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLLIElement>) => {
        e.currentTarget.style.boxShadow = 'none';
        e.currentTarget.style.transform = 'scale(1)';
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLLIElement>, targetIndex: number) => {
        e.preventDefault();
        e.currentTarget.style.boxShadow = 'none';
        e.currentTarget.style.transform = 'scale(1)';
        const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
        const newOrder = [...citationOrder];
        const [removed] = newOrder.splice(sourceIndex, 1);
        newOrder.splice(targetIndex, 0, removed);
        setCitationOrder(newOrder);
        onCitationOrderChange(newOrder);
        setDraggedItem(null);
    }, [citationOrder, onCitationOrderChange]);

    const handleDragEnd = useCallback((e: React.DragEvent<HTMLLIElement>) => {
        e.currentTarget.style.boxShadow = 'none';
        e.currentTarget.style.transform = 'scale(1)';
        setDraggedItem(null);
    }, []);



    const moveItem = useCallback((fromIndex: number, toIndex: number) => {
        const newOrder = [...citationOrder];
        const [removed] = newOrder.splice(fromIndex, 1);
        newOrder.splice(toIndex, 0, removed);
        setCitationOrder(newOrder);
        onCitationOrderChange(newOrder);
    }, [citationOrder, onCitationOrderChange]);

    const handleMoveUp = useCallback((index: number) => {
        if (index > 0) {
            moveItem(index, index - 1);
        }
    }, [moveItem]);

    const handleMoveDown = useCallback((index: number) => {
        if (index < citationOrder.length - 1) {
            moveItem(index, index + 1);
        }
    }, [moveItem, citationOrder]);



    const citationList = useMemo(() => (
        <ul className="space-y-2">
            {citationOrder.map((source, index) => (
                <li
                    key={source.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(e, index)}
                    onDragEnd={handleDragEnd}
                    className={`bg-white p-3 rounded-md shadow-sm cursor-move hover:bg-gray-50 transition-all duration-150
                                ${draggedItem === index ? 'opacity-50' : 'opacity-100'}
                                ${draggedItem !== null && draggedItem !== index ? 'border-t-2 border-blue-500' : ''}`}
                >
                    <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                        <div className="flex-grow">
                            <GlobalCopyCitation source={source} prefix={`[${index + 1}] `} />
                        </div>
                        {/* Citation Move Buttons */}
                        <div className="flex items-center justify-end">
                            <div className="flex sm:flex-col">
                                <button
                                    onClick={() => handleMoveUp(index)}
                                    disabled={index === 0}
                                    className="p-1 text-gray-500 hover:text-gray-700 disabled:opacity-50"
                                    aria-label="Move citation up"
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    onClick={() => handleMoveDown(index)}
                                    disabled={index === citationOrder.length - 1}
                                    className="p-1 text-gray-500 hover:text-gray-700 disabled:opacity-50"
                                    aria-label="Move citation down"
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    ), [citationOrder, currentStyle, handleDragStart, handleDragOver, handleDragLeave, handleDrop, handleDragEnd, draggedItem, handleMoveUp, handleMoveDown]);

    return (
        <div className="citation-component p-2 bg-gray-100 rounded-lg shadow-md">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4 bg-white p-3 rounded-md shadow-sm gap-2">
                <h3 className="text-lg font-semibold">Change Citation Style</h3>
                <GlobalCitationStyleControl />
            </div>

            {citationList}
        </div>
    );
});

export default CitationComponent;