import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createProject, deleteProject, editProject, getAllProjectsForUser, UserProject, getProjectQueries, RecentSearch } from "../../authService";
import { sortAndFilterProjects, getActiveProject } from "../../utils/projectUtils";
import { CreateProjectPopup } from "../../components/Project/CreateProjectPopup";
import SeoComp from "../../components/SeoComp";
import { Toaster, toast } from 'sonner';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useContentHeight } from "../../utils/tabsHeightCalculator";

export interface FormData {
  title: string;
  domain: string;
  projectId: string;
  otherDomain: string;
}


interface ProjectsProps {
  showOnlyList?: boolean;
}
const Projects = ({ showOnlyList = false }: ProjectsProps) => {
  const { id } = useParams<{ id: string }>();
  const [projects, setProjects] = useState<UserProject[]>([]);
  const [activeProject, setActiveProject] = useState<UserProject | null>(null);
  const [projectQueries, setProjectQueries] = useState<RecentSearch[]>([]);
  const [formData, setFormData] = useState<FormData>({ title: "", domain: "", projectId: "", otherDomain: "" });
  const [open, setOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [contentHeight] = useContentHeight();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [showProjectList, setShowProjectList] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchProjects = useCallback(async () => {
    try {
      const projectsData = await getAllProjectsForUser();
      const sortedProjects = sortAndFilterProjects(projectsData, id || null);
      setProjects(sortedProjects);
      const currentProject = getActiveProject(projectsData, id || null) || sortedProjects[0];
      if (currentProject) {
        setActiveProject(currentProject);
        fetchQueries(currentProject.id);
      }
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      toast.error("Failed to load projects. Please try again.");
    }
  }, [id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const fetchQueries = async (projectId: string) => {
    try {
      const queries = await getProjectQueries(projectId);
      setProjectQueries(queries);
    } catch (error) {
      console.error("Failed to fetch queries:", error);
      toast.error("Failed to load queries. Please try again.");
    }
  };

  const handleProjectClick = (project: UserProject) => {
    setActiveProject(project);
    fetchQueries(project.id);
    if (isMobile) {
      setShowProjectList(false);
    }
    if(showOnlyList){
      navigate(`/${project.id}/ask-answerthis`);
    }else{
      navigate(`/${project.id}/ask-answerthis?tab=project`);

    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleEdit = (project: UserProject) => {
    setEditMode(true);
    setFormData({
      title: project.project_name,
      domain: project.subject,
      projectId: project.id,
      otherDomain: ""
    });
    setOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const domain = formData.domain === "others" ? formData.otherDomain : formData.domain;

    try {
      if (editMode) {
        await editProject(formData.projectId, formData.title, domain);
        toast.success("Project updated successfully!");
      } else {
        const newProject = await createProject(formData.title, domain);
        toast.success("Project created successfully!");
        handleProjectClick(newProject.project);
      }

      await fetchProjects();
      setOpen(false);
      setFormData({ title: "", domain: "", projectId: "", otherDomain: "" });
      setEditMode(false);
    } catch (error) {
      console.error("Error updating/creating project:", error);
      toast.error("Failed to update/create project. Please try again.");
    }
  };

  const handleDelete = async (projectId: string) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await deleteProject(projectId);
        await fetchProjects();
        toast.success("Project deleted successfully!");
      } catch (error) {
        console.error("Error deleting project:", error);
        toast.error("Failed to delete project. Please try again.");
      }
    }
  };

  return (
    <>
      <SeoComp />
      <div className={`overflow-hidden bg-gray-100 flex flex-col`} style={{ height: contentHeight, overflow: 'hidden' }}>
        <Toaster />
        {!showOnlyList && (
        <div className="flex justify-between items-center p-4 bg-white shadow-sm">
          <h1 className="text-2xl md:text-3xl font-bold text-brandMaroon">Projects</h1>
          <Button
            onClick={() => { setEditMode(false); setOpen(true); }}
            className="bg-brandMaroon text-white hover:bg-darkMaroon"
          >
            Add New Project
          </Button>
        </div>)}

        <div className={`flex flex-grow overflow-hidden p-4 ${isMobile ? 'flex-col' : ''}`}>
          {(!isMobile || showProjectList) && (
            <Card className={`${isMobile ? 'w-full mb-4' : 'w-1/3 mr-4'} shadow-lg overflow-hidden flex flex-col`}>
              <CardContent className="p-4 flex-grow overflow-hidden">
                <ScrollArea className={`${isMobile ? 'h-[calc(50vh-120px)]' : 'h-[calc(100vh-240px)]'} pr-4`}>
                  {projects.map((project) => (
                    <div
                      key={project.id}
                      className={`p-4 mb-3 rounded-lg cursor-pointer transition-all ${activeProject?.id === project.id
                        ? 'bg-brandMaroon text-white shadow-md'
                        : 'bg-white hover:bg-gray-50 shadow'
                        }`}
                      onClick={() => handleProjectClick(project)}
                    >
                      <h3 className="font-semibold text-lg">{project.project_name}</h3>
                      <p className="text-sm mt-1">{project.subject}</p>
                      <p className="text-xs mt-2 opacity-75">{new Date(project.created_at).toLocaleDateString()}</p>
                    </div>
                  ))}
                </ScrollArea>
              </CardContent>
            </Card>
          )}

          {(!isMobile || !showProjectList) && (
            <Card className={`${isMobile ? 'w-full' : 'w-2/3'} shadow-lg overflow-hidden flex flex-col`}>
              <CardContent className="p-6 flex-grow overflow-hidden">
                {activeProject ? (
                  <div className="h-full flex flex-col overflow-hidden">
                    {isMobile && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setShowProjectList(true)}
                        className="mb-4"
                      >
                        Back to Projects
                      </Button>
                    )}
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-brandMaroon">{activeProject.project_name}</h2>
                        <div>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleEdit(activeProject)}
                            className="mr-2"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => handleDelete(activeProject.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                      <p className="text-lg"><strong>Domain:</strong> {activeProject.subject}</p>
                      <p className="text-sm text-gray-600"><strong>Created:</strong> {new Date(activeProject.created_at).toLocaleDateString()}</p>
                    </div>
                    <h3 className="font-semibold text-xl mb-4">Recent Queries</h3>
                    <ScrollArea className={`flex-grow pr-4 ${isMobile ? 'h-[calc(50vh-200px)]' : 'h-[calc(100vh-400px)]'}`}>
                      {projectQueries.length > 0 ? (
                        projectQueries.map((query, index) => (
                          <div
                            key={index}
                            className="mb-3 p-4 bg-white rounded-lg shadow cursor-pointer hover:bg-gray-50"
                            onClick={() => navigate(`/${activeProject.id}/answerthis/answer/${query.query_hash}`)}
                          >
                            <p className="font-medium text-brandMaroon">{query.query_text}</p>
                            <p className="text-sm text-gray-500 mt-2">{new Date(query.query_madeAt).toLocaleString()}</p>
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-500 italic">No queries for this project yet.</p>
                      )}
                    </ScrollArea>
                  </div>
                ) : (
                  <p className="text-center text-gray-500 italic">Select a project to view details</p>
                )}
              </CardContent>
            </Card>
          )}
        </div>

        {open && (
          <CreateProjectPopup
            editMode={editMode}
            formData={formData}
            handleChange={handleChange}
            handleClick={() => setOpen(false)}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
};

export default Projects;
