import { toast } from "sonner";
import "./FileUploadBar.css";
import cloudIcon from "/img/cloud-upload-icon.svg";
import deleteIcon from "/img/delete-icon.svg";
import documentIcon from "/img/document-icon-filled.svg";
import { useState, useRef } from "react";
import closeIcon from "/img/delete-icon.svg";
import { useAuth } from "../contexts/AuthContext";

interface FileUploadBarProps {
  onFilesChange: (files: File[]) => void;
  files?: File[];
  show: boolean;
  reverseShow: () => void;
}

function FileUploadBar({ onFilesChange, show, reverseShow, files = [] }: FileUploadBarProps) {
  const { isPro } = useAuth();
  let MAX_FILE_SIZE = 15 * 1024 * 1024;
  let maxFileSize = "15MB";

  if (isPro) {
    MAX_FILE_SIZE = 120 * 1024 * 1024;
    maxFileSize = "100MB";
  }


  const [dragCounter, setDragCounter] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(files);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const updateFiles = (newFiles: File[]) => {
    setUploadedFiles(newFiles);
    onFilesChange(newFiles);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev + 1);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev - 1);
  };

  const onDropHandler = (
    e: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setDragCounter(0);

    let files: File[] = [];

    if ("dataTransfer" in e) {
      files = Array.from(e.dataTransfer.files);
    } else {
      if (e.currentTarget.files) {
        files = Array.from(e.currentTarget.files);
      }
    }

    //Filter files based on type of file
    const validFileTypes = [
      "application/pdf"
    ];

    const typeValidFiles = files.filter((file) =>
      validFileTypes.includes(file.type)
    );

    if (typeValidFiles.length === 0) {
      toast.error("Please upload PDF files only.")
      return;
    }

    //Filter files based on individual file size limit
    const sizeFilteredFiles = typeValidFiles.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        toast.error(`File ${file.name} is too large. Maximum size is ${maxFileSize}`)
        return false;
      }
      return true;
    });

    // Check if the total size of selected files exceeds the limit
    const totalSize = sizeFilteredFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    if (totalSize > MAX_FILE_SIZE) {
      toast.error(`The total size of files exceeds the maximum limit. ${maxFileSize}`);
      return;
    }

    updateFiles([...uploadedFiles, ...sizeFilteredFiles]);
    console.log("Valid files:", sizeFilteredFiles);
  };

  function formatBytesToKB(bytes: number) {
    return (bytes / 1024).toFixed(2) + " kB"; // Converts bytes to kB and keeps two decimal places
  }

  const handleDeleteFile = (fileIndex: number) => {
    const newFiles = uploadedFiles.filter((_, index) => index !== fileIndex);
    updateFiles(newFiles);
  };


  const closeFileUploadBar = () => {
    reverseShow();
  }

  return (
    <>
      {show && <>
        <div className="file-upload-bar z-20">
          <div className="file-upload">
            <div
              className={`drop-zone ${dragCounter > 0 ? "file-dragged-over" : ""
                }`}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDropHandler}
            >
              <div className="drop-message">
                <img
                  src={cloudIcon}
                  alt="Cloud Upload Icon"
                  className="cloud-icon"
                />
                <p>Drag and drop your files here</p>
                <p>OR</p>
                <button onClick={() => fileInputRef.current?.click()}>
                  Browse files
                </button>
              </div>
            </div>
            <input
              ref={fileInputRef}
              id="fileInput"
              type="file"
              multiple
              className="hidden-input"
              accept=".pdf"
              onChange={onDropHandler}
            />
            {isPro ? <p>Maximum file size: 100MB</p> : <p>Maximum file size: 15MB</p>}
            {!isPro && <p>Upgrade to Pro to upload files up to 100MB</p>}
          </div>

          <div className="file-list">
            <div className="file-items">
              {uploadedFiles.map((file, index) => (
                <div key={file.name + index} className="file-item">
                  <img src={documentIcon} className="document-icon" alt="Document Icon" />
                  <span className="file-name">
                    {file.name} <br />
                    <span className="file-size">
                      - {formatBytesToKB(file.size)}
                    </span>
                  </span>
                  <img
                    className="delete-icon"
                    src={deleteIcon}
                    alt="Delete Icon"
                    onClick={() => handleDeleteFile(index)}
                  />
                </div>
              ))}
            </div>
          </div>

        </div>  <img
          src={closeIcon}
          alt="close menu"
          className="close-upload-menu-icon z-30 cursor-pointer"
          onClick={closeFileUploadBar}
        /></>}
    </>
  );
}

export default FileUploadBar;
