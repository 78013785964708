import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { activateSubscription } from '../../authService';
import { toast } from 'sonner';

const SuccessPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const hostedPageId = queryParams.get('id');
        const processor = queryParams.get('processor');
        if (hostedPageId && processor) {
            activateSubscription(hostedPageId, processor)
                .then(() => {
                    setIsLoading(false);
                    toast.success("Subscription activated successfully!");
                    // You might want to update user context here
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error.message);
                    toast.error(error.message);
                });
        } else {
            setIsLoading(false);
            setError("Missing hosted page information");
        }
    }, [location]);

    if (isLoading) {
        return <div className="h-screen flex justify-center items-center">
            Activating your subscription...</div>;
    }

    if (error) {
        return <div className="h-screen flex justify-center items-center">
            Error: {error}</div>;
    }

    return (
        <div className="h-screen flex justify-center items-center">
            <h1>Thank you for your subscription!</h1>
            <p>Your account has been successfully upgraded.</p>
            <button onClick={() => navigate('/')}>Go to Dashboard</button>
        </div>
    );
};

export default SuccessPage;