import React from 'react';
// import { useState } from "react";
// import { addToNewsletter } from "../authService";
// import { toast } from "sonner";
const AboutUs: React.FC = () => {
    // const [email, setEmail] = useState("");
    // const [isSubmitted, setIsSubmitted] = useState(false);
    // const [submissionStatus, setSubmissionStatus] = useState("");

    // const handleSubmit = async (event: React.FormEvent) => {
    //     event.preventDefault();
    //     let success;
    //     try {
    //         success = await addToNewsletter(email);
    //         setIsSubmitted(true);
    //         toast.success("Thank you for subscribing to our newsletter");

    //     } catch (error) {
    //         toast.error("Failed to add to newsletter. Please try again.")
    //         console.error("Error submitting form:", error);
    //     }
    //     setSubmissionStatus(
    //         success ? success.message : "Failed to add to newsletter. Please try again."
    //     );
    // };

    return (
        <div className="about-us">
            <div className="about-us-inner">
                <p className="largeText">AI research assistant</p>
                <p className="text-lg">
                    Our AI-driven platform
                    doesn&#39;t just find papers, it understands them. AnswerThis
                    analyzes your questions and provides a wholistic, research-backed
                    explanation along with direct citations and summary for every
                    paper used in your answer.
                </p>
                {/* <form onSubmit={handleSubmit} className="sign-up-form">
                    <input
                        type="email"
                        name="email"
                        id="emailInput"
                        placeholder="your email"
                        className="email-input"
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isSubmitted}
                    />
                    <input
                        type="submit"
                        value="Join our newsletter!"
                        className="join-button"
                        disabled={isSubmitted}
                    />
                </form>
                {isSubmitted && (
                    <>
                        <br /> <p className="submission-status">{submissionStatus}</p>
                    </>
                )} */}
            </div>
            <div className="about-us-pricing about-us-pricing-bottom">
                <div className="highlight"><span className="largeText">30</span> <span className="description">Save upto 30% of your valuable time using AnswerThis</span></div>
                <div className="highlight"><span className="largeText">250</span> <span className="description">Search across 250+ million research papers</span></div>
            </div>
        </div>
    );
};

export default AboutUs;