import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CustomButton, SourceInfo, deletePaperFromLibrary, enablePreciseSearch, fetchLibraryPapers, uploadPapersToProject } from "../../authService";
import PaperList from "../../components/PaperList/PaperList";
import { toast } from "sonner";
import SeoComp from "../../components/SeoComp";
import LibraryImporter from "../../components/ImportLibrary/LibraryImporter";
import { buttonClassWhiteBig } from "../../components/Buttons/buttonClasses";
import { Info, Search, Trash } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { useProcessing } from "@/contexts/processingContext";
import Projects from "../Projects/Projects";
import { useAuth } from "../../contexts/AuthContext";
const PRECISE_SEARCH_TOOLTIP = `
When enabled, precise search allows AnswerThis to search through the entire content of your papers, 
not just their metadata (title, abstract, etc.). This provides more accurate results when:
- Using the paper as a source for questions
- Searching within your library
- Generating summaries

Papers must have PDFs available to enable precise search.
`;
function YourLibrary() {
  const [selectedSourceIds, setSelectedSourceIds] = useState<string[]>([]);
  const { id } = useParams();
  const [libPapers, setLibPapers] = useState<SourceInfo[]>([]);
  const [restart, setRestart] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { addTask, removeTask } = useProcessing();
  const { checkCredits, removeCredits, addCredits } = useAuth();
  useEffect(() => {
    const fetchPapers = async () => {
      try {
        setIsLoading(true);
        const papers = await fetchLibraryPapers(id!);
        setLibPapers(papers.reverse());
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPapers();
  }, [id, restart]);

  const handleCheckboxChange = (sourceId: string) => {
    if (sourceId) {
      setSelectedSourceIds((prevSelected: string[]) => {
        const isAlreadySelected = prevSelected.includes(sourceId);
        if (isAlreadySelected) {
          return prevSelected.filter((id) => id !== sourceId);
        } else {
          return [...prevSelected, sourceId];
        }
      });
    }
  };

  const handleDeletePapers = async () => {
    const taskId = addTask("Deleting papers");
    if (selectedSourceIds.length > 0) {
      const loadingToastId = toast.loading("Deleting papers...");
      const updatedPapers = libPapers.filter((paper) => !selectedSourceIds.includes(paper.id));
      setLibPapers(updatedPapers);
      setSelectedSourceIds([]);
      for (const idPaper of selectedSourceIds) {
        await deletePaperFromLibrary(idPaper, id!);
        await addCredits(1);
      }
      removeTask(taskId);
      toast.dismiss(loadingToastId);
      toast.success("Papers deleted successfully");
    } else {
      toast.error("Select papers to delete");
    }
  }
  // Create a base function that works for any list of paper IDs
  const enablePreciseSearchForPapers = async (paperIds: string[], papers: SourceInfo[], projectId: string) => {
    // Filter to only include papers that have PDFs but no whole content
    const eligiblePapers = paperIds.filter(id => {
      const paper = papers.find(p => p.id === id);
      return paper &&
        paper.pdf_path &&
        paper.pdf_path !== "None" &&
        paper.pdf_path !== "" &&
        (!paper.whole_content || paper.whole_content === "None" || paper.whole_content === "");
    });

    if (eligiblePapers.length === 0) {
      toast.error("Selected papers are not eligible for precise search. Papers must have PDFs available and not already have precise search enabled. You can upload pdfs now using the edit icon for a paper.");
      return;
    }

    const loadingToastId = toast.loading("Enabling precise search...");

    try {
      await enablePreciseSearch(
        eligiblePapers,
        projectId,
        (message) => {
          toast.dismiss(loadingToastId);
          toast.success(message);
          setRestart(prev => !prev); // Refresh the library
        },
        (error) => {
          toast.dismiss(loadingToastId);
          toast.error(error);
        }
      );
    } catch (error) {
      toast.dismiss(loadingToastId);
      toast.error("Failed to enable precise search");
    }
  };

  // Function for single paper
  const handleEnablePreciseSearchForPaper = (paperId: string) => {
    enablePreciseSearchForPapers([paperId], libPapers, id!);
  };

  // Original multiple papers function, now using the base function
  const handleEnablePreciseSearch = () => {
    if (selectedSourceIds.length === 0) {
      toast.error("Please select papers to enable precise search for");
      return;
    }

    enablePreciseSearchForPapers(selectedSourceIds, libPapers, id!);
  };
  const customButtons: CustomButton[] = [{
    name: "Enable Precise Search",
    onClick: handleEnablePreciseSearchForPaper
  }];
  const handleUploadClick = async () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
    fileInput.onchange = async (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (!target.files) return;

      const hasEnoughCredits = checkCredits(id!, target.files.length);
      if (!hasEnoughCredits) {
        return;
      }

      setIsUploading(true);
      const taskIdPapers = addTask("Uploading papers");
      try {
        const response = await uploadPapersToProject(
          id!,
          target.files,
          (message) => toast.success(message),
          (error) => toast.error(error),
          (id) => navigate(id),
          setIsUploading
        );
        if (response.paperIds.length > 0) {
          await removeCredits(target.files.length);
          setRestart(prev => !prev);
        }
      } catch (error) {
        removeTask(taskIdPapers);
        console.error("Error uploading papers:", error);
      } finally {
        removeTask(taskIdPapers);
        setIsUploading(false);
        target.value = "";
      }
    };
  };

  const handlePaperUpdate = (updatedPaper: SourceInfo) => {
    setLibPapers(prevPapers =>
      prevPapers.map(paper =>
        paper.id === updatedPaper.id ? updatedPaper : paper
      )
    );
  };

  return (
    <>
      <SeoComp />

      <div className="mt-[5px] md:mt-0 w-full">
        <div>
          <div className="flex ">
            <div className=" font-Manrope font-medium text-[20px] md:text-[26px] mt-4 ml-4">
              Your library
            </div>
            <div>
              <button
                className="duration-300 text-white bg-brandMaroon rounded-lg py-2 px-4 hover:text-white hover:bg-black font-medium disabled:bg-lightMaroon  mt-4 ml-4 "
                onClick={handleUploadClick}
                disabled={selectedSourceIds.length > 0}
              >
                {isUploading ? "Uploading..." : "Upload New papers"}
                <input
                  type="file"
                  multiple
                  id="fileInput"
                  className="hidden"
                  accept=".docx, .pdf, .txt"
                />
              </button>
            </div>
            <div className="mt-4 ml-4 flex gap-2 flex-col sm:flex-row">
              <LibraryImporter projectId={id!} />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      className={buttonClassWhiteBig}
                      onClick={handleEnablePreciseSearch}
                    >
                      <Search className="w-4 h-4 mr-2" />
                      Enable Precise Search
                      <Info className="w-4 h-4 ml-2 text-gray-500" />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-sm">
                    <p className="text-sm">
                      {PRECISE_SEARCH_TOOLTIP}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <button
                className={buttonClassWhiteBig}
                onClick={handleDeletePapers}
              >  <Trash className="w-4 h-4 mr-2" />Delete Papers   </button>
            </div>
          </div>
          <PaperList
            papers={libPapers}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
            selectedSourceIds={selectedSourceIds}
            emptyMessage="No papers found in your library. If you've created multiple projects, please check the other projects. Upload some papers, or search and add papers to get started with chatting and extracting data from papers."
            id={id!}
            onPaperUpdate={handlePaperUpdate}
            customButtonsPaperItem={customButtons}
          />
          {libPapers.length === 0 && (<Projects showOnlyList={true} />)}

        </div>
      </div>
    </>
  );
}

export default YourLibrary;
