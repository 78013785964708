import { useState } from 'react';
import { handleSaveToLibrary } from '../../authService';
import { toast } from 'sonner';
import { useLibrary } from '@/contexts/LibraryContext';

export const useAddToLibrary = (projectId: string) => {
    const [selectedSourceIds, setSelectedSourceIds] = useState<string[]>([]);
    const { refreshLibrary } = useLibrary();

    const handleCheckboxChange = (sourceId: string) => {
        setSelectedSourceIds(prev =>
            prev.includes(sourceId)
                ? prev.filter(id => id !== sourceId)
                : [...prev, sourceId]
        );
    };

    const addToLibrary = async (givenId: string | null = null) => {
        const idsToAdd = givenId ? [givenId] : selectedSourceIds;

        if (idsToAdd.length === 0) {
            toast.error("Please select at least one source to add to your library.");
            return;
        }

        for (const id of idsToAdd) {
            await handleSaveToLibrary(
                id,
                projectId,
                (response) => { toast.success(response); },
                (error) => { toast.error(error); }
            );
        }

        // Refresh library after adding papers
        await refreshLibrary(projectId);
        setSelectedSourceIds([]);
    };

    return { selectedSourceIds, handleCheckboxChange, addToLibrary };
};