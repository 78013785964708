import PricingCards from "../../components/pricing/PricingCards";
import Faq from '../../components/pricing/Faq';
import ComparePlans from '../../components/pricing/ComparePlans';
import logo from "/img/logo.svg";

import TrustedPartners from "../../components/TrustedPartners";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

interface NewPricingProps {
    addInMobileMargin?: boolean;
}

const NewPricing = ({ addInMobileMargin }: NewPricingProps) => {
    const { id } = useParams();

    return (
        <>
            <Helmet>
                <title>Pricing | Unleash the full power of AI using AnswerThis</title>
                <meta name="description" content="The best AI research assistant. Get AnswerThis Pro to access whole suite of game changing features to make research easier than ever before." />
                <meta property="og:title" content="Pricing | Unleash the full power of AI using AnswerThis" />
                <meta property="og:description" content="The best AI research assistant. Get AnswerThis Pro to access whole suite of game changing features to make research easier than ever before." />
                <meta name="keywords" content="AI research assistant, AI tools, Optimize research" />
                <meta name="publisher" content="Ayush Garg" />
                <meta property="og:image" content={logo} />
                <meta name="twitter:title" content="Pricing | Unleash the full power of AI using AnswerThis" />
                <meta name="twitter:description" content="The best AI research assistant. Get AnswerThis Pro to access whole suite of game changing features to make research easier than ever before." />
                <meta name="twitter:image" content={logo} />
            </Helmet>
            <div className={`text-gray-900 bg-white ${addInMobileMargin ? 'mt-[50px] md:mt-0' : ''}`}>
                <main className="mb-16">
                    <PricingCards showOnlyCards={false} showGradient={true} />

                    <div className="pb-2 md:pb-14 mt-10 md:mt-16">
                        <TrustedPartners />
                    </div>
                    <div className="mt-10 md:mt-16">

                        <ComparePlans />
                    </div>
                    <div className="mt-10 md:mt-16">

                        <Faq id={id} />
                    </div>
                </main>
            </div>
        </>
    );
};

export default NewPricing;
