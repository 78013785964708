import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios'; // Import Axios
import "./Login.css";
import { serverUrl } from "../../authService";
import { toast, Toaster } from "sonner";
import SeoComp from "../../components/SeoComp";

const ResetPassword: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();


    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();

        // Validate the passwords
        if (password !== confirmPassword) {
            toast.error("Passwords do not match!")
            // alert("Passwords do not match!");
            return;
        }


        try {
            // Send a POST request to reset the password
            const response = await axios.post(`${serverUrl}/reset_password`, {
                token,
                password
            });
            toast.error(response.data.message); // Display success message
            navigate('/login');
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toast.error(
                    error.response?.data?.error ||
                    "An error occurred during the forgot password request"
                );
                console.log(error.response?.data?.error || error.message);
            } else {
                toast.error("An unexpected error occurred");
                console.log(error);
            }
        }
    };

    return (
        <>
            <SeoComp />
            <Toaster closeButton expand={false} richColors position="top-center" />

            <div className="h-screen flex justify-center items-center">
                <div className="sign-in-body">
                    <div className="sign-up-head">Reset Password</div>
                    <form onSubmit={handleResetPassword} className="login-form">
                        <input
                            className="text-input"
                            type="password"
                            name="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input
                            className="text-input"
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <br />
                        <input type="submit" value="Reset Password" className="submit-forgot-password-button" />
                    </form>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
