import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface Reference {
    id: string;
    number: string;
    text: string;
}

interface CollapsibleReferencesProps {
    answer: string;
}

const CollapsibleReferences: React.FC<CollapsibleReferencesProps> = ({ answer }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Function to extract references from the answer
    const extractReferences = (text: string): Reference[] => {
        const referenceSection = text.split('References:')[1];
        if (!referenceSection) return [];

        const referenceLines = referenceSection.split('<br>');
        return referenceLines
            .map(line => {
                const match = line.match(/<a href="#([^"]+)">(\[\d+\])<\/a>\s*(.*)/);
                if (match) {
                    return {
                        id: match[1],
                        number: match[2],
                        text: match[3].trim()
                    };
                }
                return null;
            })
            .filter((ref): ref is Reference => ref !== null);
    };

    const references = extractReferences(answer);

    if (references.length === 0) {
        return null;
    }

    return (
        <div className="mt-4 border rounded-md">
            <button
                className="flex items-center justify-between w-full p-4 text-left"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="font-semibold">References model added</span>
                {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {isOpen && (
                <div className="p-4 bg-gray-50">
                    <ul className="list-none">
                        {references.map((ref) => (
                            <li key={ref.id} className="mb-2">
                                <a href={`#${ref.id}`} className="mr-2 text-blue-600">{ref.number}</a>
                                {ref.text}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CollapsibleReferences;