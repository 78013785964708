import React, { useState, useEffect, useRef } from 'react';
import { createCheckoutSession, checkIsLoggedIn } from "../../authService";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useAuth } from "../../contexts/AuthContext";
import { plans } from "./Plans";
import ExitIntentModal from './ExitIntentModal';

interface PricingCardsProps {
    showOnlyCards?: boolean;
    showGradient?: boolean;
}

const OptimizedPricingCards: React.FC<PricingCardsProps> = ({ showOnlyCards = false, showGradient = true }) => {
    const [billPlan, setBillPlan] = useState<'monthly' | 'annually'>('monthly');
    const [showTooltip, setShowTooltip] = useState<string>('');
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showExitModal, setShowExitModal] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const hasShownModal = useRef<boolean>(false);
    const mousePosition = useRef({ x: 0, y: 0 });
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const getUserData = async () => {
            try {
                const { logged_in } = await checkIsLoggedIn();
                setIsLoggedIn(logged_in);
            } catch (error) {
                setIsLoggedIn(false);
            }
        };

        getUserData();

        // Track mouse position to detect exit intent
        const handleMouseMove = (e: MouseEvent) => {
            mousePosition.current = { x: e.clientX, y: e.clientY };
        };

        // Single function to handle exit intent detection from any direction
        const handleExitIntent = (e: MouseEvent) => {
            const { x, y } = mousePosition.current;
            // Calculate the velocity/direction the mouse was moving
            const deltaX = e.clientX - x;
            const deltaY = e.clientY - y;

            // Detect if the mouse is exiting from any edge of the viewport
            const isExitingTop = e.clientY <= 0 && deltaY < 0;
            const isExitingLeft = e.clientX <= 0 && deltaX < 0;
            const isExitingRight = e.clientX >= window.innerWidth - 1 && deltaX > 0;
            const isExitingBottom = e.clientY >= window.innerHeight - 1 && deltaY > 0;

            if ((isExitingTop || isExitingLeft || isExitingRight || isExitingBottom) && !hasShownModal.current) {
                setShowExitModal(true);
                hasShownModal.current = true;
            }
        };



        // Setup intersection observer to detect when component is about to leave viewport
        const observerOptions = {
            root: null, // use viewport as root
            rootMargin: '0px',
            threshold: 0.1, // trigger when 10% of the element is visible
        };

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                // When component is leaving the viewport and modal hasn't been shown yet
                if (!entry.isIntersecting && !hasShownModal.current) {
                    setShowExitModal(true);
                    hasShownModal.current = true;
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        // Set up event listeners
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseleave', handleExitIntent);
        document.addEventListener('mouseout', (e) => {
            // Check if the mouse has left the document
            if (e.relatedTarget === null) {
                handleExitIntent(e);
            }
        });

        // Clean up
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseleave', handleExitIntent);
            document.removeEventListener('mouseout', (e) => {
                if (e.relatedTarget === null) {
                    handleExitIntent(e);
                }
            });
            observer.disconnect();
        };
    }, []);
    const handlePaymentClickButton = async () => {
        if (!isLoggedIn) {
            navigate("/login");
            return;
        }

        setIsLoading(true);
        try {
            const result = await createCheckoutSession(
                billPlan === 'monthly' ? 'monthly' : 'annually'
            );

            if (result.url) {
                window.location.href = result.url;
            } else {
                toast.error("Failed to create checkout session");
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
            toast.error("An error occurred while processing your request");
        } finally {
            setIsLoading(false);
        }
    };

    const toggleBilling = () => {
        setBillPlan(billPlan === 'monthly' ? 'annually' : 'monthly');
    };

    const handleUpgradeClick = () => {
        setShowExitModal(false);
        handlePaymentClickButton();
    };

    const handleCloseModal = () => {
        setShowExitModal(false);
    };
    return (
        <div className='flex flex-col font-Manrope'>
            {showOnlyCards ? null : (
                <div
                    className={`pb-20 pt-12 text-center px-6`}
                    style={showGradient ? {
                        overflow: 'hidden',
                        backgroundImage: "url('/img/gradient-bg-small.png')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center bottom',
                    } : undefined}
                >
                    <h1 className="mb-5 text-3xl font-bold md:text-4xl lg:text-5xl">
                        Accelerate Your Research <span className="text-brandMaroon">by 65%</span>
                    </h1>
                    <p className="text-xl font-normal text-gray-700 max-w-2xl mx-auto mb-6">
                        Join thousands of researchers who save 20+ hours on every literature review
                    </p>
                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mt-8 mb-2">
                        <div className="flex items-center bg-white shadow-sm rounded-full px-4 py-2">
                            <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span className="text-sm font-medium">Unlimited References</span>
                        </div>
                        <div className="flex items-center bg-white shadow-sm rounded-full px-4 py-2">
                            <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span className="text-sm font-medium">Line-by-line Citations</span>
                        </div>
                        <div className="flex items-center bg-white shadow-sm rounded-full px-4 py-2">
                            <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span className="text-sm font-medium">Advanced AI Writer</span>
                        </div>
                    </div>
                </div>
            )}

            {/* Billing toggle */}
            <div className="flex items-center justify-center space-x-4 -mt-10 mb-8 z-10 relative">
                <div className="mt-3 px-6 py-1 rounded-full  flex items-center space-x-4">
                    <span className={`text-lg font-medium ${billPlan === "monthly" ? "text-brandMaroon" : "text-gray-500"}`}>Monthly</span>
                    <button
                        className="relative rounded-full focus:outline-none focus:ring-none focus:ring-offset-none"
                        onClick={toggleBilling}
                    >
                        <div className="w-16 h-8 transition bg-brandMaroon rounded-full shadow-md outline-none"></div>
                        <div
                            className={`absolute inline-flex items-center justify-center w-6 h-6 transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm top-1 left-1 ${billPlan === 'annually' ? 'translate-x-8' : 'translate-x-0'}`}
                        ></div>
                    </button>
                    <div className="flex flex-col">
                        <span className={`text-lg font-medium ${billPlan === "annually" ? "text-brandMaroon" : "text-gray-500"}`}>
                            Annual
                        </span>
                        <span className="text-sm text-white font-bold bg-green-600 px-2 py-0.5 rounded-full -mt-1">Save 65%</span>
                    </div>
                </div>
            </div>

            {/* Pricing cards */}
            <div className="flex gap-8 2xl:gap-12 flex-col md:flex-row mt-8 max-w-6xl mx-auto px-6 lg:items-stretch justify-center">
                {/* Pro Plan Card - Displayed First */}
                <div className="w-full md:w-1/2 max-w-lg order-1">
                    <div className="w-full -mt-[40px] bg-lightMaroon h-[40px] rounded-t-lg text-center pt-2 text-[17px] font-semibold border-t border-x border-brandMaroon">
                        Recommended for Researchers
                    </div>
                    <div className="flex flex-col justify-between w-full h-full px-8 py-10 space-y-6 text-left bg-white border rounded-b-lg border-brandMaroon shadow-xl group hover:shadow-2xl transition-all duration-300 relative">
                        <div className="absolute top-6 right-6 bg-green-600 text-white text-sm font-bold px-3 py-1.5 rounded-full">
                            SAVE 20+ HOURS PER REVIEW
                        </div>
                        <div className='space-y-6'>
                            <h2 className="text-2xl font-bold">Pro Plan</h2>
                            <div className='w-full h-1 border-t border-gray-200'></div>
                            <p className="text-lg text-gray-600">Advanced features for serious researchers</p>
                            <div className="mt-4">
                                {billPlan === 'monthly' ? (
                                    <div className="flex items-baseline">
                                        <span className="text-5xl font-bold">${plans['Passionate researchers'].price.monthly}</span>
                                        <span className="text-xl text-gray-500 ml-2">/month</span>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="flex items-center">
                                            <span className="text-xl line-through text-gray-400">
                                                ${plans['Passionate researchers'].price.annually}
                                            </span>
                                            <span className="ml-3 text-lg font-semibold text-brandMaroon">
                                                {plans['Passionate researchers'].price.offer}
                                            </span>
                                        </div>
                                        <div className="flex items-baseline mt-1">
                                            <span className="text-5xl font-bold">
                                                ${plans['Passionate researchers'].price.annualWithOffer}
                                            </span>
                                            <span className="text-xl text-gray-500 ml-2">/month</span>
                                        </div>
                                        <div className="text-sm text-gray-600 mt-1">
                                            billed annually (${Number(plans['Passionate researchers'].price.annualWithOffer) * 12}/year)
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-col space-y-4 mt-8">
                                {plans['Passionate researchers'].features.map((feature, i) => (
                                    <div key={i} className="flex items-start">
                                        <svg
                                            className="min-w-6 h-6 text-green-500 mt-0.5 mr-3"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 13l4 4L19 7"
                                            ></path>
                                        </svg>
                                        <span className='relative text-base'>
                                            {feature}
                                            {plans['Passionate researchers'].tooltip[i] ? (
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline hover:cursor-pointer ml-2 text-gray-400"
                                                        onMouseEnter={() => { setShowTooltip(`Passionate researchers.${i}`) }}
                                                        onMouseLeave={() => { setShowTooltip(``) }}
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                    </svg>

                                                    <div className={`absolute z-50 bottom-6 right-0 bg-gray-800 rounded-md text-white px-3 py-2 text-sm max-w-xs transition-opacity duration-200 ${showTooltip === `Passionate researchers.${i}` ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                                        {plans['Passionate researchers'].tooltip[i]}
                                                    </div>
                                                </>
                                            ) : null}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-8">
                            <button
                                className="w-full py-4 px-6 rounded-lg text-white bg-brandMaroon hover:bg-brandMaroon/90 transition duration-300 text-lg font-semibold shadow-md hover:shadow-lg disabled:opacity-70 disabled:cursor-not-allowed"
                                disabled={isLoading || user?.level === "paid"}
                                onClick={() => {
                                    if (user?.level !== "paid") {
                                        if (isLoggedIn) {
                                            handlePaymentClickButton()
                                        }
                                        else {
                                            navigate("/login")
                                        }
                                    } else {
                                        toast.success("You are already subscribed to a plan")
                                    }
                                }}
                            >
                                {isLoading ? "Processing..." : (user?.level === "paid" ? "Already Pro" : "Upgrade Now")}
                            </button>
                            <p className="text-sm text-center mt-4 text-gray-600">
                                {user?.level === "paid" ? "Thank you for being a Pro user!" : "Supercharge your research instantly"}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Free Plan Card - Displayed Second with reduced opacity */}
                <div className="w-full md:w-1/2 max-w-lg order-2 opacity-75">
                    <div className="flex flex-col justify-between w-full h-full px-8 py-10 space-y-6 text-left bg-white border rounded-lg border-gray-200 shadow-sm transition-all duration-300">
                        <div className='space-y-6'>
                            <h2 className="text-2xl font-bold">Current Free Plan</h2>
                            <div className='w-full h-1 border-t border-gray-200'></div>
                            <p className="text-lg text-gray-600">Basic features with limitations</p>
                            <div className="text-5xl font-bold mt-4">
                                $0
                                <span className="text-xl text-gray-500 ml-2">/month</span>
                            </div>

                            <div className="flex flex-col space-y-4 mt-8">
                                {plans['Free Forever'].features.map((feature, i) => (
                                    <div key={i} className="flex items-start">
                                        {feature.includes('Limited') && !feature.includes('Unlimited') ? (
                                            <svg
                                                className="min-w-6 h-6 text-yellow-500 mt-0.5 mr-3"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                className="min-w-6 h-6 text-green-500 mt-0.5 mr-3"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        )}
                                        <span className='relative text-base'>
                                            {feature}
                                            {plans['Free Forever'].tooltip[i] ? (
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline hover:cursor-pointer ml-2 text-gray-400"
                                                        onMouseEnter={() => { setShowTooltip(`Free Forever.${i}`) }}
                                                        onMouseLeave={() => { setShowTooltip(``) }}
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                                    </svg>

                                                    <div className={`absolute z-50 bottom-6 right-0 bg-gray-800 rounded-md text-white px-3 py-2 text-sm max-w-xs transition-opacity duration-200 ${showTooltip === `Free Forever.${i}` ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                                        {plans['Free Forever'].tooltip[i]}
                                                    </div>
                                                </>
                                            ) : null}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-8">
                            <button
                                className="w-full py-4 px-6 rounded-lg text-gray-500 bg-gray-100 cursor-not-allowed border border-gray-200 text-lg font-semibold"
                                disabled={true}
                            >
                                Limited Features
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Testimonials */}
            <div className="mt-20 bg-white py-12 px-6">
                <div className="max-w-6xl mx-auto">
                    <h3 className="text-2xl font-bold mb-10 text-center">What researchers say about Pro</h3>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-100">
                            <div className="flex items-center mb-4">
                                <div className="w-10 h-10 rounded-full bg-brandMaroon/20 flex items-center justify-center text-brandMaroon font-bold">SK</div>
                                <div className="ml-3">
                                    <p className="font-semibold">Sarah K.</p>
                                    <p className="text-sm text-gray-600">PhD Candidate</p>
                                </div>
                            </div>
                            <p className="text-gray-700 italic">
                                "AnswerThis Pro has cut my literature review time by 65%. I'm saving 20+ hours on each paper. The unlimited references feature alone is worth the price."
                            </p>
                        </div>
                        <div className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-100">
                            <div className="flex items-center mb-4">
                                <div className="w-10 h-10 rounded-full bg-brandMaroon/20 flex items-center justify-center text-brandMaroon font-bold">MT</div>
                                <div className="ml-3">
                                    <p className="font-semibold">Michael T.</p>
                                    <p className="text-sm text-gray-600">Professor</p>
                                </div>
                            </div>
                            <p className="text-gray-700 italic">
                                "I was skeptical at first, but the Pro features have completely transformed my research workflow. My team now completes tasks in hours that used to take days."
                            </p>
                        </div>
                        <div className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-100">
                            <div className="flex items-center mb-4">
                                <div className="w-10 h-10 rounded-full bg-brandMaroon/20 flex items-center justify-center text-brandMaroon font-bold">JL</div>
                                <div className="ml-3">
                                    <p className="font-semibold">Jennifer L.</p>
                                    <p className="text-sm text-gray-600">Research Director</p>
                                </div>
                            </div>
                            <p className="text-gray-700 italic">
                                "The line-by-line citations feature is incredible. Our department upgraded all our accounts to Pro after seeing the time savings in our first month."
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Exit Intent Modal */}
            <ExitIntentModal
                isOpen={showExitModal}
                onClose={handleCloseModal}
                onUpgrade={handleUpgradeClick}
            />
        </div>

    );
};

export default OptimizedPricingCards;
