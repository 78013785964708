import "./Login.css"; // Reusing the same CSS file as login page
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../authService";
import { toast } from "sonner";
import SeoComp from "../../components/SeoComp";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  const handleForgotPassword = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (email.length === 0) {
      toast.error("Email cannot be blank!")
      // alert("Email cannot be blank!");
      return;
    }

    forgotPassword(
      email,
      () => {
        toast.success("If an account with that email exists, we have sent a password reset link.")
        // alert(
        //   "If an account with that email exists, we have sent a password reset link."
        // );
        navigate("/login"); // Navigate to login after successful request
      },
      (errorMessage) => {
        toast.error(errorMessage); // Show error message if something goes wrong
      }
    );
  };

  return (
    <>
      <SeoComp />
      <div className="h-screen flex justify-center items-center">
        <div className="sign-in-body">
          <div className="sign-up-head">Forgot Password</div>
          <form action="" method="post" className="login-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="forgotPasswordEmailInput"
              placeholder="Enter your email"
              className="text-input"
            />
            <br />
            <button className="submit-forgot-password-button" onClick={handleForgotPassword}>
              Send Reset Link
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
