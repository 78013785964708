import { Editor } from '@tiptap/react';
import { SlashCmd } from "@harshtalks/slash-tiptap";
import { Range } from '@tiptap/core';

interface SlashCommand {
    title: string;
    searchTerms: string[];
    command: ({ editor, range }: { editor: Editor; range: Range }) => Promise<void>;
}

interface SlashCommandMenuProps {
    editor: Editor;
    commands: SlashCommand[];
}

export const SlashCommandMenu: React.FC<SlashCommandMenuProps> = ({ editor, commands }) => {
    return (
        <SlashCmd.Root editor={editor}>
            <SlashCmd.Cmd>
                <div className="bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden min-w-[200px] max-w-[300px]">
                    <div className="p-2">
                        <SlashCmd.Empty>
                            <div className="text-sm text-gray-500 p-2">
                                No commands available
                            </div>
                        </SlashCmd.Empty>
                        <SlashCmd.List>
                            {commands.map((item) => (
                                <SlashCmd.Item
                                    value={item.title}
                                    onCommand={(val) => {
                                        item.command(val);
                                    }}
                                    key={item.title}
                                >
                                    <div className="flex items-center gap-2 p-2 text-sm hover:bg-gray-100 rounded cursor-pointer">
                                        <span className="font-medium text-gray-700">{item.title}</span>
                                        {item.searchTerms && (
                                            <span className="text-xs text-gray-400">
                                                {item.searchTerms.join(', ')}
                                            </span>
                                        )}
                                    </div>
                                </SlashCmd.Item>
                            ))}
                        </SlashCmd.List>
                    </div>
                </div>
            </SlashCmd.Cmd>
        </SlashCmd.Root>
    );
}; 