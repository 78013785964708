// documentService.ts
import axios from 'axios';
import { toast } from 'sonner';
import { AnswerObject, defaultFilters, Filters, serverUrl } from '../../authService';
import { useState, useEffect } from 'react';
import { WriterDocument } from './types';
import { Editor } from '@tiptap/react';
import { convertAnswerToEditorFormat, convertEditorToAnswer } from './answerConversion';
import { answerObjectListToFormattedAnswer } from '@/HelperUtils/AnswerFetcher';
import { citationStyles } from '@/pages/Answer/citationUtils';
import QueueAdapter from '@/HelperUtils/QueueAdapter';

const queueAdapter = new QueueAdapter({
    taskBaseUrl: serverUrl,     // For SSE/status endpoints
    endpointBaseUrl: serverUrl, // For queued endpoint
    backupEndpointUrl: serverUrl      // For non-queued backup
});

interface UpdateDocumentParams {
    title?: string;
    description?: string;
    is_public?: boolean;
    content?: AnswerObject;
    doc_metadata?: Record<string, string | number | boolean>;
}
export const useDocument = (documentId: string) => {
    const [document, setDocument] = useState<WriterDocument | null>(null);
    const [loading, setLoading] = useState(true);

    const loadDocument = async () => {
        try {
            setLoading(true);
            const response = await axios.post<{ data: WriterDocument }>(
                `${serverUrl}/writer/get-document`,
                { document_id: documentId },
                { withCredentials: true }
            );
            setDocument(response.data.data);
        } catch (error) {
            toast.error('Failed to load document');
        } finally {
            setLoading(false);
        }
    };

    const updateDocument = async (updateData: UpdateDocumentParams) => {
        if (!document) return;

        try {
            setLoading(true);
            const response = await axios.post<{ data: WriterDocument }>(
                `${serverUrl}/writer/edit-document`,
                {
                    document_id: documentId,
                    ...updateData,
                    // Only include doc_metadata if it exists in updateData or document
                    ...(updateData.doc_metadata || document.doc_metadata ? {
                        doc_metadata: {
                            ...document.doc_metadata,
                            ...updateData.doc_metadata
                        }
                    } : {})
                },
                { withCredentials: true }
            );

            // Only update state if the content has actually changed
            const newDoc = response.data.data;
            if (JSON.stringify(newDoc.content) !== JSON.stringify(document.content)) {
                setDocument(newDoc);
            }
            return response.data.data;
        } catch (error: unknown) {
            toast.error('Failed to update document');
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const deleteDocument = async () => {
        if (!document) return;

        try {
            await axios.post(
                `${serverUrl}/writer/delete-document`,
                { document_id: documentId },
                { withCredentials: true }
            );
            return true;
        } catch (error) {
            console.error('Error deleting document:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (documentId) {
            loadDocument();
        }
    }, [documentId]);

    return { document, loading, updateDocument, reload: loadDocument, deleteDocument };
};

// Document creation
export const createDocument = async (
    title: string,
    description: string,
    projectId?: string,
    answerObject?: AnswerObject
): Promise<WriterDocument> => {
    try {
        const initialContent: AnswerObject = answerObject || {
            sources: { result: {} },
            unused_sources: { result: {} },
            query: '',
            answer: '',
            filters: defaultFilters,
            getPapers: false
        };

        const response = await axios.post<{ data: WriterDocument }>(
            `${serverUrl}/writer/create-doc`,
            {
                title,
                description,
                project_id: projectId,
                content: initialContent,
            },
            { withCredentials: true }
        );

        return response.data.data;
    } catch (error) {
        console.error('Error creating document:', error);
        throw error;
    }
};

// Document list fetching
export const getDocumentList = async (projectId: string): Promise<WriterDocument[]> => {
    try {
        const response = await axios.post<{ data: WriterDocument[] }>(
            `${serverUrl}/writer/get-docs`,
            { project_id: projectId },
            { withCredentials: true }
        );

        return response.data.data.map(doc => ({
            ...doc,
            created_at: new Date(doc.created_at).toISOString(),
            updated_at: new Date(doc.updated_at).toISOString(),
            doc_metadata: {
                ...doc.doc_metadata,
            }
        }));
    } catch (error) {
        console.error('Error fetching document list:', error);
        throw error;
    }
};

// Check if document exists with query hash
export const findDocumentByQueryHash = async (projectId: string, queryHash: string): Promise<WriterDocument | null> => {
    try {
        const documents = await getDocumentList(projectId);
        return documents.find(doc => doc.description === queryHash) || null;
    } catch (error) {
        console.error('Error finding document by query hash:', error);
        throw error;
    }
};

export const writeOutline = async (query_text: string,
    project_id: string, numOfHeadings: number, numOfSubHeadings: number,
    filters?: Filters, description?: string) => {
    if (!filters) {
        filters = defaultFilters;
    }
    try {
        const payload = {
            query_text,
            project_id,
            filters,
            numOfHeadings,
            numOfSubHeadings,
            description
        };
        const result = await queueAdapter.executeTask(
            '/writer/writeOutlineQ',  // Queued endpoint
            payload,
            '/writer/writeOutline'    // Backup endpoint
        );
        return result;
    } catch (error) {
        console.error('Error writing outline:', error);
        throw error;
    }
}

export const retrieveOutlineToAnswer = async (query_text: string,
    answerObject: AnswerObject,
) => {
    try {
        const payload = {
            query_text,
            answerObject,
        };
        const result = await queueAdapter.executeTask(
            '/writer/expandQ',  // Queued endpoint
            payload,
            '/writer/expand'    // Backup endpoint
        );
        return result;
    } catch (error) {
        console.error('Error expanding outline:', error);
        throw error;
    }
}

export const updateDocumentContent = async (query_text: string, instruction: string, answerObject: AnswerObject, section: string, project_id: string, filters: Filters) => {
    try {
        const payload = {
            query_text,
            instruction,
            answerObject,
            section,
            project_id,
            filters
        };
        const result = await queueAdapter.executeTask(
            '/writer/updateSectionOfDocumentQ',  // Queued endpoint
            payload,
            '/writer/updateSectionOfDocument'    // Backup endpoint
        );
        return result;
    } catch (error) {
        console.error('Error updating document content:', error);
        throw error;
    }
}

export const insertAtCursor = async (
    query_text: string,
    instruction: string,
    answerObject: AnswerObject,
    before_content: string,
    after_content: string,
    project_id: string,
    filters: Filters
) => {
    try {
        const payload = {
            query_text,
            instruction,
            answerObject,
            before_content,
            after_content,
            project_id,
            filters
        };
        const result = await queueAdapter.executeTask(
            '/writer/insertAtCursorQ',  // Queued endpoint
            payload,
            '/writer/insertAtCursor'    // Backup endpoint
        );
        return result;
    } catch (error) {
        console.error('Error inserting content at cursor:', error);
        throw error;
    }
};

export const addOutline = async (query_text: string, description: string, project_id: string, numOfHeadings: number, numOfSubHeadings: number, filters: Filters, editor: Editor, citationStyle: citationStyles) => {
    if (!editor) {
        toast.error('Editor not available');
        return;
    }

    try {
        const outline: AnswerObject[] = await writeOutline(query_text, project_id, numOfHeadings, numOfSubHeadings, filters, description);
        const formattedOutline = await answerObjectListToFormattedAnswer(outline);
        const convertedAnswer = await convertAnswerToEditorFormat(formattedOutline.answers[0], citationStyle);
        return convertedAnswer;
    } catch (error) {
        console.error('Error generating outline:', error);
        throw error;
    }
};

export const outlineToAnswer = async (query_text: string, editor: Editor, citationStyle: citationStyles) => {
    if (!editor) {
        toast.error('Editor not available');
        return;
    }

    try {
        const answerObject = convertEditorToAnswer(editor);
        const newEditorContent = await retrieveOutlineToAnswer(query_text, answerObject);
        const formattedOutline = await answerObjectListToFormattedAnswer(newEditorContent);
        console.log('Formatted Outline:', formattedOutline);
        const convertedAnswer = await convertAnswerToEditorFormat(formattedOutline.answers[0], citationStyle);
        return convertedAnswer;
    } catch (error) {
        console.error('Error generating answer:', error);
        throw error;
    }
};
export const copyDocument = async (documentId: string, projectId?: string): Promise<WriterDocument> => {
    try {
        // First get the document to copy
        const response = await axios.post<{ data: WriterDocument }>(
            `${serverUrl}/writer/get-document`,
            { document_id: documentId },
            { withCredentials: true }
        );

        const sourceDocs = response.data.data;

        // Create a new document with the same content
        return createDocument(
            `${sourceDocs.title} (Copy)`,
            sourceDocs.description,
            projectId,
            sourceDocs.content
        );
    } catch (error) {
        console.error('Error copying document:', error);
        throw error;
    }
};
