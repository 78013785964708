import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Paper } from "./types";
import PaperItemWithOrigin from './PaperItemWithOrigin';

interface MetricsTabProps {
    handleCheckboxChange: (paperId: string) => void;
    handleSavePaper: (paperId: string) => Promise<void>;
    handleAddPaper: (paper: Paper, asOrigin: boolean) => Promise<void>;
    isAddingPaper: { [key: string]: boolean };
    mostCited: Paper[];
    mostCommonAuthors: { name: string; count: number; papers: Paper[]; }[];
    mostConnected: Paper[];
    projectId: string;
    selectedPapersToSave: Set<string>;
    allPapers: Paper[];
}

interface MetricsSectionProps {
    title: string;
    description: string;
    isOpen: boolean;
    onToggle: () => void;
    children: React.ReactNode;
}

function MetricsSection({ title, description, isOpen, onToggle, children }: MetricsSectionProps) {
    return (
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 transition-all duration-200 hover:shadow-md g">
            <button
                onClick={onToggle}
                className="w-full p-4 flex justify-between items-center hover:bg-gray-50 transition-colors duration-150"
            >
                <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                        {title}
                        <span className="text-xs font-normal text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                            {isOpen ? 'Click to collapse' : 'Click to expand'}
                        </span>
                    </h3>
                    <p className="text-sm text-gray-600 mt-1">{description}</p>
                </div>
                {isOpen ?
                    <ChevronUp className="h-5 w-5 text-gray-400" /> :
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                }
            </button>
            {isOpen && (
                <div className="p-4 space-y-4 border-t border-gray-100 h-[50vh] overflow-y-auto">
                    {children}
                </div>
            )}
        </div>
    );
}

export function MetricsTab({
    handleCheckboxChange,
    handleSavePaper,
    handleAddPaper,
    isAddingPaper,
    mostCited,
    mostCommonAuthors,
    mostConnected,
    projectId,
    selectedPapersToSave,
    allPapers = [],
}: MetricsTabProps) {
    const [openSections, setOpenSections] = useState({
        searchResults: false,
        cited: false,
        connected: false,
        authors: false
    });

    const toggleSection = (section: keyof typeof openSections) => {
        setOpenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const renderPaperItem = (paper: Paper) => (
        <PaperItemWithOrigin
            key={paper.paperId}
            paper={paper}
            onSetAsOrigin={(paper) => handleAddPaper(paper, true)}
            handleCheckboxChange={handleCheckboxChange}
            handleSavePaper={handleSavePaper}
            isAddingPaper={isAddingPaper}
            selectedPapersToSave={selectedPapersToSave}
            projectId={projectId}
        />
    );

    const renderEmptyState = (message: string) => (
        <div className="text-center py-8 text-gray-500">
            <p>{message}</p>
        </div>
    );

    return (
        <div className="space-y-6 p-4">
            {allPapers.length > 0 && (
                <MetricsSection
                    title={`Papers (${allPapers.length})`}
                    description="All papers in your research graph"
                    isOpen={openSections.searchResults}
                    onToggle={() => toggleSection('searchResults')}
                >
                    {allPapers.map(renderPaperItem)}
                </MetricsSection>
            )}

            <MetricsSection
                title="Most Cited Papers"
                description="Papers with the highest citation count in your graph"
                isOpen={openSections.cited}
                onToggle={() => toggleSection('cited')}
            >
                {mostCited.length > 0 ? (
                    <div className="space-y-3">
                        {mostCited.map(renderPaperItem)}
                    </div>
                ) : renderEmptyState("No papers in the graph yet. Search and add papers to see citation metrics.")}
            </MetricsSection>

            <MetricsSection
                title="Most Connected Papers"
                description="Papers with the most references and citations in your graph"
                isOpen={openSections.connected}
                onToggle={() => toggleSection('connected')}
            >
                {mostConnected.length > 0 ? (
                    <div className="space-y-3">
                        {mostConnected.map(renderPaperItem)}
                    </div>
                ) : renderEmptyState("Add more papers to the graph to see connection metrics.")}
            </MetricsSection>

            <MetricsSection
                title="Top Contributing Authors"
                description="Authors with multiple papers in your research graph"
                isOpen={openSections.authors}
                onToggle={() => toggleSection('authors')}
            >
                {mostCommonAuthors.length > 0 ? (
                    <div className="space-y-6">
                        {mostCommonAuthors.map(author => (
                            <div key={author.name} className="space-y-3">
                                <div className="flex items-center gap-2">
                                    <h4 className="font-medium text-gray-900">{author.name}</h4>
                                    <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                                        {author.count} {author.count === 1 ? 'paper' : 'papers'}
                                    </span>
                                </div>
                                <div className="ml-4 space-y-3">
                                    {author.papers.map(renderPaperItem)}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : renderEmptyState("Add more papers to see author statistics.")}
            </MetricsSection>
        </div>
    );
}