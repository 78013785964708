import { FC } from 'react';

interface ThProps {
    children: React.ReactNode;
    className?: string;
}

export const Th: FC<ThProps> = ({ children, className = "" }) => {
    return (
        <th className={`block md:table-cell font-semibold text-[20px] border border-gray-300 pt-4 px-4 ${className}`}>
            {children}
        </th>
    );
};

interface TdProps {
    children: React.ReactNode;
    className?: string;
}

export const Td: FC<TdProps> = ({ children, className = "" }) => {
    return (
        <td className={`table-cell ${className}`}>
            {children}
        </td>
    );
};
