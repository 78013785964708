import React, { createContext, useContext, useState, useCallback } from 'react';
import { Loader2, X } from 'lucide-react';

type ProcessingTask = {
    id: string;
    action: string;
    startTime: Date;
};

type ProcessingContextType = {
    tasks: ProcessingTask[];
    addTask: (action: string) => string;
    removeTask: (taskId: string) => void;
};

const ProcessingContext = createContext<ProcessingContextType | undefined>(undefined);

export function ProcessingProvider({ children }: { children: React.ReactNode }) {
    const [tasks, setTasks] = useState<ProcessingTask[]>([]);

    const addTask = useCallback((action: string) => {
        const taskId = Math.random().toString(36).substring(7);
        const newTask = {
            id: taskId,
            action,
            startTime: new Date(),
        };
        setTasks(prev => [...prev, newTask]);
        return taskId;
    }, []);

    const removeTask = useCallback((taskId: string) => {
        setTasks(prev => prev.filter(task => task.id !== taskId));
    }, []);

    return (
        <ProcessingContext.Provider value={{ tasks, addTask, removeTask }}>
            {children}
            {tasks.length > 0 && (
                <div className="z-50 fixed inset-x-0 bottom-0 p-4 pointer-events-none">
                    <div className="max-w-md mx-auto space-y-2">
                        {tasks.map((task, index) => {


                            return (
                                <div
                                    key={task.id}
                                    className="bg-brandMaroon text-white border border-gray-400 rounded-lg shadow-lg p-3 flex items-center gap-3 pointer-events-auto
                                             transform transition-all duration-200 ease-out"
                                    style={{
                                        opacity: Math.max(0.6, 1 - index * 0.2),
                                        transform: `translateY(${-index * 4}px) scale(${1 - index * 0.05})`,
                                    }}
                                >
                                    <div className="flex-shrink-0">
                                        <Loader2 className="h-5 w-5 animate-spin text-white" />
                                    </div>
                                    <div className="flex-grow min-w-0">
                                        <div className="font-medium text-white truncate">
                                            {task.action}
                                        </div>

                                    </div>
                                    <button
                                        onClick={() => removeTask(task.id)}
                                        className="flex-shrink-0 text-gray-400 hover:text-gray-600 transition-colors"
                                    >
                                        <X className="h-4 w-4 text-white" />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </ProcessingContext.Provider>
    );
}

export function useProcessing() {
    const context = useContext(ProcessingContext);
    if (context === undefined) {
        throw new Error('useProcessing must be used within a ProcessingProvider');
    }
    return context;
}
