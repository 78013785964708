import { useEffect, useRef, useState, useMemo } from 'react';
import { Paper } from './types';
import { fetchPaperByDoi, searchPapers } from './api';
import { handleSaveToLibraryWithData } from '../../authService';
import { toast } from 'sonner';
import cytoscape from 'cytoscape';
import cola from 'cytoscape-cola';
import { SearchBar } from '../searchInput/SearchBar';
import { ButtonToggle } from './ButtonToggle';
import { MetricsTab } from './MetricsTab';
import SearchResultsSelector from './SearchResultSelector';
import { graphStyles, LAYOUT_OPTIONS } from './graphConstants';
import { paperToSourceInfo, handleAddPaperFunction, analyzeNetworkFunction, similarPaperFinder, calculateMostCommonAuthors, calculateMostConnected, calculateMostCited, addNodesToGraph, observeContainerResize } from './utilsGraph';
import { GraphControls } from './GraphControl';
import EmptySearchState from './EmptySearchState';
import OriginPapers from './OriginPapers';
import PaperInfoSection from './PaperInfoSection';
import { Search } from 'lucide-react';
import { buttonClassWhiteBig } from '../Buttons/buttonClasses';
import { useProcessing } from '@/contexts/processingContext';
import { useLibrary } from '@/contexts/LibraryContext';
import { useAuth } from '@/contexts/AuthContext';

// Register plugins
cytoscape.use(cola);

export interface PaperCache {
  [paperId: string]: {
    citations?: Paper[];
    references?: Paper[];
    citedBy: Set<string>;
    citing: Set<string>;
    timestamp: number;
    cachedNetworkData?: any;
  };
}

interface CitationMapProps {
  projectId: string;
}

export default function CitationMap({ projectId }: CitationMapProps) {
  // State
  const { addTask, removeTask } = useProcessing();
  const { refreshLibrary } = useLibrary();
  const { checkCredits, removeCredits } = useAuth();

  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'info' | 'metrics' | 'origins'>('metrics');
  const [selectedPaperDetails, setSelectedPaperDetails] = useState<Paper | null>(null);
  const [graphPapers, setGraphPapers] = useState<Paper[]>([]);
  const [originPapers, setOriginPapers] = useState<Paper[]>([]);
  const [isAddingPaper, setIsAddingPaper] = useState<{ [key: string]: boolean }>({});
  const [selectedPapersToSave, setSelectedPapersToSave] = useState<Set<string>>(new Set());
  const [searchResults, setSearchResults] = useState<Paper[]>([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const hideSingleConnections = false;
  const colors = ['#ff7f0e', '#2ca02c', '#9467bd', '#d62728', '#1f77b4'];
  const [colorIndex, setColorIndex] = useState(0);

  // NEW: Max papers state
  const [maxPapers, setMaxPapers] = useState<number>(20);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const cyRef = useRef<cytoscape.Core | null>(null);
  const papersCache = useRef<PaperCache>({});

  // Network analysis functions
  const analyzeNetwork = analyzeNetworkFunction(papersCache);

  const handleSearch = async () => {
    const hasEnoughCredits = checkCredits(projectId, 1);
    if (!hasEnoughCredits) {
      toast.error("You don't have enough credits to perform this action.");
      return;
    }

    if (!searchQuery.trim()) return;
    const taskId = addTask('Searching papers');
    setIsLoading(true);
    try {
      let results: Paper[];
      if (searchQuery.toLowerCase().includes('doi.org/') || searchQuery.toLowerCase().startsWith('10.')) {
        const doi = searchQuery.includes('doi.org/')
          ? searchQuery.split('doi.org/')[1]
          : searchQuery;
        const paper = await fetchPaperByDoi(doi);
        results = [paper];
      } else {
        results = await searchPapers(searchQuery);
      }

      if (results.length === 0) {
        toast.error('No papers found');
        return;
      }

      setSearchResults(results);
      setShowSearchResults(true);
    } catch (error) {
      console.error('Error searching papers:', error);
      toast.error('Failed to search papers');
    } finally {
      setIsLoading(false);
      removeTask(taskId);
      await removeCredits(1);
    }
  };

  const handleSelectOrigin = async (paper: Paper) => {
    const taskId = addTask('Adding origin paper...');
    setShowSearchResults(false);
    setSearchQuery('');
    setOriginPapers(prev => [...prev, paper]);
    await handleAddPaper(paper, true);
    setActiveTab('metrics');
    removeTask(taskId);
  };

  const handleAddPaper = handleAddPaperFunction(
    cyRef,
    setIsLoading,
    papersCache,
    analyzeNetwork,
    setGraphPapers,
    setOriginPapers,
    toast.success,
    toast.error
  );

  // New function to reset everything
  const handleResetEverything = () => {
    // Clear states
    setGraphPapers([]);
    setOriginPapers([]);
    setSelectedPaperDetails(null);
    setSelectedPapersToSave(new Set());
    setActiveTab('metrics');
    setSearchQuery('');
    setSearchResults([]);
    setShowSearchResults(false);

    // Clear cytoscape graph
    if (cyRef.current) {
      cyRef.current.elements().remove();
      cyRef.current.layout(LAYOUT_OPTIONS).run();
    }

    // Clear cache
    papersCache.current = {};

    // Clear localStorage
    localStorage.removeItem('cachedGraph');
    localStorage.removeItem('cachedGraphPapers');
    localStorage.removeItem('cachedOriginPapers');
    localStorage.removeItem('cachedPapersCache');

    toast.success("Graph and all data have been reset.");
  };

  const handleRemoveOrigin = (paperId: string) => {
    setOriginPapers(prev => prev.filter(p => p.paperId !== paperId));

    // Remove the corresponding node from the graph
    if (cyRef.current) {
      const nodeToRemove = cyRef.current.$(`#${paperId}`);
      if (nodeToRemove) {
        nodeToRemove.remove();
        cyRef.current.layout(LAYOUT_OPTIONS).run();
      }
    }

    // Remove from graphPapers as well
    setGraphPapers(prev => prev.filter(p => p.paperId !== paperId));

    // If no origin papers left, reset everything
    if (originPapers.length - 1 === 0) {
      handleResetEverything();
    }
  };

  // Initial graph setup + restore from localStorage if available
  useEffect(() => {
    if (!containerRef.current) return;

    cyRef.current = cytoscape({
      container: containerRef.current,
      style: graphStyles as cytoscape.StylesheetCSS[],
    });

    // Setup event handlers
    cyRef.current.on('tap', 'node', (evt: any) => {
      const node = evt.target;
      const paper = node.data('paper');
      if (paper) {
        setSelectedPaperDetails(paper);
        setActiveTab('info');

        // Highlight related papers
        cyRef.current?.elements().addClass('faded');
        node.addClass('highlighted').removeClass('faded');
        node.neighborhood().addClass('related').removeClass('faded');
      }
    });

    cyRef.current.on('tap', (evt: any) => {
      if (evt.target === cyRef.current) {
        if (cyRef.current) {
          cyRef.current.elements().removeClass('faded highlighted related');
        }
      }
    });

    // Attempt to restore from local storage
    const savedGraph = localStorage.getItem('cachedGraph');
    const savedPapers = localStorage.getItem('cachedGraphPapers');
    const savedOrigins = localStorage.getItem('cachedOriginPapers');
    const savedCache = localStorage.getItem('cachedPapersCache');

    if (savedGraph && cyRef.current) {
      const graphJson = JSON.parse(savedGraph);
      cyRef.current.json(graphJson);
    }

    if (savedPapers) {
      setGraphPapers(JSON.parse(savedPapers));
    }

    if (savedOrigins) {
      setOriginPapers(JSON.parse(savedOrigins));
    }

    if (savedCache) {
      papersCache.current = JSON.parse(savedCache, (key, value) => {
        // Revive Sets
        if (key === 'citedBy' || key === 'citing') {
          return new Set(value);
        }
        return value;
      });
    }

    // Re-run layout after restore
    cyRef.current?.layout(LAYOUT_OPTIONS).run();
  }, []);

  const handleFit = () => {
    if (!cyRef.current) return;
    cyRef.current.fit();
  };

  const handleCheckboxChange = (paperId: string) => {
    setSelectedPapersToSave(prev => {
      const newSet = new Set(prev);
      if (newSet.has(paperId)) {
        newSet.delete(paperId);
      } else {
        newSet.add(paperId);
      }
      return newSet;
    });
  };

  const handleNodeSizeChange = (factor: number) => {
    if (cyRef.current) {
      cyRef.current.nodes().forEach(node => {
        const currentWidth = parseFloat(node.style('width'));
        const currentHeight = parseFloat(node.style('height'));

        node.style('width', `${currentWidth * factor}px`);
        node.style('height', `${currentHeight * factor}px`);
      });
    }
  };

  const handleNodeColorChange = () => {
    if (cyRef.current) {
      const newColor = colors[colorIndex % colors.length];
      setColorIndex(prev => prev + 1);
      cyRef.current.nodes().forEach(node => {
        node.style('background-color', newColor);
      });
    }
  };

  useEffect(observeContainerResize(containerRef, cyRef), []);

  const handleSavePaper = async (paperId: string) => {
    if (!projectId) {
      toast.error("Please select a project first");
      return;
    }

    setIsAddingPaper(prev => ({ ...prev, [paperId]: true }));
    try {
      await handleSaveToLibraryWithData(
        paperToSourceInfo(graphPapers.find(p => p.paperId === paperId)!),
        projectId,
        () => {
          toast.success("Paper saved to library successfully");
          refreshLibrary(projectId);
        },
        (error) => toast.error(error || "Failed to save paper")
      );
    } catch (error) {
      toast.error("Failed to save paper to library");
    } finally {
      setIsAddingPaper(prev => ({ ...prev, [paperId]: false }));
    }
  };

  useEffect(
    addNodesToGraph(cyRef, containerRef, papersCache, graphPapers, originPapers, hideSingleConnections, maxPapers),
    [graphPapers, originPapers, maxPapers]
  );

  // Cache entire graph when it changes
  useEffect(() => {
    if (cyRef.current) {
      const graphJson = cyRef.current.json();
      localStorage.setItem('cachedGraph', JSON.stringify(graphJson));
    }
    localStorage.setItem('cachedGraphPapers', JSON.stringify(graphPapers));
    localStorage.setItem('cachedOriginPapers', JSON.stringify(originPapers));

    // Convert Sets to arrays before stringifying
    const cacheCopy = JSON.parse(JSON.stringify(papersCache.current, (_key, value) => {
      if (value instanceof Set) {
        return Array.from(value);
      }
      return value;
    }));
    localStorage.setItem('cachedPapersCache', JSON.stringify(cacheCopy));
  }, [graphPapers, originPapers, papersCache.current]);

  const mostCited = useMemo(() => calculateMostCited(graphPapers), [graphPapers]);
  const mostConnected = useMemo(() => calculateMostConnected(graphPapers, cyRef), [graphPapers]);
  const mostCommonAuthors = useMemo(() => calculateMostCommonAuthors(graphPapers), [graphPapers]);

  // Find similar papers
  const findSimilarPapers = similarPaperFinder(graphPapers, papersCache);

  return (
    <>
      <div className="flex flex-col w-full h-full md:h-[90vh]" >
        <div className="bg-white shadow-md px-4 py-2 z-10 sticky top-0 ">
          <SearchBar
            query={searchQuery}
            handleInputChange={(e) => setSearchQuery(e.target.value)}
            handleSearch={handleSearch}
            placeholder="Search by paper title or enter DOI"
          />
          {graphPapers.length > 0 && (
            <button
              onClick={handleResetEverything}
              className={buttonClassWhiteBig}
            >
              <Search className="w-5 h-5 text-gray-600" />
              <span className="text-xs text-gray-600 mt-1">New Search</span>
            </button>
          )}

          {showSearchResults && (
            <div className="absolute left-0 right-0 top-full z-50 mt-2 px-4">
              <SearchResultsSelector
                searchResults={searchResults}
                onSelectOrigin={handleSelectOrigin}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>

        <div className="flex-1 flex flex-col md:flex-row h-full">
          <div className="relative flex-1 h-[60%] md:h-full bg-gray-100">
            <div
              ref={containerRef}
              className="w-full h-full graph-container"
              style={{ minHeight: '400px' }}
            />
            <GraphControls
              handleZoom={(factor) => cyRef.current?.zoom(factor * cyRef.current?.zoom())}
              onFitView={handleFit}
              handleNodeSizeChange={handleNodeSizeChange}
              handleNodeColorChange={handleNodeColorChange}
              maxPapers={maxPapers}
              handleMaxPapersChange={setMaxPapers}
            />
          </div>
          <div className="h-[40%] md:h-full md:w-1/3 bg-gray-50 overflow-y-auto border-r border-gray-300">
            {graphPapers.length === 0 ? (
              <div className="flex items-center justify-center h-full">
                <EmptySearchState />
              </div>
            ) : (
              <div className="p-4 space-y-4">
                <ButtonToggle
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  originCount={originPapers.length}
                />

                {activeTab === 'info' && (
                  !selectedPaperDetails ? (
                    <div className="text-center py-8 text-gray-500">
                      <p>Select a paper from the graph to view details</p>
                      <p className="text-sm mt-2">Tap or click on any node in the graph to see paper information and similar papers</p>
                    </div>
                  ) : (
                    <PaperInfoSection
                      findSimilarPapers={findSimilarPapers}
                      handleCheckboxChange={handleCheckboxChange}
                      handleSavePaper={handleSavePaper}
                      handleAddPaper={handleAddPaper}
                      isAddingPaper={isAddingPaper}
                      projectId={projectId}
                      selectedPaperDetails={selectedPaperDetails}
                      selectedPapersToSave={selectedPapersToSave}
                      originPapers={originPapers}
                      handleRemoveOrigin={handleRemoveOrigin}
                    />
                  )
                )}

                {activeTab === 'metrics' && (
                  <MetricsTab
                    handleCheckboxChange={handleCheckboxChange}
                    handleSavePaper={handleSavePaper}
                    isAddingPaper={isAddingPaper}
                    mostCited={mostCited}
                    mostCommonAuthors={mostCommonAuthors}
                    mostConnected={mostConnected}
                    projectId={projectId}
                    selectedPapersToSave={selectedPapersToSave}
                    allPapers={graphPapers}
                    handleAddPaper={handleAddPaper}
                  />
                )}

                {activeTab === 'origins' && (
                  <OriginPapers
                    handleCheckboxChange={handleCheckboxChange}
                    handleRemoveOrigin={handleRemoveOrigin}
                    handleSavePaper={handleSavePaper}
                    isAddingPaper={isAddingPaper}
                    originPapers={originPapers}
                    projectId={projectId}
                    selectedPapersToSave={selectedPapersToSave}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}