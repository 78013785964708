import React, { ReactNode } from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

interface CTAButton {
    text: string;
    onClick: () => void;
    icon?: ReactNode;
}

interface HeroSectionProps {
    badge?: string;
    title: ReactNode;
    description: string;
    primaryCTA: CTAButton;
    secondaryCTA?: CTAButton;
    variant?: 'default' | 'university' | 'affiliate';
    image?: string;
    backgroundPattern?: 'grid' | 'dots' | 'none';
    customContent?: ReactNode;
    noTopPadding?: boolean;
}

const HeroSection: React.FC<HeroSectionProps> = ({
    badge,
    title,
    description,
    primaryCTA,
    secondaryCTA,
    variant = 'default',
    image,
    customContent,
    backgroundPattern = 'grid',
    noTopPadding = false
}) => {
    const variants = {
        default: {
            container: `relative overflow-hidden ${noTopPadding ? '' : 'pt-24'}`,
            background: "bg-gradient-to-b from-purple-50/80 to-white",
            content: "text-center space-y-8",
            badge: "bg-brandMaroon/10 text-brandMaroon",
            title: "text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900",
            description: "max-w-2xl mx-auto text-lg text-gray-600",
            primaryButton: "bg-brandMaroon hover:bg-brandMaroon/90 text-white",
            secondaryButton: "border-brandMaroon text-brandMaroon hover:bg-brandMaroon hover:text-white"
        },
        university: {
            container: `relative overflow-hidden ${noTopPadding ? '' : 'pt-24'}`,
            background: "bg-gradient-to-br from-lightMaroon/20 via-lightGrey/50 to-white",
            content: "flex flex-col md:flex-row items-center justify-between gap-12",
            badge: "bg-brandMaroon/10 text-brandMaroon",
            title: "text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 text-left",
            description: "max-w-xl text-lg text-gray-600 text-left",
            primaryButton: "bg-brandMaroon hover:bg-darkMaroon text-white",
            secondaryButton: "border-brandMaroon text-brandMaroon hover:bg-brandMaroon hover:text-white"
        },
        affiliate: {
            container: `relative h-screen flex items-center justify-center overflow-hidden ${noTopPadding ? '' : 'pt-24'}`,
            background: "bg-gradient-to-r from-brandMaroon/5 to-lightMaroon/30",
            content: "flex flex-col md:flex-row-reverse items-center justify-between gap-12",
            badge: "bg-brandMaroon/10 text-brandMaroon",
            title: "text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 text-left",
            description: "max-w-xl text-lg text-gray-600 text-left",
            primaryButton: "bg-gradient-to-r from-brandMaroon to-darkMaroon hover:from-brandMaroon/90 hover:to-darkMaroon/90 text-white",
            secondaryButton: "border-brandMaroon text-brandMaroon hover:bg-brandMaroon hover:text-white"
        }
    };

    const style = variants[variant];
    const CustomContent = () => customContent || null;
    return (
        <div className={style.container}>
            {/* Background Pattern */}
            {backgroundPattern === 'grid' && (
                <div className="absolute inset-0 bg-[url('/img/grid-pattern.png')] opacity-5" />
            )}
            {backgroundPattern === 'dots' && (
                <div className="absolute inset-0">
                    <div className="absolute inset-0" style={{
                        backgroundImage: 'radial-gradient(circle at 1px 1px, currentColor 1px, transparent 0)',
                        backgroundSize: '40px 40px',
                        opacity: 0.1
                    }} />
                </div>
            )}
            <div className={`absolute inset-0 ${style.background}`} />

            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-28">
                <div className={style.content}>
                    <div className="flex-1 space-y-8">
                        {badge && (
                            <div className={`inline-block rounded-full px-4 py-1.5 mb-4 ${style.badge}`}>
                                <span className="text-sm font-medium">{badge}</span>
                            </div>
                        )}
                        <h1 className={style.title}>{title}</h1>
                        <p className={style.description}>{description}</p>

                        <div className="flex flex-col sm:flex-row gap-4">
                            <Button
                                onClick={primaryCTA.onClick}
                                className={`w-full sm:w-auto ${style.primaryButton} px-8 py-6 rounded-xl text-lg`}
                            >
                                {primaryCTA.text}
                                {primaryCTA.icon || <ArrowRight className="ml-2 h-5 w-5" />}
                            </Button>
                            {secondaryCTA && (
                                <Button
                                    variant="outline"
                                    onClick={secondaryCTA.onClick}
                                    className={`w-full sm:w-auto ${style.secondaryButton} px-8 py-6 rounded-xl text-lg`}
                                >
                                    {secondaryCTA.text}
                                    {secondaryCTA.icon}
                                </Button>
                            )}
                        </div>
                    </div>

                    {image && (
                        <div className="flex-1">
                            <img
                                src={image}
                                alt="Hero"
                                className="w-full h-auto object-cover rounded-2xl shadow-2xl"
                            />
                        </div>
                    )}
                </div>
                <CustomContent />
            </div>
        </div>
    );
};

export default HeroSection;
