import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { Diamond } from 'lucide-react';

interface ProFeatureBlurProps {
    children: React.ReactNode;
}

const ProFeatureBlur: React.FC<ProFeatureBlurProps> = ({ children }) => {
    const { isPro } = useAuth();
    const { id: projectId } = useParams<{ id: string }>();
    const navigate = useNavigate();

    if (isPro) {
        return <>{children}</>;
    }

    const handleNavigate = () => navigate(`/${projectId}/pricing`);

    return (
        <button
            onClick={handleNavigate}
            className="relative w-full h-full block cursor-pointer"
        >
            <div className="w-full h-full filter blur-sm pointer-events-none">
                {children}
            </div>
            <div
                className="absolute top-[15%] left-1/2 -translate-x-1/2 z-10 flex flex-col items-center gap-4"
            >
                <div
                    className="flex items-center gap-3 px-8 py-4 bg-white text-black rounded-xl shadow-lg hover:bg-gray-100 transition-colors font-Manrope text-xl"
                >
                    <Diamond className="w-7 h-7" />
                    <span className="text-xl">Unlock with Pro</span>
                </div>
            </div>
            <div className="absolute inset-0 bg-black/5" />
        </button>
    );
};

export default ProFeatureBlur; 