import { useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "sonner"
import { createDocument } from "./documentService"
import { WriterDocument } from "./types"

interface CreateDocumentModalProps {
    isOpen: boolean
    onClose: () => void
    projectId?: string
    onDocumentCreated: (document: WriterDocument) => void
}

export function CreateDocumentModal({
    isOpen,
    onClose,
    projectId,
    onDocumentCreated
}: CreateDocumentModalProps) {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const handleCreate = async () => {
        if (!title.trim()) {
            toast.error("Please enter a document title")
            return
        }

        try {
            setIsCreating(true)
            const newDocument = await createDocument(title, description, projectId)
            onDocumentCreated(newDocument)
            toast.success("Document created successfully")
            setTitle("")
            setDescription("")
            onClose()
        } catch (error) {
            toast.error("Failed to create document")
        } finally {
            setIsCreating(false)
        }
    }

    const handleCancel = () => {
        setTitle("");
        setDescription("");
        onClose();
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-md">
                <DialogHeader>
                    <DialogTitle>Create New Document</DialogTitle>
                </DialogHeader>
                <div className="space-y-4 py-4">
                    <div className="space-y-2">
                        <label htmlFor="title" className="text-sm font-medium">
                            Document Title
                        </label>
                        <Input
                            id="title"
                            placeholder="Enter document title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            disabled={isCreating}
                        />
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="description" className="text-sm font-medium">
                            Description
                        </label>
                        <Textarea
                            id="description"
                            placeholder="Enter document description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={isCreating}
                            className="min-h-[100px]"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button
                            variant="outline"
                            onClick={handleCancel}
                            disabled={isCreating}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCreate}
                            disabled={isCreating}
                        >
                            {isCreating ? "Creating..." : "Create Document"}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}