import { useCallback, useState, useMemo } from 'react'
import { Search, SlidersHorizontal, X, Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { defaultFiltersSearch, Filters, searchForPapers, CustomButton } from '@/authService'
import { SourceInfo } from '@/authService'
import { useParams } from 'react-router-dom'
import FilterSidebar from '../filters/FiltersSidebar'
import { toast } from 'sonner'
import { ScrollArea } from "@/components/ui/scroll-area";
import PaperList from '../PaperList/PaperList'
import { useAddToLibrary } from '../PaperList/useAddToLibrary'
import { useLibrary } from '@/contexts/LibraryContext'

interface SearchPapersProps {
    citationHandler: ((source: SourceInfo) => void) | null;
}

export default function SearchPapersComponent({ citationHandler }: SearchPapersProps) {
    const { id } = useParams();
    const { refreshLibrary } = useLibrary();
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [libPapers, setLibPapers] = useState<SourceInfo[]>([]);
    const [filtersInfo, setFiltersInfo] = useState<Filters>(defaultFiltersSearch);
    const { selectedSourceIds, handleCheckboxChange, addToLibrary } = useAddToLibrary(id!);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleSearchQuery = async (query: string) => {
        try {
            setIsLoading(true);
            const papers = await searchForPapers(query, id!, filtersInfo);
            setLibPapers(papers);
            setIsLoading(false);
        } catch (error) {
            if (error instanceof Error && error.message !== "No input in search bar") {
                toast.error("Failed to perform search. Please try again.");
            }
        }
    }
    const handleSearch = async () => {
        if (searchTerm == "") {
            toast.error("Enter what you are looking for before hitting the search button !!")
            throw new Error("No input in search bar")
        }
        handleSearchQuery(searchTerm);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const addCitationToEditor = useCallback((paperId: string) => {
        if (!citationHandler) {
            console.warn('Citation handler not available');
            return;
        }

        const paper = libPapers.find(p => p.id === paperId);
        if (!paper) {
            console.warn('Paper not found:', paperId);
            return;
        }

        citationHandler(paper);
    }, [citationHandler, libPapers]);

    const customButtons: CustomButton[] = useMemo(() => [{
        name: "Add Citation",
        onClick: addCitationToEditor,
        icon: <Plus size={14} />
    }], [addCitationToEditor]);

    const handleAddToLibrary = async (givenId: string | null) => {
        if (givenId) {
            await addToLibrary(givenId);
            // Refresh library using context
            if (id) {
                await refreshLibrary(id);
            }
        }
    };

    return (
        <div className="max-w-3xl mx-auto">
            <div className="flex gap-2">
                <div className="relative flex-1">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
                    <Input
                        placeholder="Search your references"
                        className="pl-10 pr-10"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        onKeyDown={handleKeyDown}
                    />
                    {searchTerm && (
                        <Button
                            variant="ghost" size="icon"
                            className="absolute right-1 top-1/2 transform -translate-y-1/2"
                            onClick={() => setSearchTerm('')}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    )}
                </div>
                <Button
                    variant="outline" size="icon"
                    className="right-1"
                    onClick={() => setIsFilterOpen((prev) => !prev)}
                >
                    <SlidersHorizontal className="h-4 w-4" />
                </Button>
            </div>

            <div className="h-px bg-gray-200 my-4" />
            {(!isLoading && !libPapers.length) && (
                <p className="text-sm text-center flex justify-center">
                    Search in from vast database to get all the relevant papers
                </p>
            )}
            {(isLoading && !isFilterOpen) ? (
                <p className="text-sm text-center h-48 flex items-center justify-center">
                    Loading papers... <div className="spinner"></div>
                </p>
            ) : !isFilterOpen && (
                <div className="mt-4 space-y-4">
                    <ScrollArea className="h-[calc(100vh-240px)] pr-4">
                        <PaperList
                            papers={libPapers}
                            handleCheckboxChange={handleCheckboxChange}
                            isLoading={isLoading}
                            selectedSourceIds={selectedSourceIds}
                            emptyMessage=""
                            handleSave={handleAddToLibrary}
                            id={id!}
                            compactUi={true}
                            customButtonsPaperItem={customButtons}
                        />
                    </ScrollArea>
                </div>
            )}

            {isFilterOpen ? <FilterSidebar
                filters={filtersInfo}
                setFilters={setFiltersInfo}
                isOpen={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
                onApply={handleSearch}
                isSearchingPapers={true}
                isSidebar={false}

            /> : ""}


        </div>
    )
}

