import React, { useState, useEffect } from 'react';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import FullScreenExpandable from "./expandableSections/FullScreenExpandable";

interface SplitViewProps {
    leftComponent: React.ReactNode;
    rightComponent: React.ReactNode;
    leftTitle: string;
    rightTitle: string;
    minimizeForDesktop?: boolean;
    minimizeForMobile?: boolean;

}

const SplitView: React.FC<SplitViewProps> = ({ leftComponent, rightComponent, leftTitle, rightTitle, minimizeForDesktop, minimizeForMobile }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) {
        return (
            <div className="flex flex-col overflow-y-auto">
                <div className="p-4">
                    <FullScreenExpandable title={leftTitle} minimizable={minimizeForMobile}>
                        {leftComponent}
                    </FullScreenExpandable>
                </div>
                <div className="p-4">
                    <FullScreenExpandable title={rightTitle} minimizable={minimizeForMobile}>
                        {rightComponent}
                    </FullScreenExpandable>
                </div>
            </div>
        );
    }

    return (
        <Allotment vertical={false}>
            <Allotment.Pane minSize={300}>
                <div className="h-full overflow-y-auto">
                    <FullScreenExpandable title={leftTitle} minimizable={minimizeForDesktop}>
                        {leftComponent}
                    </FullScreenExpandable>
                </div>
            </Allotment.Pane>
            <Allotment.Pane minSize={300}>
                <div className="h-full overflow-y-auto">
                    <FullScreenExpandable title={rightTitle} minimizable={minimizeForDesktop}>
                        {rightComponent}
                    </FullScreenExpandable>
                </div>
            </Allotment.Pane>
        </Allotment>
    );
};

export default SplitView;