import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

const EmailConfirmation = ({ email }: { email: string }) => {
    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8">
                <div className="flex flex-col items-center">
                    <div className="mb-6 p-3 bg-[#fadae2] rounded-full">
                        <PaperAirplaneIcon className="w-12 h-12 text-brandMaroon" />
                    </div>
                    <h2 className="text-3xl font-bold mb-2 text-gray-800">Confirmation Email Sent</h2>
                    <p className="text-lg font-medium text-brandMaroon mb-4">{email}</p>
                    <p className="text-center text-gray-600 mb-6">
                        Please check your inbox (and spam folder) for a{' '}
                        <span className="font-semibold text-brandMaroon">confirmation link</span> to
                        continue setup.
                    </p>
                    <p className="text-sm text-gray-500">Thank you for registering!</p>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmation;