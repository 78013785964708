import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Paper } from './types';
import PaperItemWithOrigin from './PaperItemWithOrigin';

interface SearchResultsSelectorProps {
    searchResults: Paper[];
    onSelectOrigin: (paper: Paper) => Promise<void>;
    isLoading: boolean;
}

export default function SearchResultsSelector({
    searchResults,
    onSelectOrigin,
    isLoading
}: SearchResultsSelectorProps) {
    const [expandedPaper, setExpandedPaper] = useState<string | null>(null);

    const toggleExpand = (paperId: string) => {
        setExpandedPaper(expandedPaper === paperId ? null : paperId);
    };

    if (searchResults.length === 0 && !isLoading) {
        return (
            <div className="p-4 text-center text-gray-500">
                No papers found matching your search.
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg shadow-lg border border-gray-200 max-h-[70vh] overflow-y-auto">
            <div className="p-4 border-b border-gray-200">
                <h3 className="text-lg font-semibold">Search Results</h3>
                <p className="text-sm text-gray-600">Select a paper to use as the origin for your citation graph</p>
            </div>

            <div className="divide-y divide-gray-100">
                {searchResults.map((paper) => (
                    <div key={paper.paperId} className="p-4 hover:bg-gray-50">
                        <div className="flex gap-4">
                            <div className="flex-1">
                                {/* We wrap PaperItemWithOrigin in a custom container for search-specific styling */}
                                <div className="search-result-wrapper">
                                    <PaperItemWithOrigin
                                        paper={paper}
                                        onSetAsOrigin={onSelectOrigin}
                                        handleCheckboxChange={() => { }}
                                        handleSavePaper={async () => { }}
                                        isAddingPaper={{}}
                                        selectedPapersToSave={new Set()}
                                        projectId=""
                                    />
                                </div>

                                <div className="flex justify-end mt-2">
                                    <button
                                        onClick={() => toggleExpand(paper.paperId)}
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        {expandedPaper === paper.paperId ? (
                                            <ChevronUp className="h-5 w-5" />
                                        ) : (
                                            <ChevronDown className="h-5 w-5" />
                                        )}
                                    </button>
                                </div>

                                {expandedPaper === paper.paperId && (
                                    <div className="mt-3 text-sm text-gray-600 pl-2 border-l-2 border-gray-200">
                                        {paper.abstract || 'No abstract available'}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}