// EditorComponent.tsx
import React from 'react';
import './Editor.css';
import { handleGeneratePdf } from '../../HelperUtils/ExportUtils';
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from '@/components/ui/separator'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Save, Download, Bold, Italic, Underline as UnderlineIcon, AlignLeft, AlignCenter, AlignRight, AlignJustify, ListOrdered, Link as LinkIcon, Image as ImageIcon, Code, ChevronDown, Loader2, Copy, Table, Plus, Trash2, FileText } from 'lucide-react';
import { ListBulletIcon, MagicWandIcon } from '@radix-ui/react-icons';
import { GlobalCitationStyleControl, useGlobalCitationStyle } from '@/contexts/citationContext';
import { Editor } from '@tiptap/react';
import { getEditorAnswerText } from './answerConversion';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';
import { ProFeatureButton } from '@/components/ProFeatureButton';

interface MenuBarProps {
    editor: Editor;
    lastSave: string;
    handleSaveClick: () => void;
    isSaving?: boolean;
    handleDeleteDocument?: () => Promise<void>;
    isDeleting?: boolean;
}

export const MenuBar: React.FC<MenuBarProps> = ({
    editor,
    lastSave,
    handleSaveClick,
    isSaving,
    handleDeleteDocument,
    isDeleting,
}) => {
    const { globalStyle } = useGlobalCitationStyle();
    const navigate = useNavigate();
    const { id: projectId } = useParams<{ id: string }>();

    if (!editor) {
        return null;
    }

    const handleNewDocument = () => {
        navigate(`/${projectId}/ask-answerthis?tab=documents`);
    };

    const MenuButton: React.FC<{
        icon: React.ReactNode;
        label?: string;
        onClick: () => void;
        disabled?: boolean;
        active?: boolean;
        variant?: 'default' | 'destructive' | 'ghost';
    }> = ({ icon, label, onClick, disabled, active, variant = 'default' }) => {
        const handleMouseDown = (event: React.MouseEvent) => {
            event.preventDefault(); // Prevent the button from taking focus
            onClick();
        };

        const getButtonStyles = () => {
            if (variant === 'destructive') {
                return active
                    ? 'bg-red-600 text-white ring-2 ring-red-600 ring-offset-2'
                    : 'hover:bg-red-100 text-red-600';
            }
            return active
                ? 'bg-brandMaroon text-white ring-2 ring-brandMaroon ring-offset-2'
                : 'hover:bg-brandMaroon/10 text-brandMaroon';
        };

        return (
            <Button
                variant={active ? "default" : "ghost"}
                size="sm"
                onMouseDown={handleMouseDown}
                disabled={disabled}
                className={`flex items-center justify-center space-x-1 px-2 py-1 text-base ${getButtonStyles()} transition-all duration-200 ease-in-out`}
            >
                {icon}
                {label && <span className="hidden sm:inline">{label}</span>}
            </Button>
        );
    };

    return (
        <Card className="mb-4 shadow-sm w-full">
            <CardContent className="p-4">
                <div className="flex flex-col">
                    <div className="flex flex-wrap gap-2">
                        <div className="flex items-center gap-2">
                            <MenuButton
                                icon={<FileText className="w-4 h-4" />}
                                onClick={handleNewDocument}
                                label="Documents"
                            />
                            <MenuButton
                                icon={isSaving ? <Loader2 className="w-4 h-4 animate-spin" /> : <Save className="w-4 h-4" />}
                                onClick={handleSaveClick}
                                disabled={isSaving}
                                active={isSaving}
                                label={isSaving ? "Saving..." : "Save"}
                            />
                            <ProFeatureButton onClick={() => handleGeneratePdf('', editor)} retainChildStyle>
                                <MenuButton
                                    icon={<Download className="w-4 h-4" />}
                                    onClick={() => { }}
                                    label="Export"
                                />
                            </ProFeatureButton>
                            <ProFeatureButton
                                onClick={() => {
                                    const text = getEditorAnswerText(editor);
                                    navigator.clipboard.writeText(text)
                                        .then(() => toast.success('Answer text copied to clipboard'))
                                        .catch(() => toast.error('Failed to copy text'));
                                }}
                                retainChildStyle
                            >
                                <MenuButton
                                    icon={<Copy className="w-4 h-4" />}
                                    onClick={() => { }}
                                    label="Copy Text"
                                />
                            </ProFeatureButton>
                            <MenuButton
                                icon={<MagicWandIcon className="w-4 h-4" />}
                                onClick={() => {
                                    editor.chain().focus().run();
                                    editor.chain().focus().updateCitationNumbers().updateCitationStyles(globalStyle).run();
                                    handleSaveClick();
                                    toast.success('Answer reformatted');
                                }}
                                label="Reformat"
                            />
                            {handleDeleteDocument && (
                                <>
                                    <Separator orientation="vertical" className="mx-1 h-6" />
                                    <MenuButton
                                        icon={isDeleting ? <Loader2 className="w-4 h-4 animate-spin" /> : <Trash2 className="w-4 h-4" />}
                                        onClick={handleDeleteDocument}
                                        disabled={isDeleting}
                                        active={isDeleting}
                                        label={isDeleting ? "Deleting..." : "Delete"}
                                        variant="destructive"
                                    />
                                </>
                            )}
                        </div>
                        <Separator orientation="vertical" className="hidden sm:block mx-1 h-6" />
                        <div className="flex flex-wrap items-center gap-2">
                            <MenuButton
                                icon={<Bold className="w-4 h-4" />}
                                onClick={() => editor.chain().focus().toggleBold().run()}
                                active={editor.isActive('bold')}
                            />
                            <MenuButton
                                icon={<Italic className="w-4 h-4" />}
                                onClick={() => editor.chain().focus().toggleItalic().run()}
                                active={editor.isActive('italic')}
                            />
                            <MenuButton
                                icon={<UnderlineIcon className="w-4 h-4" />}
                                onClick={() => editor.chain().focus().toggleUnderline().run()}
                                active={editor.isActive('underline')}
                            />
                            <MenuButton
                                icon={<Code className="w-4 h-4" />}
                                onClick={() => editor.chain().focus().toggleCode().run()}
                                active={editor.isActive('code')}
                            />

                            <Separator orientation="vertical" className="mx-1 h-6" />

                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" size="sm" className="flex items-center space-x-1 text-base p-0">
                                        <AlignLeft className="w-4 h-4 mr-2" />
                                        <ChevronDown className="w-4 h-4 ml-1" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => editor.chain().focus().setTextAlign('left').run()}>
                                        <AlignLeft className="w-4 h-4 mr-2" />
                                        Left
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => editor.chain().focus().setTextAlign('center').run()}>
                                        <AlignCenter className="w-4 h-4 mr-2" />
                                        Center
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => editor.chain().focus().setTextAlign('right').run()}>
                                        <AlignRight className="w-4 h-4 mr-2" />
                                        Right
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => editor.chain().focus().setTextAlign('justify').run()}>
                                        <AlignJustify className="w-4 h-4 mr-2" />
                                        Justify
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" size="sm" className="flex items-center space-x-1 text-base p-0">
                                        <ListBulletIcon className="w-4 h-4 mr-2" />
                                        <ChevronDown className="w-4 h-4 ml-1" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => editor.chain().focus().toggleBulletList().run()}>
                                        <ListBulletIcon className="w-4 h-4 mr-2" />
                                        Bullet List
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => editor.chain().focus().toggleOrderedList().run()}>
                                        <ListOrdered className="w-4 h-4 mr-2" />
                                        Numbered List
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => {
                                        const url = window.prompt('Enter the URL');
                                        if (url) {
                                            editor.chain().focus().setLink({ href: url }).run();
                                        }
                                    }}>
                                        <LinkIcon className="w-4 h-4 mr-2" />
                                        Link
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => {
                                        const input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'image/*');
                                        input.onchange = async () => {
                                            const file = input.files?.[0];
                                            if (file) {
                                                try {
                                                    const reader = new FileReader();
                                                    reader.onload = (e) => {
                                                        const base64String = e.target?.result as string;
                                                        editor.chain().focus().setImage({ src: base64String }).run();
                                                        toast.success('Image added successfully');
                                                    };
                                                    reader.readAsDataURL(file);
                                                } catch (error) {
                                                    toast.error('Failed to add image');
                                                }
                                            }
                                        };
                                        input.click();
                                    }}>
                                        <ImageIcon className="w-4 h-4 mr-2" />
                                        Add Image
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <div className="flex items-center">
                                <GlobalCitationStyleControl />
                            </div>
                            <Separator orientation="vertical" className="mx-1 h-6" />
                            <div className="flex items-center gap-0.5">
                                <MenuButton
                                    icon={<span className="font-bold text-[15px]">H1</span>}
                                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                                    active={editor.isActive('heading', { level: 1 })}
                                />
                                <MenuButton
                                    icon={<span className="font-bold text-[13px]">H2</span>}
                                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                                    active={editor.isActive('heading', { level: 2 })}
                                />
                                <MenuButton
                                    icon={<span className="font-bold text-[11px]">H3</span>}
                                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                                    active={editor.isActive('heading', { level: 3 })}
                                />
                            </div>
                            <Separator orientation="vertical" className="mx-1 h-6" />
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        variant={editor.isActive('table') ? "default" : "ghost"}
                                        size="sm"
                                        className={`flex items-center space-x-1 text-base p-2 ${editor.isActive('table')
                                            ? 'bg-brandMaroon text-white ring-2 ring-brandMaroon ring-offset-2'
                                            : 'hover:bg-brandMaroon/10 text-brandMaroon'
                                            }`}
                                    >
                                        <Table className="w-4 h-4 mr-2" />
                                        <span className="hidden sm:inline">Table</span>
                                        <ChevronDown className="w-4 h-4 ml-1" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem
                                        onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
                                        disabled={editor.isActive('table')}
                                    >
                                        <Table className="w-4 h-4 mr-2" />
                                        Insert Table
                                    </DropdownMenuItem>
                                    {editor.isActive('table') && (
                                        <>
                                            <DropdownMenuItem onClick={() => editor.chain().focus().addColumnBefore().run()}>
                                                <Plus className="w-4 h-4 mr-2" />
                                                Add Column Before
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => editor.chain().focus().addColumnAfter().run()}>
                                                <Plus className="w-4 h-4 mr-2" />
                                                Add Column After
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => editor.chain().focus().deleteTable().run()}>
                                                <Trash2 className="w-4 h-4 mr-2" />
                                                Delete Table
                                            </DropdownMenuItem>
                                        </>
                                    )}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                        <div className="flex-grow"></div>
                        <div className="mt-1 text-xs text-muted-foreground text-right">
                            Last saved: {lastSave}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
