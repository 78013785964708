import React, { useState, useRef } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { registerUser, verificationID } from "../../authService";
import GoogleLoginComponent from "../../components/GoogleLogin";
import { toast, Toaster } from "sonner";
import SeoComp from "../../components/SeoComp";
import EmailConfirmation from "./CheckInbox";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthLayout } from './authCommon';

const Signup: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<'signup' | 'verification'>('signup');
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const handleRegisterClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!captchaToken) {
      toast.error("Please complete the reCAPTCHA.");
      return;
    }

    await registerUser(
      name,
      "",
      email,
      password,
      confirmPassword,
      true,
      "",
      "",
      captchaToken,
      (response) => {
        toast.success(response);
        setCurrentStep('verification');
      },
      (error) => {
        toast.error(error);
        setCurrentStep('signup');
        recaptchaRef.current?.reset();
        setCaptchaToken(null);
      }
    );
  };

  const setEmailError = (message: string) => {
    toast.error(message);
  }

  const isValidEmail = (email: string): boolean => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  if (currentStep === 'verification') {
    return <div className="body"><EmailConfirmation email={email} /></div>;
  }

  return (
    <>
      <SeoComp />
      <Toaster closeButton expand={false} richColors position="top-center" />
      <AuthLayout
        title="Create an account"
        subtitle="Sign up to start using AnswerThis"
      >
        <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Name</label>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="border-gray-200 focus:border-brandMaroon focus:ring-brandMaroon"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Email</label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="border-gray-200 focus:border-brandMaroon focus:ring-brandMaroon"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Password</label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="border-gray-200 focus:border-brandMaroon focus:ring-brandMaroon"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Confirm Password</label>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              className="border-gray-200 focus:border-brandMaroon focus:ring-brandMaroon"
            />
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={verificationID}
              onChange={handleCaptchaChange}
            />
          </div>

          <Button
            onClick={handleRegisterClick}
            className="w-full bg-brandMaroon hover:bg-darkMaroon text-white transition-colors"
          >
            Sign up
          </Button>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>

          <GoogleLoginComponent />

          <div className="text-center text-sm text-gray-600 mt-4">
            <p>
              By signing up you agree to our{' '}
              <a href="/terms" target="_blank" className="text-brandMaroon hover:text-darkMaroon">
                Terms & Privacy Policy
              </a>
            </p>
          </div>

          <p className="text-center text-gray-600 pt-4">
            Already have an account?{' '}
            <a href="/login" className="text-brandMaroon hover:text-darkMaroon">
              Sign in
            </a>
          </p>
        </form>
      </AuthLayout>
    </>
  );
};

export default Signup;
