import { useState, useCallback, useEffect, useRef } from "react"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { ArrowRight, FileText, Plus, Settings, X } from 'lucide-react'
import { useSidebar } from '../../contexts/sidebarContext';
import { useParams } from 'react-router-dom';
import SlicedText from "../slicedText"
import { fetchReview, FetchReviewResult } from "@/HelperUtils/AnswerFetcher"
import { defaultFilters, Filters, rerunQuery, sendQuery, SourceInfo } from "@/authService"
import AnswerChatView from "../AnswerChatView"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import AnswerTable from "../AnswerTable/AnswerTable"
import { toast } from "sonner"
import { SearchBar } from "../searchInput/SearchBar"
import FilterSidebar from "../filters/FiltersSidebar"
import UploadedFilesList from "@/pages/MainUploadPage/UploadFilesList"
import { useAuth } from "@/contexts/AuthContext"
import documentIcon from "/img/document-icon-gray.svg";
import { Editor } from "@tiptap/core"
import Loading from "../Loading"


export default function ChatInterface({ editor }: { editor: Editor }) {
    const { id } = useParams<{ id: string, query_hash: string }>();
    const { user, checkCredits, removeCredits } = useAuth();

    const bottomRef = useRef<HTMLDivElement | null>(null);

    const [unusedSources, setUnusedSources] = useState<SourceInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reviewData, setReviewData] = useState<FetchReviewResult | null>(null);
    const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);
    const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);

    const [answerFilters, setAnswerFilters] = useState<Filters>(defaultFilters);
    const [query, setQuery] = useState<string>('');

    const { recentSearches, isNewChat, handleNewChat, refreshSidebar } = useSidebar();

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filtersInfo, setFiltersInfo] = useState<Filters>({ ...defaultFilters, answerStyle: 'research_assistant' });
    const [searchBarContext, setSearchBarContext] = useState<string>("");
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [getPapers, setGetPapers] = useState<boolean>(false);
    const [hasFreeUploads, setHasFreeUploads] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (recentSearches.length && !currentProjectId) {
            const recentSearch = recentSearches[0];
            fetchData(recentSearch.query_hash);
            setCurrentProjectId(recentSearch.query_hash);
        }
    }, [recentSearches])

    const handleNewChatClick = () => {
        handleNewChat(true);
        setCurrentProjectId(null);
        setQuery('');
        setUploadedFiles([]);
        setGetPapers(false);
        setReviewData(null);
        setCurrentAnswerIndex(0);
    }

    const fetchData = async (query_hash: string) => {
        setIsLoading(true);
        try {
            const data = await fetchReview(query_hash);
            setReviewData(data);
            setCurrentAnswerIndex(data.answers.length - 1)
            if (data.answers.length > 0) {
                setAnswerFilters(data.answers[0].answerObject.filters);
                if (data.answers[0].answerObject.unused_sources && data.answers[0].answerObject.unused_sources.result) {
                    setUnusedSources(Object.values(data.answers[0].answerObject.unused_sources.result));
                } else {
                    setUnusedSources([]);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setIsLoading(false);
    };




    const handleAnswerUpdate = useCallback((updatedAnswer: string) => {
        if (reviewData) {
            const updatedAnswers = [...reviewData.answers];
            updatedAnswers[currentAnswerIndex] = {
                ...updatedAnswers[currentAnswerIndex],
                formattedAnswer: updatedAnswer,
            };
            setReviewData({ ...reviewData, answers: updatedAnswers });
        }
    }, [reviewData, currentAnswerIndex]);

    const handleChatSession = (chatSession: string) => {
        setCurrentProjectId(chatSession);
        fetchData(chatSession);
        setQuery(''); // Clear query when switching sessions
        setUploadedFiles([]); // Clear uploaded files
        setGetPapers(false); // Reset getPapers state
        handleNewChat(false); // Reset new chat state when switching to existing chat
    }

    useEffect(() => {
        setContext();
    }, [user]);

    const upload = useCallback(
        async (filesArray: File[]) => {
            if (filesArray.length === 0 && !getPapers) {
                toast.error("Please select files to upload before asking a query.");
                return;
            }

            try {
                const hasEnoughCredits = checkCredits(id!, getPapers ? 1 : filesArray.length);
                if (!hasEnoughCredits) {
                    return;
                }

                await sendQuery(
                    id!,
                    query,
                    getPapers,
                    filtersInfo,
                    filesArray,
                    async (response) => {
                        console.log("Upload response:", response);
                        await removeCredits(getPapers ? 1 : filesArray.length);
                        setUploadedFiles([]);
                        const queryHash = response.query_hash;
                        refreshSidebar();
                        handleNewChat(false);
                        handleChatSession(queryHash)
                        toast.success("Query sent successfully");
                    },
                    (errorMessage) => {
                        console.error(errorMessage);
                        toast.error(errorMessage);
                    }
                );
                setIsSubmitDisabled(false);
            } catch (error) {
                toast.error(error instanceof Error ? error.message : "An error occurred");
                setIsSubmitDisabled(false);
            }
        },
        [query, getPapers, filtersInfo, id, refreshSidebar, handleNewChat, checkCredits, removeCredits]
    );

    const handleSubmit = () => {
        setIsSubmitDisabled(true);
        if (uploadedFiles.length > 0 && query.length > 0) {
            console.log("sending files here");
            upload(uploadedFiles);
        } else if (getPapers && query.length > 0) {
            console.log("sending query here");
            upload([]);
        } else if (!getPapers && uploadedFiles.length === 0) {
            if (!checkCredits(id!, 1)) {
                setShowFilters(false);
                setIsSubmitDisabled(false);
                return;
            }
        } else if (query.length === 0) {
            toast.error("You need to ask a question first");
            setShowFilters(false);
            setIsSubmitDisabled(false);
        }
    };

    const handleNewQuestionSubmit = async (query: string) => {
        if (!checkCredits(id!, 1)) {
            return;
        }

        try {
            setIsLoading(true);
            if (currentProjectId) {
                await rerunQuery(currentProjectId, query);
                refreshSidebar();
                fetchData(currentProjectId);
                setQuery('');
            } else {
                handleNewChat(false);
                await sendQuery(
                    id!,
                    query,
                    true,
                    filtersInfo,
                    [],
                    (response) => {
                        console.log("Upload response:", response);
                        const queryHash = response.query_hash;
                        refreshSidebar();
                        handleChatSession(queryHash);
                    },
                    (errorMessage) => {
                        console.error(errorMessage);
                        toast.error(errorMessage);
                        setIsLoading(false);
                    }
                );
            }
        } catch (error) {
            console.error("Error with query:", error);
            toast.error(error instanceof Error ? error.message : "An error occurred");
            setIsLoading(false);
        }
    };
    const showFiltersSidebar = () => {
        setShowFilters(!showFilters);
    }
    const handleShowFilters = () => {
        if (uploadedFiles.length > 0) {
            handleSubmit();
        } else {
            if (getPapers) {
                setShowFilters(!showFilters);
            } else {
                checkCredits(id!, 1);
            }
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        setUploadedFiles(files);
        // Reset the input value so the same file can be selected again
        event.target.value = '';
    };

    const setContext = () => {
        if (user?.level === "trial" && (user?.credits ?? 0) <= 0) {
            setSearchBarContext(
                "No credits left, you need to upgrade to pro. It will also help us continue building answerthis."
            );
            setGetPapers(false);
            setHasFreeUploads(false);
        } else if (uploadedFiles.length > 0) {
            setSearchBarContext(
                `Answering question from: ${uploadedFiles.length} uploaded papers `
            );
            setGetPapers(false);
            setHasFreeUploads(true);
        } else {
            setSearchBarContext(
                ""
            );
            setGetPapers(true);
            setHasFreeUploads(true);
        }
    };

    const handleRemoveFile = (index: number) => {
        const newFiles = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles(newFiles);
    };

    useEffect(() => {
        setContext();
        if (uploadedFiles.length > 0) {
            setGetPapers(false);
        } else {
            setGetPapers(true);
        }
    }, [uploadedFiles]);

    const handleShowFileUpload = () => {
        fileInputRef.current?.click();
    };

    const handleSubmitAssistantQuery = () => {
        if (currentProjectId) {
            handleNewQuestionSubmit(query);
        } else {
            handleSubmit();
        }
    }


    return (
        <div className="flex flex-col h-full w-full">
            {/* Header */}
            <div className="flex items-center justify-between py-4 border-b px-4 w-full">
                {recentSearches.length > 0 ? (
                    <Select value={currentProjectId || "New chat session"} onValueChange={handleChatSession}>
                        <SelectTrigger className="w-[200px]">
                            <SelectValue placeholder="New chat session" />
                        </SelectTrigger>
                        <SelectContent>
                            {recentSearches.map((chat) => (
                                <SelectItem key={chat.query_hash} value={chat.query_hash}>
                                    <span className="font-normal text-sm block">
                                        <SlicedText text={chat.query_text} maxLength={20} />
                                    </span>
                                    <span className="text-xs text-gray-500">
                                        {new Date(chat.query_madeAt).toLocaleDateString()}
                                    </span>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                ) : (
                    <div className="text-sm text-gray-500">New chat session</div>
                )}
                <Button variant="ghost" size="icon" onClick={handleNewChatClick}>
                    <Plus className="h-4 w-4" />
                </Button>
            </div>

            {/* Main Content */}
            <div className="flex-1 min-h-0 flex flex-col w-full">
                <div className="flex-1 overflow-auto w-full">
                    {isLoading ? (
                        <div className="h-full w-full flex items-center justify-center">
                            <Loading question={query} />
                        </div>
                    ) : ((isNewChat && !isLoading) || recentSearches.length == 0) ? (
                        <div className="flex flex-col space-y-6 p-4 w-full">
                            {/* Feature Cards */}
                            <Card className="p-4">
                                <div className="flex items-center gap-2 mb-2">
                                    <div className="flex -space-x-1">
                                        <div className="w-5 h-5 bg-purple-100 rounded-full" />
                                        <div className="w-5 h-5 bg-gray-100 rounded-full" />
                                        <div className="w-5 h-5 bg-orange-100 rounded-full" />
                                    </div>
                                </div>
                                <p className="text-sm text-muted-foreground">
                                    Conduct reliable, citation-backed research with three different AI modes.
                                </p>
                            </Card>

                            <Card className="p-4">
                                <div className="flex items-center gap-2 mb-2">
                                    <ArrowRight className="w-4 h-4" />
                                    <div className="w-4 h-4 rounded bg-red-100" />
                                </div>
                                <p className="text-sm text-muted-foreground">
                                    Connect your document to the AI assistant and ask research questions on your writing.
                                </p>
                            </Card>

                            <Card className="p-4">
                                <div className="flex items-center gap-2 mb-2">
                                    <Plus className="w-4 h-4" />
                                    <ArrowRight className="w-4 h-4" />
                                    <div className="w-4 h-4" />
                                </div>
                                <p className="text-sm text-muted-foreground">
                                    Add answers and citations into your document in just one click.
                                </p>
                            </Card>

                            {/* Filters Section */}
                            {showFilters && getPapers && !currentProjectId && (
                                <div className="border rounded-lg p-4 bg-gray-50">
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="font-medium">Search Filters</h3>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => setShowFilters(false)}
                                        >
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                    <FilterSidebar
                                        isOpen={true}
                                        filters={filtersInfo}
                                        setFilters={setFiltersInfo}
                                        onClose={showFiltersSidebar}
                                        onApply={handleSubmit}
                                        isSidebar={false}
                                        showTopBar={false}
                                        showSubmitButton={false}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="h-full w-full flex flex-col">
                            <Tabs defaultValue="assistant" className="w-full h-full flex flex-col">
                                <TabsList className="grid w-full grid-cols-2 mb-4 sticky top-0 z-10">
                                    <TabsTrigger value="assistant" className="flex items-center gap-2">
                                        <FileText className="h-4 w-4" />
                                        Assistant
                                    </TabsTrigger>
                                    <TabsTrigger value="sources" className="flex items-center gap-2">
                                        <Settings className="h-4 w-4" />
                                        Sources
                                    </TabsTrigger>
                                </TabsList>

                                <TabsContent value="assistant">
                                    <div className='rounded-lg'>
                                        {reviewData && reviewData.answers.map((answer, index) => (
                                            <div key={index.toString()} className="mb-4">
                                                <AnswerChatView
                                                    id={id!}
                                                    answer={answer}
                                                    index={index}
                                                    chatIndex={currentProjectId!}
                                                    handleAnswerUpdate={handleAnswerUpdate}
                                                    answerFilters={answerFilters}
                                                    compactUi={true}
                                                    editor={editor}
                                                />
                                            </div>
                                        ))}
                                        <div ref={bottomRef}></div>
                                    </div>
                                </TabsContent>

                                <TabsContent value="sources">
                                    <div id="sourceSection" >
                                        {reviewData && reviewData.answers[currentAnswerIndex].papersArraySorted.length > 0 ? (
                                            <div className="p-4">
                                                <AnswerTable
                                                    compactUi={true}
                                                    data={reviewData.answers[currentAnswerIndex].papersArraySorted}
                                                    unusedSources={unusedSources}
                                                    referencesIdNumbers={reviewData.answers[currentAnswerIndex].idNumbers}
                                                    query_hash={currentProjectId!}
                                                    projectId={id!}
                                                />
                                            </div>
                                        ) : (
                                            <p className="p-4 text-gray-500">No sources available</p>
                                        )}
                                    </div>
                                </TabsContent>
                            </Tabs>
                        </div>
                    )}
                </div>

                {/* Input Area */}
                <div className="flex-shrink-0 border-t bg-white w-full">
                    <div className="px-4 pt-4 pb-4">
                        <div className="select-where-from mb-2">
                            <p className={`${hasFreeUploads ? "has-free-uploads" : "has-no-free-uploads"}`}>
                                {searchBarContext}
                            </p>
                        </div>

                        {/* Search Bar and Actions */}
                        <div className="flex items-center gap-2 mb-4">
                            <SearchBar
                                compactUi={true}
                                handleInputChange={(e) => setQuery(e.target.value)}
                                query={query}
                                handleSearch={handleSubmitAssistantQuery}
                                placeholder={reviewData ? "Ask a follow up question" : "Enter your question"}
                                submitIcon={<ArrowRight></ArrowRight>}
                                disabled={isSubmitDisabled}
                            />
                            <div className="flex items-center gap-2">
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    className="hidden"
                                    multiple
                                    accept=".pdf"
                                    onChange={handleFileSelect}
                                />
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-10 w-10"
                                    onClick={handleShowFileUpload}
                                    title="Upload PDFs"
                                >
                                    <img
                                        src={documentIcon}
                                        alt="Upload PDFs"
                                        className="w-8 h-8"
                                    />
                                </Button>
                                {!currentProjectId && (
                                    <Button
                                        variant="outline"
                                        size="icon"
                                        className={`h-10 w-10 ${showFilters ? 'bg-brandMaroon text-white' : ''}`}
                                        onClick={handleShowFilters}
                                    >
                                        <Settings className="h-4 w-4" />
                                    </Button>
                                )}
                            </div>
                        </div>

                        {/* Uploaded Files List */}
                        <UploadedFilesList files={uploadedFiles} onRemoveFile={handleRemoveFile} />
                    </div>
                </div>
            </div>
        </div>
    )
}

