import { Button } from "@/components/ui/button";
import { Ghost, Home, ArrowLeft } from 'lucide-react';

const NotFound = () => {
    return (
        <div className="h-screen w-full flex flex-col items-center justify-center bg-lightGrey">
            <div className="max-w-2xl px-8 py-16 text-center">
                {/* Ghost Icon with animated float effect */}
                <div className="mb-8 animate-bounce">
                    <Ghost
                        size={64}
                        className="mx-auto text-brandMaroon"
                    />
                </div>

                {/* Error Message */}
                <h1 className="mb-4 text-6xl font-bold text-darkMaroon">404</h1>
                <h2 className="mb-8 text-3xl font-semibold text-brandMaroon">
                    Page Not Found
                </h2>

                {/* Description */}
                <p className="mb-8 text-lg text-darkMaroon">
                    Oops! It seems you've wandered into uncharted territory.
                    The page you're looking for has mysteriously disappeared.
                </p>

                {/* Action Buttons */}
                <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                    <Button
                        variant="default"
                        className="bg-brandMaroon hover:bg-darkMaroon text-white"
                        onClick={() => window.history.back()}
                    >
                        <ArrowLeft className="mr-2 h-4 w-4" />
                        Go Back
                    </Button>

                    <Button
                        variant="outline"
                        className="border-brandMaroon text-brandMaroon hover:bg-lightMaroon hover:text-darkMaroon"
                        onClick={() => window.location.href = '/'}
                    >
                        <Home className="mr-2 h-4 w-4" />
                        Return Home
                    </Button>
                </div>

                {/* Decorative Elements */}
                <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-lightMaroon via-brandMaroon to-darkMaroon" />
                <div className="absolute bottom-0 left-0 w-full h-2 bg-gradient-to-r from-darkMaroon via-brandMaroon to-lightMaroon" />
            </div>
        </div>
    );
};

export default NotFound;