import {
    getAnswer, SourceInfo, formatChicago, AnswerObject, referencePattern,
    getChatMessages
} from "../authService";

import { marked } from "marked";

export interface FetchReviewResult {
    answers: AnswerData[];
    allSources: SourceInfo[];
    allIdNumbers: ReferencesIdNumbers;
}

export interface AnswerData {
    combinedAnswer: string;
    formattedAnswer: string;
    answerObject: AnswerObject;
    papersArraySorted: SourceInfo[];
    idNumbers: ReferencesIdNumbers;
}

interface ReferencesIdNumbers {
    [id: string]: number;
}

export const convertToReferencesId = (sources: { [id: string]: SourceInfo }): { [id: string]: string } => {
    const referencesId: { [id: string]: string } = {};
    for (const databaseID in sources) {
        if (sources[databaseID] && sources[databaseID].sections && sources[databaseID].sections.length)
            for (let j = 0; j < sources[databaseID].sections.length; j++) {
                referencesId[sources[databaseID].sections[j].id] = databaseID;
            }
        else {
            referencesId[databaseID] = databaseID;
        }
    }
    return referencesId;
};

export const convertDocumentReferencesToIdLinks = (
    answerText: string,
    sources: { [id: string]: SourceInfo },
    referencesId: { [id: string]: string },
    citationStyle: string = 'numberedLinks'
): { convertedText: string; idNumbers: { [id: string]: number } } => {
    if (!sources || typeof sources !== "object") {
        console.error("Sources are not defined or not an object:", sources);
        return { convertedText: answerText, idNumbers: {} };
    }

    let referenceNumber = 0;
    const referencesIdNumbers: { [id: string]: number } = {};

    const convertedText = answerText.replace(referencePattern, (_match, id) => {
        const sourceId = referencesId[id];

        if (!referencesIdNumbers[sourceId]) {
            referenceNumber += 1;
            referencesIdNumbers[sourceId] = referenceNumber;
        }
        const source = Object.values(sources).find((source) =>
            source.sections.some((section) => section.id === id)
        );
        if (source) {
            let authorText = "";
            let date;

            if (
                source.date_of_publication === "" ||
                source.date_of_publication === null ||
                source.date_of_publication === undefined ||
                source.date_of_publication === "Internet_result"
            ) {
                date = "n.d.";
            } else {
                date = new Date(source.date_of_publication).getFullYear();
            }
            if (source.doi === "Internet_result") {
                return `<a href="#${id}">${source.title}</a>`;
            }
            if (source.author) {
                const authors = source.author.split(", ");

                if (authors.length === 1) {
                    if (authors[0] === "" || authors[0] === "None") {
                        authorText = source.title;
                    } else {
                        authorText = authors[0];
                    }
                } else if (authors.length === 2) {
                    authorText = `${authors[0]} & ${authors[1]}`;
                } else if (authors.length >= 3) {
                    authorText = `${authors[0]} et al.`;
                }
            } else {
                authorText = source.title;
            }

            if (citationStyle === 'numberedLinks') {
                return `<a href="#${id}">[${referencesIdNumbers[sourceId]}]</a>`;
            } else {
                return `<a href="#${id}">${authorText}, ${date}</a>`;
            }
        }

        return _match;
    });

    return { convertedText, idNumbers: referencesIdNumbers };
};

export const sortPapersArray = (
    sources: { [id: string]: SourceInfo },
    idNumbers: { [id: string]: number }
): SourceInfo[] => {
    return Object.values(sources)
        .filter((sourceInfo) => {
            let summaryCondition = false;

            if (sourceInfo && sourceInfo.summary) {
                summaryCondition = !sourceInfo.summary
                    .toLowerCase()
                    .includes("not possible given");
            }
            let referencesCondition = false;
            if (sourceInfo && sourceInfo.sections) {
                referencesCondition = sourceInfo.sections.length > 0;
            }

            return (
                (sourceInfo.summary && summaryCondition && referencesCondition) ||
                idNumbers[sourceInfo.id] !== undefined
            );
        })
        .sort((a, b) => {
            const refA = idNumbers[a.id] || Number.MAX_VALUE;
            const refB = idNumbers[b.id] || Number.MAX_VALUE;
            return refA - refB;
        });
};

export const fetchReview = async (reviewId: string): Promise<FetchReviewResult> => {
    const answerDataTotal: AnswerObject[] = await getAnswer(reviewId);

    return await answerObjectListToFormattedAnswer(answerDataTotal);
};


export const fetchChats = async (reviewId: string): Promise<FetchReviewResult> => {
    const answerDataTotal: AnswerObject[] = await getChatMessages(reviewId);
    return await answerObjectListToFormattedAnswer(answerDataTotal);

};
export async function answerObjectListToFormattedAnswer(answerDataTotal: AnswerObject[]) {
    const answersArray = Array.isArray(answerDataTotal) ? answerDataTotal : [answerDataTotal];
    const processedAnswers: AnswerData[] = await Promise.all(answersArray.map(processAnswerData));

    const allSources = processedAnswers.flatMap(answer => answer.papersArraySorted);
    const allIdNumbers = processedAnswers.reduce((acc, answer) => ({ ...acc, ...answer.idNumbers }), {});

    return {
        answers: processedAnswers,
        allSources,
        allIdNumbers
    };
}

export function formatReferences(papersArraySorted: any[],) {
    // Create references with proper formatting and line breaks
    const references = papersArraySorted
        .map((sourceInfo, index) => {
            const referenceNumber = index + 1;
            const formattedReference = formatChicago(sourceInfo);
            return `[${referenceNumber}] ${formattedReference}`;
        })
        .join('<br/>'); // Double line break between references

    return references;
}
async function processAnswerData(answerData: AnswerObject): Promise<{ combinedAnswer: string; formattedAnswer: string; answerObject: AnswerObject; papersArraySorted: SourceInfo[]; idNumbers: { [id: string]: number; }; }> {

    const papersArray = answerData.sources.result;
    const referencesId = convertToReferencesId(papersArray);

    let answerText = answerData.answer;
    console.log("Answer text:", answerText);
    answerText = answerText.replace(/\n(?=\n)/g, "\n\n<br/>\n");
    answerText = answerText.replace("```html", "");
    answerText = answerText.replace("```", "");
    answerText = await marked(answerText, { breaks: true, gfm: true, });
    console.log("Answer text:", answerText);
    const { convertedText: formattedAnswer, idNumbers } = convertDocumentReferencesToIdLinks(
        answerText,
        answerData.sources.result,
        referencesId
    );

    const papersArraySorted = sortPapersArray(answerData.sources.result, idNumbers);

    const references = formatReferences(papersArraySorted);

    const combinedAnswer = `${formattedAnswer} ${references}`;

    return {
        combinedAnswer,
        formattedAnswer,
        answerObject: answerData,
        papersArraySorted,
        idNumbers
    };

}
