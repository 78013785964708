import React, { ReactNode } from 'react';
import { Card } from '@/components/ui/card';

interface StepCardProps {
    title: string;
    description: string;
    icon: ReactNode;
    action?: ReactNode;
    preview?: ReactNode;
    highlight?: ReactNode;
}

const StepCard: React.FC<StepCardProps> = ({
    title,
    description,
    icon,
    action,
    preview,
    highlight
}) => {
    return (
        <Card className="relative group hover:shadow-lg transition-shadow duration-300">
            <div className="absolute -inset-px bg-gradient-to-r from-brandMaroon to-purple-600 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <div className="relative bg-white rounded-[7px] p-6 h-full">
                <div className="w-12 h-12 bg-brandMaroon/10 rounded-xl flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-semibold mb-3">{title}</h3>
                <p className="text-gray-600 mb-4">{description}</p>
                {action}
                {preview}
                {highlight}
            </div>
        </Card>
    );
};

export default StepCard;
