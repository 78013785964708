import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="h-screen flex justify-center items-center">
            <h1>Subscription Cancelled</h1>
            <p>Your subscription process was cancelled. If this was a mistake, please try again.</p>
            <button onClick={() => navigate('/pricing')}>Return to Pricing</button>
        </div>
    );
};

export default CancelPage;