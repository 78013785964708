import React, { useState } from 'react';

interface SlicedTextProps {
    text: string;
    maxLength?: number;
}

const SlicedText: React.FC<SlicedTextProps> = ({ text, maxLength = 400 }) => {
    const [showFull, setShowFull] = useState(false);

    const toggleShowFull = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowFull(!showFull);
    };

    const slicedText = text.length > maxLength ? text.slice(0, maxLength) : text;

    return (
        <div className="group cursor-pointer">
            {showFull ? text : slicedText}
            {text.length > maxLength && (
                <span
                    className="text-brandMaroon font-bold ml-1"
                    onClick={toggleShowFull}
                >
                    {showFull ? ' (Less)' : '...'}
                </span>
            )}
        </div>
    );
};

export default SlicedText;