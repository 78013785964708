import { getAnswerText, SourceInfo } from '../authService';
import { Document, Packer, Paragraph, HeadingLevel } from 'docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { Table, TableRow, TableCell, WidthType } from 'docx';
import autoTable from 'jspdf-autotable';
import { Editor } from '@tiptap/react';
import { getEditorAnswerText } from '@/components/editor/answerConversion';

export const exportToBibTeX = (papers: SourceInfo[]): string => {
    return papers.map(paper => {
        return `@article{${paper.id},
    title = {${paper.title}},
    author = {${paper.author}},
    journal = {${paper.journal}},
    year = {${new Date(paper.date_of_publication).getFullYear()}},
    doi = {${paper.doi}},
    url = {${paper.url}}
}`;
    }).join('\n');
};

export const exportToCSV = (
    papers: SourceInfo[],
    customColumns: string[],
    customColumnData: { [key: string]: { [key: string]: string } }
): string => {
    const standardHeaders = ["Title", "Author", "Journal", "DOI", "Date of Publication", "Citations", "URL", "Abstract", "Relevant Sections"];
    const allHeaders = [...standardHeaders, ...customColumns];

    // Sanitize and escape function for CSV fields
    const sanitizeAndEscapeField = (field: any): string => {
        // Handle various empty/null cases
        if (field === null || field === undefined) {
            return '';
        }

        // Handle numbers and other non-string types
        if (typeof field === 'number') {
            return field.toString();
        }

        // Handle boolean values
        if (typeof field === 'boolean') {
            return field.toString();
        }

        // Handle arrays (like sections) by joining with newlines
        if (Array.isArray(field)) {
            return field.map(item => item?.toString() || '').join('\n');
        }

        // Convert to string, trimming whitespace
        const str = field.toString().trim();

        // Check if the field needs escaping
        const needsEscape = str.includes('"') || str.includes(',') || str.includes('\n') || str.includes('\r');

        if (!needsEscape) {
            return str || ''; // Return empty string for whitespace-only strings
        }

        // Double up any existing quotes and wrap in quotes
        return `"${str.replace(/"/g, '""')}"`;
    };

    const headers = allHeaders.map(sanitizeAndEscapeField).join(',') + '\n';

    const data = papers.map(paper => {
        const standardData = [
            paper?.title,
            paper?.author,
            paper?.journal,
            paper?.doi,
            paper?.date_of_publication,
            paper?.citations,
            paper?.url,
            paper?.abstract,
            paper?.sections?.map(section => section?.content).join('\n') || []
        ];

        const customData = customColumns.map(column =>
            customColumnData[column]?.[paper?.id ?? '']
        );

        return [...standardData, ...customData]
            .map(sanitizeAndEscapeField)
            .join(',');
    }).join('\n');

    return headers + data;
};

export const downloadFile = (data: string, filename: string, type: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            const file = new Blob([data], { type });
            const url = URL.createObjectURL(file);

            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filename;

            document.body.appendChild(a);

            a.onclick = () => {
                // Use setTimeout to delay the cleanup
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                    if (a.parentNode === document.body) {
                        document.body.removeChild(a);
                    }
                    resolve();
                }, 150); // Delay of 150ms to ensure download starts before cleanup
            };

            a.click();

            // Fallback in case the click event doesn't fire
            setTimeout(() => {
                if (a.parentNode === document.body) {
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                    resolve();
                }
            }, 2000); // 2 second fallback

        } catch (error) {
            reject(new Error(`Failed to download file: ${error instanceof Error ? error.message : String(error)}`));
        }
    });
};

const sanitizeTitle = (title: string): string => {
    return title
        .substring(0, 50) // Increased from 30 to 50 to allow longer filenames
        .replace(/[^\p{L}\p{N}\s-]/gu, '')
        .trim()
        .replace(/\s+/g, '_');
};

export const handleGenerateDocx = async (question: string, editor?: Editor) => {
    const content = editor ? getEditorAnswerText(editor) : getAnswerText();
    const lines = content.split('\n');

    // Title handling logic
    const maxTitleLength = 100; // Characters per line for title
    const titleLines = [];
    let remainingTitle = question;

    while (remainingTitle.length > 0) {
        let cutIndex = remainingTitle.length > maxTitleLength
            ? remainingTitle.lastIndexOf(' ', maxTitleLength)
            : remainingTitle.length;

        if (cutIndex === -1) cutIndex = maxTitleLength;

        titleLines.push(remainingTitle.substring(0, cutIndex));
        remainingTitle = remainingTitle.substring(cutIndex).trim();
    }

    const parseTable = (line: string) => {
        const rows = line.split('\n').map(row => row.split('\t'));
        return new Table({
            rows: rows.map(cells =>
                new TableRow({
                    children: cells.map(cell =>
                        new TableCell({
                            children: [new Paragraph({ text: cell })],
                        })
                    ),
                })
            ),
            width: { size: 100, type: WidthType.PERCENTAGE },
        });
    };

    const isTable = (line: string) => line.includes('\t');

    const doc = new Document({
        sections: [{
            properties: {},
            children: [
                ...titleLines.map(titleLine =>
                    new Paragraph({
                        text: titleLine,
                        heading: HeadingLevel.HEADING_1,
                        spacing: { after: 200 },
                    })
                ),
                new Paragraph({ text: '', spacing: { after: 400 } }),
                ...lines.map(line =>
                    isTable(line)
                        ? parseTable(line)
                        : new Paragraph({ text: line, spacing: { after: 200 } })
                ),
            ],
        }],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${sanitizeTitle(question)}.docx`);
};

export const handleGeneratePdf = async (question: string, editor?: Editor) => {
    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
    }) as any;

    pdf.addFont('/fonts/NotoSans.ttf', 'NotoSans', 'normal');
    pdf.setFont('NotoSans');

    const content = editor ? getEditorAnswerText(editor) : getAnswerText();
    const lines = content.split('\n');

    // Configure margins and spacing
    const margin = 20;
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    const bottomMargin = 20;
    let yPosition = margin;

    // Title handling logic
    pdf.setFontSize(16);
    const titleMaxWidth = pageWidth - 2 * margin;
    const wrappedTitle = pdf.splitTextToSize(question, titleMaxWidth);

    wrappedTitle.forEach((titleLine: string) => {
        pdf.text(titleLine, margin, yPosition);
        yPosition += 8; // Space between title lines
    });

    yPosition += 10; // Extra space between title and content

    // Function to handle tables
    const isTable = (line: string) => line.includes('\t');
    const parseTable = (line: string) => {
        const rows = line.split('\n').map(row => row.split('\t'));

        autoTable(pdf, {
            startY: yPosition,
            head: [rows[0]],
            body: rows.slice(1),
            theme: 'grid', // Use a professional-looking grid theme
            styles: {
                font: 'NotoSans', // Use the added font
                fontSize: 10,
                cellPadding: 5,
                valign: 'middle',
                halign: 'center',
                lineWidth: 0.1,
                lineColor: [220, 220, 220], // Light gray for borders
            },
            headStyles: {
                fillColor: [240, 240, 240], // Light gray for the header background
                textColor: [0, 0, 0], // Black text for headers
                fontStyle: 'bold',
            },
            bodyStyles: {
                textColor: [60, 60, 60], // Dark gray for body text
            },
            alternateRowStyles: {
                fillColor: [245, 245, 245], // Very light gray for alternating rows
            },
            tableLineWidth: 0.1,
            tableLineColor: [200, 200, 200], // Subtle border color
        });

        yPosition = pdf.lastAutoTable.finalY + 10; // Update yPosition after table rendering
    };
    pdf.setFontSize(12);

    lines.forEach((line) => {
        if (isTable(line)) {
            parseTable(line);
        } else {
            const wrappedLines = pdf.splitTextToSize(line, pageWidth - 2 * margin);

            wrappedLines.forEach((wrappedLine: string) => {
                if (yPosition > pageHeight - bottomMargin) {
                    pdf.addPage();
                    yPosition = margin;
                }

                pdf.text(wrappedLine, margin, yPosition);
                yPosition += 6;
            });

            yPosition += 4; // Extra space after each original line
        }
    });

    pdf.save(`${sanitizeTitle(question)}.pdf`);
};

