import React, { useState } from "react";
import { AnswerObject, Filters, getAnswerText, sendQuery } from "../../authService";
import AnswerThisFiltersComponent from "./AnswerThisFilterComponent";
import FilterSidebar from "../../components/filters/FiltersSidebar";
import { handleGenerateDocx, handleGeneratePdf } from "../../HelperUtils/ExportUtils";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { CopyIcon, DownloadIcon, Repeat, SlidersHorizontal } from "lucide-react";
import { buttonClass } from "@/components/Buttons/buttonClasses";
import { ProFeatureButton } from "@/components/ProFeatureButton";

interface FiltersAnswerProps {
    id: string;
    answerFilters: Filters;
    answerObject: AnswerObject;
    question: string | null;
    answerIndex: number;
    compactUi?: boolean
}

export function FiltersAnswer({
    id,
    answerFilters: initialAnswerFilters,
    answerObject,
    question,
    answerIndex,
    compactUi = false,
}: FiltersAnswerProps) {
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [answerFilters, setAnswerFilters] = useState<Filters>(initialAnswerFilters);
    const navigate = useNavigate();

    const copyAnswer = async () => {
        try {
            const textContent = getAnswerText();
            await navigator.clipboard.writeText(textContent);
            toast.success('Answer copied!');

        } catch (err) {
            toast.error('Failed to copy answer!');
        }
    }
    const handleShowFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleApplyFilters = () => {
        const event = { preventDefault: () => { } } as React.MouseEvent<HTMLButtonElement>;
        newFiltersSearch(event);
    };

    const newFiltersSearch = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        try {
            if (question) {
                await sendQuery(
                    id,
                    question,
                    true,
                    answerFilters,
                    [],
                    (response) => {
                        const queryHash = response.query_hash;
                        navigate(`/${id}/answerthis/answer/${queryHash}`, {
                            state: { question: question, filters: answerFilters },
                        });
                    },
                    (errorMessage) => {
                        console.error(errorMessage);
                        toast.error("An error occurred while rerunning the query with new filters.");
                    }
                );
            }
        } catch (error) {
            console.error("Error rerunning query with new filters:", error);
            toast.error("An error occurred while rerunning the query with new filters.");
        }
    };

    return (
        <div>
            {answerObject && answerObject.getPapers && answerIndex == 0 ? (
                <>
                    <p>
                        Not satisfied with your answer? Try adjusting the
                        filters and ask again
                    </p>
                    <AnswerThisFiltersComponent
                        answerFilters={answerFilters}
                        setAnswerFilters={setAnswerFilters}
                    />
                    <button
                        onClick={handleShowFilters}
                        className={buttonClass}
                        style={{ marginTop: "1rem" }}
                    >
                        <SlidersHorizontal className="w-4 h-4 mr-2" />
                        {!showFilters ? "Show Filters" : "Hide Filters"}
                    </button>
                    {answerFilters ? (
                        <FilterSidebar
                            filters={answerFilters}
                            setFilters={setAnswerFilters}
                            isOpen={showFilters}
                            onClose={handleShowFilters}
                            onApply={handleApplyFilters}
                            isSidebar={false}
                            showSubmitButton={false}
                            showTopBar={false}
                        />
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}

            <div className="flex flex-wrap gap-4 mt-4">
                {answerObject && answerObject.getPapers && answerIndex == 0 ? (
                    <button onClick={newFiltersSearch} className={buttonClass}>
                        <Repeat size={20} className="w-4 h-4" />
                        {!compactUi && 'Send question again'}
                    </button>) : <></>}
                <div className="inline-flex flex-wrap gap-2 sm:ml-auto group">
                    <ProFeatureButton onClick={() => handleGeneratePdf(question!)}>
                        <DownloadIcon className="w-4 h-4 mr-2" />
                        PDF
                    </ProFeatureButton>
                    <ProFeatureButton onClick={() => handleGenerateDocx(question!)}>
                        <DownloadIcon className="w-4 h-4 mr-2" />
                        DOCX
                    </ProFeatureButton>
                    <ProFeatureButton
                        onClick={copyAnswer}
                        className={buttonClass}
                    >
                        <CopyIcon size={20} className="w-4 h-4" />
                        {!compactUi && 'Copy'}
                    </ProFeatureButton>
                </div>
            </div>
        </div>
    );
}