// Citation.ts
import { Node, mergeAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { SourceInfo } from '@/authService'
import { citationStyles, getInTextCitation } from '@/pages/Answer/citationUtils'


declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        citation: {
            insertCitation: (sourceInfo: SourceInfo, citationStyle?: citationStyles) => ReturnType
            updateCitationNumbers: () => ReturnType
            updateCitationStyles: (style: citationStyles) => ReturnType
        }
    }
}

export const Citation = Node.create({
    name: 'citation',
    group: 'inline',
    inline: true,
    atom: true,
    draggable: true,

    addAttributes() {
        return {
            sourceInfo: {
                default: null,
                parseHTML: (element) => {
                    const data = element.getAttribute('data-citation')
                    if (data) {
                        try {
                            return JSON.parse(decodeURIComponent(data))
                        } catch (e) {
                            console.error('Error parsing sourceInfo:', e)
                            return null
                        }
                    }
                    return null
                },
                renderHTML: (attributes) => ({
                    'data-citation': attributes.sourceInfo
                        ? encodeURIComponent(JSON.stringify(attributes.sourceInfo))
                        : null,
                }),
            },
            citationNumber: {
                default: 1,
                parseHTML: (element) => parseInt(element.getAttribute('data-citation-number') || '1'),
                renderHTML: (attributes) => ({
                    'data-citation-number': attributes.citationNumber,
                }),
            },
            citationStyle: {
                default: 'apa',
                parseHTML: (element) => element.getAttribute('data-citation-style') || 'apa',
                renderHTML: (attributes) => ({
                    'data-citation-style': attributes.citationStyle,
                }),
            },
            chunkId: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-chunk-id'),
                renderHTML: (attributes) => ({
                    'data-chunk-id': attributes.chunkId,
                }),
            },
            sectionContent: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-section-content'),
                renderHTML: (attributes) => ({
                    'data-section-content': attributes.sectionContent,
                }),
            },
        }
    },

    parseHTML() {
        return [{ tag: 'span[data-citation]' }]
    },

    renderHTML({ node, HTMLAttributes }) {
        const sourceInfo = node.attrs.sourceInfo
        const citationNumber = node.attrs.citationNumber
        const style = (node.attrs.citationStyle || 'apa').toLowerCase()
        const chunkId = node.attrs.chunkId
        const sectionContent = node.attrs.sectionContent

        // Use your helper to generate the in-text citation
        const displayText = sourceInfo
            ? getInTextCitation(sourceInfo, style, citationNumber - 1)
            : `[${citationNumber}]`

        return [
            'span',
            mergeAttributes(
                {
                    class:
                        'citation inline-block bg-blue-100 px-2 py-0.5 rounded cursor-pointer hover:bg-blue-200',
                    title: sourceInfo?.title || '',
                    'data-source-id': sourceInfo?.id || '',
                    'data-chunk-id': chunkId,
                    'data-section-content': sectionContent,
                },
                HTMLAttributes
            ),
            displayText,
        ]
    },

    addCommands() {
        return {
            insertCitation: (sourceInfo: SourceInfo, citationStyle?: citationStyles) => ({
                chain,
                state,
            }) => {
                // Determine if this source has already been cited.
                let existingNumber = 0
                const sourceMap = new Map<string, number>()
                let counter = 1

                state.doc.descendants((node) => {
                    if (node.type.name === this.name) {
                        const nodeSourceId = node.attrs.sourceInfo?.id
                        if (nodeSourceId && !sourceMap.has(nodeSourceId)) {
                            sourceMap.set(nodeSourceId, counter++)
                        }
                        if (nodeSourceId === sourceInfo.id) {
                            existingNumber = sourceMap.get(nodeSourceId) || 0
                        }
                    }
                })

                const citationNumber = existingNumber || counter
                return chain()
                    .insertContent({
                        type: this.name,
                        attrs: {
                            sourceInfo,
                            citationNumber,
                            citationStyle: (citationStyle || 'apa').toLowerCase(),
                        },
                    })
                    .run()
            },
            updateCitationNumbers: () => ({ tr, state }) => {
                const sourceMap = new Map<string, number>()
                let counter = 1
                // First pass: assign numbers by the order of first occurrence.
                state.doc.descendants((node) => {
                    if (node.type.name === this.name) {
                        const sourceId = node.attrs.sourceInfo?.id
                        if (sourceId && !sourceMap.has(sourceId)) {
                            sourceMap.set(sourceId, counter++)
                        }
                    }
                })
                // Second pass: update each citation node.
                state.doc.descendants((node, pos) => {
                    if (node.type.name === this.name) {
                        const sourceId = node.attrs.sourceInfo?.id
                        if (sourceId) {
                            const number = sourceMap.get(sourceId) || 1
                            tr.setNodeMarkup(pos, undefined, {
                                ...node.attrs,
                                citationNumber: number,
                            })
                        }
                    }
                })
                return true
            },
            updateCitationStyles: (style: citationStyles) => ({ tr, state }) => {
                state.doc.descendants((node, pos) => {
                    if (node.type.name === this.name) {
                        tr.setNodeMarkup(pos, undefined, {
                            ...node.attrs,
                            citationStyle: style.toLowerCase(),
                        })
                    }
                })
                return true
            },
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('citationAutoUpdate'),
                appendTransaction: (_, oldState, newState) => {
                    // Build sets of unique citation source IDs in the old and new document.
                    const oldSources = new Set<string>()
                    oldState.doc.descendants((node) => {
                        if (node.type.name === this.name) {
                            const sourceId = node.attrs.sourceInfo?.id
                            if (sourceId) oldSources.add(sourceId)
                        }
                    })
                    const newSources = new Set<string>()
                    newState.doc.descendants((node) => {
                        if (node.type.name === this.name) {
                            const sourceId = node.attrs.sourceInfo?.id
                            if (sourceId) newSources.add(sourceId)
                        }
                    })
                    // Only update numbering if at least one citation source is completely removed.
                    let removed = false
                    oldSources.forEach((sourceId) => {
                        if (!newSources.has(sourceId)) {
                            removed = true
                        }
                    })
                    if (!removed) {
                        return null
                    }

                    // Recalculate citation numbers based on the new document order.
                    const tr = newState.tr
                    const sourceMap = new Map<string, number>()
                    let counter = 1
                    newState.doc.descendants((node, _) => {
                        if (node.type.name === this.name) {
                            const sourceId = node.attrs.sourceInfo?.id
                            if (sourceId && !sourceMap.has(sourceId)) {
                                sourceMap.set(sourceId, counter++)
                            }
                        }
                    })
                    newState.doc.descendants((node, pos) => {
                        if (node.type.name === this.name) {
                            const sourceId = node.attrs.sourceInfo?.id
                            if (sourceId) {
                                const number = sourceMap.get(sourceId) || 1
                                tr.setNodeMarkup(pos, undefined, {
                                    ...node.attrs,
                                    citationNumber: number,
                                })
                            }
                        }
                    })
                    return tr
                },
            }),
        ]
    },
})

export default Citation
