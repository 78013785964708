import "./AnswerThisAnswer.css";
import Loading from "../../components/Loading";
import "../../components/Sidebar.css"
import { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useLocation, } from "react-router-dom";
import {
  rerunQuery,
  Filters,
  defaultFilters,
  SourceInfo,
} from "../../authService";
import AnswerTable from "../../components/AnswerTable/AnswerTable";
// import Feedback from "../../components/Feedback";
import { fetchReview, FetchReviewResult } from "../../HelperUtils/AnswerFetcher";
import { toast, Toaster } from "sonner";
import SeoComp from "../../components/SeoComp";
import { useSidebar } from "../../contexts/sidebarContext";
import { useAuth } from "@/contexts/AuthContext";
import { AskNewQuestion } from "./AskNewQuestion";
import SplitView from "../../components/SplitView";
import AnswerView from "../../components/AnswerView";

import ExpandableSection from "@/components/expandableSections/ExpandableSection";
import { AddToEditorButton } from "@/components/editor/addToEditor";
const AnswerThisAnswer: React.FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { query } = location.state || {};
  const { checkCredits, removeCredits } = useAuth();

  const [unusedSources, setUnusedSources] = useState<SourceInfo[]>([]);
  const { refreshSidebar } = useSidebar();
  const citationStyle = 'numberedLinks';
  const { query_hash } = useParams<{ query_hash: string }>();
  const [reviewData, setReviewData] = useState<FetchReviewResult | null>(null);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);
  const [sentRerun, setSetRerun] = useState<boolean>(false);
  const [answerFilters, setAnswerFilters] = useState<Filters>(defaultFilters);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const askNewQuestionRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNewQuestionSubmit = async (query: string) => {
    if (!checkCredits(id!, 1)) {
      return;
    }

    try {
      setIsLoading(true);
      await rerunQuery(query_hash!, query);
      await removeCredits(1);
      refreshSidebar();
      setSetRerun(!sentRerun);
    } catch (error) {
      console.error("Error rerunning query:", error);
      toast.error("An error occurred while processing your question");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchReview(query_hash!);

        // Check if there are no results and reload the page if needed
        if (!data || !data.answers || data.answers.length === 0) {
          console.log("No results found, refreshing page...");
          window.location.reload();
          return;
        }

        setReviewData(data);
        setCurrentAnswerIndex(data.answers.length - 1)
        if (data.answers.length > 0) {
          setAnswerFilters(data.answers[0].answerObject.filters);
          if (data.answers[0].answerObject.unused_sources && data.answers[0].answerObject.unused_sources.result) {
            setUnusedSources(Object.values(data.answers[0].answerObject.unused_sources.result));
          } else {
            setUnusedSources([]);
          }
        }
        refreshSidebar();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    if (query_hash) {
      fetchData();
    }
  }, [query_hash, citationStyle, sentRerun]);

  const handleCitationClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const isLink = target.tagName === 'A' && target.getAttribute('href')?.startsWith('#');

    if (isLink) {
      e.preventDefault();
      if (!isMobile) {
        const id = target.getAttribute('href')!.substring(1);
        handleViewTable(id);
      }
    }
  };

  const handleViewTable = (id: string) => {
    document.querySelectorAll('.selected').forEach((el) => {
      el.classList.remove('selected');
    });

    const targetElement = document.getElementById(id);
    if (targetElement) {
      const parentSection = targetElement.closest('.paper-section');
      if (parentSection) {
        parentSection.scrollIntoView({ behavior: 'smooth' });
        targetElement.classList.add('selected');
      } else {
        // Fallback to original behavior if parent section not found
        targetElement.scrollIntoView({ behavior: 'smooth' });
        targetElement.classList.add('selected');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleCitationClick);
    return () => {
      document.removeEventListener('click', handleCitationClick);
    };
  }, []);

  const handleAnswerUpdate = useCallback((updatedAnswer: string) => {
    if (reviewData) {
      const updatedAnswers = [...reviewData.answers];
      updatedAnswers[currentAnswerIndex] = {
        ...updatedAnswers[currentAnswerIndex],
        formattedAnswer: updatedAnswer,
      };
      setReviewData({ ...reviewData, answers: updatedAnswers });
    }
  }, [reviewData, currentAnswerIndex]);

  const renderContent = () => (
    <div className="h-screen-header pb-24">
      <SplitView
        leftTitle="Answer"
        minimizeForMobile={true}
        minimizeForDesktop={true}
        leftComponent={
          <div className="bg-white rounded-lg h-full overflow-auto">
            {reviewData && reviewData.answers.map((answer, index) => (
              <div className="px-2 pb-24" key={index}>
                <ExpandableSection
                  title={answer.answerObject.query}
                  onExpand={() => setCurrentAnswerIndex(index)}
                  isExpandedForce={index == currentAnswerIndex}
                  titleClass={`w-full flex items-center justify-center p-3 font-bold text-brandMaroon text-[1.2rem] font-['Manrope',_sans-serif] ${(index == currentAnswerIndex) ? '' : 'gap-2'}`}
                >
                  <div>
                    <div className="p-2">
                      <AddToEditorButton
                        answer={answer}
                        projectId={id!}
                        queryHash={query_hash}
                      />
                    </div>
                    <AnswerView
                      answer={answer}
                      queryHash={query_hash!}
                      id={id!}
                      answerFilters={answerFilters}
                      onAnswerUpdate={handleAnswerUpdate}
                      answerIndex={index}
                    />
                  </div>
                </ExpandableSection>
              </div>
            ))}
          </div>
        }
        rightTitle="Sources"
        rightComponent={
          <div className="h-full overflow-auto">
            {reviewData && reviewData.answers[currentAnswerIndex].papersArraySorted.length > 0 ? (
              <div className="p-2 pb-24">
                <AnswerTable
                  data={reviewData.answers[currentAnswerIndex].papersArraySorted}
                  unusedSources={unusedSources}
                  referencesIdNumbers={reviewData.answers[currentAnswerIndex].idNumbers}
                  query_hash={query_hash!}
                  projectId={id!}
                />
              </div>
            ) : (
              <p className="p-4 text-gray-500">No sources available</p>
            )}
          </div>
        }
      />
    </div>
  );

  return (
    <>
      <SeoComp />
      {isLoading ? (
        <Loading question={query} />
      ) : (
        <div className="h-screen-header overflow-y-auto overflow-x-hidden font-['Manrope',sans-serif]">
          <Toaster closeButton expand={false} richColors position="top-center" />
          {/* <Feedback queryHash={query_hash!} /> */}
          {renderContent()}
          <div className="relative">
            <div ref={askNewQuestionRef} className=" fixed bottom-0 left-1/2 -translate-x-1/2 flex justify-center items-center pointer-events-none w-[90%] md:w-[70%] z-10" >
              {reviewData && (
                <div className="w-full pointer-events-auto">
                  <div className=" p-4 rounded-t-lg overflow-hidden ">
                    <AskNewQuestion
                      handleNewQuestionSubmit={handleNewQuestionSubmit}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AnswerThisAnswer;
