import SupportScript from "../../Support";
import { FadeIn } from "../../components/Animations";
import "./Homepage.css";
import Footer from "../../components/Footer";
import Testimonial from "../../components/Testimonial";
import CommonFaq from "../../components/CommonFaq";
import TrustedPartners from "../../components/TrustedPartners";
import FeaturesSection from "../../components/Homepage/Features";
import SeoComp from "../../components/SeoComp";
import FeaturesSingle from "../../components/Homepage/FeaturesSingle";
import AboutUs from "../../components/AboutUs";
import Hero from "../../components/Homepage/Hero";
import { handleRedirectMainPage } from "../../authService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
// import Videos from "@/components/Homepage/Videos";

interface FaqItemProps {
  question: string;
  answer: string;
}

function Homepage() {
  const navigate = useNavigate();
  const { loggedIn, isLoading } = useAuth();

  // Only redirect after auth check is complete and user is confirmed to be logged in
  useEffect(() => {
    if (!isLoading && loggedIn === true) {
      handleRedirectMainPage(navigate);
    }
  }, [loggedIn, isLoading, navigate]);

  //FAQs
  const faqs: FaqItemProps[] = [
    {
      question: "How does AnswerThis streamline the research process?",
      answer:
        "AnswerThis revolutionizes the initial stages of research by utilizing a highly trained AI model to sift through extensive arrays of academic papers, creating easy-to-read summaries directly supported by research sources to answer your questions. To deliver the most accurate answers, our intelligent search goes beyond simple Boolean queries, considering the context and drawing connections between terms, such as 'Methylene blue' and 'dye', to ensure that no relevant research is overlooked.",
    },
    {
      question:
        "What makes AnswerThis different from reading abstracts or simply skimming the paper?",
      answer: `While abstracts only offer a brief glimpse into a paper's content and other "summarization tools" provide disjointed page-by-page summaries, AnswerThis delivers a comprehensive overview that not only cites sources but also draws connections and offers compelling explanations for your exact questions. Our platform uniquely synthesizes key findings from hundreds of papers, highlighting precisely what information was used from each source if further context is needed. This approach saves you from endless skimming and efficiently directs your search effort to exactly where it is most necessary.`,
    },
    {
      question: "How does the pricing model for AnswerThis work?",
      answer:
        "Our mission is to fundamentally revolutionize research. Because of this, we have devised three plans depending on your goals. Want to dip your toes into this new world of research? Our Free Forever Plan offers you 5 credits to test our powerful tool. When you're ready for the deep dive, our Passionate Researchers Plan unlocks unlimited credits and access to our database of over 200 million research papers. With this plan, you will always have access to the best AnswerThis has to offer whenever new features or updates are launched. On top of this, you will have priority in any assistance you may need. Finally, if you want to help your team bring out the most out of their research, you can contact us about the Enterprise Plan. With Enterprise, you will get all the features of the Passionate Researchers Plan with additional support and volume discount pricing. We offer security integration, dedicated account management and on-site training at your convenience.",
    },
    {
      question: "Who is the team behind AnswerThis?",
      answer:
        "AnswerThis is developed by a team deeply entrenched in both the technical and practical aspects of research. Boasting a rich background in many fields, we have an especially deep passion for natural language processing and a profound connection to academic research, we are not merely creators of this tool; we are its initial users and most stringent critics. We hope our newsletter, blog, and product can show our love and dedication to research.",
    },
  ];

  return (
    <div className="homepage-parent-container">
      <SeoComp />
      <div className="mainPage snap-section pt-24">
        <SupportScript />

        <FadeIn>
          <Hero />
        </FadeIn>
        <div className="pb-8 md:pb-14">
          <FadeIn>
            <TrustedPartners />
          </FadeIn>
        </div>

        <hr />
      </div>
      <div className="snap-section" >
        <FeaturesSection />
      </div>
      {/* <div className="snap-section" >
        <Videos videos={[
          'https://www.youtube.com/watch?v=MY-WoqE5Lxs',
          'https://www.youtube.com/watch?v=LBzrVEG5qsk',
          'https://www.youtube.com/watch?v=pz_JDKq8mqU',
          'https://www.youtube.com/watch?v=E9x3O3uyu0I',
          'https://www.youtube.com/watch?v=OZYMRYBaZ2k',
          'https://www.youtube.com/watch?v=V0_BxOwWzUI',
          'https://www.youtube.com/watch?v=QxzgdpMjxf4',
          'https://youtu.be/KJm9okU5eTg',
          'https://www.youtube.com/watch?v=KMLKt5Q4V5E'
        ]} initialDisplay={6} />
      </div> */}
      <div className="snap-section" id="aboutus">
        <FadeIn>
          <AboutUs />
        </FadeIn>
      </div>
      <FadeIn>
        <FeaturesSingle />
      </FadeIn>

      <section id="pricing" className="">
        <>
          <div className="snap-section ">
            <FadeIn>
              <Testimonial />
            </FadeIn>
          </div>
        </>
      </section>

      <div className="pt-24 pb-48 snap-section">
        <FadeIn>
          <>
            <div className="faq-section" id="faq">
              <h2>Got Questions? We've Got Answers.</h2>
              <p>
                Your research queries should lead to discoveries, not dead ends.
                Here's how we ensure your journey is seamless:
              </p>
            </div>
            <div className="text-[20px] w-[70%] mx-auto mt-8">
              <CommonFaq faqs={faqs} />
            </div>
          </>
        </FadeIn>
      </div>

      <div className="snap-section">
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;