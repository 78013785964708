import { useCallback, useEffect, useState, useMemo } from 'react'
import { Plus } from 'lucide-react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Checkbox } from '@/components/ui/checkbox'
import { CustomButton } from '@/authService'
import { SourceInfo } from '@/authService'
import { useParams } from 'react-router-dom'
import PaperList from '../PaperList/PaperList'
import { useAddToLibrary } from '../PaperList/useAddToLibrary'
import { useLibrary } from '@/contexts/LibraryContext'

interface ReferenceManagerProps {
    citationHandler: ((source: SourceInfo) => void) | null;
}

const referenceLibraries = ['Personal Library', 'Research Library']

export default function EditorLibraryModal({ citationHandler }: ReferenceManagerProps) {
    const { id } = useParams();
    const { libPapers, isLoading, refreshLibrary } = useLibrary();

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])
    const { selectedSourceIds, handleCheckboxChange } = useAddToLibrary(id!);

    useEffect(() => {
        if (id) {
            refreshLibrary(id);
        }
    }, [id, refreshLibrary]);

    const handleFilterChange = (filter: string) => {
        if (selectedFilters.includes(filter)) {
            setSelectedFilters(selectedFilters.filter(f => f !== filter))
        } else {
            setSelectedFilters([...selectedFilters, filter])
        }
    }

    const addCitationToEditor = useCallback((paperId: string) => {
        if (!citationHandler) {
            console.warn('Citation handler not available');
            return;
        }

        const paper = libPapers.find(p => p.id === paperId);
        if (!paper) {
            console.warn('Paper not found:', paperId);
            return;
        }

        citationHandler(paper);
    }, [citationHandler, libPapers]);

    const customButtons: CustomButton[] = useMemo(() => [{
        name: "Add Citation",
        onClick: addCitationToEditor,
        icon: <Plus size={14} />
    }], [addCitationToEditor]);

    return (
        <div className="max-w-3xl mx-auto">


            <div className="h-px bg-gray-200 my-4" />
            {isLoading ? (
                <p className="text-sm text-center h-48 flex items-center justify-center">
                    Loading papers... <div className="spinner"></div>
                </p>
            ) : (
                <div className="mt-4 space-y-4">
                    <div className="pr-4">
                        <PaperList
                            papers={libPapers}
                            handleCheckboxChange={handleCheckboxChange}
                            isLoading={isLoading}
                            selectedSourceIds={selectedSourceIds}
                            emptyMessage="No papers found in your library. Upload some papers, or search and add papers to get started with chatting and extracting data from papers."
                            id={id!}
                            customButtonsPaperItem={customButtons}
                            compactUi={true}
                        />
                    </div>
                </div>
            )}

            <Dialog open={isFilterOpen} onOpenChange={setIsFilterOpen}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader className="flex flex-row items-center justify-between">
                        <DialogTitle>Search filters</DialogTitle>
                    </DialogHeader>
                    <div className="mt-4">
                        <h3 className="font-medium mb-3">Your libraries</h3>
                        <div className="space-y-3">
                            {referenceLibraries.map((library, index: number) => (
                                <label key={`${library}-${index}`} className="flex items-center space-x-3">
                                    <Checkbox id={library} checked={selectedFilters.includes(library)} onClick={() => handleFilterChange(library)} />
                                    <span>{library}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>



        </div>
    )
}
