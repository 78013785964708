import React from 'react';

interface Stat {
  value: string;
  label: string;
}

interface StatsSectionProps {
  stats: Stat[];
  className?: string;
}

const StatsSection: React.FC<StatsSectionProps> = ({ stats, className = '' }) => {
  const getGridClass = () => {
    switch (stats.length) {
      case 1:
        return 'grid-cols-1 md:grid-cols-1';
      case 2:
        return 'grid-cols-2 md:grid-cols-2';
      case 3:
        return 'grid-cols-2 md:grid-cols-3';
      default:
        return 'grid-cols-2 md:grid-cols-4';
    }
  };

  return (
    <div className={`grid ${getGridClass()} gap-8 ${className}`}>
      {stats.map((stat, index) => (
        <div key={index} className="text-center">
          <p className="text-4xl font-bold text-brandMaroon mb-2">{stat.value}</p>
          <p className="text-gray-600">{stat.label}</p>
        </div>
      ))}
    </div>
  );
};

export default StatsSection;