import React from "react";
import { RecentSearch } from "../../authService";

interface RecentSearchesListProps {
    searches: RecentSearch[];
    onSearchClick: (search: RecentSearch) => void;
}

const RecentSearchesList: React.FC<RecentSearchesListProps> = ({
    searches,
    onSearchClick
}) => {
    if (searches.length === 0) return null;

    return (
        <div className=" pt-4 px-4 font-Manrope border-b border-gray-200 ">
            <div className="">

                <h2 className="text-gray-700 text-sm mb-3">My Searches ({searches.length}):</h2>

                <div className="max-h-[300px] h-full overflow-y-scroll overflow-x-hidden pr-1 space-y-2 overscroll-contain touch-pan-y" style={{ WebkitOverflowScrolling: 'touch' }}>
                    {searches.map((search) => {
                        const date = new Date(search.query_madeAt);
                        const formattedDate = date.toLocaleDateString('en-US', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        });
                        const formattedTime = date.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        });

                        return (
                            <div
                                key={search.query_hash}
                                className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden cursor-pointer hover:shadow-md transition-shadow"
                                onClick={() => onSearchClick(search)}
                            >
                                <div className="p-2">
                                    <div className="border-b border-gray-100 pb-2 mb-2">
                                        <p className="text-gray-600 text-xs">{formattedDate}</p>
                                    </div>
                                    <div className="flex items-start justify-between">
                                        <div>
                                            <div className="flex items-center font-Manrope">
                                                <span className="text-gray-500 text-xs mr-2">{formattedTime}</span>
                                                <h3 className=" text-gray-800 text-sm">{search.query_text}</h3>
                                            </div>
                                            <div className="mt-1 text-gray-500 text-xs">
                                                {search.type === "summary" && <span className="ml-1.5 text-brandMaroon"> · Deep Review</span>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div >
    );
};

export default RecentSearchesList; 