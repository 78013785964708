import React from "react";
import { FormData } from "../../pages/Projects/Projects";

interface CreateProjectPopupProps {
    editMode: boolean;
    formData: FormData;
    handleChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
    handleClick: () => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}
export function CreateProjectPopup(
    { editMode, formData, handleChange, handleClick, handleSubmit }: CreateProjectPopupProps
) {
    return (
        <div className="h-screen w-screen absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 font-Manrope bg-gray-50 border-2 border-black mx-auto rounded-lg py-8 px-12 flex flex-col items-center justify-center"
                style={{ width: '90vw', maxWidth: '500px' }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.6"
                    stroke="currentColor"
                    className="w-6 h-6 absolute top-5 right-5 hover:stroke-2 hover:cursor-pointer duration-300"
                    onClick={handleClick}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12" />
                </svg>
                <div className="text-[32px] font-medium text-center ">
                    {editMode ? "Edit Project" : "Create Project"}
                </div>
                <form
                    onSubmit={handleSubmit}
                    className="pt-4 w-full text-[18px]"
                >
                    <div className="mb-4">
                        <div className="text-black font-semibold mb-2">
                            Project title
                        </div>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="w-full px-4 py-2 bg-brandMaroon bg-opacity-30 rounded-md ring-none outline-none focus:outline-none focus:ring-[2px] focus:ring-brandMaroon duration-300 group"
                            required
                            placeholder="" />
                    </div>
                    <div className="mb-4">
                        <div className="text-black font-semibold mb-2">Domain</div>
                        <div className="relative">
                            <select
                                id="domain"
                                name="domain"
                                value={formData.domain}
                                onChange={handleChange}
                                className="w-full px-4 py-2 bg-brandMaroon bg-opacity-30 rounded-md ring-none outline-none focus:outline-none focus:ring-[2px] focus:ring-brandMaroon duration-300"
                                required
                            >
                                <option value="" disabled>
                                    Select a domain
                                </option>
                                <option value="computer science">Computer Science</option>
                                <option value="chemistry">Chemistry</option>
                                <option value="ecology">Ecology</option>
                                <option value="biology">Biology</option>
                                <option value="humanities">Humanities</option>
                                <option value="government">Government</option>
                                <option value="consultancy">Consultancy</option>
                                <option value="others">Others</option>
                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                className="w-5 h-5 absolute top-1/2 right-5 transform -translate-y-1/2 "
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </div>
                    </div>
                    {formData.domain === "others" && (
                        <div className="mb-4">
                            <div className="text-black font-semibold mb-2">Enter your domain</div>
                            <input
                                type="text"
                                id="otherDomain"
                                name="otherDomain"
                                value={formData.otherDomain}
                                onChange={handleChange}
                                className="w-full px-4 py-2 bg-brandMaroon bg-opacity-30 rounded-md ring-none outline-none focus:outline-none focus:ring-[2px] focus:ring-brandMaroon duration-300 group"
                                required
                                placeholder="" />
                        </div>
                    )}
                    <div className="w-full flex justify-center mt-8">
                        <button
                            type="submit"
                            className="w-[200px] hover:bg-white text-[17px] font-medium hover:text-brandMaroon border-brandMaroon border  py-2 px-4 rounded-lg bg-brandMaroon text-white transition duration-300 disabled:bg-lightMaroon"
                        >
                            {editMode ? "Edit" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
