import { useDocument } from './documentService';
import { useEditor, EditorContent } from '@tiptap/react';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { MenuBar } from './MenuBar';
import { toast } from 'sonner';
import SeoComp from "../SeoComp";
import { WriterDocument } from "./types";
import { SourceInfo, serverUrl } from "@/authService";
import axios from 'axios';
import CitationManager from './CitationManager.tsx';
import RecentsSidebar from "./RecentsSidebar";
import { Helmet } from 'react-helmet';

import { CitationPopup } from './extension/CitationPopup'
import { Slash, SlashCmdProvider } from "@harshtalks/slash-tiptap";
import { SlashCommandMenu } from './extension/SlashCommandMenu';

import './Editor.css';
import { useGlobalCitationStyle } from '@/contexts/citationContext.tsx';
import { useAuth } from '@/contexts/AuthContext';
import { createSlashCommands } from './createSlashCommands.tsx';
import { extensions } from './extensions.tsx';
import { FloatingMenu } from './FloatingMenu.tsx';


function UnifiedEditorPage() {
    const { id: projectId, docId } = useParams<{ id: string, docId: string }>();
    const navigate = useNavigate();

    const [activeDocument, setActiveDocument] = useState<WriterDocument | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 992);

    const { globalStyle } = useGlobalCitationStyle();
    const [customPrompt, setCustomPrompt] = useState<string>('');
    const { document, updateDocument, deleteDocument } = useDocument(docId || '');

    const [lastSave, setLastSave] = useState<string>('');
    const [isSaving, setIsSaving] = useState(false);
    const lastSavedContent = useRef<string>('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const autoSaveTimeoutRef = useRef<NodeJS.Timeout>();
    const { checkCredits, removeCredits } = useAuth();
    const [processedContent, setProcessedContent] = useState<{
        formattedAnswer: string;
        papersArraySorted: SourceInfo[];
    } | null>(null);

    const [selectedCitation, setSelectedCitation] = useState<{ id: string, content: string } | null>(null);
    const [isCitationPopupOpen, setIsCitationPopupOpen] = useState<boolean>(false);

    const [updateLoading, setUpdateLoading] = useState(false);

    // Create slash commands with current projectId and citationStyle
    const slashCommands = createSlashCommands(projectId || '', globalStyle, checkCredits, removeCredits);

    const editor = useEditor({
        extensions: [
            ...extensions,
            Slash.configure({
                suggestion: {
                    items: () => slashCommands,
                },
            }),
        ],
        content: '',
        editorProps: {
            attributes: {
                class: 'prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-2xl p-5 focus:outline-none min-h-[70vh] max-w-full',
            },
            handleKeyDown: (_view, event) => {
                // Check for Ctrl+S or Cmd+S
                if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                    event.preventDefault();
                    handleSave(false);
                    return true;
                }
                return false;
            },
        },
        onUpdate: ({ editor }) => {

            const currentContent = editor.getHTML();
            setHasUnsavedChanges(currentContent !== lastSavedContent.current);

            // Clear any existing timeout
            if (autoSaveTimeoutRef.current) {
                clearTimeout(autoSaveTimeoutRef.current);

            }

            // Set a new timeout for autosave
            autoSaveTimeoutRef.current = setTimeout(() => {
                if (currentContent !== lastSavedContent.current) {
                    handleSave(true); // Pass true to indicate this is an auto-save

                }
            }, 30000); // Autosave after 30 seconds of no typing
        },
    });

    // Cleanup timeout on unmount
    useEffect(() => {
        return () => {
            if (autoSaveTimeoutRef.current) {
                clearTimeout(autoSaveTimeoutRef.current);
            }
        };
    }, []);


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 992);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (!editor || editor.isDestroyed) return;

        // Find all citation elements in the editor
        const editorElement = editor.view.dom;
        const handleCitationClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const citationElement = target.closest('.citation');
            if (!citationElement) return;

            event.preventDefault();
            // Get the DOM position of the clicked element
            const domPos = editor.view.posAtDOM(citationElement, 0);
            if (domPos === null) return;

            // Find the node at this position
            const node = editor.state.doc.nodeAt(domPos);
            if (!node || node.type.name !== 'citation') return;

            // Get the source info directly from the node
            const sourceInfo = node.attrs.sourceInfo;
            if (!sourceInfo) return;

            setSelectedCitation({
                id: sourceInfo.id,
                content: node.attrs.citationContent || sourceInfo.title || ''
            });
            setIsCitationPopupOpen(true);
        };

        // Add click handler only to the editor DOM element
        editorElement.addEventListener('click', handleCitationClick);
        return () => {
            editorElement.removeEventListener('click', handleCitationClick);
        };
    }, [editor]);

    // Clear editor when document ID changes
    useEffect(() => {
        if (editor && !editor.isDestroyed) {
            editor.commands.setContent(activeDocument?.title || '');
            lastSavedContent.current = '';
            setProcessedContent(null);
            setLastSave('');
            setHasUnsavedChanges(false);

        }
    }, [activeDocument?.id]);

    // Process document content when loaded
    useEffect(() => {
        if (!editor || editor.isDestroyed) return;

        const updateContent = async () => {
            try {
                if (document?.content && document.content.answer) {
                    // Only update content if it's different from current content
                    const currentContent = editor.getHTML();
                    if (currentContent !== document.content.answer) {
                        editor.commands.setContent(document.content.answer);
                        editor.chain().updateCitationStyles(globalStyle).updateCitationNumbers().run();
                    }
                    lastSavedContent.current = document.content.answer;
                    setLastSave(new Date(document.updated_at).toLocaleString());
                } else {
                    // For new documents, just set the title as h1
                    editor.commands.clearContent();
                    editor.commands.insertContent({
                        type: 'heading',
                        attrs: { level: 1 },
                        content: [{ type: 'text', text: activeDocument?.title || 'Untitled Document' }]
                    });
                    setHasUnsavedChanges(true);
                    // Save the initial content
                    handleSave(true);
                }
            } catch (error) {
                console.error('Error updating editor content:', error);
                editor.commands.setContent('');
            }
        };

        updateContent();
    }, [document, editor, activeDocument]);

    // Load document from URL parameter if provided
    useEffect(() => {
        if (docId && !activeDocument) {
            const loadInitialDocument = async () => {
                try {
                    const response = await axios.post<{ data: WriterDocument }>(
                        `${serverUrl}/writer/get-document`,
                        { document_id: docId },
                        { withCredentials: true }
                    );
                    setActiveDocument(response.data.data);
                } catch (error) {
                    console.error('Error loading initial document:', error);
                    toast.error('Failed to load document');
                }
            };
            loadInitialDocument();
        }
    }, [docId, activeDocument]);

    const handleAnswerUpdate = useCallback((updatedAnswer: string) => {
        if (editor && !editor.isDestroyed) {
            editor.commands.setContent(updatedAnswer);
            if (processedContent) {
                setProcessedContent({
                    ...processedContent,
                    formattedAnswer: updatedAnswer
                });
            }
        }
    }, [editor, processedContent]);

    const handleSave = async (isAutoSave: boolean = false) => {
        if (!editor || !document || isSaving) return;
        if (!hasUnsavedChanges && isAutoSave) return;
        const currentContent = editor.getHTML();


        setIsSaving(true);
        const loadingId = isAutoSave ? undefined : toast.loading('Saving document...');

        try {
            await updateDocument({
                content: {
                    ...document.content,
                    answer: currentContent,
                }
            });
            lastSavedContent.current = currentContent;
            setHasUnsavedChanges(false);
            setLastSave(new Date().toLocaleString());

            if (!isAutoSave) {
                toast.success('Document saved successfully');
            }
        } catch (error) {
            console.error('Failed to save document:', error);
            if (!isAutoSave) {
                toast.error('Failed to save document');
            }
        } finally {
            setIsSaving(false);
            if (loadingId) {
                toast.dismiss(loadingId);
            }
        }
    };

    const handleAddCitation = useCallback(async (source: SourceInfo) => {
        if (!source || !editor || !document) {
            console.error('Missing required dependencies:', { source, editor: !!editor, document: !!document });
            return;
        }

        try {
            const currentContent = document.content || { sources: { result: {} }, filters: {} };
            const currentSources = currentContent.sources?.result || {};

            // Pass the current citationStyle into the insertCitation command.
            editor
                .chain()
                .focus()
                .insertCitation(source, globalStyle)
                .run();

            const updatedSources = { ...currentSources, [source.id]: source };
            if (!source.sections) {
                source.sections = [{ id: source.id, content: source.abstract || "", name: source.title }];
            }

            await updateDocument({
                content: {
                    ...currentContent,
                    answer: editor.getHTML(),
                    sources: { result: updatedSources },
                },
            });

        } catch (error) {
            console.error('Error adding citation:', error);
        }
    }, [editor, updateDocument, document, globalStyle]);

    const handleDeleteDocument = async () => {
        if (!activeDocument) return;

        try {
            await deleteDocument();
            toast.success('Document deleted successfully');
            navigate(`/${projectId}/ask-answerthis?tab=documents`);
        } catch (error) {
            console.error('Error deleting document:', error);
            toast.error('Failed to delete document');
        }
    };

    if (!editor) return null;


    return (
        <div className="w-full bg-background font-Manrope">
            <SeoComp />
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <SlashCmdProvider>
                <div className="h-screen-header mx-auto my-auto rounded-lg">
                    {!isMobile ? (
                        <Allotment>
                            <Allotment.Pane>
                                <div className="flex flex-col h-full bg-background px-6">
                                    <MenuBar
                                        editor={editor}
                                        lastSave={lastSave}
                                        handleSaveClick={() => handleSave(false)}
                                        isSaving={isSaving}
                                        handleDeleteDocument={handleDeleteDocument}
                                    />
                                    <div className="flex-1 overflow-y-auto relative scrollbar-thin scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/30">
                                        <div className="mx-auto bg-gray-50 border border-gray-200 rounded-lg mr-2">
                                            <EditorContent
                                                className="editor__content prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-xl mx-auto"
                                                editor={editor}
                                            />
                                            <CitationManager editor={editor} onAnswerUpdate={handleAnswerUpdate} />
                                        </div>
                                    </div>
                                </div>
                            </Allotment.Pane>
                            <Allotment.Pane minSize={400} preferredSize={500}>
                                <div className="h-full rounded-none border-0 shadow-none bg-muted/30">
                                    <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/30">
                                        <RecentsSidebar
                                            projectId={projectId!}
                                            activeDocument={activeDocument}
                                            editor={editor}
                                            citationHandler={handleAddCitation}
                                            citationStyle={globalStyle}
                                        />
                                    </div>
                                </div>
                            </Allotment.Pane>
                        </Allotment>
                    ) : (
                        <div className="flex flex-col h-full relative">
                            {/* Main content */}
                            <div className="flex-1 flex flex-col px-2 bg-background">
                                <MenuBar
                                    editor={editor}
                                    lastSave={lastSave}
                                    handleSaveClick={() => handleSave(false)}
                                    isSaving={isSaving}
                                    handleDeleteDocument={handleDeleteDocument}
                                />

                                <div className="flex-1 overflow-y-auto relative scrollbar-thin scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/30">
                                    <div className="mx-auto py-2 bg-gray-50 border border-gray-200 rounded-lg">
                                        <EditorContent
                                            className="editor__content prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-xl mx-auto"
                                            editor={editor}
                                        />
                                        <CitationManager editor={editor} onAnswerUpdate={handleAnswerUpdate} />
                                    </div>
                                </div>
                            </div>

                            {/* Sidebar positioned with zero height but interactive when needed */}
                            <div className="overflow-visible">
                                <RecentsSidebar
                                    projectId={projectId!}
                                    activeDocument={activeDocument}
                                    editor={editor}
                                    citationHandler={handleAddCitation}
                                    citationStyle={globalStyle}
                                    isMobile={true}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* Add the slash command UI */}
                {editor && (
                    <SlashCommandMenu editor={editor} commands={slashCommands} />
                )}

                {editor && (
                    <FloatingMenu
                        customPrompt={customPrompt}
                        editor={editor}
                        projectId={projectId}
                        setCustomPrompt={setCustomPrompt}
                        setUpdateLoading={setUpdateLoading}
                        updateLoading={updateLoading}
                        citationStyle={globalStyle}
                    />
                )}

                {selectedCitation && (
                    <CitationPopup
                        citationId={selectedCitation.id}
                        citationContent={selectedCitation.content}
                        isOpen={isCitationPopupOpen}
                        onClose={() => setIsCitationPopupOpen(false)}
                        editor={editor}
                    />
                )}
            </SlashCmdProvider>
        </div>
    );
}

export default UnifiedEditorPage;





