import React from 'react';
import { Editor } from '@tiptap/react';
import { PlusCircle } from 'lucide-react';
import { toast } from 'sonner';
import { convertAnswerToEditorFormat } from './answerConversion';
import { AnswerObject } from '@/authService';
import { useGlobalCitationStyle } from '@/contexts/citationContext';
import { createDocument, findDocumentByQueryHash } from './documentService';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ProFeatureButton } from '../ProFeatureButton';

interface AddToEditorButtonProps {
    answer: {
        formattedAnswer: string;
        answerObject: AnswerObject;
        papersArraySorted: any[];
    };
    editor?: Editor;
    projectId?: string;
    queryHash?: string;
}

export const AddToEditorButton: React.FC<AddToEditorButtonProps> = ({
    answer,
    editor,
    projectId,
    queryHash
}) => {
    const { globalStyle } = useGlobalCitationStyle();
    const navigate = useNavigate();

    const handleAddToEditor = async () => {
        const loadingToastId = toast.loading('Adding to editor...');
        try {
            const convertedAnswer = await convertAnswerToEditorFormat(answer, globalStyle);
            answer.answerObject.answer = convertedAnswer;

            if (!editor) {
                if (!projectId) {
                    toast.error('Project ID is required to create a new document');
                    return;
                }

                let existingDoc = null;
                if (queryHash) {
                    existingDoc = await findDocumentByQueryHash(projectId, queryHash);
                }

                if (existingDoc) {
                    navigate(`/${projectId}/doc/${existingDoc.id}`);
                    toast.success('Navigated to existing document', { id: loadingToastId });
                    return;
                }

                // Create a new document if editor is not provided and no existing document found
                const title = answer.answerObject.query;
                const description = queryHash || 'Imported from AnswerThis Answer';
                const newDoc = await createDocument(
                    title,
                    description,
                    projectId,
                    answer.answerObject
                );
                navigate(`/${projectId}/doc/${newDoc.id}`);
                toast.success('Created new document with answer', { id: loadingToastId });
                return;
            }

            // Insert at current cursor position
            editor
                .chain()
                .focus()
                .insertContent(convertedAnswer)
                .updateCitationNumbers()
                .updateCitationStyles(globalStyle)
                .run();

            toast.success('Added to editor', { id: loadingToastId });
        } catch (error) {
            console.error('Error adding to editor:', error);
            toast.error('Failed to add content to editor');
        }
    };

    return (
        <motion.div
            className="relative group w-fit"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
        >
            {/* Subtle gradient glow effect */}
            <div className="absolute -inset-0.5 bg-gradient-to-r from-brandMaroon/30 via-purple-500/30 to-brandMaroon/30 rounded-2xl blur opacity-40 group-hover:opacity-70 transition-opacity duration-200" />

            {/* Main button with hover effect */}
            <ProFeatureButton
                onClick={handleAddToEditor}
                className="relative flex p-1 items-center gap-2 bg-white border-2 border-brandMaroon/50 hover:border-brandMaroon rounded-2xl text-brandMaroon hover:text-white hover:bg-brandMaroon transition-all duration-300 z-10"
            >
                <PlusCircle className="h-4 w-4" />
                <span className="font-medium">Edit with AI</span>
            </ProFeatureButton>
        </motion.div>
    );
};