import React, { useState, useEffect } from 'react';
import { citationStyles, formatCitation } from './citationUtils';
import { CloudLightningIcon, Copy } from 'lucide-react';
import { toast } from 'sonner';
import { fetchPaperData, SourceInfo } from '../../authService';
import CitationStyleSelector from './citationSelector';
import { TooltipContent, Tooltip, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

export interface CopyCitationProps {
    source: SourceInfo;
    initialStyle?: citationStyles;
    compact?: boolean;
    externalStyle?: citationStyles;
    onExternalStyleChange?: (style: citationStyles) => void;
    prefix?: string
}

const CopyCitation: React.FC<CopyCitationProps> = ({
    source,
    initialStyle = 'chicago',
    compact = false,
    externalStyle,
    onExternalStyleChange,
    prefix
}) => {
    const [internalStyle, setInternalStyle] = useState<citationStyles>(initialStyle);
    const [formattedCitation, setFormattedCitation] = useState<string>(formatCitation(source, externalStyle || internalStyle));

    useEffect(() => {
        if (externalStyle) {
            setFormattedCitation(formatCitation(source, externalStyle));
        }
    }, [externalStyle, source]);

    const handleStyleChange = (newStyle: citationStyles) => {
        if (onExternalStyleChange) {
            onExternalStyleChange(newStyle);
        } else {
            setInternalStyle(newStyle);
            setFormattedCitation(formatCitation(source, newStyle));
        }
    };

    const handleCopyCitation = () => {
        navigator.clipboard.writeText(formattedCitation).then(() => {
            toast.success('Citation copied to clipboard');
        }).catch(() => {
            toast.error('Failed to copy citation');
        });
    };
    const handleRefineCitation = () => {
        fetchPaperData(source.title, source.doi, source.author).then((data) => {
            setFormattedCitation(formatCitation(data, externalStyle || internalStyle));
            toast.success('Citation refined. Please double check. Refreshing the page will reset the citation.');
        }).catch(() => {
            toast.error('Failed to refine citation');
        });
    }

    const currentStyle = externalStyle || internalStyle;

    if (compact) {
        return (
            <div className="flex flex-wrap items-center gap-2">
                {!externalStyle && (
                    <CitationStyleSelector currentStyle={currentStyle} onStyleChange={handleStyleChange} />
                )}
                <button
                    onClick={handleRefineCitation}
                    className="text-xs bg-brandMaroon text-white p-1.5 rounded-md hover:bg-darkMaroon transition-colors shadow-sm inline-flex items-center justify-center"
                    aria-label="Copy citation"
                >
                    <Copy size={14} />
                </button>
            </div>
        );
    }

    return (
        <div className="citation-manager w-full">
            <div className="flex flex-col gap-2 mb-2">
                <div className="flex-1 w-full bg-gray-100 break-all p-2 rounded-md text-xs text-darkMaroon overflow-hidden">
                    {prefix && prefix}{formattedCitation}
                </div>
                <div className="flex flex-wrap items-center gap-2">
                    <TooltipProvider delayDuration={300}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <button
                                    onClick={handleCopyCitation}
                                    className="flex-shrink-0 text-xs bg-brandMaroon text-white p-1.5 rounded-md hover:bg-darkMaroon transition-colors shadow-sm inline-flex items-center justify-center"
                                    aria-label="Copy citation"
                                >
                                    <Copy size={14} />
                                </button>
                            </TooltipTrigger>
                            <TooltipContent className="max-w-sm">
                                <p className="text-sm">{'Copy citation'}</p>
                            </TooltipContent>
                        </Tooltip>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <button
                                    onClick={handleRefineCitation}
                                    className="flex-shrink-0 text-xs bg-brandMaroon text-white p-1.5 rounded-md hover:bg-darkMaroon transition-colors shadow-sm inline-flex items-center justify-center"
                                    aria-label="Refine Citations"
                                >
                                    <CloudLightningIcon size={14} />
                                </button>
                            </TooltipTrigger>
                            <TooltipContent className="max-w-sm">
                                <p className="text-sm">{'Refined citation'}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>
            {!externalStyle && (
                <CitationStyleSelector currentStyle={currentStyle} onStyleChange={handleStyleChange} />
            )}
        </div>
    );
};

export default CopyCitation;