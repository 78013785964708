import { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { DiagramTypeSelector } from './DiagramTypeSelector';
import { Loader2, Wand2 } from 'lucide-react';

interface DiagramInputProps {
    onGenerate: (text: string, type: string, isCustomType: boolean) => void;
    loading: boolean;
}

export const DiagramInput = ({ onGenerate, loading }: DiagramInputProps) => {
    const [inputText, setInputText] = useState('');
    const [selectedType, setSelectedType] = useState('flowchart');
    const [isCustomType, setIsCustomType] = useState(false);

    const handleTypeChange = (value: string) => {
        setIsCustomType(false);
        setSelectedType(value);
    };

    const handleCustomTypeChange = (value: string) => {
        setIsCustomType(true);
        setSelectedType(value);
    };

    const handleGenerate = () => {
        onGenerate(inputText, selectedType, isCustomType);
    };

    return (
        <Card className="h-full">
            <CardHeader className="pb-4">
                <CardTitle className="flex items-center gap-2">
                    <Wand2 className="h-5 w-5 text-brandMaroon" />
                    Create Your Diagram
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-4">

                <div className="space-y-1">
                    <Label className="text-sm">Description</Label>
                    <Textarea
                        placeholder="Describe what you want to visualize..."
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        className="h-[100px] resize-none text-sm"
                    />
                </div>


                <DiagramTypeSelector
                    selectedType={selectedType}
                    onTypeChange={handleTypeChange}
                    onCustomTypeChange={handleCustomTypeChange}
                />
                <Button
                    onClick={handleGenerate}
                    disabled={loading || !inputText.trim() || (!selectedType && !isCustomType)}
                    className="w-full bg-brandMaroon hover:bg-brandMaroon/90 h-12 text-lg"
                >
                    {loading ? (
                        <>
                            <Loader2 className="h-5 w-5 animate-spin mr-2" />
                            Generating...
                        </>
                    ) : (
                        <>
                            <Wand2 className="h-5 w-5 mr-2" />
                            Generate Diagram
                        </>
                    )}
                </Button>


            </CardContent>
        </Card>
    );
};