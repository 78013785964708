import { FC } from 'react'
import { Helmet } from 'react-helmet'
import logo from "/img/logo.svg";


interface SeoCompProps {

}

const SeoComp: FC<SeoCompProps> = ({ }) => {
  return <div>
    <Helmet>
      <title>Best AI research assistant | AnswerThis</title>
      <meta name="description" content="The best AI research assistant. Type a question and AnswerThis writes a comprehensive literature review from 200+ million papers." />
      <meta property="og:title" content="Best AI research assistant | AnswerThis" />
      <meta property="og:description" content="The best AI research assistant. Type a question and AnswerThis writes a comprehensive literature review from 200+ million papers." />
      <meta name="keywords" content="AI research assistant, AI tools, Optimize research " />
      <meta name="publisher" content="Ayush Garg" />
      <meta property="og:image" content={logo} />
      <meta name="twitter:title" content="Best AI research assistant | AnswerThis" />
      <meta name="twitter:description" content="The best AI research assistant. Type a question and AnswerThis writes a comprehensive literature review from 200+ million papers." />
      <meta name="twitter:image" content={logo} />
    </Helmet>
  </div>
}

export default SeoComp