import { Helmet } from 'react-helmet';

const ClarityScript = () => (
  <Helmet>
    {/* <script type="text/javascript">
      {`
        (function(c, l, a, r, i, t, y) {
          c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
          t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "kzquuk37wi");
      `}
    </script> */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-KB6QCZBJ7G"></script>
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-KB6QCZBJ7G');
      `}
    </script>
  </Helmet>
);

export default ClarityScript;