import React from 'react';
import { cn } from '@/lib/utils';
import { CheckCircle2 } from 'lucide-react';

interface Feature {
  title: string;
  description: string;
}

interface FeatureSectionProps {
  badge?: string;
  title: string;
  description: string;
  features: Feature[];
  image?: {
    src: string;
    alt: string;
  };
  reverse?: boolean;
  variant?: 'default' | 'university' | 'affiliate';
  className?: string;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  badge,
  title,
  description,
  features,
  image,
  reverse = false,
  variant = 'default',
  className
}) => {
  const variants = {
    default: {
      badge: "bg-brandMaroon/10 text-brandMaroon",
      title: "text-gray-900",
      description: "text-gray-600",
      featureTitle: "text-gray-900",
      featureDescription: "text-gray-600",
      container: "bg-white",
      icon: "text-brandMaroon"
    },
    university: {
      badge: "bg-brandMaroon/10 text-brandMaroon",
      title: "text-gray-900",
      description: "text-gray-600",
      featureTitle: "text-brandMaroon",
      featureDescription: "text-gray-600",
      container: "bg-white",
      icon: "text-brandMaroon"
    },
    affiliate: {
      badge: "bg-brandMaroon/10 text-brandMaroon",
      title: "text-gray-900",
      description: "text-gray-600",
      featureTitle: "text-brandMaroon",
      featureDescription: "text-gray-600",
      container: "bg-gradient-to-br from-white to-lightMaroon/20",
      icon: "text-brandMaroon"
    }
  };

  const style = variants[variant];

  return (
    <div className={cn("py-12", className)}>
      <div className={cn(
        "flex flex-col md:flex-row items-center gap-12",
        reverse && "md:flex-row-reverse"
      )}>
        <div className="flex-1 space-y-8">
          {badge && (
            <div className={cn("inline-block rounded-full px-4 py-1.5", style.badge)}>
              <span className="text-sm font-medium">{badge}</span>
            </div>
          )}
          <h2 className={cn("text-3xl font-bold", style.title)}>{title}</h2>
          <p className={cn("text-lg", style.description)}>{description}</p>

          <ul className="space-y-6">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start">
                <CheckCircle2 className={cn("h-6 w-6", style.icon)} />
                <div>
                  <h3 className={cn("text-xl font-semibold", style.featureTitle)}>
                    {feature.title}
                  </h3>
                  <p className={cn("", style.featureDescription)}>
                    {feature.description}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {image && (
          <div className="flex-1">
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-auto rounded-2xl shadow-2xl"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureSection;
