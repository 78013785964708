import axios from "axios";
import { toast } from 'sonner';

// import { loadStripe } from "@stripe/stripe-js";
// const stripe_id = import.meta.env.PROD
//     ? (import.meta.env.VITE_SERVER_STRIPE_KEY as string)
//     : (import.meta.env.VITE_SERVER_STRIPE_KEY_DEMO as string);
// const stripePromise = loadStripe(stripe_id);
export const referencePattern = /\[([^\]]+)\]/g;
import { useNavigate, useParams } from "react-router-dom";
import QueueAdapter from "./HelperUtils/QueueAdapter";
import { ReactNode } from "react";

// import { loadStripe } from "@stripe/stripe-js";
export const verificationID = import.meta.env.VITE_RECAPTCHA_CLIENT;
export interface CustomButton {
    name: string;
    onClick: (paperId: string) => void;
    icon?: ReactNode;
}
export interface PaperInfo {
    id: string;
    title: string;
    author: string;
    journal: string;
    date_of_publication: string;
    doi: string;
    citations: number;
    abstract: string;
    pdf_path: string;
    url: string;
}
export interface UploadPdfResponse {
    message: string;
    query_hash: string;
    files: string[];
}
export interface Section {
    id: string;
    content: string;
    name: string;
    databaseId?: string;
}
export interface AnswerObject {
    sources: {
        result: { [id: string]: SourceInfo };
    };
    unused_sources?: {
        result: { [id: string]: SourceInfo };
    };
    query: string;
    answer: string;
    filters: Filters;
    getPapers: boolean;

}
export interface Blog {
    title: string;
    coverImage: string;
    url: string,
    createdAt: string;
    updatedAt: string;
    contentFile: string;
    description: string;
    keywords: string;
    id: string;
}

export const handleChatClick = async (
    selectedSourceIds: string[],
    projectId: string,
    navigate: (id: string) => void,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
    filteredAndSortedData?: SourceInfo[],
) => {
    if (selectedSourceIds.length === 0) {
        onError("Please select at least one source to chat with.");
        return;
    }

    const loadingMessage = "Setting up chat. Please wait...";
    let loadingToastId: string | number = '';

    try {
        loadingToastId = toast.loading(loadingMessage);

        if (filteredAndSortedData) {
            for (const item of filteredAndSortedData) {
                if (
                    selectedSourceIds.includes(item.id) &&
                    (item.pdf_path === "None" || item.pdf_path == "")
                ) {
                    throw new Error("Make sure all the selected sources have PDF available.");
                }
            }
        }

        const chatResult = await setupChat(selectedSourceIds, projectId);
        const chatIndex = chatResult.chatIndex;
        const warning = chatResult.warning || null;
        toast.dismiss(loadingToastId);
        onSuccess("Chat set up successfully");
        if (warning) {
            toast.error(warning);
        }
        navigate(`/${projectId}/answerthis/answer/chat/${chatIndex}`);
    } catch (err) {
        toast.dismiss(loadingToastId);
        const errorMessage = err instanceof Error ? err.message : "AnswerThis can't access the PDF. Please Download the PDF and chat with PDF after uploading it to your library";
        onError(errorMessage);
    }
};




export interface SourceInfo {
    sections: Section[];
    title: string;
    author: string;
    journal: string;
    doi: string;
    summary: string;
    id: string;
    date_of_publication: string;
    citations: number;
    pdf_path: string | null;
    url: string;
    abstract: string | null;
    whole_content?: string | null;
}

export interface RecentSearch {
    query_hash: string;
    query_text: string;
    query_madeAt: Date;
    type: "summary" | "answer";
}

export interface UserProject {
    id: string;
    project_name: string;
    subject: string;
    created_at: string;
}
export type AnswerStyle = 'literature_review' | 'research_assistant' | 'ai_only';

export interface Filters {
    minCitationCount?: number;
    fieldsOfStudy?: string[];
    startDate?: string;
    endDate?: string;
    numberOfAbstracts?: number | string;
    memory?: [string, string][];
    database?: string[];
    customSections?: string[];
    internetFilter?: ("all" | "gov" | "edu")[];
    researchPapersFilter?: ("semanticscholar" | "openalex" | "pubmed" | "arxiv")[];
    doubleCheckCitations?: boolean;
    isFastMode?: boolean;
    answerStyle: AnswerStyle;
    journalQuality?: string[];
}
export const serverUrl = import.meta.env.PROD
    ? (import.meta.env.VITE_SERVER_URL_PROD as string)
    : (import.meta.env.VITE_SERVER_URL_DEV as string);
export const answerServerUrl = import.meta.env.PROD
    ? (import.meta.env.VITE_ANSWER_SERVER_URL_PROD as string)
    : (import.meta.env.VITE_ANSWER_SERVER_URL_DEV as string);
axios.defaults.withCredentials = true;
export function formatChicago(source: SourceInfo) {
    const { author, title, journal, date_of_publication, doi, url } = source;
    const authorText = author || "";
    const dateText = (date_of_publication && date_of_publication != "None")
        ? new Date(date_of_publication).getFullYear()
        : "n.d.";
    const titleText = title || "No title available";
    const journalText = journal || "Journal data not available";
    const doiText = doi ? `DOI: ${doi}` : url;

    return `${authorText} "${titleText}." ${journalText} (${dateText}). ${doiText}.`;
}
const queueAdapter = new QueueAdapter({
    taskBaseUrl: serverUrl,     // For SSE/status endpoints
    endpointBaseUrl: serverUrl, // For queued endpoint
    backupEndpointUrl: serverUrl      // For non-queued backup
});
export const defaultFilters: Filters = {
    minCitationCount: 0,
    fieldsOfStudy: [],
    startDate: "",
    endDate: "",
    numberOfAbstracts: 0,
    database: ["research papers"],
    researchPapersFilter: ["semanticscholar", "openalex"],
    doubleCheckCitations: false,
    isFastMode: true,
    answerStyle: "literature_review",
    journalQuality: ["Q1", "Q2", "Q3", "Q4"],
};
export const defaultFiltersNoDatabase: Filters = {
    minCitationCount: 0,
    fieldsOfStudy: [],
    startDate: "",
    endDate: "",
    numberOfAbstracts: 0,
    database: [],
    researchPapersFilter: [],
    doubleCheckCitations: false,
    isFastMode: true,
    answerStyle: "literature_review",
    journalQuality: ["Q1", "Q2", "Q3", "Q4"],
};

export const defaultFiltersSearch: Filters = {
    minCitationCount: 0,
    fieldsOfStudy: [],
    startDate: "",
    endDate: "",
    numberOfAbstracts: 0,
    database: ["research papers"],
    researchPapersFilter: ["semanticscholar"],
    doubleCheckCitations: false,
    isFastMode: true,
    answerStyle: "literature_review",
    journalQuality: ["Q1", "Q2", "Q3", "Q4"],
};

export function convertSourceInfoToPaperInfo(source: SourceInfo): PaperInfo {
    return {
        title: source.title,
        author: source.author,
        journal: source.journal,
        date_of_publication: source.date_of_publication,
        doi: source.doi,
        citations: source.citations,
        abstract: source.abstract || '',
        pdf_path: source.pdf_path || '',
        url: source.url,
        id: source.id
    };
}
export function handlePdfTitleClick(paper: SourceInfo | PaperInfo) {
    if (paper.url != null && paper.url != "" && paper.url != "None") {
        window.open(paper.url);
    } else {
        window.open(getPdfPath(paper));
    }
}
export interface IDocument {
    uri: string;
    fileType?: string;
    fileData?: string | ArrayBuffer;
    fileName: string;
}
export const OpenPaperInfo = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const navigateToPaperInfo = (sourceId: string) => {
        const paperInfoUrl = `/${id}/paper/${sourceId}`;
        navigate(paperInfoUrl);
    };

    return { navigateToPaperInfo };
};
export function checkIfParamAvailable(param: string | null) {
    return !(param == null || param == "" ||
        param == "None");
}

export function openPdf(paper: SourceInfo) {
    const path = getPdfPath(paper);
    window.open(path);
}


export interface AllChatResponse {
    chatIndex: string;
    lastUserMessage: string;
    created_at: Date;
}

export const getAllChatsForUser = async (): Promise<AllChatResponse[]> => {
    try {
        const response = await axios.post(`${serverUrl}/getAllChatsForUser`, {}, {
            withCredentials: true,
        });

        return response.data;
    } catch (error) {
        console.error("Error getting chats:", error);
        throw error;
    }
};

export const fetchLibraryPapers = async (projectId: string) => {
    try {
        const response = await axios.post(
            `${serverUrl}/viewLibraryForProject`,
            { projectId },
            {
                withCredentials: true,
            }
        );
        // Transform the response to include hasFullContent
        const papers = response.data.map((paper: SourceInfo) => ({
            ...paper,
            hasFullContent: paper.whole_content && paper.whole_content !== "None" && paper.whole_content !== ""
        }));
        return papers;
    } catch (error) {
        console.error("Failed to fetch library papers:", error);
    }
};

export const handleSaveToLibrary = async (
    paperId: string,
    projectId: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
) => {
    try {
        onSuccess("Paper saved to library successfully.");
        await axios.post(`${serverUrl}/addPaperToLibrary`, {
            paperId,
            projectId,
        });
    } catch (error) {
        console.error("Error saving paper to library:", error);
        onError("Failed to save paper to library.");
        // alert("Failed to save paper to library.");
    }
};
export const handleSaveToLibraryWithData = async (
    paperData: any,
    projectId: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
): Promise<string | null> => {
    try {
        const response = await axios.post(
            `${serverUrl}/addPaperWithDataToLibrary`,
            {
                paperData,
                projectId
            },
            {
                withCredentials: true,
            }
        );
        onSuccess(response.data.message);
        return response.data.paperId;
    } catch (error) {
        onError("Failed to add paper to library");
        return null;
    }
};
export const sendMessageToChat = async (message: string, chatIndex: string) => {

    try {
        if (message.trim().length === 0) {
            throw new Error("Message cannot be empty");
        }
        if (message.length > 40000) {
            throw new Error("Message is too long. Please keep it under 40000 characters.");
        }
        const payload = {
            chatIndex,
            message,
        };
        const result = await queueAdapter.executeTask(
            '/sendMessageQ',  // Queued endpoint
            payload,
            '/sendMessage'    // Backup endpoint
        );
        return result;
    } catch (error) {
        console.error("Error sending message:", error);
        throw error;
    }
};
export interface billingDetails {
    billing_cycle_end: string;
    cancelling: boolean;
}
export const removeScheduledCancellation = async () => {
    try {
        const response = await axios.post(
            `${serverUrl}/payments/cancel_cancellation_schedule`,
            {},
            { withCredentials: true }
        );
        if (response.status === 200) {
            toast.success(response.data.message || "Scheduled cancellation removed!");
            return response.data;
        } else {
            toast.error("Failed to remove scheduled cancellation");
            throw new Error("Failed to remove scheduled cancellation");
        }
    } catch (err) {
        console.error("Error removing scheduled cancellation:", err);
        toast.error("Error removing scheduled cancellation");
        throw err;
    }
};
export const createPortalSession = async (): Promise<{ url: string, processor: string }> => {
    try {
        const response = await axios.post(`${serverUrl}/create_portal_session`, {}, {
            withCredentials: true,
        });

        if (!response || !response.data || !response.data.url) {
            throw new Error("Failed to create subscription portal session");
        }

        return {
            url: response.data.url,
            processor: response.data.processor || 'unknown'
        };
    } catch (error) {
        console.error("Error creating portal session:", error);
        throw error;
    }
};
export const applyDiscount40 = async () => {
    try {
        // Make sure your server is configured to accept this route
        const response = await axios.post(`${serverUrl}/apply_discount_40`, {}, {
            withCredentials: true,
        });

        if (response.status === 200) {
            // success
            toast.success(response.data.message || "40% discount applied!");
            return response.data;
        } else {
            // some non-200 status
            toast.error("Failed to apply discount");
            throw new Error("Failed to apply discount");
        }
    } catch (error) {
        console.error("Error applying discount:", error);
        toast.error("Error applying discount");
        throw error;
    }
};
export const getUserBillingEnd = async (): Promise<billingDetails> => {
    try {
        const response = await axios.post(`${serverUrl}/get-billing-cycle-end`, {
            withCredentials: true,
        });

        if (response.data && response.data.billing_cycle_end) {
            response.data.cancelling
            // Parse the ISO date string into a Date object
            const billingEndDate = new Date(response.data.billing_cycle_end);

            // Format the date as a string (e.g., "May 15, 2023")
            const date = billingEndDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            return { billing_cycle_end: date, cancelling: response.data.cancelling };
        } else {
            console.warn("Billing cycle end date not found in response");
            return { billing_cycle_end: "Not found", cancelling: false };
        }
    } catch (error) {
        console.error("Error getting billing end date:", error);
        throw error;
    }
};



interface EditorDocument {
    body: { content: JSON };
    last_updated: string;
}
export const loadDocument = async (
    projectId: string
): Promise<EditorDocument | null> => {
    try {
        const response = await axios.post(
            `${serverUrl}/loadDocument`,
            {
                projectId,
            },
            {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error loading document:", error);
        return null;
    }
};

export const saveDocument = async (
    content: JSON,
    projectId: string
): Promise<EditorDocument | null> => {
    try {
        const response = await axios.post(
            `${serverUrl}/saveDocument`,
            {
                body: JSON.stringify({ content }),
                projectId,
            },
            {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error saving document:", error);
        return null;
    }
};
export const editProject = async (
    projectId: string,
    projectName: string,
    subject: string
) => {
    try {
        const response = await axios.post(
            `${serverUrl}/editProject`,
            {
                projectId,
                projectName,
                subject,
            },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error editing project:", error);
        throw error;
    }
};
export const searchForPapers = async (
    query: string,
    projectId: string,
    filters: Filters
): Promise<SourceInfo[]> => {

    try {
        const payload = {
            query,
            projectId,
            filters,
        };
        const result = await queueAdapter.executeTask(
            '/searchForPapersQ',  // Queued endpoint
            payload,
            '/searchForPapers'    // Backup endpoint
        );

        console.log("Search result:", result);
        return result;

    } catch (error) {
        console.error("Error searching for papers:", error);
        throw error;
    }
};
export const fetchWithRetry = async (fn: () => Promise<any>, maxAttempts = 3) => {
    let attempts = 0;
    while (attempts < maxAttempts) {
        try {
            return await fn();
        } catch (error) {
            attempts++;
            if (attempts === maxAttempts) {
                throw error;
            }
            // Wait for a short time before retrying (e.g., 1 second)
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
};
export const getProjectQueries = async (projectId: string) => {
    try {
        const response = await axios.post(
            `${serverUrl}/viewQueriesForProject`,
            {
                projectId,
            },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error getting project queries:", error);
        return [];
    }
};
export const getProjectInfo = async (projectId: string) => {
    try {
        const response = await axios.post(
            `${serverUrl}/getProjectInfo`,
            {
                projectId,
            },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error getting project info:", error);
        throw error;
    }
};
export const deleteProject = async (projectId: string) => {
    try {
        const response = await axios.post(
            `${serverUrl}/deleteProject`,
            {
                projectId,
            },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error deleting project:", error);
        throw error;
    }
};

export interface projectCreateData {
    message: string;
    project_id: number;
    project: UserProject;
}
export const createProject = async (projectName: string, subject: string): Promise<projectCreateData> => {
    try {
        const response = await axios.post(
            `${serverUrl}/createProject`,
            {
                projectName,
                subject,
            },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error creating project:", error);
        throw error;
    }
};
export const getPaperInfo = async (paperId: string) => {
    try {
        return await axios.post(`${serverUrl}/get_paper_info`, { paper_id: paperId }, {
            withCredentials: true,
        });
    } catch (error) {
        console.error("Error creating project:", error);
        throw error;
    }
}

export interface SummaryData {
    answer: string;
    sourceInfo: SourceInfo;
}

export interface SummaryResult {
    [paperId: string]: SummaryData;
}
export const getAllProjectsForUser = async () => {
    try {
        const response = await axios.post(`${serverUrl}/view_projects`, {
            withCredentials: true,
        });

        return response.data;
    } catch (error) {
        console.error("Error getting projects:", error);
        throw error;
    }
};
export const getChatMessages = async (chatIndex: string) => {
    try {
        const response = await axios.post(
            `${serverUrl}/getMessages`,
            { chatIndex },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error getting messages:", error);
        throw error;
    }
};
export const getSources = async (chatIndex: string) => {
    try {
        const response = await axios.post(
            `${serverUrl}/getSources`,
            { chatIndex },
            {
                withCredentials: true,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error getting messages:", error);
        throw error;
    }
};
export const setupChat = async (paperIds: string[], projectId: string): Promise<{ chatIndex: string; warning?: string }> => {
    try {
        const response = await axios.post(
            `${serverUrl}/setupChat`,
            { paperIds, projectId },
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.data.error) {
            throw new Error(response.data.error);
        }

        const result: { chatIndex: string; warning?: string } = {
            chatIndex: response.data.chatIndex,
        };

        if (response.data.warning) {
            result.warning = response.data.warning;
            console.warn(response.data.warning);
        }

        return result;
    } catch (error) {
        console.error("Error setting up chat:", error);
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.error || "An unknown error occurred");
        }
        throw error;
    }
};
interface UploadPaperResponse {
    message: string;
    project_id: string;
    files: string[];
    paperIds: string[];
}
export const uploadPapersToProject = async (
    projectId: string,
    files: FileList,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
    navigate: (id: string) => void,
    setLoading: (loading: boolean) => void
): Promise<UploadPaperResponse> => {
    const formData = new FormData();
    formData.append("projectId", projectId);
    Array.from(files).forEach((file) => {
        formData.append("files", file);
    });

    try {
        setLoading(true);
        const response = await queueAdapter.executeTask(
            '/uploadPaperToProjectQ',  // Queued endpoint
            formData,
            '/uploadPaperToProject',    // Backup endpoint,
            60000,
        );
        onSuccess(response.message);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 413) {
                onError("File size too large. Please upload a smaller file.");
            } else if (error.response.status === 403) {
                onError("You have reached the maximum number of uploads for free trial users.");
                navigate(`/${projectId}/pricing`);
            } else {
                onError(error.response.data.error || "An unexpected error occurred during the upload process.");
            }
        } else {
            onError("An unexpected error occurred during the upload process.");
        }
    } finally {
        setLoading(false);
    }
    return { message: "", project_id: "", files: [], paperIds: [] };
};


export const logInUser = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (message: string) => void
): Promise<void> => {
    try {
        const response = await axios.post(`${serverUrl}/login`, {
            email,
            password,
        });
        console.log("Login response:", response);
        if (response.status === 200) {
            onSuccess();
        }
        else {
            onError("Login failed, please try again.");
        }
    } catch (error) {
        console.error("Error during login:", error);
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 401) {
                onError("Invalid credentials");
            } else {
                onError(error.response?.data?.error || "Log in failed.");
            }
        } else {
            onError("An error occurred during login");
        }
    }
};

export const logInUserGoogle = async (
    code: string,
    onSuccess: () => void,
    onError: (message: string) => void
): Promise<void> => {
    try {
        const response = await axios.post(`${serverUrl}/google_login`, {
            code,
        });

        if (response.status === 200) {
            onSuccess();
        } else {
            onError("Login failed, please try again.");
        }
    } catch (error) {
        console.error("Error during login:", error);
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 401) {
                onError("Invalid credentials");
            } else {
                onError("Log in failed.");
            }
        } else {
            onError("An error occurred during login");
        }
    }
};
export interface UserInfo {
    name: string;
    email: string;
    level: string;
    credits: number;
    onboarding_completed: boolean;
    subscription_paused: boolean;
    pause_end_date: string;
}
export const defaultUserInfo: UserInfo = {
    name: "",
    email: "",
    level: "trial",
    credits: 0,

    onboarding_completed: false,
    subscription_paused: false,
    pause_end_date: "",
};
// Define an interface for errors
interface FetchError {
    message: string;
}

export const fetchUserInfo = async (): Promise<UserInfo> => {
    try {
        const response = await fetch(`${serverUrl}/get_user_info`, {
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error("Failed to fetch user info");
        }
        return await response.json() as UserInfo;
    } catch (error) {
        throw error as FetchError;
    }
};
export const createPaypalSubscription = async (plan: string, duration: string) => {
    try {
        const response = await fetch(`${serverUrl}/paypal/create-subscription`, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userAction: "SUBSCRIBE_NOW", plan, duration }),
        });
        if (!response.ok) {
            throw new Error("Failed to fetch user info");
        }
        return response.json();
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};

export function getPdfPath(paper: SourceInfo | PaperInfo): string {
    let path = "";
    path = paper.pdf_path!.startsWith('pdf_store') ? `${serverUrl}/proxy/${encodeURIComponent(paper.pdf_path!)}` : paper.pdf_path!;
    return path;
}

export function convertDocumentReferencesToLinks(answerText: string): string {
    let referenceNumber: number = 1;
    const references: { [id: string]: number } = {};
    const convertedText = answerText.replace(referencePattern, (_match, id) => {
        if (!references[id]) {
            references[id] = referenceNumber++;
        }
        return `<a href="#${id}">[${references[id]}]</a>`;
    });

    return convertedText;
}
export function convertDocumentReferencesToLinksCSV(
    answerText: string
): string {
    let referenceNumber: number = 1;
    const references: { [id: string]: number } = {};
    const convertedText = answerText.replace(referencePattern, (_match, id) => {
        if (!references[id]) {
            references[id] = referenceNumber++;
        }
        return `[${references[id]}]`;
    });

    return convertedText;
}


export const registerUser = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
    tosCheck: boolean,
    organization: string,
    role: string,
    captchaToken: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
): Promise<void> => {
    if (!tosCheck) {
        onError("You haven't agreed to the terms of service");
        return;
    } else if (firstName.length === 0) {
        onError("First name has left blank!");
        return;
    } else if (email.length === 0) {
        onError("Email has left blank!");
        return;
    } else if (password.length === 0) {
        onError("Password has left blank!");
        return;
    } else if (confirmPassword.length === 0 || confirmPassword !== password) {
        onError("Confirm Password has left blank!");
        return;
    }

    try {
        onSuccess("Please wait while we register you..");
        await axios.post(`${serverUrl}/register`, {
            firstName,
            lastName,
            organization,
            role,
            email,
            password,
            captchaToken
        });
        onSuccess(
            "Please verify your email before logging in. A verification email has been sent to your email address."
        );
        return;
    } catch (error) {
        console.error("Registration error:", error);
        onError("An error occurred during registration");
    }
};

export const logOutUser = async (
    onSuccess: () => void,
    onError: (error: string) => void
): Promise<void> => {
    try {
        const response = await axios.post(`${serverUrl}/logout`);

        console.log("Logout successful:", response);
        onSuccess();
    } catch (error) {
        console.error("Logout error:", error);
        onError("An error occurred during logout");
    }
};

export const sendQuery = async (
    projectId: string,
    queryText: string,
    getPapers: boolean,
    filters: Filters,
    files: File[] = [],
    onSuccess: (response: UploadPdfResponse) => void,
    onError: (error: string) => void
): Promise<void> => {

    try {
        if (queryText.trim().length === 0) {
            onError("Query text cannot be empty");
            return;
        }
        if (queryText.length > 40000) {
            onError("Query text is too long. Please keep it under 40000 characters.");
            return;
        }
        const formData = new FormData();
        formData.append("query", queryText);
        formData.append("projectId", projectId);
        formData.append("getPapers", getPapers.toString());
        console.log("Filters:", filters);

        if (!getPapers) {
            files.forEach((file) => formData.append("files", file));
            filters.isFastMode = false;
        }
        formData.append("filters", JSON.stringify(filters));


        const result = await axios.post(`${serverUrl}/add_new_query`, formData, {
            withCredentials: true,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        console.log("File upload successful:", result);
        onSuccess(result.data as UploadPdfResponse);
    } catch (error) {
        console.error("File upload error:", error);
        onError("An error occurred during file upload");
    }
};

export const forgotPassword = async (
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void
): Promise<void> => {
    try {
        const response = await axios.post(`${serverUrl}/forgot_password`, {
            email,
        });
        console.log("Forgot Password Request:", response);
        onSuccess();
    } catch (error) {
        console.error("Error during forgot password request:", error);
        if (axios.isAxiosError(error)) {
            onError(
                error.response?.data?.error ||
                "An error occurred during the forgot password request"
            );
        } else {
            onError("An unexpected error occurred");
        }
    }
};
export interface LoggedInResponse {
    logged_in: boolean;
    user: UserInfo | null;
}


export const checkIsLoggedIn = async (): Promise<LoggedInResponse> => {
    try {
        const response = await axios.get(`${serverUrl}/is_logged_in`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        return { logged_in: false, user: null };
    }
};





export const getAnswer = async (
    queryHash: string,
    getPapers: boolean = true
): Promise<AnswerObject[]> => {
    const queueAdapter = new QueueAdapter({
        taskBaseUrl: serverUrl,     // For SSE/status endpoints  
        endpointBaseUrl: answerServerUrl, // For queued endpoint
        backupEndpointUrl: serverUrl      // For non-queued backup
    });
    const payload = { query_hash: queryHash, getPapers };

    try {

        // Wrap the executeTask call in fetchWithRetry
        const result = await fetchWithRetry(async () => {
            return await queueAdapter.executeTask(
                '/answerQ',  // This goes to answerServerUrl/answerQ
                payload,
                '/answer',
                600000
            );
        });

        return result;
    } catch (error) {
        try {
            console.error("retrying getAnswer:", error);
            const response = await fetch(`${answerServerUrl}/answer`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ query_hash: queryHash, getPapers: getPapers }),
            });
            return response.json();
        } catch (error) {
            console.error("Error in getAnswer:", error);
            emailSupportError(error, queryHash);
            return [{
                answer: `Please refresh the page! The connection broke, but your answer has been most likely processed already. Your email will also probably have the answer link. If the issue persists, please email me at ayush@answerthis.io`,
                query: "",
                filters: defaultFilters,
                sources: { result: {} },
                getPapers: true,
            }];
        }
    }
};

export const emailSupportError = async (error: any, extraMessage: string = '') => {
    try {
        let sendingMessage = error.stack || error.toString();
        sendingMessage += '\n';
        sendingMessage += extraMessage;
        await axios.post(`${serverUrl}/emailSupport`, {
            message: sendingMessage,
        });
    } catch (error) {
        console.error("Error emailing support:", error);
    }
}

export const deleteQuery = async (queryHash: string): Promise<void> => {
    try {
        const response = await fetch(`${serverUrl}/delete_query`, {
            method: "POST",
            credentials: "include", // Important for sending cookies in cross-origin requests
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query_hash: queryHash }),
        });
        if (!response.ok) {
            throw new Error("Failed to delete query");
        }
        console.log("Query deleted successfully");
    } catch (error) {
        console.error("Error deleting query:", error);
        throw error; // Re-throw to let the caller handle it
    }
};
export const getCustomColumnData = async (sourceIds: string[], columnName: string): Promise<SummaryResult> => {

    try {

        const response = await queueAdapter.executeTask(
            '/get_custom_column_dataQ',  // Queued endpoint
            { paper_ids: sourceIds, column_name: columnName },
            '/get_custom_column_data'    // Backup endpoint
        );
        if (!response) {
            throw new Error("Failed to fetch summary");
        }
        const data: SummaryResult = await response;
        return data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }


}

export const getSummary = async (
    queryHash: string,
    sources: string[],
): Promise<SummaryResult> => {
    try {
        const requestBody = {
            query_hash: queryHash,
            filters: sources,
        };
        const response = await queueAdapter.executeTask(
            '/summaryQ',  // Queued endpoint
            requestBody,
            '/summary'    // Backup endpoint
        );
        if (!response) {
            throw new Error("Failed to fetch summary");
        }
        const data: SummaryResult = await response;
        return data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};
// export const addToNewsletter = async (email: string) => {
//     try {
//         const response = await fetch(`${serverUrl}/newsletter`, {
//             method: "POST",
//             credentials: "include",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ email: email }),
//         });
//         return response.json();
//     } catch (error) {
//         console.error("Error:", error);
//         throw error;

//     }
// };

export const rerunQuery = async (queryHash: string, newQuery: string) => {
    try {
        const response = await fetch(`${serverUrl}/rerun`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query_hash: queryHash, query: newQuery }),
        });
        if (!response.ok) {
            throw new Error("Failed to rerun query");
        }
        return response.json();
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};

export const cancelSubscription = async (surveyData: { reason: string; feedback: string }, onSuccess: (message: string) => void,
    onError: (message: string) => void) => {
    try {

        const response = await axios.post(`${serverUrl}/payments/cancel_subscription`, { surveyData: surveyData }, {
            withCredentials: true,
        });
        if (!response) {
            onError("Failed to cancel subscription");
            throw new Error("Failed to Create new blog post");
        }
        onSuccess("Subscription cancelled successfully");
        return response.data; // Or handle the response as needed
    } catch (error) {
        console.error("Error cancelling subscription:", error);
        throw error; // Re-throw to let the caller handle it
    }
};

export const contactUs = async (name: string, email: string, message: string) => {
    try {
        const response = await fetch(`${serverUrl}/contactUs`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, email, message }),
        });
        if (!response.ok) {
            throw new Error("Failed to send message");
        }
        return response.json();
    } catch (error) {
        console.error("Error sending message:", error);
        throw error;
    }
}
export const submitReview = async (
    queryHash: string,
    reviewType: string,
    comment = ""
) => {
    try {
        const response = await fetch(`${serverUrl}/submit_review`, {
            method: "POST",
            credentials: "include", // For sending cookies with a cross-domain request
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query_hash: queryHash,
                review_type: reviewType,
                comment,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to submit review");
        }
        return response.json();
    } catch (error) {
        console.error("Error submitting review:", error);
        throw error;
    }
};
export function getAnswerText() {
    const answerElement = document.getElementById("answerText") as HTMLElement;
    const citationChanger = document.getElementById("dontShowInOutput") as HTMLElement;
    const citationText = citationChanger ? citationChanger.innerText : "";
    let answerText = answerElement ? answerElement.innerText : "";

    // Normalize line breaks
    answerText = answerText.replace(/\n{3,}/g, "\n\n").trim();

    // Remove unwanted text
    answerText = answerText.replace(
        "RephraseContinue WritingGenerate OutlineGenerate AbstractGenerate Research QuestionsSave Answer",
        ""
    );
    answerText = answerText.replace(citationText, "");

    // Fix citation formatting
    // answerText = answerText.replace(/(\d+\.)\n+/g, "$1 ");

    return answerText;
}

export async function handleRedirectMainPage(navigate: any) {
    // normal users will only have default projects so get that take user to def_proj_id/ask-answerthis
    try {
        const updatedProjects: UserProject[] = await getAllProjectsForUser();
        const defaultProjectId = updatedProjects[updatedProjects.length - 1].id;
        navigate(`/${defaultProjectId}/ask-answerthis`);
    } catch (error) {
        console.error("Error getting projects:", error);
    }

}



export async function redirectToOriginalPage(navigate: any, locationState: any) {
    const updatedProjects: UserProject[] = await getAllProjectsForUser();
    const defaultProjectId = updatedProjects[updatedProjects.length - 1].id;
    const redirectPath = locationState?.from?.pathname || `/${defaultProjectId}/ask-answerthis`;
    navigate(redirectPath);
}


// should include image url here and send it to backend 
export const createNewBlog = async (title: string, content: string, cover_image: string, onSuccess: (message: string) => void,
    onError: (message: string) => void) => {
    try {
        const response = await axios.post(`${serverUrl}/blogsApi`, {
            title,
            content: content,
            cover_image: cover_image
        }, { withCredentials: true });
        if (!response) {
            throw new Error("Failed to Create new blog post");
        }
        onSuccess("Created blog post successfully")
        return response.data;
    } catch (error) {
        onError(`Error creating new blog post`);
        console.error("Error creating new blog post:", error);
        throw error;
    }
};

export const uploadImage = async (imageFile: File): Promise<string> => {
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
        const response = await axios.post(`${serverUrl}/upload_image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }, withCredentials: true
        });
        if (response.data.url) {
            // Convert relative URL to absolute URL
            return `${serverUrl}${response.data.url}`;
        } else {
            throw new Error("Failed to upload image");
        }
    } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
    }
};

export const fetchAllBlog = async () => {
    try {
        const response = await axios.get(`${serverUrl}/blogsApi`);
        if (!response) {
            throw new Error("Failed to fetch blog posts");
        }
        return response.data;
    } catch (error) {
        console.error("Error to fetch new blog posts:", error);
        throw error;
    }
}


export const fetchBlogData = async (url: string) => {
    try {
        const response = await axios.get(`${serverUrl}/blogsApi/${url}`);
        if (!response) {
            throw new Error("Failed to Create new blog post");
        }
        return response.data;
    } catch (error) {
        console.error("Error creating new blog post:", error);
        throw error;
    }
}
export const updateBlogData = async (url: string, title: string, content: string, coverImage: string): Promise<any> => {
    try {
        const response = await axios.put(`${serverUrl}/blogsApi/${url}`, {
            title,
            content,
            coverImage
        }, { withCredentials: true });
        if (response.status !== 200) {
            throw new Error("Failed to update blog post");
        }
        return response.data;
    } catch (error) {
        console.error("Error updating blog post:", error);
        throw error;
    }
};



// export const scrollToElement = (id: string, callback?: () => Promise<void>, offset: number = 0): void => {
//     const performScroll = () => {
//         const element = document.getElementById(id);
//         if (element) {
//             const headerHeight = (document.querySelector('header') as HTMLElement)?.offsetHeight ?? 0; // Fallback to 0 if header not found
//             const elementPosition = element.getBoundingClientRect().top;
//             const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//             console.log(headerHeight)
//             console.log(elementPosition)
//             console.log(scrollTop)
//             const finalPosition = elementPosition + scrollTop - headerHeight - offset;

//             window.scrollTo({ top: finalPosition, behavior: 'smooth' });
//         }
//     };

//     if (callback) {
//         callback().then(() => {
//             performScroll();
//         });
//     } else {
//         performScroll();
//     }
// };

export const handleNavigationAndScroll = (path: string, id: string, navigate: any) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    navigate(path);
    // Wait for route change and then scroll
    setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, 100);
};
export const checkIsNewUser = async (id?: string): Promise<boolean> => {
    const recentQueriesData: RecentSearch[] = await getProjectQueries(id!);
    const data = await getAllProjectsForUser();
    // If user has only one projects (default project) and has no past queries -> new user
    if (recentQueriesData.length == 0 && data.length == 1) return true;
    else return false;
}
export interface SubscriptionDetail {
    id: string;
    status: string;
    customer_id: string;
    current_term_start: number;
    current_term_end: number;
    next_billing_at: number;
    billing_period: number;
    billing_period_unit: string;
    base_currency_code: string;
    currency_code: string;
    mrr: number;
    activated_at: number;
    created_at: number;
    updated_at: number;
    subscription_items: SubscriptionItem[];
    shipping_address: ShippingAddress;
}

export interface SubscriptionItem {
    item_price_id: string;
    item_type: string;
    quantity: number;
    unit_price: number;
    amount: number;
    current_term_start: number;
    current_term_end: number;
    next_billing_at: number;
}

export interface CustomerDetail {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    auto_collection: string;
    created_at: number;
    updated_at: number;
    billing_address: BillingAddress;
    card_status: string;
    primary_payment_source_id: string;
    mrr: number;
    promotional_credits: number;
    refundable_credits: number;
    excess_payments: number;
    payment_method: PaymentMethod;
}

export interface PaymentMethod {
    type: string;
    gateway: string;
    reference_id: string;
    status: string;
}

export interface BillingAddress {
    line1: string;
    city: string;
    state_code: string;
    state: string;
    country: string;
    zip: string;
    validation_status: string;
}

export interface ShippingAddress extends BillingAddress { }

export interface ServerResponse {
    subscription: SubscriptionDetail;
    customer: CustomerDetail;
}
export const postDataChargebee = async (
    onSuccess: (message: ServerResponse) => void,
    onError: (message: string) => void,
    subscriptionId: string,
    invoiceId: string
): Promise<void> => {
    try {
        const response = await axios.post(`${serverUrl}/chargeBeeCheckout`, {
            subscriptionId,
            invoiceId
        }, {
            withCredentials: true
        });
        console.log("Chargebee response:", response);
        if (response.status === 200 && response.data) {
            console.log("Chargebee response:", response.data);
            onSuccess(response.data);
        } else {
            onError('Activation failed. Please try again.');
        }
    } catch (error) {
        onError('Error activating plan: ' + error);
    }
};


export const getUserCountry = async () => {
    try {
        const locationResponse = await fetch('https://ipapi.co/json/');
        const locationData = await locationResponse.json();
        const countryName = locationData.country_name;
        return countryName;
    } catch (error) {
        console.error("Error getting user country:", error);
        throw error;
    }
}



// export const handleStripeClick = async (
//     onSuccess: (message: string) => void,
//     onError: (message: string) => void,
//     duration: string,
//     plan: string
// ) => {
//     try {
//         const response = await fetch(`${serverUrl}/create-checkout-session`, {
//             method: "POST",
//             credentials: "include",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ plan, duration }),
//         });
//         console.log("Checkout session response:", response);

//         const { sessionId } = await response.json();

//         // Redirect to Stripe Checkout
//         const stripe = await stripePromise;
//         if (!stripe) {
//             console.error("Stripe.js failed to load");
//             return;
//         }
//         const { error } = await stripe.redirectToCheckout({
//             sessionId,
//         });
//         if (error) {
//             onError("Stripe checkout error:" + error.message);
//         }
//         if (!response.ok) {
//             throw new Error("Failed to create checkout session");
//         } else {
//             onSuccess("Payment Successful!");
//             // alert("Payment Successful!");
//             //reload the page
//             window.location.reload();
//         }
//     } catch (error) {
//         onError("Error creating checkout session: " + error);
//     }
// };

export const deletePaperFromLibrary = async (paperId: string, projectId: string): Promise<void> => {
    try {
        await axios.post(`${serverUrl}/deletePaperFromProject`, {
            paperId,
            projectId
        }, {
            withCredentials: true
        });
    } catch (error) {
        console.error("Error deleting paper from library:", error);
        throw error;
    }
};

export const editPaperInProject = async (
    projectId: string,
    paperId: string,
    updatedPaper: Partial<SourceInfo>,
    file?: File
): Promise<SourceInfo> => {
    try {
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('paperId', paperId);

        // Add file if present
        if (file) {
            formData.append('file', file);
        }

        // Add all updatedPaper fields
        Object.entries(updatedPaper).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value.toString());
            }
        });

        const response = await axios.post(
            `${serverUrl}/editPaperInProject`,
            // If no file, send as JSON; if file present, send as FormData
            file ? formData : { projectId, paperId, ...updatedPaper },
            {
                withCredentials: true,
                headers: file ? {
                    'Content-Type': 'multipart/form-data'
                } : {
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data.paper;
    } catch (error) {
        console.error("Error editing paper in project:", error);
        throw error;
    }
};
export const checkIfStudent = (email: string): boolean => {
    // Basic email format check
    const basicEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!basicEmailRegex.test(email)) {
        return false;
    }

    // Split the email into local part and domain
    const [, domain] = email.split('@');

    // Common academic top-level domains
    const commonAcademicTLDs = ['edu', 'ac', 'edu.au', 'ac.uk', 'edu.sg', 'ihu.gr'];

    // Check for common academic TLDs
    if (commonAcademicTLDs.some(tld => domain.endsWith(`.${tld}`))) {
        return true;
    }

    // Check for country-specific academic domains (e.g., .edu.gr, .ac.jp)
    if (/\.(edu|ac)\.[a-z]{2}$/i.test(domain)) {
        return true;
    }

    // Check for institutional domains (e.g., harvard.edu, ox.ac.uk)
    const institutionalDomainRegex = /^(?:[a-z0-9-]+\.)*(?:college|university|institute|school|academy|faculty|campus)\.(?:[a-z]{2,})$/i;
    if (institutionalDomainRegex.test(domain)) {
        return true;
    }

    // If none of the above checks pass, it's likely not an academic email
    return false;
};

export const handleSaveSurvey = async (
    email: string,
    responses: any,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
) => {
    try {
        await axios.post(`${serverUrl}/save_survey`, { email, responses }, { withCredentials: true });
        onSuccess("Thank you! You've received 2 extra free queries.");
    } catch (error) {
        console.error("Error saving survey:", error);
        onError("Failed to save survey");
    }
}
export const createCheckoutSession = async (duration: string) => {
    try {
        const response = await axios.post(`${serverUrl}/create_checkout`, {
            plan_type: duration === 'monthly' ? 'monthly' : 'yearly'
        }, {
            withCredentials: true
        });

        if (response.status === 200 && response.data) {
            return {
                url: response.data.url,
                hosted_page_id: response.data.hosted_page_id
            };
        } else {
            throw new Error('Failed to create checkout session');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
export const activateSubscription = async (hostedPageId: string, payment_processor: string) => {
    try {
        const response = await axios.post(`${serverUrl}/checkout_complete`, {
            hosted_page_id: hostedPageId,
            payment_processor
        }, {
            withCredentials: true
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to activate subscription');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
export const isIntercomAvailable = (): boolean => {
    // Check if Intercom is initialized
    return typeof (window as any).Intercom !== 'undefined' &&
        typeof (window as any).intercomSettings !== 'undefined'
}
interface FetchDataParams {
    text: string;
    prompt: string;
}
export const fetchDataEditor = async ({ text, prompt }: FetchDataParams): Promise<string> => {
    const response = await queueAdapter.executeTask(
        '/autocompleteQ',  // Queued endpoint
        { text, prompt },
        '/autocomplete'    // Backup endpoint
    );
    if (!response) {
        throw new Error('Network response was not ok');
    }
    return response;
};


export const fetchPaperData = async (title: string, doi: string, author: string): Promise<SourceInfo> => {
    try {
        const response = await axios.post(`${serverUrl}/search_paper_info`, {
            title,
            doi,
            author
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching paper data:", error);
        throw error;
    }
}

export const enablePreciseSearch = async (
    paperIds: string[],
    projectId: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
) => {
    try {
        const result = await queueAdapter.executeTask(
            '/populatePdfQ',  // Queued endpoint
            { paperIds, projectId },
            '/populatePdf'    // Backup endpoint
        );

        const { processed_papers = [], failed_papers = [] } = result;


        if (processed_papers.length > 0 || failed_papers.length > 0) {
            if (processed_papers.length > 0) {
                const successMessage = `Successfully enabled precise search for:\n- ${processed_papers.join('\n- ')}\n\nPapers with precise search enabled will be thoroughly searched when used as sources.`;
                onSuccess(successMessage);
            }

            if (failed_papers.length > 0) {
                const errorMessage = `Could not download the following papers (You can click the edit icon and upload their pdfs separately):\n- ${failed_papers.join('\n- ')}`;
                onError(errorMessage);
            }
        } else {
            onError("No papers were processed. Please try again.");
        }

        return result;
    } catch (error) {
        console.error("Error enabling precise search:", error);
        onError("An error occurred while enabling precise search. Please try again.");
        return null;
    }
};
