import React, { useState, useEffect, useMemo } from 'react';
import parse from "html-react-parser";
import { SourceInfo, Section, OpenPaperInfo, convertDocumentReferencesToLinks } from "../../authService";
import "./tableAt.css";
import { buttonClassBig } from '../Buttons/buttonClasses';
import GlobalCopyCitation from '@/contexts/citationContext';
interface ReusableTableProps {
    data: SourceInfo[];
    selectedSourceIds: string[];
    handleCheckboxChange: (sourceId: string) => void;
    referencesIdNumbers?: { [id: string]: number };
    markedSummaries?: { [id: string]: string };
    customColumns: string[];
    customColumnData: { [key: string]: { [key: string]: string } };
    loadingColumns: { [key: string]: boolean };
    showExtractedSource?: boolean;
    showCitation?: boolean;
    handleTitleClick?: (sourceId: string) => void;
}

const ReusableTable: React.FC<ReusableTableProps> = ({
    data,
    selectedSourceIds,
    handleCheckboxChange,
    referencesIdNumbers,
    markedSummaries,
    customColumns,
    customColumnData,
    loadingColumns,
    showExtractedSource = false,
    showCitation = false,
    handleTitleClick,
}) => {
    const { navigateToPaperInfo } = OpenPaperInfo();
    const [hiddenColumns, setHiddenColumns] = useState<{ [key: string]: boolean }>({});
    const [allColumns, setAllColumns] = useState<string[]>([]);
    const hasMarkedSummaries = markedSummaries && Object.values(markedSummaries).some(summary => summary !== '');

    useEffect(() => {
        const columns = ["Source", "Source Information",
            ...(hasMarkedSummaries ? ['Insight Related to Question'] : []),
            ...(showExtractedSource ? ["Relevant Extracts"] : []),
            ...customColumns
        ];
        setAllColumns(columns);
    }, [hasMarkedSummaries, showExtractedSource, customColumns]);


    const toggleColumn = (columnName: string) => {
        setHiddenColumns(prev => ({ ...prev, [columnName]: !prev[columnName] }));
    };

    const getColumnId = (columnName: string) => {
        return `column-${columnName.toLowerCase().replace(/\s+/g, '-')}`;
    };

    const showAllColumns = () => {
        setHiddenColumns({});
    };
    const isAnyColumnHidden = useMemo(() => {
        return Object.values(hiddenColumns).some(isHidden => isHidden);
    }, [hiddenColumns]);
    const handleTitleClickWrapper = (sourceId: string) => {
        if (handleTitleClick) {
            handleTitleClick(sourceId);
        } else {
            navigateToPaperInfo(sourceId);
        }
    };
    return (
        <div className="table-wrapper">
            <style>
                {Object.entries(hiddenColumns).map(([columnName, isHidden]) => `
                    ${isHidden ? `#${getColumnId(columnName)}, .${getColumnId(columnName)} { display: none; }` : ''}
                `).join('\n')}
            </style>
            <div className="">
                {isAnyColumnHidden && (
                    <button onClick={showAllColumns} className={`${buttonClassBig} mb-3 hidden md:block`}>
                        Show All Columns
                    </button>
                )}
            </div>
            <table className="tableAtSource">
                <colgroup>
                    {allColumns.map((columnName, index) => (
                        <col key={index} id={getColumnId(columnName)} style={{ width: index === 0 ? "10%" : index === 1 ? "25%" : "40%" }} />
                    ))}
                </colgroup>
                <thead>
                    <tr>
                        {allColumns.map((columnName) => (
                            <th key={columnName} className={getColumnId(columnName)}>
                                {columnName}
                                <button onClick={() => toggleColumn(columnName)} className="hide-column-btn">
                                    {hiddenColumns[columnName] ? '☐' : '✕'}
                                </button>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((sourceInfo, index) => (
                        <tr key={sourceInfo.id} className='paper-section'>
                            <td className={`source-number ${getColumnId("Source")}`}>
                                <input
                                    type="checkbox"
                                    checked={selectedSourceIds.includes(sourceInfo.id)}
                                    onChange={() => handleCheckboxChange(sourceInfo.id)}
                                    className="hover:cursor-pointer"
                                />
                                <strong>
                                    {referencesIdNumbers ? (referencesIdNumbers[sourceInfo.id] || "Additional Reads") : (index + 1)}
                                </strong>
                            </td>
                            <td className={getColumnId("Source Information")}>

                                <div className="heightLimit">
                                    <a
                                        className="source-title-link"
                                        onClick={() => handleTitleClickWrapper(sourceInfo.id)}
                                    >
                                        <strong>{sourceInfo.title}</strong>
                                    </a>

                                    {sourceInfo.pdf_path === '' || sourceInfo.pdf_path === "None" ? (
                                        <p className="source-title-author">
                                            <strong>PDF:</strong> <div className="text-red-500 inline font-semibold">Not Available</div>
                                        </p>
                                    ) : (
                                        <p className="source-title-author">
                                            <strong>PDF:</strong><div className="text-green-500 inline font-semibold"> Available</div>
                                        </p>
                                    )}
                                    <p className="source-title-author">
                                        <strong>Author:</strong> {sourceInfo.author}
                                    </p>
                                    {sourceInfo.journal !== "internet" ? (
                                        <>
                                            <p className="source-title-author">
                                                <strong>Citation Count:</strong> {sourceInfo.citations}
                                            </p>
                                            <p className="source-title-author">
                                                <strong>Journal:</strong> {sourceInfo.journal}
                                            </p>

                                            <p className="source-title-author">
                                                <strong>Publication Date:</strong>{" "}
                                                {sourceInfo.date_of_publication || "N/A"}
                                            </p>
                                            <a
                                                className="source-doi-link"
                                                onClick={() => navigateToPaperInfo(sourceInfo.id)}
                                            >
                                                <p>
                                                    <strong>DOI:</strong> {sourceInfo.doi}
                                                </p>
                                            </a>
                                        </>
                                    ) : (
                                        <a
                                            className="source-doi-link"
                                            onClick={() => window.open(sourceInfo.url, "_blank", "noopener noreferrer")}
                                        >
                                            <p>
                                                <strong>Source:</strong> {sourceInfo.url}
                                            </p>
                                        </a>
                                    )}
                                    {showCitation && (
                                        <GlobalCopyCitation source={sourceInfo} />
                                    )}
                                </div>
                            </td>
                            {hasMarkedSummaries && (
                                <td className={getColumnId('Insight Related to Question')}>
                                    <div className="heightLimit">
                                        <p className="extracted-source">
                                            {markedSummaries[sourceInfo.id]
                                                ? parse(convertDocumentReferencesToLinks(markedSummaries[sourceInfo.id]))
                                                : 'No insight available'}
                                        </p>
                                    </div>
                                </td>
                            )}
                            {showExtractedSource && (
                                <td className={`extracted-source-class ${getColumnId("Relevant Extracts")}`}>
                                    <div className="heightLimit">
                                        {sourceInfo.sections.length > 0 ? (
                                            sourceInfo.sections.map((detail: Section, detailIndex: number) => (
                                                <p key={detailIndex} id={detail.id} className="extracted-source">
                                                    <mark>
                                                        <span className="footnote">[{detailIndex + 1}]</span>
                                                        <strong> Extracted Source</strong>
                                                        <br /> {detail.content}
                                                    </mark>
                                                </p>
                                            ))
                                        ) : (
                                            <p className="extracted-source">
                                                {sourceInfo.abstract}
                                            </p>
                                        )}
                                    </div>
                                </td>
                            )}
                            {customColumns.map((columnName) => (
                                <td key={columnName} className={`custom-column ${getColumnId(columnName)}`}>
                                    {loadingColumns[columnName] ? (
                                        <div className="spinner"></div>
                                    ) : (
                                        <div className="heightLimit">
                                            <p>{customColumnData[columnName]?.[sourceInfo.id] || ''}</p>
                                        </div>
                                    )}
                                </td>
                            ))}

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReusableTable;