import React, { ReactNode } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

interface ResourceCardProps {
    icon: ReactNode;
    title: string;
    description: string;
    buttonText: string;
    buttonIcon?: ReactNode;
    onClick?: () => void;
    extraContent?: ReactNode;
}

const ResourceCard: React.FC<ResourceCardProps> = ({
    icon,
    title,
    description,
    buttonText,
    buttonIcon,
    onClick,
    extraContent
}) => {
    return (
        <Card className="bg-white hover:shadow-lg transition-shadow duration-300">
            <CardContent className="p-6">
                <div className="w-12 h-12 bg-brandMaroon/10 rounded-xl flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-semibold mb-3">{title}</h3>
                <p className="text-gray-600 mb-6">{description}</p>
                {extraContent}
                <Button
                    variant="outline"
                    onClick={onClick}
                    className="w-full border-brandMaroon text-brandMaroon hover:bg-brandMaroon hover:text-white"
                >
                    {buttonIcon && <span className="mr-2">{buttonIcon}</span>}
                    {buttonText}
                </Button>
            </CardContent>
        </Card>
    );
};

export default ResourceCard;
