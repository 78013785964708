import { handleNavigationAndScroll } from "../authService";
import { Location, NavigateFunction } from "react-router-dom";

interface NavigationListProps {
    location: Location;
    navigate: NavigateFunction;
    isMobile?: boolean;
}

export function NavigationList({ location, navigate, isMobile }: NavigationListProps) {
    const listClassName = isMobile ? "flex flex-col gap-5" : "hidden md:flex gap-8";
    const itemClassName = isMobile
        ? "text-lg py-2 hover:text-brandMaroon transition-colors"
        : "text-base hover:text-brandMaroon transition-colors";
    const activeClassName = "text-brandMaroon font-medium";

    return (
        <ul className={listClassName}>
            <a href="/affiliate">
                <li className={`${itemClassName} ${location.pathname.includes('/affiliate') ? activeClassName : ''}`}>
                    Affiliate
                </li>
            </a>
            <a href="/university">
                <li className={`${itemClassName} ${location.pathname.includes('/university') ? activeClassName : ''}`}>
                    University
                </li>
            </a>
            <a href="#!" onClick={handleNavigationAndScroll('/', 'features', navigate)}>
                <li className={`${itemClassName} ${location.pathname === '/' && location.hash === '#features' ? activeClassName : ''}`}>
                    Features
                </li>
            </a>
            <a href="/blog">
                <li className={`${itemClassName} ${location.pathname.includes('/blog') ? activeClassName : ''}`}>
                    Blogs
                </li>
            </a>
            <a href="#!" onClick={handleNavigationAndScroll('/', 'faq', navigate)}>
                <li className={`${itemClassName} ${location.pathname === '/' && location.hash === '#faq' ? activeClassName : ''}`}>
                    Faq
                </li>
            </a>
            <a href="https://guide.answerthis.io/">
                <li className={itemClassName}>
                    Guide
                </li>
            </a>
        </ul>
    );
}