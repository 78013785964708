import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import vastSourcesImg from "/img/search_page.png";
import litReviewImg from "/img/scholarly_answer.png";
import tableImg from "/img/table_sources.png";

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title, subtitle }) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-white via-white to-lightMaroon/10">
      {/* Desktop Layout */}
      <div className="hidden lg:flex min-h-screen">
        {/* Left Side - Auth Form */}
        <div className="w-1/2 p-8 flex items-center justify-center">
          <Card className="w-full max-w-md backdrop-blur-sm bg-white/80 border border-white/40 shadow-xl">
            <CardContent className="pt-8 px-8">
              <div className="flex flex-col items-center ">
                <img 
                  src="/img/logo.svg" 
                  alt="AnswerThis Logo" 
                  className="h-12 " 
                  onClick={() => navigate('/')}
                  style={{ cursor: 'pointer' }}
                />
                <h1 className="text-2xl font-bold text-gray-800 mb-4">{title}</h1>
                <p className="text-gray-600 mb-4">{subtitle}</p>
              </div>
              {children}
            </CardContent>
          </Card>
        </div>

        {/* Right Side - Feature Images */}
        <div className="w-1/2 bg-gradient-to-br from-brandMaroon/5 to-lightMaroon/10 p-8 flex items-center justify-center">
          <div className="grid grid-cols-1 gap-6 max-w-2xl">
            <div className="relative rounded-2xl overflow-hidden shadow-xl">
              <img
                src={vastSourcesImg}
                alt="Feature Preview"
                className="w-full h-auto object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end p-6">
                <h3 className="text-white text-xl font-semibold">Discover vast sources of knowledge</h3>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="relative rounded-2xl overflow-hidden shadow-xl">
                <img
                  src={litReviewImg}
                  alt="Literature Review"
                  className="w-full h-auto object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end p-4">
                  <h3 className="text-white text-sm font-semibold">Smart Literature Review</h3>
                </div>
              </div>
              <div className="relative rounded-2xl overflow-hidden shadow-xl">
                <img
                  src={tableImg}
                  alt="Table Sources"
                  className="w-full h-auto object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end p-4">
                  <h3 className="text-white text-sm font-semibold">Organized Research</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="lg:hidden min-h-screen p-4 flex items-center justify-center">
        <Card className="w-full max-w-md backdrop-blur-sm bg-white/80 border border-white/40 shadow-xl">
          <CardContent className="pt-8 px-6">
            <div className="flex flex-col items-center mb-8">
              <img src="/img/logo.svg" alt="AnswerThis Logo" className="h-10 " />
              <h1 className="text-xl font-bold text-gray-800 mb-4">{title}</h1>
              <p className="text-sm text-gray-600">{subtitle}</p>
            </div>
            {children}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
