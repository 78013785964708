import defaultProfilePic from "/img/default-pfp.png"
import { ReactNode, useEffect, useState } from "react"
import { cancelSubscription, getUserBillingEnd, applyDiscount40, removeScheduledCancellation, createPortalSession } from "../authService";
import Spinner from "../components/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import SeoComp from "../components/SeoComp";
import { useAuth } from "../contexts/AuthContext";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import CustomLabel from "@/components/CustomLabel";
import { buttonClass } from "@/components/Buttons/buttonClasses";
import RetentionAlert from "@/components/pricing/RetentionMeeting";

interface TitleProps {
    children: ReactNode;
}

const Title: React.FC<TitleProps> = ({ children }) => (
    <div className="font-Manrope text-md md:text-[18px] font-medium text-brandMaroon">
        {children}
    </div>
);

interface ValueProps {
    children: React.ReactNode;
}

const Value: React.FC<ValueProps> = ({ children }) => (
    <div className="font-Manrope text-md md:text-[18px] font-medium break-all">
        {children}
    </div>
);

function Profile() {
    const pfp = defaultProfilePic;
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuth();

    // Displayed in UI
    const [billingEnd, setBillingEnd] = useState<string | null>(null);
    const [cancelling, setCancelling] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Survey modal state
    const [showSurvey, setShowSurvey] = useState(false);
    const [surveyReason, setSurveyReason] = useState("");
    const [surveyFeedback, setSurveyFeedback] = useState("");
    const [errors, setErrors] = useState({ reason: false, feedback: false });

    // 40%-Off Offer modal state
    const [showSave40Modal, setShowSave40Modal] = useState(false);

    // 1) Show discount-offer modal first
    const handleCancelSubscription = async () => {
        setShowSave40Modal(true);
    };

    // 2) If user accepts discount => call /apply_discount_40
    const handleAcceptDiscount = async () => {
        try {
            await applyDiscount40();
            setShowSave40Modal(false);
            // Possibly refetch or reload
            toast.success("Discount applied. Thank you for staying with us!");
        } catch (err) {
            console.error("Failed to apply discount", err);
        }
    };

    // 3) If user declines => show survey
    const handleDeclineDiscount = () => {
        setShowSave40Modal(false);
        setShowSurvey(true);
    };
    const handleDontCancelSubscription = () => {
        toast.loading("Resuming subscription...");
        removeScheduledCancellation().then(() => {
            toast.success("Subscription resumed successfully");
            window.location.reload();
        }
        ).catch(() => {
            toast.error("Error resuming subscription");
        });
    }
    // Survey validation
    const validateSurvey = () => {
        const newErrors = {
            reason: surveyReason === "",
            feedback: surveyFeedback.trim() === ""
        };
        setErrors(newErrors);
        return !newErrors.reason && !newErrors.feedback;
    };

    // Submit survey => final cancel
    const handleSubmitSurvey = async () => {
        if (validateSurvey()) {
            const surveyData = {
                reason: surveyReason,
                feedback: surveyFeedback
            };

            try {
                toast.loading("Cancelling subscription...");
                await cancelSubscription(surveyData, toast.success, toast.error);
                setShowSurvey(false);
                toast.success("Subscription cancelled successfully");
                window.location.reload();
            } catch (error) {
                toast.error("Error cancelling subscription");
            }
        } else {
            toast.error("Please fill out all required fields");
        }
    };

    // Function to handle opening the subscription management portal
    const handleManageProfile = async () => {
        if (!user) {
            toast.error("User information not available");
            return;
        }

        if (user.level !== "paid") {
            toast.error("Only Pro users can manage their subscription");
            return;
        }

        setIsLoading(true);
        try {
            const portalData = await createPortalSession();
            // Redirect to the portal URL
            window.location.href = portalData.url;
        } catch (error) {
            console.error('Error creating portal session:', error);
            toast.error('Failed to open subscription management portal');
        } finally {
            setIsLoading(false);
        }
    };

    // On mount, fetch billing info
    useEffect(() => {
        getUserBillingEnd().then((data) => {
            setBillingEnd(data.billing_cycle_end);
            setCancelling(data.cancelling);
        });
    }, []);

    if (!user) {
        return <Spinner />
    }

    return (
        <>

            <SeoComp />

            {/** 
             * =========================
             *   40%-OFF OFFER MODAL
             * =========================
             */}
            <Dialog open={showSave40Modal} onOpenChange={setShowSave40Modal}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-bold text-center text-brandMaroon">
                            Wait! You're Eligible for an Exclusive Offer
                        </DialogTitle>
                        <DialogDescription className="text-center text-lg font-medium">
                            Save 40% on your subscription - forever!
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col space-y-6 mt-6">
                        <div className="bg-lightMaroon bg-opacity-10 p-4 rounded-lg border-2 border-brandMaroon">
                            <div className="flex items-center justify-between mb-4">
                                <span className="text-xl font-bold line-through text-gray-500">Regular Price</span>
                                <span className="text-xl font-bold line-through text-gray-500">$35/mo</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="text-xl font-bold text-brandMaroon">Your Special Price</span>
                                <span className="text-xl font-bold text-brandMaroon">$21/mo</span>
                            </div>
                        </div>

                        <div className="space-y-3">
                            <p className="font-medium flex items-center">
                                <svg className="w-5 h-5 mr-2 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                                Keep all Pro features you love
                            </p>
                            <p className="font-medium flex items-center">
                                <svg className="w-5 h-5 mr-2 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                                40% discount locked in forever
                            </p>
                            <p className="font-medium flex items-center">
                                <svg className="w-5 h-5 mr-2 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                                No commitments - cancel anytime
                            </p>
                        </div>

                        <p className="text-sm text-gray-600 text-center">
                            This is a one-time offer that won't be available again
                        </p>
                    </div>
                    <DialogFooter className="flex-col gap-3 sm:flex-col mt-6">
                        <Button className={`${buttonClass} w-full text-lg py-6`} onClick={handleAcceptDiscount}>
                            Yes! I Want 40% Off Forever
                        </Button>
                        <Button variant="outline" className="w-full" onClick={handleDeclineDiscount}>
                            No thanks, I'll pass
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/**
             * =========================
             *   SURVEY + CANCEL MODAL
             * =========================
             */}
            <Dialog open={showSurvey} onOpenChange={setShowSurvey}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>We're sorry to see you go</DialogTitle>
                        <DialogDescription>
                            Please help us improve our service by answering a few quick questions.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <RadioGroup
                            value={surveyReason}
                            onValueChange={(value) => {
                                setSurveyReason(value);
                                setErrors(prev => ({ ...prev, reason: false }));
                            }}
                        >
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="too-expensive" id="too-expensive" />
                                <CustomLabel htmlFor="too-expensive">Too expensive</CustomLabel>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="not-useful" id="not-useful" />
                                <CustomLabel htmlFor="not-useful">References not relevant</CustomLabel>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="not-answer" id="not-answer" />
                                <CustomLabel htmlFor="not-answer">Answer writing not good</CustomLabel>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="too-errors" id="too-errors" />
                                <CustomLabel htmlFor="too-errors">Too many errors</CustomLabel>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="found-alternative" id="found-alternative" />
                                <CustomLabel htmlFor="found-alternative">Found a better alternative</CustomLabel>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="other" id="other" />
                                <CustomLabel htmlFor="other">Other</CustomLabel>
                            </div>
                        </RadioGroup>
                        {errors.reason && (
                            <p className="text-red-500 text-sm">Please select a reason</p>
                        )}

                        <Textarea
                            placeholder="Please give us additional feedback"
                            value={surveyFeedback}
                            onChange={(e) => {
                                setSurveyFeedback(e.target.value);
                                setErrors(prev => ({ ...prev, feedback: false }));
                            }}
                            className={errors.feedback ? "border-red-500" : ""}
                        />
                        {errors.feedback && (
                            <p className="text-red-500 text-sm">Please provide additional feedback</p>
                        )}
                    </div>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowSurvey(false)}>
                            Cancel
                        </Button>
                        <Button className={buttonClass} onClick={handleSubmitSurvey}>
                            Submit and Cancel Subscription
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/**
             * ========================
             *   MAIN PROFILE CONTENT
             * ========================
             */}
            <div
                className="flex justify-center items-center h-[100vh] md:h-screen max-w-screen"
                style={{
                    overflow: 'hidden',
                    backgroundImage: "url('/img/gradient-bg-full.png')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >

                <div className="bg-white group shadow-2xl w-[90vw] md:w-[50vw] mx-auto rounded-lg py-6 px-8 flex flex-col gap-2 2xl:gap-4">

                    <div className="font-Manrope text-2xl md:text-3xl lg:text-[32px] font-semibold text-brandMaroon mb-5">
                        Profile
                    </div>
                    <RetentionAlert
                        user={user}
                        onBookMeeting={() => window.location.href = 'https://ayushgarg.youcanbookme.com/'}

                    />
                    <div className='border-t-[1px] border-slate-300 duration-300 group-hover:border-brandMaroon'></div>
                    <div className="flex gap-6 my-2 items-center">
                        <img src={pfp} alt="Default Profile Photo" className="w-[50px] h-[50px] object-contain" />
                        <div className="font-Manrope text-2xl md:text-3xl lg:text-[32px] font-semibold ">
                            {user.name}
                        </div>
                    </div>
                    <div className="flex gap-6 my-2 items-center">
                        <Title>Email:</Title>
                        <Value>{user.email}</Value>
                    </div>
                    {/* If user is trial */}
                    <div className="flex flex-col gap-2 2xl:gap-4 bg-gray-50 p-4 rounded-lg">
                        <div className="flex gap-6 my-2 items-center">
                            <Title>Credits:</Title>
                            <Value>
                                {user.level === "trial"
                                    ? `${user.credits} remaining`
                                    : '✨ Unlimited'}
                            </Value>
                        </div>

                    </div>

                    <div className='w-full border-[1.5px] rounded-xl border-brandMaroon duration-300 py-4 px-6 mx-auto gap-2 font-Manrope md:flex items-center bg-lightMaroon bg-opacity-60 mt-2 mb-4'>
                        <div className='w-full'>
                            <div className='font-semibold md:text-[18px]'>
                                {user.level === "trial"
                                    ? "🎁 You're on the Trial plan - Upgrade for unlimited access!"
                                    : "💎 You're on the Pro plan"}
                            </div>
                            {user.level === 'paid' && (
                                <div className='text-black font-medium text-[15px] md:text-[16px] mt-1'>
                                    {cancelling
                                        ? `Access available until ${billingEnd}`
                                        : `Next billing: ${billingEnd}`
                                    }
                                    {cancelling && (
                                        <button
                                            className="ml-2 text-brandMaroon underline font-semibold"
                                            onClick={handleDontCancelSubscription}
                                        >
                                            Continue my subscription
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex gap-2">
                            {/* Manage Profile Button - Only visible for paid users */}
                            {user.level === "paid" && !cancelling && (
                                <button
                                    className="hover:bg-brandMaroon font-semibold text-[17px] hover:text-white border-brandMaroon border-2 py-1 px-4 rounded-lg mt-4 md:mt-0 md:rounded-full bg-white text-brandMaroon transition duration-300 min-w-max h-[40px]"
                                    onClick={handleManageProfile}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading...' : 'Manage Subscription'}
                                </button>
                            )}

                            {cancelling ? null : (
                                <button
                                    className="hover:bg-white font-semibold text-[17px] hover:text-brandMaroon border-brandMaroon border-2 py-1 px-4 rounded-lg mt-4 md:mt-0 md:rounded-full bg-brandMaroon text-white transition duration-300 min-w-max h-[40px] md:ml-2"
                                    onClick={() => {
                                        if (user.level === "trial") {
                                            navigate(`/${id}/pricing`);
                                        } else {
                                            // Instead of going directly to survey,
                                            // we now prompt for discount first
                                            handleCancelSubscription();
                                        }
                                    }}
                                >
                                    {user.level === "trial" ? "Get Pro plan" : "Cancel Subscription"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;
