import "../Answer/AnswerThisAnswer.css";
import "./AnswerThisSummary.css";
import Loading from "../../components/Loading";
import { useEffect, useState } from "react";
import { getSummary } from "../../authService";
import { useParams, useLocation } from "react-router-dom";
// import FeedbackForm from "../../components/Feedback";
import { Toaster } from "sonner";
import SeoComp from "../../components/SeoComp";
import { SummaryResult } from "../../authService";

function AnswerThisSummary() {
  const { query_hash } = useParams<{ query_hash: string }>();
  const location = useLocation();
  const question = location.state.question;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState<SummaryResult>({});
  const { selectedSources } = location.state as { selectedSources: string[] };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const summary = await getSummary(query_hash!, selectedSources);
        console.log("summary", summary);
        setSummaryData(summary);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    if (query_hash) {
      fetchData();
    }
  }, [query_hash, selectedSources]);

  if (isLoading) {
    return <Loading question={question} />;
  }

  const exportToCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      '"Title","Author","Citations","Journal","Publication Date","DOI","Summary"\n';

    Object.entries(summaryData).forEach(([, data]) => {
      const { sourceInfo, answer } = data;
      const rowData = [
        sourceInfo.title,
        sourceInfo.author,
        sourceInfo.citations.toString(),
        sourceInfo.journal,
        sourceInfo.date_of_publication.split(" ")[0],
        sourceInfo.doi,
        answer.replace(/"/g, '""').replace(/(\r\n|\n|\r)/gm, " "),
      ]
        .map((field) => `"${field}"`)
        .join(",");

      csvContent += rowData + "\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sourceSummaries.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <SeoComp />
      <Toaster closeButton expand={false} richColors position="top-center" />
      {/* <FeedbackForm queryHash={query_hash!} /> */}
      <div className="wrapper">
        <div
          className="source-information answer-block source-summaries"
          id="source-summaries"
        >
          <h2>Source summaries</h2>
          <div className="action-buttons my-4">
            <button
              onClick={exportToCSV}
              className="summary-button export-button"
            >
              Export to CSV
            </button>
          </div>
          <div className="table-wrapper-summary">
            <table className="tableAtSourcesummary">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "75%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <p>Source</p>
                  </th>
                  <th>
                    <p>Summary</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(summaryData).map(([id, data]) => (
                  <tr key={id}>
                    <td className="source-info-box">
                      <div>
                        <a
                          href={data.sourceInfo.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="source-title">{data.sourceInfo.title}</p>
                        </a>
                        <p className="source-title-author">
                          <strong>Author:</strong> {data.sourceInfo.author}
                        </p>
                        <p className="source-title-author">
                          <strong>Citations:</strong> {data.sourceInfo.citations}
                        </p>
                        <p className="source-title-author">
                          <strong>Journal:</strong> {data.sourceInfo.journal}
                        </p>
                        <p className="source-title-author">
                          <strong>Publication Date:</strong>{" "}
                          {data.sourceInfo.date_of_publication
                            ? data.sourceInfo.date_of_publication.split(" ")[0]
                            : " "}
                        </p>
                        <p className="source-title-author">
                          <strong>DOI:</strong> {data.sourceInfo.doi}
                        </p>
                      </div>
                    </td>
                    <td className="source-summary-box">
                      <p>{data.answer}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnswerThisSummary;