import { FC, } from "react";
import Carousel from "../../components/blog/BlogCarousel";
import { Helmet } from "react-helmet";
import logo from "/img/logo.svg";

interface BlogsProps { }

const Blogs: FC<BlogsProps> = () => {


  return (
    <>
      <Helmet>
        <title>AnswerThis blogs | Quality content on AI tools for research</title>
        <meta name="description" content="Quality content on how to optimize research workflow using AI research assistant and leveraging AI tools to improve efficiency." />
        <meta property="og:title" content="AnswerThis blogs | Quality content on AI tools for research" />
        <meta property="og:description" content="Quality content on how to optimize research workflow using AI research assistant and leveraging AI tools to improve efficiency." />
        <meta name="keywords" content="AI research assistant, AI tools, Optimize research " />
        <meta name="publisher" content="Ayush Garg" />
        <meta property="og:image" content={logo} />
        <meta name="twitter:title" content="AnswerThis blogs | Quality content on AI tools for research" />
        <meta name="twitter:description" content="Quality content on how to optimize research workflow using AI research assistant and leveraging AI tools to improve efficiency." />
        <meta name="twitter:image" content={logo} />

      </Helmet>
      <div className=" font-Manrope pt-4 mx-[10px] md:mx-[70px] flex flex-col pb-10 min-h-[calc(100vh-300px)]">
        <div className="mt-10 mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn about how to optimize your research workflow using AI research assistant and leveraging AI tools to improve efficiency.
          </p>
        </div>
        <Carousel />
      </div>
    </>
  );
};

export default Blogs;
