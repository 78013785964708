import { FC } from 'react'
import "./Spinner.css"
interface SpinnerProps {

}

const Spinner: FC<SpinnerProps> = () => {
    return (
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-4 text-brandMaroon spinner-font'>
            <div className='text-[22px]'>Loading</div>
            <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-brandMaroon border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
            </div>
        </div>
    )
}

export default Spinner