import React, { useEffect, useState } from 'react';
import {
    BookOpen, Search, ArrowRight, ArrowLeft,
    Sparkles, Clock, MessageSquare, Network,
    Library, BarChart2,
    Settings
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent } from '@/components/ui/card';
import { SearchBar } from '@/components/searchInput/SearchBar';
import { Button } from '@/components/ui/button';
import PromptHelper from '@/components/promptHelper';
import FilterSidebar from '@/components/filters/FiltersSidebar';
import { defaultFilters, Filters, handleRedirectMainPage, sendQuery, serverUrl } from '@/authService';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '@/contexts/AuthContext';



type ResearchOption = 'literature-review' | 'assistant' | null;
type Step = 'welcome' | 'query' | 'approach' | 'completion';

const FirstTimeSearch: React.FC = () => {
    const { id } = useParams();
    console.log('Query:', id);
    const { refreshUserInfo, isOnboardingCompleted } = useAuth();
    const navigate = useNavigate();

    const [query, setQuery] = useState<string>('');
    const [step, setStep] = useState<Step>('welcome');
    const [selectedOption, setSelectedOption] = useState<ResearchOption>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState<Filters>(defaultFilters);

    useEffect(() => {
        if (isOnboardingCompleted) {
            handleRedirectMainPage(navigate);
        }
    }, []);

    const handleQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const handlePromptHelp = (value: string) => {
        setQuery(value);
    };

    const handleSubmit = async () => {
        if (!query.trim() || !selectedOption) return;

        // First show completion step
        setStep('completion');

        // After a short delay to show features, process the query
        setTimeout(async () => {

            try {
                await axios.post(`${serverUrl}/onboardingComplete`, { withCredentials: true });
                refreshUserInfo();

                await sendQuery(
                    id!,
                    query,
                    true,
                    filters,
                    [],
                    (response) => {
                        toast.success("Onboarding completed successfully");
                        navigate(`/${id}/answerthis/answer/${response.query_hash}`, {
                            state: { question: query }
                        });
                    },
                    (error) => {
                        console.error(error);
                        setStep('approach'); // Go back to approach step on error
                    }
                );
            } catch (error) {
                setStep('approach'); // Go back to approach step on error
            }
        }, 2000); // Give users 2 seconds to see the features
    };

    const handleApproachSelect = (option: ResearchOption) => {
        setSelectedOption(option);
        if (option) {
            setFilters(prev => ({
                ...prev,
                answerStyle: option === 'literature-review' ? 'literature_review' : 'research_assistant'
            }));
        }
    };

    const renderWelcome = () => (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="text-center relative"
        >
            <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="mb-8 inline-flex items-center gap-2 bg-brandMaroon/10 text-brandMaroon px-6 py-3 rounded-full"
            >
                <Clock size={20} />
                <span className="font-semibold">Save up to 70% of your research time</span>
            </motion.div>

            <h1 className="text-5xl font-extrabold text-gray-800 mb-4">
                Welcome to <span className="text-brandMaroon">AnswerThis</span>
            </h1>

            <p className="text-xl text-gray-600 mb-4">The fastest growing research AI platform</p>
            <p className="text-gray-500 mb-12">Experience smarter, faster, and more insightful research</p>

            <Button
                onClick={() => setStep('query')}
                className="bg-brandMaroon hover:bg-brandMaroon/90 text-white px-8 py-6 rounded-full text-lg font-semibold transition-all duration-300 transform hover:scale-105 shadow-lg group"
            >
                <span className="mr-2">Get Started</span>
                <ArrowRight size={20} className="inline-block transition-transform group-hover:translate-x-1" />
            </Button>

            <div className="mt-12 flex justify-center gap-8 text-sm text-gray-500">
                <div className="flex items-center gap-2">
                    <Sparkles size={16} className="text-brandMaroon" />
                    <span>AI-Powered Analysis</span>
                </div>
                <div className="flex items-center gap-2">
                    <Clock size={16} className="text-brandMaroon" />
                    <span>Time-Saving Insights</span>
                </div>
            </div>
        </motion.div>
    );

    const renderQueryStep = () => (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="max-w-2xl mx-auto"
        >
            <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-gray-800 mb-4">What would you like to research?</h2>
                <p className="text-gray-600">Enter your research topic or question below</p>
            </div>

            <div className="relative mb-4 group w-full">
                <div className="absolute -inset-1 bg-gradient-to-r from-brandMaroon/30 to-purple-500/30 rounded-lg blur opacity-30" />
                <div className="relative rounded-lg min-h-[60px]">
                    <SearchBar
                        handleInputChange={handleQueryChange}
                        handleSearch={() => setStep('approach')}
                        query={query}
                        placeholder="e.g., What are the latest developments in renewable energy storage?"
                    />
                </div>
            </div>



            <div className="mb-8">
                <h3 className="text-lg font-semibold mb-4 text-center">Need inspiration? Try these prompts:</h3>
                <div className="relative group">
                    <div className="absolute -inset-1 bg-gradient-to-r from-purple-500/20 to-brandMaroon/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-1000" />
                    <div className="relative rounded-lg">
                        <PromptHelper onSelectPrompt={handlePromptHelp} />
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center">
                <Button
                    onClick={() => setStep('welcome')}
                    variant="outline"
                    className="flex items-center gap-2 group"
                >
                    <ArrowLeft size={16} className="transition-transform group-hover:-translate-x-1" />
                    Back
                </Button>
                <Button
                    onClick={() => query.trim() && setStep('approach')}
                    disabled={!query.trim()}
                    className="bg-brandMaroon hover:bg-brandMaroon/90 text-white px-6 py-2 rounded-full group"
                >
                    Continue
                    <ArrowRight size={16} className="ml-2 transition-transform group-hover:translate-x-1" />
                </Button>
            </div>
        </motion.div>
    );

    const renderApproachStep = () => (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="max-w-2xl mx-auto"
        >
            <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-gray-800 mb-4">Choose Your Research Approach</h2>
                <p className="text-gray-600 mb-8">Select the best option for your needs</p>
            </div>

            <div className="space-y-4 mb-8">
                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleApproachSelect('literature-review')}
                    className={`w-full flex items-center p-6 rounded-xl border-2 transition-all duration-300 relative group ${selectedOption === 'literature-review'
                        ? 'border-brandMaroon bg-brandMaroon/5'
                        : 'border-gray-200 hover:border-brandMaroon/50'
                        }`}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-brandMaroon/20 to-purple-500/20 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500 -z-10" />
                    <BookOpen className="mr-4 text-brandMaroon" size={24} />
                    <div className="text-left">
                        <h3 className="font-bold text-lg mb-2">Structured Literature Review</h3>
                        <p className="text-gray-600">Get a comprehensive analysis with organized sections, methodology review, and key findings</p>
                    </div>
                </motion.button>

                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleApproachSelect('assistant')}
                    className={`w-full flex items-center p-6 rounded-xl border-2 transition-all duration-300 relative group ${selectedOption === 'assistant'
                        ? 'border-brandMaroon bg-brandMaroon/5'
                        : 'border-gray-200 hover:border-brandMaroon/50'
                        }`}
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-brandMaroon/20 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500 -z-10" />
                    <Search className="mr-4 text-brandMaroon" size={24} />
                    <div className="text-left">
                        <h3 className="font-bold text-lg mb-2">Research Assistant</h3>
                        <p className="text-gray-600">Interactive exploration with AI guidance, perfect for exploratory research and quick insights</p>
                    </div>
                </motion.button>
                {/* Advanced Filters Button */}
                <div className="mb-6 flex justify-end">
                    <Button
                        onClick={() => setShowFilters(true)}
                        variant="outline"
                        className="text-sm flex items-center gap-2 text-gray-600 hover:text-brandMaroon"
                    >
                        <Settings size={14} />
                        Advanced Filters
                    </Button>
                </div>

                {/* FilterSidebar with isSidebar false */}
                <FilterSidebar
                    isOpen={showFilters}
                    filters={filters}
                    setFilters={setFilters}
                    onClose={() => setShowFilters(false)}
                    onApply={() => setStep('approach')}
                    isSidebar={false}
                />
            </div>

            <div className="flex justify-between items-center">
                <Button
                    onClick={() => setStep('query')}
                    variant="outline"
                    className="flex items-center gap-2 group"
                >
                    <ArrowLeft size={16} className="transition-transform group-hover:-translate-x-1" />
                    Back
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!selectedOption}
                    className="bg-brandMaroon hover:bg-brandMaroon/90 text-white px-6 py-2 rounded-full group"
                >
                    Begin Research
                    <ArrowRight size={16} className="ml-2 transition-transform group-hover:translate-x-1" />
                </Button>
            </div>
        </motion.div>
    );

    const renderCompletionStep = () => (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center"
        >
            <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                className="inline-flex items-center gap-2 bg-green-100 text-green-700 px-6 py-3 rounded-full mb-8"
            >
                <Sparkles size={20} />
                <span className="font-semibold">Preparing your research journey!</span>
            </motion.div>

            <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Your first guided research is starting
            </h2>
            <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
                While we prepare your results, discover what else AnswerThis can do for your research:
            </p>

            <div className="grid md:grid-cols-2 gap-6 mb-8">
                <FeatureCard
                    icon={MessageSquare}
                    title="Chat with PDFs"
                    description="Upload your research papers and have interactive conversations about their content"
                />
                <FeatureCard
                    icon={Network}
                    title="Citation Maps"
                    description="Discover related papers and visualize research connections"
                />
                <FeatureCard
                    icon={Library}
                    title="Document Library"
                    description="Organize and manage your research papers efficiently"
                />
                <FeatureCard
                    icon={BarChart2}
                    title="Data Extraction"
                    description="Extract tables, figures, and data from research papers"
                />
            </div>

            <div className="flex items-center justify-center gap-3 mt-8">
                <div className="w-2 h-2 bg-brandMaroon rounded-full animate-bounce [animation-delay:-0.3s]" />
                <div className="w-2 h-2 bg-brandMaroon rounded-full animate-bounce [animation-delay:-0.15s]" />
                <div className="w-2 h-2 bg-brandMaroon rounded-full animate-bounce" />
            </div>
        </motion.div>
    );

    const FeatureCard: React.FC<{
        icon: React.ElementType;
        title: string;
        description: string;
    }> = ({ icon: Icon, title, description }) => (
        <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-100 hover:border-brandMaroon/50 transition-all duration-300">
            <Icon className="text-brandMaroon mb-3" size={28} />
            <h3 className="font-semibold text-lg mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    );

    return (
        <div className="min-h-screen bg-white font-[Manrope] flex items-center justify-center p-6 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-brandMaroon/20 via-purple-500/20 to-brandMaroon/20 opacity-50 blur-3xl -z-10" />

            <AnimatePresence mode="wait">
                <Card className="relative bg-white rounded-2xl p-8 max-w-3xl w-full mx-auto shadow-2xl border border-gray-100">
                    <div className="absolute inset-0 border border-white/50 rounded-2xl" />
                    <CardContent className="relative z-10">
                        {step === 'welcome' && renderWelcome()}
                        {step === 'query' && renderQueryStep()}
                        {step === 'approach' && renderApproachStep()}
                        {step === 'completion' && renderCompletionStep()}
                    </CardContent>
                </Card>
            </AnimatePresence>


        </div>
    );
};

export default FirstTimeSearch;