// updateSectionHelper.ts
import { DOMSerializer } from 'prosemirror-model';
import {
    convertCitationsToChunkIds,
    convertEditorToAnswer,
    convertAnswerToEditorFormat,
} from './answerConversion';
import { defaultFiltersNoDatabase } from '@/authService';
import { Editor } from '@tiptap/react';
import { updateDocumentContent, insertAtCursor } from './documentService';
import { answerObjectListToFormattedAnswer } from '@/HelperUtils/AnswerFetcher';
import { citationStyles } from '@/pages/Answer/citationUtils';

export interface SectionUpdateParams {
    editor: Editor;
    projectId: string;
    instruction: string;
    useNewSources?: boolean;
    filters?: typeof defaultFiltersNoDatabase;
    placement?: 'replace' | 'before' | 'after';
    citationStyle?: citationStyles;
}

// Helper function to get text content from editor
function getEditorContent(editor: Editor, from: number, to: number): string {
    const slice = editor.state.doc.slice(from, to);
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const tempDiv = document.createElement("div");
    slice.content.forEach(node => {
        tempDiv.appendChild(serializer.serializeNode(node));
    });
    return tempDiv.innerHTML;
}

/**
 * Performs the section update:
 * - Serializes the currently selected text.
 * - Sends data to your update API.
 * - Formats the result and inserts it back into the editor.
 */
export async function performSectionUpdate({
    editor,
    projectId,
    instruction,
    useNewSources = false,
    filters = defaultFiltersNoDatabase,
    placement = 'replace',
    citationStyle = 'apa',
}: SectionUpdateParams): Promise<void> {
    const selection = editor.state.selection;
    if (selection.from === selection.to) {
        throw new Error('Please highlight text first');
    }

    // Get the selected HTML using the helper function
    const selectedHtml = getEditorContent(editor, selection.from, selection.to);

    // Process the selected HTML (e.g., convert citation markers)
    const rawAnswerText = convertCitationsToChunkIds(selectedHtml);

    // Call the API/update function using the current editor content and selected text
    const newEditorContent = await updateDocumentContent(
        '',
        instruction,
        convertEditorToAnswer(editor),
        rawAnswerText,
        projectId,
        useNewSources ? filters : defaultFiltersNoDatabase
    );

    // Format the API result into the answer data object
    const formattedOutline = await answerObjectListToFormattedAnswer(newEditorContent);
    const answerData = formattedOutline.answers[0];

    // Convert the answer data into a format compatible with TipTap
    const convertedAnswerEditorFormat = await convertAnswerToEditorFormat(answerData, citationStyle);

    // Insert the updated content based on the specified placement
    if (placement === 'replace') {
        editor.chain().focus().deleteSelection().insertContent(convertedAnswerEditorFormat).updateCitationNumbers().run();
    } else if (placement === 'before') {
        editor.chain().focus().insertContentAt(selection.from, convertedAnswerEditorFormat).updateCitationNumbers().run();
    } else {
        editor.chain().focus().insertContentAt(selection.to, convertedAnswerEditorFormat).updateCitationNumbers().run();
    }
}

/**
 * Updates the section by rephrasing (i.e. "Reword for clarity").
 */
export async function updateSectionRephrase(editor: Editor, projectId: string, citationStyle: citationStyles): Promise<void> {
    return await performSectionUpdate({
        editor,
        projectId,
        instruction: 'Reword this text for clarity',
        placement: 'replace',
        citationStyle
    });
}

/**
 * Updates the section by elongating (i.e. "Extend the text further").
 */
export async function updateSectionElongate(editor: Editor, projectId: string, citationStyle: citationStyles): Promise<void> {
    return await performSectionUpdate({
        editor,
        projectId,
        instruction: 'Extend the text further',
        placement: 'replace',
        citationStyle
    });
}
export async function elongateOutline(editor: Editor, projectId: string, citationStyle: citationStyles): Promise<void> {
    return await performSectionUpdate({
        editor,
        projectId,
        instruction: 'Transform this outline into a comprehensive, well-structured section. For each point in the outline: 1) Develop it into a detailed paragraph with clear topic sentences, supporting evidence, and explanations, 2) Maintain logical flow and transitions between paragraphs, 3) Preserve the hierarchical structure of the original outline, and 4) Ensure each major point receives proportional coverage. If there are subpoints, incorporate them naturally into their parent points\' paragraphs.',
        placement: 'replace',
        citationStyle
    });
}
/**
 * Updates the section using a custom prompt.
 * @param customPrompt The custom instruction to use.
 */
export async function updateSectionCustom(
    editor: Editor,
    projectId: string,
    customPrompt: string,
    citationStyle: citationStyles
): Promise<void> {
    if (!customPrompt.trim()) {
        throw new Error('Please enter a custom prompt');
    }
    return await performSectionUpdate({
        editor,
        projectId,
        instruction: customPrompt,
        placement: 'replace',
        citationStyle,
    });
}

export async function performCursorInsertion({
    editor,
    projectId,
    instruction,
    useNewSources = false,
    filters = defaultFiltersNoDatabase,
    citationStyle = 'apa'
}: Omit<SectionUpdateParams, 'placement'>): Promise<{ success: boolean }> {
    if (!instruction.trim()) {
        throw new Error('Please enter a custom prompt');
    }

    try {
        const { state } = editor;
        const cursorPos = state.selection.from;

        // Get content before and after cursor using the helper function
        const beforeHtml = getEditorContent(editor, 0, cursorPos);
        const afterHtml = getEditorContent(editor, cursorPos, editor.state.doc.content.size);

        // Convert citations to chunk IDs for both before and after content
        const rawBeforeHtml = convertCitationsToChunkIds(beforeHtml);
        const rawAfterHtml = convertCitationsToChunkIds(afterHtml);

        // Call the API with the processed content
        const newEditorContent = await insertAtCursor(
            '',
            instruction,
            convertEditorToAnswer(editor),
            rawBeforeHtml,
            rawAfterHtml,
            projectId,
            useNewSources ? filters : defaultFiltersNoDatabase
        );

        // Format the API result
        const formattedOutline = await answerObjectListToFormattedAnswer(newEditorContent);
        const answerData = formattedOutline.answers[0];

        // Convert the answer data into editor format
        const convertedAnswerEditorFormat = await convertAnswerToEditorFormat(answerData, citationStyle);

        // Insert the content at cursor position
        editor.chain().focus().insertContentAt(cursorPos, convertedAnswerEditorFormat).updateCitationNumbers().run();

        return { success: true };
    } catch (error) {
        console.error('Error in performCursorInsertion:', error);
        return { success: false };
    }
}