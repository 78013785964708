import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';

interface CommissionCalculatorProps {
    isYearly: boolean;
    onToggle: (checked: boolean) => void;
    baseCommission: number;
    referralCounts?: number[];
}

const CommissionCalculator: React.FC<CommissionCalculatorProps> = ({
    isYearly,
    onToggle,
    baseCommission,
    referralCounts = [10, 50, 100]
}) => {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
                <h2 className="text-3xl font-bold text-gray-900">How much could you earn?</h2>
                <p className="mt-4 text-lg text-gray-600">20% commission for each referral</p>
                <div className="flex items-center justify-center gap-4 mt-6">
                    <span className={`text-sm ${!isYearly ? 'text-brandMaroon font-semibold' : 'text-gray-600'}`}>
                        Monthly
                    </span>
                    <Switch
                        checked={isYearly}
                        onCheckedChange={onToggle}
                        className="data-[state=checked]:bg-brandMaroon"
                    />
                    <span className={`text-sm ${isYearly ? 'text-brandMaroon font-semibold' : 'text-gray-600'}`}>
                        Yearly
                    </span>
                </div>
            </div>

            <Card className="bg-white shadow-lg">
                <CardContent className="p-6">
                    <div className="grid grid-cols-4 gap-4 text-sm font-medium text-gray-500 mb-4 p-4">
                        <div>Referrals</div>
                        <div>Per referral</div>
                        <div>Monthly earnings</div>
                        <div>Annual earnings</div>
                    </div>

                    {referralCounts.map((referrals) => (
                        <div key={referrals}
                            className="grid grid-cols-4 gap-4 p-4 hover:bg-gray-50 rounded-lg transition-colors duration-150"
                        >
                            <div className="font-semibold text-gray-900">{referrals}</div>
                            <div className="text-brandMaroon font-medium">
                                ${baseCommission.toFixed(2)}
                            </div>
                            <div className="text-gray-900">
                                ${(baseCommission * referrals).toFixed(2)}
                            </div>
                            <div className="font-bold text-brandMaroon">
                                ${(baseCommission * referrals * (isYearly ? 1 : 12)).toFixed(2)}
                            </div>
                        </div>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};

export default CommissionCalculator;
