import { FC } from 'react'


const partners = [
    {
        cover: true,
        src: "/img/logos/cambridge.png",
        name: "Cambridge University"
    },
    {
        cover: false,
        src: "/img/logos/mit.png",
        name: "Massachusetts Institute of Technology"
    },
    {
        cover: false,
        src: "/img/logos/oxford.png",
        name: "Oxford University"
    },
    {
        cover: false,
        src: "/img/logos/richmond-logo.jpeg",
        name: "University of Richmond"
    },
    {
        cover: true,
        src: "/img/logos/stanford.png",
        name: "Stanford University"
    }, {
        cover: true,
        src: "/img/logos/upenn.png",
        name: "University of Pennsylvania"
    },

]

const TrustedPartners: FC = () => {
    return (
        <>


            <div className=' font-Manrope flex flex-col items-center w-[80%] justify-center mx-auto '>
                <div className='text-center text-[20px] font-medium'>
                    <span className='text-brandMaroon font-semibold px-1'>AnswerThis</span> is trusted by researches from <span className='text-brandMaroon font-semibold px-1'>World's top organizations</span>
                </div>
                <div className='grid grid-cols-4 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 justify-center mt-5 md:mt-5'>
                    {partners.map((partner) => (
                        <img src={partner.src} alt={`${partner.name} Logo`} title={`${partner.name} Logo`} className={`w-[80px] md:w-[150px] md:h-[80px] h-[50px] ${partner.cover ? 'md:object-cover object-contain' : 'object-contain'}`}></img>
                    ))}
                </div>
            </div>
        </>

    )
}

export default TrustedPartners