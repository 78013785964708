import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight, BookOpen, Users2, GraduationCap, BarChart3, FileCheck2, Building2, Brain, Search, FileText } from 'lucide-react';
import HeroSection from '@/components/marketing/HeroSection';
import StatsSection from '@/components/marketing/StatsSection';
import FeatureSection from '@/components/marketing/FeatureSection';
import vastSourcesImg from "/img/search_page.png";
import litReviewImg from "/img/scholarly_answer.png";
import tableImg from "/img/table_sources.png";

const UniversityLanding = () => {
    const stats = [
        { value: "500+", label: "Research Institutions" },
        { value: "100M+", label: "Papers Analyzed" },
        { value: "25%", label: "Faster Literature Reviews" },
        { value: "24/7", label: "Research Support" }
    ];

    const steps = [
        {
            icon: FileText,
            title: "Upload Papers",
            description: "Upload research papers to generate insights and citations effortlessly."
        },
        {
            icon: Search,
            title: "Ask Questions",
            description: "Interact with papers through AI-driven Q&A for instant insights."
        },
        {
            icon: Brain,
            title: "Get Insights",
            description: "Get precise answers, ready-to-use citations, and comprehensive reviews."
        }
    ];

    const features = [
        {
            icon: <BookOpen className="h-6 w-6 text-brandMaroon" />,
            title: "Enhanced Learning Experience",
            description: "Enable students to craft detailed, citation-backed literature reviews and improve research skills."
        },
        {
            icon: <Users2 className="h-6 w-6 text-brandMaroon" />,
            title: "Student Collaboration",
            description: "Facilitate collaboration by enabling students to share organized libraries and research projects."
        },
        {
            icon: <GraduationCap className="h-6 w-6 text-brandMaroon" />,
            title: "Academic Success",
            description: "Support academic growth with tools that streamline research and improve citation accuracy."
        },
        {
            icon: <BarChart3 className="h-6 w-6 text-brandMaroon" />,
            title: "Research Analytics",
            description: "Provide faculty with analytics on citation trends and institutional research performance."
        },
        {
            icon: <FileCheck2 className="h-6 w-6 text-brandMaroon" />,
            title: "Compliance & Ethics",
            description: "Maintain academic integrity with verifiable citations and robust compliance tools."
        },
        {
            icon: <Building2 className="h-6 w-6 text-brandMaroon" />,
            title: "Institution-wide Benefits",
            description: "Standardize research tools across departments to enhance productivity and efficiency."
        }
    ];

    return (
        <div className="w-full min-h-screen font-Manrope bg-white">
            <HeroSection
                badge="AI Research Assistant for Universities"
                title={<>
                    Revolutionize University Research<br />
                    with <span className="text-brandMaroon">AI-Powered</span><br />
                    Assistance
                </>}
                description="Boost academic research efficiency with AnswerThis. Our AI-powered platform simplifies literature reviews, organizes citations, and enhances research quality."
                primaryCTA={{
                    text: "Schedule Demo",
                    onClick: () => window.open('https://ayushgarg.youcanbookme.com/'),
                }}
                secondaryCTA={{
                    text: "Contact Us",
                    onClick: () => window.open('mailto:outreach@answerthis.io'),
                }}
                variant="university"
                customContent={<div className="mt-12 transition-all duration-300">
                    <div className="bg-white rounded-xl shadow-xl overflow-hidden h-full">

                    </div>
                </div>}
                backgroundPattern="dots"
            />

            <section className="py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <StatsSection stats={stats} className="pt-12" />
                </div>
            </section>

            <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white">
                <div className="max-w-7xl mx-auto space-y-20">
                    <FeatureSection
                        badge="Research Excellence"
                        title="Enhance Research Quality & Output"
                        description="Accelerate your institution's research impact with AI-powered literature analysis. 
                        Help faculty and researchers produce higher quality work in less time."
                        features={[
                            {
                                title: "Comprehensive Literature Analysis",
                                description: "Analyze hundreds of papers to craft literature reviews with accurate citations."
                            },
                            {
                                title: "Research Impact Tracking",
                                description: "Measure research productivity with detailed citation and impact analysis."
                            }
                        ]}
                        image={{
                            src: vastSourcesImg,
                            alt: "AnswerThis Research Assistant Interface"
                        }}
                        variant="university"
                    />

                    <FeatureSection
                        badge="Academic Integrity"
                        title="Maintain Academic Standards"
                        description="Ensure research integrity with properly cited sources and verified academic content.
                        Support your institution's commitment to academic excellence."
                        features={[
                            {
                                title: "Verified Citations",
                                description: "Generate accurate, verifiable citations directly from trusted sources."
                            },
                            {
                                title: "Research Compliance",
                                description: "Ensure compliance with institutional and academic publishing standards."
                            }
                        ]}
                        image={{
                            src: litReviewImg,
                            alt: "Literature Review Interface"
                        }}
                        reverse
                        variant="university"
                    />

                    <FeatureSection
                        badge="Institutional Impact"
                        title="Drive Research Innovation"
                        description="Access millions of academic sources to identify emerging research trends, 
                        potential collaborations, and funding opportunities for your institution."
                        features={[
                            {
                                title: "Research Analytics",
                                description: "Access analytics on research productivity and emerging trends."
                            },
                            {
                                title: "Collaboration Opportunities",
                                description: "Discover collaboration opportunities by mapping key papers and citation networks."
                            }
                        ]}
                        image={{
                            src: tableImg,
                            alt: "Organized Research Results"
                        }}
                        variant="university"
                    />
                </div>
            </section>

            <section className="py-16 px-4 sm:px-6 lg:px-8 bg-lightMaroon/10">
                <div className="max-w-7xl mx-auto">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold mb-4">How AnswerThis Works</h2>
                        <p className="text-gray-600">Simple, powerful, and intuitive research assistance</p>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        {steps.map((step, index) => (
                            <div key={index} className="text-center p-8 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
                                <div className="bg-lightMaroon/20 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                                    <step.icon className="h-8 w-8 text-brandMaroon" />
                                </div>
                                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                                <p className="text-gray-600">{step.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-24 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-gray-900">Why Universities Choose AnswerThis</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            Discover how AnswerThis can transform research and learning at your institution
                        </p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        {features.map((feature, index) => (
                            <div key={index} className="p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-100">
                                <div className="w-12 h-12 bg-lightMaroon/20 rounded-xl flex items-center justify-center mb-6">
                                    {React.cloneElement(feature.icon as React.ReactElement, {
                                        className: "h-6 w-6 text-brandMaroon"
                                    })}
                                </div>
                                <h3 className="text-xl font-semibold mb-3 text-brandMaroon">{feature.title}</h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-24 bg-gradient-to-br from-lightMaroon/20 to-lightGrey/50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                        <div className="p-12 text-center">
                            <h2 className="text-3xl font-bold text-gray-900 mb-6">Ready to enhance research productivity at your institution?</h2>
                            <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
                                Partner with AnswerThis to streamline research and drive academic excellence.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                <Button
                                    onClick={() => window.open('https://ayushgarg.youcanbookme.com/')}
                                    className="bg-brandMaroon hover:bg-darkMaroon text-white px-8 py-6 rounded-xl text-lg"
                                >
                                    Schedule a Demo <ArrowRight className="ml-2 h-5 w-5" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UniversityLanding;