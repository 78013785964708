import React, { createContext, useContext, useState, useCallback } from 'react';
import { SourceInfo, fetchLibraryPapers } from '@/authService';

interface LibraryContextType {
    libPapers: SourceInfo[];
    isLoading: boolean;
    refreshLibrary: (queryId: string) => Promise<void>;
}

const LibraryContext = createContext<LibraryContextType | undefined>(undefined);

export function LibraryProvider({ children }: { children: React.ReactNode }) {
    const [libPapers, setLibPapers] = useState<SourceInfo[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const refreshLibrary = useCallback(async (queryId: string) => {
        try {
            setIsLoading(true);
            const papers = await fetchLibraryPapers(queryId);
            setLibPapers(papers.reverse());
        } catch (error) {
            console.error('Error fetching library papers:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <LibraryContext.Provider value={{ libPapers, isLoading, refreshLibrary }}>
            {children}
        </LibraryContext.Provider>
    );
}

export function useLibrary() {
    const context = useContext(LibraryContext);
    if (context === undefined) {
        throw new Error('useLibrary must be used within a LibraryProvider');
    }
    return context;
} 