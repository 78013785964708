export const guides = [
    {
        id: "359f64bd-9b79-4715-8dcf-f2df1a5aa5fc",
        title: "Getting Started With AnswerThis Editor"
    },
    {
        id: "3179548c-3f16-4966-918b-7ffe13845ced",
        title: "How to Generate High Quality Research Outlines and Drafts with Editor"
    },
    {
        id: "30ccb2f2-3cff-4227-bde8-76d7867a79d1",
        title: "AnswerThis Editor's AI Edit Feature"
    },
    {
        id: "6a5702a2-381a-4f01-8758-81e69b34e764",
        title: "Editing Citations With AnswerThis"
    },
    {
        id: "9e895926-d291-4046-a6eb-a9a5e796989f",
        title: "Formatting Tips and Tricks"
    },
] as const;
