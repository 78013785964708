import { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Loader2, ImageIcon, History, ChevronRight } from 'lucide-react';
import { createDiagram, getAllDiagramsForUser } from './api';
import { EmbeddedDiagram } from './EmbeddedDiagram';
import { toast } from 'sonner';
import type { DiagramData } from './api';
import { DiagramInput } from './diagramInput';



const DiagramGenerator = () => {
    const [createdDiagramId, setCreatedDiagramId] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [diagrams, setDiagrams] = useState<DiagramData[]>([]);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [historyLoading, setHistoryLoading] = useState(false);

    const loadDiagrams = async () => {
        try {
            setHistoryLoading(true);
            const allDiagrams = await getAllDiagramsForUser();
            setDiagrams(allDiagrams);
        } catch (error) {
            toast.error('Failed to load diagrams');
        } finally {
            setHistoryLoading(false);
        }
    };

    useEffect(() => {
        if (isHistoryOpen) {
            loadDiagrams();
        }
    }, [isHistoryOpen]);

    const handleError = (errorMessage: string) => {
        setError(errorMessage);
        toast.error(errorMessage);
    };

    const generateDiagram = async (text: string, type: string, isCustomType: boolean) => {
        if (!text.trim()) {
            toast.error("Please enter some text to generate a diagram");
            return;
        }
        if (!type && !isCustomType) {
            toast.error("Please select a diagram type");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const result = await createDiagram(
                text,
                type,
                isCustomType
            );

            if (result.error) {
                handleError(result.error);
                return;
            }

            if (result.id) {
                setCreatedDiagramId(result.id);
                toast.success('Diagram created successfully');
            }
        } catch (error) {
            handleError((error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    return (
        <div className="relative min-h-screen-header">
            {/* Main Content */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6">
                <div>
                    <DiagramInput
                        onGenerate={generateDiagram}
                        loading={loading}
                    />
                </div>

                {/* Preview Section */}
                <Card className="h-full">
                    <CardHeader className="border-b">
                        <CardTitle>Preview</CardTitle>
                    </CardHeader>
                    <CardContent className="p-4 relative min-h-[800px]">
                        {loading && (
                            <div className="absolute inset-0 flex flex-col items-center justify-center bg-background/50 backdrop-blur-sm">
                                <Loader2 className="h-8 w-8 animate-spin mb-4 text-brandMaroon" />
                                <p className="text-lg font-medium text-muted-foreground">Generating your diagram...</p>
                            </div>
                        )}

                        {!loading && !createdDiagramId && !error && (
                            <div className="absolute inset-0 flex flex-col items-center justify-center text-muted-foreground">
                                <ImageIcon className="h-12 w-12 mb-4" />
                                <p className="text-lg font-medium">No diagram generated yet</p>
                                <p className="text-sm">Enter a description and click Generate to create a diagram</p>
                            </div>
                        )}

                        {error && (
                            <Alert variant="destructive" className="m-4">
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        {createdDiagramId && !loading && (
                            <EmbeddedDiagram
                                diagramId={createdDiagramId}
                            />
                        )}
                    </CardContent>
                </Card>
            </div>

            {/* History Sidebar */}
            <div className={`fixed right-0 top-0 h-full transition-all duration-300 z-50 
                ${isHistoryOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                {/* Toggle Button */}
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsHistoryOpen(!isHistoryOpen)}
                    className="absolute left-0 top-1/2 -translate-x-full transform bg-white shadow-md rounded-l-lg border-t border-l border-b"
                >
                    {isHistoryOpen ? <ChevronRight className="h-4 w-4" /> : <History className="h-4 w-4" />}
                </Button>

                {/* Sidebar Content */}
                <div className="w-80 h-full bg-white border-l shadow-xl">
                    <div className="p-4 border-b flex justify-between items-center">
                        <h3 className="font-semibold">Diagram History</h3>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => setIsHistoryOpen(false)}
                        >
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>

                    <ScrollArea className="h-[calc(100vh-65px)]">
                        <div className="p-4 space-y-4">
                            {historyLoading ? (
                                <div className="text-center text-gray-500 py-4">
                                    <Loader2 className="h-5 w-5 animate-spin mx-auto mb-2" />
                                    Loading diagrams...
                                </div>
                            ) : diagrams.length === 0 ? (
                                <div className="text-center text-gray-500 py-8">
                                    <History className="h-8 w-8 mx-auto mb-2 opacity-50" />
                                    <p>No diagrams found</p>
                                    <p className="text-sm">Generate your first diagram to get started</p>
                                </div>
                            ) : (
                                diagrams.map((diagram) => (
                                    <div
                                        key={diagram.id}
                                        onClick={() => {
                                            setCreatedDiagramId(diagram.id);
                                            setIsHistoryOpen(false);
                                        }}
                                        className={`p-4 rounded-lg border cursor-pointer transition-all hover:shadow-md
                                            ${diagram.id === createdDiagramId
                                                ? 'bg-gray-50 border-brandMaroon'
                                                : 'hover:border-gray-300'}`}
                                    >
                                        <div className="text-sm font-medium line-clamp-2">
                                            {diagram.prompt}
                                        </div>
                                        <div className="text-xs text-gray-500 mt-2 flex items-center gap-2">
                                            <span>Last updated:</span>
                                            <span className="font-medium">
                                                {formatDate(diagram.updated_at || diagram.created_at)}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </ScrollArea>
                </div>
            </div>
        </div>
    );
};

export default DiagramGenerator;