import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PaperInfo, handlePdfTitleClick, getPaperInfo, checkIfParamAvailable, handleChatClick, getPdfPath } from '../authService';
import { ArrowLeft, ExternalLink, MessageCircle, FileText } from 'lucide-react';
import { toast } from 'sonner';
import PdfViewer from '../components/PdfViewer';
import SlicedText from '../components/slicedText';

interface PaperInfoPageProps {
    providedPaperId?: string;
    customBackAction?: () => void;
    customBackButtonText?: string;
}

const PaperInfoPage: React.FC<PaperInfoPageProps> = ({
    providedPaperId,
    customBackAction,
    customBackButtonText = 'Back to Notebook'
}) => {
    const { paperId: urlPaperId } = useParams<{ paperId?: string }>();
    const effectivePaperId = providedPaperId || urlPaperId;

    const [paperInfo, setPaperInfo] = useState<PaperInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showPdf, setShowPdf] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.pathname.split('/')[1];

    console.log(paperInfo)
    useEffect(() => {
        const fetchPaperInfo = async () => {
            if (effectivePaperId) {
                try {
                    setIsLoading(true);
                    const response = await getPaperInfo(effectivePaperId);
                    setPaperInfo(response.data);
                } catch (err) {
                    setError('Failed to fetch paper information');
                    console.error('Error fetching paper info:', err);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchPaperInfo();
    }, [effectivePaperId]);

    const handleChat = () => {
        console.log(paperInfo)
        console.log(id)
        if (paperInfo) {
            handleChatClick(
                [paperInfo.id],
                id,
                navigate,
                (response) => {
                    toast.success(response);
                },
                (error) => {
                    toast.error(error);
                },
            );
        }
    };

    const handleBackAction = () => {
        if (customBackAction) {
            customBackAction();
        } else if (location.key !== 'default') {
            navigate(-1);
        }
    };

    if (isLoading) return <div className="p-4">Loading...</div>;
    if (error) return <div className="p-4 text-red-500">{error}</div>;
    if (!paperInfo) return <div className="p-4">No paper information available.</div>;

    const PaperDetails = () => (
        <div className="p-4">
            {(customBackAction || location.key !== 'default') && (
                <button
                    onClick={handleBackAction}
                    className="flex items-center text-gray-600 hover:text-gray-800 mb-4"
                >
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    {customBackButtonText}
                </button>
            )}

            <h1 className="text-3xl font-bold mb-4 cursor-pointer break-all" onClick={() => handlePdfTitleClick(paperInfo)}>
                <SlicedText text={paperInfo.title} maxLength={60}></SlicedText>
            </h1>

            <div className="flex flex-wrap items-center text-sm text-gray-600 mb-4">
                {paperInfo.author && <p className="mr-2">{paperInfo.author}</p>}
                {paperInfo.date_of_publication && (
                    <>
                        <span className="mx-2">•</span>
                        <p>{paperInfo.date_of_publication}</p>
                    </>
                )}
                {paperInfo.citations !== undefined && (
                    <>
                        <span className="mx-2">•</span>
                        <p>{paperInfo.citations} citations</p>
                    </>
                )}
                {checkIfParamAvailable(paperInfo.url) && (
                    <>
                        <span className="mx-2">•</span>
                        <a href={paperInfo.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline flex items-center">
                            {paperInfo.url && !paperInfo.url.startsWith('pdf_store') ?
                                <>
                                    {new URL(paperInfo.url).hostname.replace(/^www\./, '').split('.')[0]}
                                    <ExternalLink className="w-3 h-3 ml-1" />
                                </>
                                : 'Uploaded Document'
                            }
                        </a>
                    </>
                )}
            </div>
            {checkIfParamAvailable(paperInfo.pdf_path) &&
                <div className="flex space-x-4 mb-6">
                    <button
                        onClick={handleChat}
                        className="flex items-center px-4 py-2 bg-brandMaroon text-white rounded-md hover:bg-brandMaroonDark transition"
                    >
                        <MessageCircle className="w-5 h-5 mr-2" />
                        Chat
                    </button>
                    <button
                        onClick={() => setShowPdf(!showPdf)}
                        className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                    >
                        <FileText className="w-5 h-5 mr-2" />
                        {showPdf ? 'Hide PDF' : 'View PDF'}
                    </button>
                </div>
            }

            {paperInfo.abstract && (
                <div className="bg-gray-100 p-4 rounded-lg mb-6">
                    <h2 className="text-xl font-semibold mb-2">Abstract</h2>
                    <p>{paperInfo.abstract}</p>
                </div>
            )}

            <div className="bg-white p-4 rounded-lg border border-gray-200 mb-6">
                <h2 className="text-xl font-semibold mb-4">Paper details</h2>
                {paperInfo.journal && <p className="mb-2"><strong>Journal:</strong> {paperInfo.journal}</p>}
                {paperInfo.doi && <p className="mb-2"><strong>DOI:</strong> {paperInfo.doi}</p>}
                {paperInfo.url && (
                    <p className="mb-2 flex items-center">
                        <strong>URL:</strong>
                        <a href={paperInfo.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline ml-2 flex items-center">
                            View Source
                            <ExternalLink className="w-4 h-4 ml-1" />
                        </a>
                    </p>
                )}
                {checkIfParamAvailable(paperInfo.pdf_path) && (
                    <p className="mb-2 flex items-center">
                        <strong>PDF:</strong>
                        <button
                            onClick={() => handlePdfTitleClick(paperInfo)}
                            className="text-blue-600 hover:underline ml-2 flex items-center"
                        >
                            View PDF
                            <FileText className="w-4 h-4 ml-1" />
                        </button>
                    </p>
                )}
            </div>
        </div>
    );

    const PaperPdfViewer = () => (
        <div className="max-h-[calc(100vh-200px)] p-4 overflow-y-auto bg-gray-100 rounded-lg shadow-inner">
            <div className="w-full max-w-4xl mx-auto">
                <PdfViewer
                    documents={[{
                        uri: getPdfPath(paperInfo),
                        fileName: paperInfo.title
                    }]}
                />
            </div>
        </div>
    );

    return (
        <div className="min-h-screen-header bg-white">
            <PaperDetails />
            {showPdf && checkIfParamAvailable(paperInfo.pdf_path) && <PaperPdfViewer />}
        </div>
    );
};

export default PaperInfoPage;
