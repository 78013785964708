import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'sonner';
import "./components/Sidebar.css";
import Sidebar from './components/sidebar/newSidebar';
import { Menu, User, Headphones, Gem, Plus } from 'lucide-react';
import logo from '/img/logo.svg';
import smallLogo from '/img/assistant.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { toast } from 'sonner';
import { applyHeaderAwareHeightVariables } from './utils/tabsHeightCalculator';
import { Helmet } from 'react-helmet';

const Layout: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const location = useLocation();
  const id = location.pathname.split('/')[1];
  const navigate = useNavigate();
  const { isPro, togglePricingPopup } = useAuth();

  // Check if current page is tab search
  const isTabSearchPage = location.pathname.includes(`/${id}/ask-answerthis`);

  // Listen for sidebar toggle events from the sidebar component
  useEffect(() => {
    const handleSidebarToggle = (event: Event) => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail && Object.prototype.hasOwnProperty.call(customEvent.detail, 'collapsed')) {
        setSidebarCollapsed(customEvent.detail.collapsed);
      }
    };

    document.addEventListener('sidebarToggle', handleSidebarToggle);
    return () => {
      document.removeEventListener('sidebarToggle', handleSidebarToggle);
    };
  }, []);

  // Apply header height CSS variables globally
  useEffect(() => {
    const cleanupFunction = applyHeaderAwareHeightVariables();

    // Update on resize to handle header size changes
    const handleResize = () => {
      cleanupFunction();
      applyHeaderAwareHeightVariables();
    };

    // Update whenever sidebar state changes which affects layout
    handleResize();

    return () => {
      cleanupFunction();
    };
  }, [sidebarCollapsed]);

  const handleShowIntercom = () => {
    // Safely access Intercom if it exists
    type IntercomType = (command: string, ...args: unknown[]) => void;
    const intercomObj = window as unknown as { Intercom?: IntercomType };
    if (typeof intercomObj.Intercom === 'function') {
      intercomObj.Intercom('show');
    } else {
      toast.error("Support system not available. Please try again later.");
    }
  };

  return (
    <div className="flex h-screen overflow-hidden font-Manrope">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes" />
      </Helmet>
      {/* Desktop Sidebar - Always visible but can be collapsed */}
      <div className="hidden md:block">
        <Sidebar
          isOpen={true}
          onClose={() => { }} // This function is for mobile view
          id={id}
          isMobile={false}
        />
      </div>

      {/* Mobile sidebar overlay */}
      <div
        className={`md:hidden fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-150 ease-out ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        onClick={() => setSidebarOpen(false)}
      >
        <div
          className={`absolute left-0 top-0 h-full w-72 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg shadow-lg transition-transform duration-150 ease-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Sidebar
            isOpen={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            id={id}
            isMobile={true}
          />
        </div>
      </div>

      <div className={`flex-grow overflow-auto ${sidebarCollapsed ? 'md:ml-[4rem]' : 'md:ml-[18rem]'} transition-all duration-150 ease-out`}>
        {/* Mobile header */}
        <div className="md:hidden fixed top-0 left-0 right-0 h-14 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg border-b border-gray-200 flex items-center justify-between px-4 z-30" id="headerSection">
          {/* Left section */}
          <div className="flex items-center">
            <Menu size={24} className="text-gray-500" onClick={() => setSidebarOpen(!sidebarOpen)} />
          </div>

          {/* Center section - Logo */}
          <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2">
            <div
              className="cursor-pointer flex items-center"
              onClick={() => navigate(`/${id}/ask-answerthis`)}
              title="Start a new search"
            >
              <img src={smallLogo} alt="Logo" className="h-8" />
            </div>
          </div>

          {/* Right section */}
          <div className="flex items-center space-x-2">
            <div
              className="w-7 h-7 rounded-full bg-gray-100 hover:bg-gray-200 flex items-center justify-center cursor-pointer transition-colors"
              onClick={() => navigate(`/${id}/ask-answerthis`)}
              title="New search"
            >
              <Plus size={16} className="text-brandMaroon" />
            </div>

            {!isPro && (
              <button
                className="bg-brandMaroon hover:bg-opacity-90 text-white px-3 py-1 rounded-md text-sm font-medium flex items-center"
                onClick={() => togglePricingPopup(id)}
              >
                <Gem size={16} className="mr-1" />
                See Pricing
              </button>
            )}
          </div>
        </div>

        {/* Desktop header - Only show on non-tab-search pages */}
        {!isTabSearchPage && (
          <div className="hidden md:flex fixed top-0 right-0 h-14 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg items-center justify-between px-4 z-30 border-b border-gray-200"
            style={{ width: `calc(100% - ${sidebarCollapsed ? '4rem' : '18rem'})` }}
            id="headerSectionDesktop">
            <div className="flex items-center">
              <div
                className="cursor-pointer flex items-center group relative overflow-hidden"
                onClick={() => navigate(`/${id}/ask-answerthis`)}
                title="Start a new search"
              >
                <img src={logo} alt="Logo" className="h-8 relative z-10" />
                <div className="h-5 mx-3 w-px bg-gray-200"></div>
                <div className="flex items-center text-brandMaroon relative z-10">
                  <Plus size={18} className="mr-1.5" />
                  <span className="text-sm font-medium">New Search</span>
                </div>
                <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-brandMaroon scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300"></div>
                <div className="absolute inset-0 bg-brandMaroon opacity-0 group-hover:opacity-5 transition-opacity duration-300 rounded-md"></div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              {!isPro && (
                <button
                  className="bg-brandMaroon hover:bg-opacity-90 text-white px-4 py-2 rounded-md text-sm font-medium flex items-center"
                  onClick={() => togglePricingPopup(id)}
                >
                  <Gem size={18} className="mr-2" />
                  See Pricing
                </button>
              )}

              <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer hover:bg-gray-200 transition-colors"
                onClick={handleShowIntercom}
                title="Support">
                <Headphones size={20} className="text-gray-600" />
              </div>

              <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer hover:bg-gray-200 transition-colors"
                onClick={() => navigate(`/${id}/profile`)}
                title="Profile">
                <User size={20} className="text-gray-600" />
              </div>
            </div>
          </div>
        )}

        {/* {!isPro && <FloatingUpgradeButton />} */}
        {/* Adjust the main content area to accommodate both collapsed and expanded sidebar */}
        <div className={`md:pt-${!isTabSearchPage ? '14' : '0'} pt-14`}>
          <Toaster closeButton expand={false} richColors position="top-center" />
          <Outlet />
        </div>
      </div>


    </div>
  );
};

export default Layout;