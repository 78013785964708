import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback, useRef } from 'react';
import { RecentSearch, UserProject, getProjectQueries, getAllProjectsForUser, AllChatResponse, getAllChatsForUser, fetchWithRetry } from '../authService';
import { sortAndFilterProjects } from '../utils/projectUtils';

interface SidebarContextType {
    setCurrentProjectId: (id: string) => void;
    recentSearches: RecentSearch[];
    chats: AllChatResponse[];
    projects: UserProject[];
    currentProjectId: string | null;
    isLoading: boolean;
    isNewChat: boolean;
    error: string | null;
    refreshSidebar: () => void;
    handleNewChat: (data: boolean) => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
    const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
    const [projects, setProjects] = useState<UserProject[]>([]);
    const [chats, setChats] = useState<AllChatResponse[]>([]);
    const [isNewChat, setIsNewChats] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const lastFetchedProjectId = useRef<string | null>(null);

    const fetchSidebarData = useCallback(async () => {
        if (!currentProjectId) return;
        if (currentProjectId === lastFetchedProjectId.current && !shouldRefresh) return;

        setIsLoading(true);
        setError(null);
        try {
            const [recentQueriesData, projectsData, recentChats] = await Promise.all([
                fetchWithRetry(() => getProjectQueries(currentProjectId)),
                fetchWithRetry(() => getAllProjectsForUser()),
                fetchWithRetry(() => getAllChatsForUser())
            ]);
            setRecentSearches(sortRecentQueries(recentQueriesData));
            setChats(sortRecentChats(recentChats));
            const sortedProjects = sortAndFilterProjects(projectsData, currentProjectId);
            setProjects(sortedProjects);
            lastFetchedProjectId.current = currentProjectId;
        } catch (error) {
            console.error("Error fetching sidebar data:", error);
            setError("Failed to fetch sidebar data. Please try again.");
        } finally {
            setIsLoading(false);
            setShouldRefresh(false);
        }
    }, [currentProjectId, shouldRefresh]);

    useEffect(() => {
        fetchSidebarData();
    }, [fetchSidebarData]);

    const sortRecentQueries = (queries: RecentSearch[]): RecentSearch[] => {
        return queries.sort((a, b) => new Date(b.query_madeAt).getTime() - new Date(a.query_madeAt).getTime());
    };
    const sortRecentChats = (chats: AllChatResponse[]): AllChatResponse[] => {
        return chats.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    }

    const refreshSidebar = useCallback(() => {
        setShouldRefresh(true);
    }, []);

    const handleNewChat = useCallback((data: boolean = false) => {
        setIsNewChats(data);
    }, []);

    return (
        <SidebarContext.Provider value={{
            setCurrentProjectId,
            recentSearches,
            chats,
            projects,
            currentProjectId,
            isLoading,
            error,
            isNewChat,
            refreshSidebar,
            handleNewChat
        }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};