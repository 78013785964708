import { FC, useState } from 'react';
import { Td, Th } from './TableComponent';

interface ComparePlansProps { }

interface Plan {
    name: string;
    pricing: string;
    credits: string;
    lineByLineCitations: string;
    exportResults: string;
    searchForPapers: string;
    aiWriter: string;
    library: string;
    projects: string;
    customIntegration: string;
    users: string;
}

type PlanFeatureKey = Exclude<keyof Plan, 'name'>;

const plans: Plan[] = [
    {
        name: "Free Forever",
        pricing: "0 $ / month",
        credits: "5 credits / month",
        lineByLineCitations: "Limited",
        exportResults: "❌",
        searchForPapers: "Limited",
        aiWriter: "Limited",
        library: "Limited",
        projects: "Limited",
        customIntegration: "❌",
        users: "1"
    },
    {
        name: "Passionate Researchers",
        pricing: "35 $ / month",
        credits: "Unlimited references",
        lineByLineCitations: "Available",
        exportResults: "Available",
        searchForPapers: "Available",
        aiWriter: "Available",
        library: "Available",
        projects: "Available",
        customIntegration: "❌",
        users: "1"
    },
    {
        name: "Enterprise",
        pricing: "3,740+ $ / month",
        credits: "Unlimited references",
        lineByLineCitations: "Available",
        exportResults: "Available",
        searchForPapers: "Available",
        aiWriter: "Available",
        library: "Available",
        projects: "Available",
        customIntegration: "Available",
        users: "11$ / User Minimum users: 150"
    }
];

// Converts camelCase text to readable text
const formatCamelCaseToReadable = (text: string) => {
    return text
        // split camelCase and add space
        .replace(/([A-Z])/g, ' $1')
        // replace underscores with spaces
        .replace(/_/g, ' ')
        // capitalize the first letter of each word
        .replace(/(^|\s)\S/g, letter => letter.toUpperCase())
        .trim();
};

const ComparePlans: FC<ComparePlansProps> = () => {
    const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(0);

    return (
        <div className=" mx-4 lg:flex lg:flex-col lg:justify-center">
            <h2 className="mb-4 text-2xl md:text-3xl lg:text-4xl font-bold text-brandMaroon text-center">
                Compare our plans
            </h2>
            <div className="overflow-x-auto mt-8 hidden lg:block">
                <table className="min-w-full border-collapse block md:table">
                    <thead className="block md:table-header-group">
                        <tr className="text-brandMaroon md:table-row">
                            <Th>Feature</Th>
                            {plans.map(plan => (
                                <Th key={plan.name}>{plan.name}</Th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="block md:table-row-group font-semibold">
                        {Object.keys(plans[0]).filter(key => key !== 'name').map(feature => (
                            <tr className="border-t border-gray-300 md:border-none" key={feature}>
                                <Td className="font-semibold text-[17px]">{formatCamelCaseToReadable(feature)}</Td>
                                {plans.map(plan => (
                                    <Td key={plan.name + feature}>{plan[feature as PlanFeatureKey]}</Td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='lg:hidden'>
                <div className=" flex flex-wrap sm:flex-row mt-8 items-start max-w-[100%] gap-4 sm:justify-center">
                    {plans.map((plan, index) => (
                        <div key={index} className="flex flex-col items-center mb-2 relative group sm:min-w-max">
                            <button
                                onClick={() => setSelectedPlanIndex(index)}
                                className={` rounded-full p-2  ${selectedPlanIndex == index ? "toggle-button-selected toggle-button" : "toggle-button"}`}
                            >
                                {plan.name}
                            </button>

                        </div>
                    ))}
                </div>
                <div className="mt-4">
                    <table className="w-full border-collapse table-fixed">
                        <tbody>
                            {Object.keys(plans[0]).filter(key => key !== 'name').map(feature => (
                                <tr className="border-t border-gray-300" key={feature}>
                                    <Td className="font-semibold text-[17px] break-all">{formatCamelCaseToReadable(feature)}</Td>
                                    <Td className="break-all">{plans[selectedPlanIndex][feature as PlanFeatureKey]}</Td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ComparePlans;