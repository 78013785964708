import React, { useState, useEffect } from 'react';
import { LucideIcon, Menu } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";

interface TabConfig {
    value: string;
    label: string;
    content: React.ReactNode;
    icon: LucideIcon;
    disabled?: boolean;
    description?: string;
}

interface ScrollableTabLayoutProps {
    tabs: TabConfig[];
    defaultTab?: string;
    headerContent?: React.ReactNode;
    footerContent?: React.ReactNode;
    onTabChange?: (value: string) => void;
    isMobile?: boolean;
}

const ScrollableTabLayout: React.FC<ScrollableTabLayoutProps> = ({
    tabs,
    defaultTab,
    headerContent,
    footerContent,
    onTabChange,
    isMobile = false
}) => {
    const [activeTab, setActiveTab] = useState(defaultTab || tabs[0].value);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        // When isMobile prop changes, update component behavior accordingly
        if (isMobile) {
            // If on mobile, ensure proper visibility
            const metaViewport = document.querySelector('meta[name="viewport"]');
            if (metaViewport) {
                metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
            }
        }
    }, [isMobile]);

    const handleTabChange = (value: string) => {
        setActiveTab(value);
        onTabChange?.(value);
    };

    const handleClose = () => setIsMobileMenuOpen(false);

    // For mobile, we use fixed positioning but handle differently
    const sidebarStyle = isMobile ? {
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        zIndex: 9999
    } as React.CSSProperties : {};

    // For content panel on mobile
    const contentPanelStyle = isMobile ? {
        position: 'fixed',
        top: 0,
        right: '5rem',
        height: '100%',
        zIndex: 9999
    } as React.CSSProperties : {};

    return (
        <TooltipProvider delayDuration={0}>
            <div className="flex h-full w-full relative">
                {/* Main Content Area - Desktop */}
                <div className="hidden lg:block flex-1 h-full overflow-hidden">
                    <div className="h-full w-full overflow-y-auto px-2">
                        {headerContent && (
                            <div className="sticky top-0 bg-white dark:bg-gray-900 z-30 py-4 w-full">
                                {headerContent}
                            </div>
                        )}

                        {tabs.map(tab => (
                            <div
                                key={tab.value}
                                className={`w-full py-2 ${activeTab === tab.value ? 'block' : 'hidden'}`}
                            >
                                {tab.content}
                            </div>
                        ))}

                        {footerContent && (
                            <div className="bg-white dark:bg-gray-900 py-4 w-full">
                                {footerContent}
                            </div>
                        )}
                    </div>
                </div>

                {/* Vertical Navigation */}
                <div
                    style={sidebarStyle}
                    className={`
                        ${!isMobile ? 'lg:relative' : ''}
                        bg-gray-900 text-white
                        w-20 flex flex-col items-center
                        transform transition-all duration-300 ease-in-out
                        ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full lg:translate-x-0'}
                        shadow-xl lg:shadow-none
                    `}
                >
                    {/* Mobile Header - Clickable Close */}
                    <button
                        onClick={handleClose}
                        className="lg:hidden w-full flex items-center justify-center p-4 border-b border-gray-800 hover:bg-gray-800/50 transition-colors font-medium"
                    >
                        Close
                    </button>

                    {/* Tab Buttons */}
                    <div className="flex flex-col items-center space-y-2 w-full pt-4">
                        {tabs.map(tab => {
                            const Icon = tab.icon;
                            return (
                                <Tooltip key={tab.value}>
                                    <TooltipTrigger asChild>
                                        <button
                                            disabled={tab.disabled}
                                            className={`
                                                w-full px-2 py-3 flex flex-col items-center justify-center
                                                transition-colors duration-200
                                                ${activeTab === tab.value
                                                    ? 'bg-brandMaroon text-white hover:bg-brandMaroon/90'
                                                    : 'text-gray-400 hover:text-white hover:bg-gray-800'}
                                                ${tab.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                                            `}
                                            onClick={(e) => {
                                                if (!tab.disabled) {
                                                    handleTabChange(tab.value);
                                                    e.stopPropagation();
                                                }
                                            }}
                                        >
                                            <Icon size={20} className="mb-1" />
                                            <span className="text-xs text-center font-medium leading-tight">
                                                {tab.label}
                                            </span>
                                        </button>
                                    </TooltipTrigger>
                                    {tab.description && (
                                        <TooltipContent side="right" align="center" className="z-50 bg-gray-900 text-white p-3 rounded-lg shadow-lg border border-gray-800">
                                            <p className="text-sm whitespace-normal">{tab.description}</p>
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            );
                        })}
                    </div>
                </div>

                {/* Mobile Toggle Button - Only visible when menu is closed */}
                {!isMobileMenuOpen && (
                    <Button
                        variant="ghost"
                        size="icon"
                        className="fixed bottom-4 right-4 lg:hidden z-[9999] bg-brandMaroon text-white hover:bg-brandMaroon/90 shadow-lg w-[5rem] h-[3rem]"
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        Tools <Menu size={24} />
                    </Button>
                )}

                {/* Mobile Content */}
                {activeTab && isMobileMenuOpen && (
                    <div
                        style={contentPanelStyle}
                        className="lg:hidden fixed w-[calc(85%-5rem-2rem)] max-w-2xl bg-white shadow-xl"
                    >
                        <div className="h-full overflow-y-auto px-6 py-4">
                            {tabs.map(tab => (
                                <div
                                    key={tab.value}
                                    className={`h-full ${activeTab === tab.value ? 'block' : 'hidden'}`}
                                >
                                    {tab.content}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Backdrop for mobile - only shown when menu is open */}
                {isMobileMenuOpen && (
                    <div
                        className="lg:hidden fixed inset-0 bg-black/60 z-[9998]"
                        onClick={handleClose}
                    />
                )}
            </div>
        </TooltipProvider>
    );
};

export default ScrollableTabLayout;