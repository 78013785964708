import React, { useState, useMemo, useEffect } from "react";
import { SourceInfo } from "../../authService";
import { marked } from "marked";
import PaperList from "../PaperList/PaperList";
import { useAddToLibrary } from "../PaperList/useAddToLibrary";
interface AnswerTableProps {
  data: SourceInfo[];
  unusedSources?: SourceInfo[];
  referencesIdNumbers: { [id: string]: number };
  query_hash: string;
  projectId: string;
  compactUi?: boolean;
}

const AnswerTable: React.FC<AnswerTableProps> = ({
  data,
  unusedSources = [],
  referencesIdNumbers,
  query_hash,
  projectId,
  compactUi = false
}) => {
  const [markedSummaries, setMarkedSummaries] = useState<{ [id: string]: string }>({});
  const { selectedSourceIds, handleCheckboxChange, addToLibrary } = useAddToLibrary(projectId);


  const filteredAndSortedData = useMemo(() => {
    const allSources = [...data, ...unusedSources];
    return allSources.sort((a, b) => {
      const refA = referencesIdNumbers[a.id] || Number.MAX_VALUE;
      const refB = referencesIdNumbers[b.id] || Number.MAX_VALUE;
      return refA - refB;
    });
  }, [data, unusedSources, referencesIdNumbers]);

  useEffect(() => {
    const runMarked = async () => {
      const markedResults = await Promise.all(
        filteredAndSortedData.map(async (sourceInfo) => {
          if (sourceInfo.summary) {
            const markedSummary = await marked(sourceInfo.summary);
            return [sourceInfo.id, markedSummary];
          }
          return null;
        })
      );

      setMarkedSummaries(
        markedResults.reduce((acc, cur) => {
          if (cur) {
            acc[cur[0]] = cur[1];
          }
          return acc;
        }, {} as { [id: string]: string })
      );
    };

    runMarked();
  }, [filteredAndSortedData]);

  return (
    <div>
      <PaperList
        compactUi={compactUi}
        papers={filteredAndSortedData}
        handleCheckboxChange={handleCheckboxChange}
        isLoading={false}
        emptyMessage="No papers found."
        selectedSourceIds={selectedSourceIds}
        id={projectId}
        referencesIdNumbers={referencesIdNumbers}
        markedSummaries={markedSummaries}
        showExtractedSource={true}
        query_hash={query_hash}
        handleSave={addToLibrary}
      />
    </div>
  );
};

export default AnswerTable;