import React from "react";

export const calculateContentHeight = (): string => {
  const updateHeights = () => {
    const tabsSection = document.getElementById('tabsSection');
    const tabsSectionDesktop = document.getElementById('tabsSectionDesktop');
    const headerSection = document.getElementById('headerSection');
    const headerSectionDesktop = document.getElementById('headerSectionDesktop');

    const isMobile = window.innerWidth <= 768;
    const activeTabsSection = isMobile ? tabsSection : tabsSectionDesktop;
    const activeHeaderSection = isMobile ? headerSection : headerSectionDesktop;

    const tabsHeight = activeTabsSection ? activeTabsSection.offsetHeight : 0;
    const headerHeight = activeHeaderSection ? activeHeaderSection.offsetHeight : 0;

    return `calc(100vh - ${tabsHeight + headerHeight}px)`;
  };

  return updateHeights();
};

// Apply CSS variables to document root for header-aware viewport heights
export const applyHeaderAwareHeightVariables = (): (() => void) => {
  const updateCSSVariables = () => {
    const tabsSection = document.getElementById('tabsSection');
    const tabsSectionDesktop = document.getElementById('tabsSectionDesktop');
    const headerSection = document.getElementById('headerSection');
    const headerSectionDesktop = document.getElementById('headerSectionDesktop');

    const isMobile = window.innerWidth <= 768;
    const activeTabsSection = isMobile ? tabsSection : tabsSectionDesktop;
    const activeHeaderSection = isMobile ? headerSection : headerSectionDesktop;

    const tabsHeight = activeTabsSection ? activeTabsSection.offsetHeight : 0;
    const headerHeight = activeHeaderSection ? activeHeaderSection.offsetHeight : 0;
    const totalOffset = tabsHeight + headerHeight;

    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    document.documentElement.style.setProperty('--tabs-height', `${tabsHeight}px`);
    document.documentElement.style.setProperty('--header-tabs-height', `${totalOffset}px`);

    // Set a custom vh unit that accounts for header
    document.documentElement.style.setProperty('--vh-without-header', `calc((100vh - ${headerHeight}px) / 100)`);
    document.documentElement.style.setProperty('--vh-without-header-tabs', `calc((100vh - ${totalOffset}px) / 100)`);

    // Direct drop-in replacements for 100vh and min-h-screen
    document.documentElement.style.setProperty('--full-height', `calc(100vh - ${headerHeight}px)`);
    document.documentElement.style.setProperty('--full-content-height', `calc(100vh - ${totalOffset}px)`);
  };

  updateCSSVariables();
  window.addEventListener('resize', updateCSSVariables);
  return () => window.removeEventListener('resize', updateCSSVariables);
};

// Convert a vh percentage to a header-aware height value
export const headerAwareHeight = (vh: number, includeTabs: boolean = false): string => {
  if (includeTabs) {
    return `calc(${vh} * var(--vh-without-header-tabs, 1vh))`;
  }
  return `calc(${vh} * var(--vh-without-header, 1vh))`;
};

/**
 * Drop-in CSS classes for use with Tailwind
 * Usage examples:
 * - h-[var(--full-height)] - Full viewport height minus header
 * - min-h-[var(--full-height)] - min-height equivalent of min-h-screen minus header
 * - h-[var(--full-content-height)] - Full viewport height minus header and tabs
 */

// Helper class for min-height that considers headers
export const getMinHeightClass = (includeTabs: boolean = false): string => {
  return includeTabs
    ? 'min-h-[var(--full-content-height)]'
    : 'min-h-[var(--full-height)]';
};

export const useContentHeight = (): [string, () => void] => {
  const [contentHeight, setContentHeight] = React.useState<string>("100vh");

  const updateHeight = React.useCallback(() => {
    setContentHeight(calculateContentHeight());
  }, []);

  React.useEffect(() => {
    updateHeight();
    // Initialize CSS variables when component mounts
    const cleanupFunction = applyHeaderAwareHeightVariables();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      cleanupFunction();
    };
  }, [updateHeight]);

  return [contentHeight, updateHeight];
};
