import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";

interface FaqItem {
    question: string;
    answer: string;
}

interface ShadcnFaqProps {
    faqs: FaqItem[];
    className?: string;
    headerClassName?: string;
    contentClassName?: string;
    accordionClassName?: string;
}

const ShadcnFaq = ({
    faqs,
    className = "",
    headerClassName = "",
    contentClassName = "",
    accordionClassName = ""
}: ShadcnFaqProps) => {
    return (
        <div className={`w-full max-w-3xl mx-auto space-y-4 ${className}`}>
            <Accordion type="single" collapsible className={accordionClassName}>
                {faqs.map((faq, index) => (
                    <AccordionItem key={index} value={`item-${index}`}>
                        <AccordionTrigger className={`text-lg font-semibold hover:no-underline ${headerClassName}`}>
                            {faq.question}
                        </AccordionTrigger>
                        <AccordionContent className={`text-base text-gray-600 ${contentClassName}`}>
                            <div className="pt-2" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default ShadcnFaq;