import {
  PencilSquareIcon,
  BuildingLibraryIcon,
  GlobeEuropeAfricaIcon,
  FingerPrintIcon,
  QueueListIcon,
  BeakerIcon,
} from '@heroicons/react/20/solid'
const features = [
  {
    name: 'AI writer.',
    description: 'Use our AI writer to generate abstracts, summaries, research questions and paraphrase text.',
    icon: PencilSquareIcon,
  },
  {
    name: 'Bibtex Exports.',
    description: 'Easily export your references to BibTex format for imports in reference managers.',
    icon: BuildingLibraryIcon,
  },
  {
    name: 'Up to Date.',
    description: 'Enjoy access to over 200 million research papers. If that is not enough, we can even search the internet.',
    icon: GlobeEuropeAfricaIcon,
  },
  {
    name: 'Advanced security.',
    description: 'None of you data is shared with third parties. We take security very seriously.',
    icon: FingerPrintIcon,
  }, {
    name: 'Increased Provenance.',
    description: 'Every literature review has line-by-line citations back to the sources.',
    icon: QueueListIcon,
  }, {
    name: 'Specialized Support.',
    description: 'We work hard to ensure your research takes place at the fastest pace.',
    icon: BeakerIcon,
  }


]

export default function FeaturesSingle() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-brandMaroon">Secure, Fast, Innovative</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">All in one research assistant</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We help you find the right papers, organize them, and distill them into a comprehensive literature review.
          </p>
        </div>
      </div>

      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-brandMaroon" aria-hidden="true" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
