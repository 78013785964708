import React from 'react';
import { BookOpen, ExternalLink, HelpCircle } from 'lucide-react';
import { showDemoIframe } from '../editor/DocumentHistory';

// Demo URLs for different tools
const DEMO_URLS = {
    diagramGenerator: "https://app.supademo.com/embed/cm7nuad5c0f8ti0nhr4dgvykf?embed_v=2",
    citationMap: "https://app.supademo.com/embed/cm7nsxbvy0duoi0nhap527qke?embed_v=2",
    searchPapers: "https://app.supademo.com/embed/cm7nslxtd0dgti0nhdme9jun4?embed_v=2",
    library: "https://app.supademo.com/embed/cm7nsa1h30czmi0nh3rwlgnke?embed_v=2",
    chatWithPaper: "https://app.supademo.com/embed/cm7nyjj1z004khilgmzuz1dgj?embed_v=2",
    literatureReview: "https://app.supademo.com/embed/cm7nzuc4i00l7hilgfn3ynvy5?embed_v=2",
    editor: "https://app.supademo.com/embed/cm7mbj5en1w0cddumsngnkr1a?embed_v=2"
};

interface DemoItem {
    title: string;
    url: string;
    description: string;
}

const DEMO_ITEMS: DemoItem[] = [
    {
        title: "Literature Review",
        url: DEMO_URLS.literatureReview,
        description: "Learn how to create comprehensive literature reviews"
    },
    {
        title: "Chat with PDF",
        url: DEMO_URLS.chatWithPaper,
        description: "Discover how to chat with your research papers"
    },
    {
        title: "Citation Map",
        url: DEMO_URLS.citationMap,
        description: "Visualize citation networks"
    },
    {
        title: "Diagram Generator",
        url: DEMO_URLS.diagramGenerator,
        description: "Create visual diagrams from your research"
    },
    {
        title: "Search Papers",
        url: DEMO_URLS.searchPapers,
        description: "Find papers in our database"
    },
    {
        title: "Library Management",
        url: DEMO_URLS.library,
        description: "Organize and manage your papers"
    },
    {
        title: "AI Writer",
        url: DEMO_URLS.editor,
        description: "Write research papers with AI assistance"
    }
];

const GuidesComponent: React.FC = () => {
    return (
        <div className="p-6 bg-white rounded-lg shadow-sm">
            <div className="flex items-center justify-between mb-4">
                <h2 className="font-semibold text-lg flex items-center">
                    <BookOpen className="mr-2 text-brandMaroon" size={18} />
                    Guides & Tutorials
                </h2>
                <a
                    href="https://answerthis.io/blog/tags/answerthis-guides"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-500 hover:text-brandMaroon flex items-center"
                >
                    <span>View all</span>
                    <ExternalLink size={14} className="ml-1" />
                </a>
            </div>

            <div className="space-y-3">
                {DEMO_ITEMS.map((item, index) => (
                    <div
                        key={index}
                        className="p-3 border border-gray-100 rounded-md hover:border-gray-300 transition-colors flex justify-between items-center cursor-pointer"
                        onClick={() => showDemoIframe(item.url)}
                    >
                        <div>
                            <h3 className="font-medium text-gray-800">{item.title}</h3>
                            <p className="text-xs text-gray-500">{item.description}</p>
                        </div>
                        <HelpCircle size={16} className="text-gray-400" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GuidesComponent; 