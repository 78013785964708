import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { toast } from 'sonner';
import { serverUrl } from '../../authService';
import { Upload, AlertCircle, X, ChevronDown, ChevronUp, FileText, Check, AlertTriangle } from 'lucide-react';
import { buttonClassBig, buttonClassWhiteBig } from '../Buttons/buttonClasses';

interface Paper {
    doi: string;
    title: string;
    authors: string;
    year: string;
}

const LibraryImporter: React.FC<{ projectId: string }> = ({ projectId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [importedPapers, setImportedPapers] = useState<Paper[]>([]);
    const [failedImports, setFailedImports] = useState<string[]>([]);
    const [progress, setProgress] = useState(0);
    const [totalPapers, setTotalPapers] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const cancelTokenSource = useRef<any>(null);

    const onClose = () => {
        if (isLoading) {
            if (window.confirm("Are you sure you want to cancel the import process?")) {
                cancelImport();
            }
        } else {
            setIsOpen(false);
            setSelectedFile(null);
            setImportedPapers([]);
            setFailedImports([]);
            setProgress(0);
            setTotalPapers(0);
            setShowDetails(false);
            window.location.reload();
        }
    };

    const cancelImport = () => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel("Import cancelled by user");
        }
        setIsLoading(false);
        toast.error("Import process cancelled");
    };

    const importPaper = async (doi: string) => {
        try {
            const response = await axios.post(`${serverUrl}/import_paper`, {
                doi,
                projectId
            }, {
                withCredentials: true,
                cancelToken: cancelTokenSource.current.token
            });
            setImportedPapers(prev => [...prev, response.data]);
            setProgress(prev => prev + 1);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error(`Error importing paper with DOI ${doi}:`, error);
                setFailedImports(prev => [...prev, doi]);
                setProgress(prev => prev + 1);
            }
        }
    };

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
    }, []);

    const startImport = async () => {
        if (!selectedFile) return;

        setIsLoading(true);
        setImportedPapers([]);
        setFailedImports([]);
        setProgress(0);

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('projectId', projectId);

        cancelTokenSource.current = axios.CancelToken.source();

        try {
            const response = await axios.post(`${serverUrl}/process_file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
                cancelToken: cancelTokenSource.current.token
            });

            const dois = response.data.dois;
            setTotalPapers(dois.length);

            for (const doi of dois) {
                await importPaper(doi);
            }

            toast.success(`Successfully imported ${importedPapers.length} papers`);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error processing and importing papers:', error);
                toast.error('Error importing papers. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/plain': ['.ris', '.bib'],
            'text/csv': ['.csv']
        },
        multiple: false
    });

    useEffect(() => {
        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel("Component unmounted");
            }
        };
    }, []);

    const renderFileInfo = () => {
        if (!selectedFile) return null;
        return (
            <div className="mt-4 p-4 bg-gray-100 rounded-md">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <FileText className="w-5 h-5 mr-2 text-gray-600" />
                        <span className="text-sm font-medium">{selectedFile.name}</span>
                    </div>
                    <button
                        onClick={() => setSelectedFile(null)}
                        className="text-red-600 hover:text-red-800"
                    >
                        <X className="w-5 h-5" />
                    </button>
                </div>
                <p className="mt-1 text-xs text-gray-500">
                    {(selectedFile.size / 1024).toFixed(2)} KB
                </p>
            </div>
        );
    };

    const renderImportSummary = () => {
        if (importedPapers.length === 0 && failedImports.length === 0) return null;
        return (
            <div className="mt-4 space-y-2">
                <h4 className="text-sm font-medium">Import Summary:</h4>
                <div className="flex space-x-4">
                    <div className="flex items-center">
                        <Check className="w-4 h-4 mr-1 text-green-600" />
                        <span className="text-sm">{importedPapers.length} imported</span>
                    </div>
                    <div className="flex items-center">
                        <AlertTriangle className="w-4 h-4 mr-1 text-red-600" />
                        <span className="text-sm">{failedImports.length} failed</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className={buttonClassWhiteBig}
            >
                <Upload className="w-4 h-4 mr-2" />
                Import Library
            </button>

            {isOpen && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Import Your Library
                                        </h3>
                                        <div className="mt-2">
                                            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <AlertCircle className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm text-yellow-700">
                                                            Don't close this window during import. The process may take a few minutes for large libraries.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                {...getRootProps()}
                                                className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer transition-colors hover:border-brandMaroon"
                                            >
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                    <p className="text-lg">Drop the file here ...</p>
                                                ) : (
                                                    <div>
                                                        <p className="text-lg mb-2">Drag 'n' drop a RIS, BIB, or CSV file here, or click to select files</p>
                                                        <p className="text-sm text-gray-500">Only papers with abstracts or PDFs will be imported</p>
                                                    </div>
                                                )}
                                            </div>
                                            {renderFileInfo()}
                                            <div className="mt-4 space-y-2">
                                                <h4 className="text-sm font-medium">How to export from reference managers:</h4>
                                                <ul className="text-sm list-disc list-inside space-y-1">
                                                    <li>Make sure the column DOI is present for all rows</li>
                                                    <li>Mendeley: File &gt; Export &gt; .bib</li>
                                                    <li>Zotero: File &gt; Export Library &gt; .bib</li>
                                                    <li>EndNote: File &gt; Export &gt; .ris</li>
                                                </ul>
                                            </div>
                                            {isLoading && (
                                                <div className="mt-4 space-y-2">
                                                    <div className="flex justify-between text-sm">
                                                        <span>Importing papers...</span>
                                                        <span>{progress} / {totalPapers} papers processed</span>
                                                    </div>
                                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                                        <div
                                                            className="bg-brandMaroon h-2.5 rounded-full"
                                                            style={{ width: `${(progress / totalPapers) * 100}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            )}
                                            {renderImportSummary()}
                                            <div className="mt-4">
                                                <button
                                                    onClick={() => setShowDetails(!showDetails)}
                                                    className="flex items-center text-sm text-gray-600 hover:text-gray-800"
                                                >
                                                    {showDetails ? (
                                                        <>
                                                            <ChevronUp className="w-4 h-4 mr-1" />
                                                            Hide Details
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ChevronDown className="w-4 h-4 mr-1" />
                                                            Show Details
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                            {showDetails && (
                                                <>
                                                    {importedPapers.length > 0 && (
                                                        <div className="mt-4">
                                                            <h4 className="text-sm font-medium mb-2">Successfully Imported Papers:</h4>
                                                            <ul className="text-sm space-y-1 max-h-40 overflow-y-auto">
                                                                {importedPapers.map((paper, index) => (
                                                                    <li key={index} className="truncate">
                                                                        {paper.title} ({paper.year})
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    {failedImports.length > 0 && (
                                                        <div className="mt-4">
                                                            <h4 className="text-sm font-medium mb-2 text-red-600">Failed Imports:</h4>
                                                            <ul className="text-sm space-y-1 max-h-40 overflow-y-auto">
                                                                {failedImports.map((doi, index) => (
                                                                    <li key={index} className="truncate text-red-600">
                                                                        {doi}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                {isLoading ? (
                                    <button
                                        type="button"
                                        onClick={cancelImport}
                                        className={buttonClassBig}
                                    >
                                        Cancel Import
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            type="button"
                                            onClick={startImport}
                                            disabled={!selectedFile}
                                            className={`${buttonClassWhiteBig} ${!selectedFile ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Start Import
                                        </button>
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className={`${buttonClassWhiteBig} mr-2`}
                                        >
                                            Close
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LibraryImporter;