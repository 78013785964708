import axios from 'axios';

interface TaskStatusResponse {
    task_id: string;
    status: TaskStatus;
    result?: any;
    error?: string;
}
const runQueueEndpoint = import.meta.env.VITE_RUN_QUEUE === 'true';
type TaskStatus = 'queued' | 'started' | 'finished' | 'failed' | 'not_found';

interface QueueAdapterConfig {
    taskBaseUrl: string;
    endpointBaseUrl: string;
    backupEndpointUrl?: string;
    maxRetries?: number;
    retryDelay?: number;
}

class QueueAdapter {
    private taskBaseUrl: string;
    private endpointBaseUrl: string;
    private backupEndpointUrl?: string;
    private readonly SSE_TIMEOUT = 120000;
    private readonly MAX_RETRIES = 4;
    private readonly RETRY_DELAY = 1000;
    private readonly POLL_INTERVAL = 2000;

    constructor(config: QueueAdapterConfig) {
        this.taskBaseUrl = config.taskBaseUrl;
        this.endpointBaseUrl = config.endpointBaseUrl;
        this.backupEndpointUrl = config.backupEndpointUrl;
    }

    private createEventSource(taskId: string): EventSource {
        return new EventSource(`${this.taskBaseUrl}/task_status_stream/${taskId}`, {
            withCredentials: true,
        });
    }

    async watchTaskStatusWithSSE(taskId: string): Promise<any> {
        let retryCount = 0;

        const attemptConnection = (): Promise<any> => {
            return new Promise<any>((resolve, reject) => {
                const eventSource = this.createEventSource(taskId);
                let timeoutId: NodeJS.Timeout;
                let isConnectionActive = true;

                const refreshConnection = async () => {
                    if (!isConnectionActive) return;

                    cleanup();

                    if (retryCount >= this.MAX_RETRIES) {
                        reject(new Error('Max retries exceeded'));
                        return;
                    }

                    retryCount++;
                    console.log(`Retrying connection (${retryCount}/${this.MAX_RETRIES})`);

                    try {
                        await new Promise(r => setTimeout(r, this.RETRY_DELAY));
                        const result = await attemptConnection();
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                };

                const resetTimeout = () => {
                    if (timeoutId) clearTimeout(timeoutId);
                    timeoutId = setTimeout(refreshConnection, this.SSE_TIMEOUT);
                };

                const cleanup = () => {
                    isConnectionActive = false;
                    if (timeoutId) clearTimeout(timeoutId);
                    eventSource.close();
                };

                eventSource.onmessage = (event: MessageEvent) => {
                    resetTimeout();
                    const data: TaskStatusResponse = JSON.parse(event.data);

                    if (data.status === 'finished') {
                        cleanup();
                        resolve(data.result);
                    } else if (data.status === 'failed') {
                        cleanup();
                        reject(new Error(data.error || 'Task failed'));
                    }
                };

                eventSource.onerror = async (error) => {
                    console.error('SSE connection error:', error);
                    await refreshConnection();
                };

                resetTimeout();
            });
        };

        return attemptConnection();
    }

    private async tryBackupEndpoint<T>(endpoint: string, payload: FormData | Record<string, any>, maxBackupTimeout: number): Promise<T> {
        let retryCount = 0;
        const maxRetries = this.MAX_RETRIES;

        while (retryCount < maxRetries) {
            try {
                console.log(`Trying backup endpoint (attempt ${retryCount + 1}): ${this.backupEndpointUrl}${endpoint}`);

                // First try OPTIONS request
                try {
                    await axios.options(`${this.backupEndpointUrl}${endpoint}`, {
                        withCredentials: true,
                        timeout: 5000 // 5 second timeout for OPTIONS
                    });
                } catch (error) {
                    console.warn('OPTIONS request failed, proceeding with POST anyway');
                }

                const headers: Record<string, string> = {
                    "Content-Type": payload instanceof FormData ?
                        "multipart/form-data" :
                        "application/json"
                };

                const response = await axios.post(
                    `${this.backupEndpointUrl}${endpoint}`,
                    payload,
                    {
                        withCredentials: true,
                        headers: headers,
                        timeout: maxBackupTimeout // 30 second timeout for POST
                    }
                );

                return response.data;
            } catch (error) {
                retryCount++;
                console.error(`Backup endpoint attempt ${retryCount} failed:`, error);

                if (retryCount === maxRetries) {
                    throw new Error(`Backup endpoint failed after ${maxRetries} attempts`);
                }

                // Exponential backoff: 1s, 2s, 4s
                await new Promise(resolve =>
                    setTimeout(resolve, this.RETRY_DELAY * Math.pow(2, retryCount - 1))
                );
            }
        }

        throw new Error('Unreachable code - loop should have thrown');
    }
    async watchTaskStatusWithPolling(taskId: string): Promise<any> {
        let retryCount = 0;

        while (retryCount < this.MAX_RETRIES) {
            try {
                const response = await axios.get(
                    `${this.taskBaseUrl}/task_status/${taskId}`,
                    { withCredentials: true }
                );

                const data: TaskStatusResponse = response.data;

                if (data.status === 'finished') {
                    return data.result;
                } else if (data.status === 'failed') {
                    throw new Error(data.error || 'Task failed');
                } else if (data.status === 'not_found') {
                    throw new Error('Task not found');
                }

                await new Promise(resolve => setTimeout(resolve, this.POLL_INTERVAL));
            } catch (error) {
                console.error('Polling error:', error);
                retryCount++;

                if (retryCount === this.MAX_RETRIES) {
                    throw new Error('Max retries exceeded');
                }

                await new Promise(resolve => setTimeout(resolve, this.RETRY_DELAY));
            }
        }
    }
    async executeTask<T = any>(
        endpoint: string,
        payload: FormData | Record<string, any>,
        backupEndpoint?: string,
        maxBackupTimeout: number = 30000,

    ): Promise<T> {
        try {
            const headers: Record<string, string> = {
                "Content-Type": payload instanceof FormData ?
                    "multipart/form-data" :
                    "application/json"
            };

            if (!runQueueEndpoint) {
                if (this.backupEndpointUrl && backupEndpoint) {
                    return await this.tryBackupEndpoint<T>(backupEndpoint, payload, maxBackupTimeout);
                }
            }

            const response = await axios.post(
                `${this.endpointBaseUrl}${endpoint}`,
                payload,
                {
                    withCredentials: true,
                    headers: headers,
                }
            );

            if (response.status === 202 && response.data.task_id) {
                return await this.watchTaskStatusWithSSE(response.data.task_id);
            } else {
                throw new Error("Failed to queue task");
            }
        } catch (error) {
            console.error("Queued task failed, trying backup endpoint:", error);
            if (this.backupEndpointUrl && backupEndpoint) {
                return await this.tryBackupEndpoint<T>(backupEndpoint, payload, maxBackupTimeout);
            }
            throw error;
        }
    }
}

export default QueueAdapter;