import { Paper } from './types';
import PaperItemWithOrigin from './PaperItemWithOrigin';

interface PaperInfoSectionProps {
    findSimilarPapers: (paperId: string) => Paper[];
    handleCheckboxChange: (paperId: string) => void;
    handleSavePaper: (paperId: string) => Promise<void>;
    handleAddPaper: (paper: Paper, asOrigin: boolean) => Promise<void>;
    isAddingPaper: { [key: string]: boolean };
    projectId: string;
    selectedPaperDetails: Paper;
    selectedPapersToSave: Set<string>;
    originPapers: Paper[];
    handleRemoveOrigin: (paperId: string) => void;
}

function PaperInfoSection({
    findSimilarPapers,
    handleCheckboxChange,
    handleSavePaper,
    handleAddPaper,
    isAddingPaper,
    projectId,
    selectedPaperDetails,
    selectedPapersToSave,
    originPapers,
    handleRemoveOrigin
}: PaperInfoSectionProps) {
    const isOriginPaper = originPapers.some(p => p.paperId === selectedPaperDetails.paperId);

    return (
        <div className="p-4 space-y-4 overflow-y-auto h-full md:h-[80vh]">
            <PaperItemWithOrigin
                paper={selectedPaperDetails}
                isOriginPaper={isOriginPaper}
                onSetAsOrigin={handleAddPaper ? (paper) => handleAddPaper(paper, true) : undefined}
                onRemoveOrigin={isOriginPaper ? handleRemoveOrigin : undefined}
                handleCheckboxChange={handleCheckboxChange}
                handleSavePaper={handleSavePaper}
                isAddingPaper={isAddingPaper}
                selectedPapersToSave={selectedPapersToSave}
                projectId={projectId}
            />

            {/* Similar Papers Section */}
            <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Similar Papers</h3>
                <div className="space-y-2">
                    {findSimilarPapers(selectedPaperDetails.paperId).map(paper => (
                        <PaperItemWithOrigin
                            key={paper.paperId}
                            paper={paper}
                            isOriginPaper={originPapers.some(p => p.paperId === paper.paperId)}
                            onSetAsOrigin={handleAddPaper ? (paper) => handleAddPaper(paper, true) : undefined}
                            onRemoveOrigin={handleRemoveOrigin}
                            handleCheckboxChange={handleCheckboxChange}
                            handleSavePaper={handleSavePaper}
                            isAddingPaper={isAddingPaper}
                            selectedPapersToSave={selectedPapersToSave}
                            projectId={projectId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PaperInfoSection;