import React, { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

interface VideosProps {
    videos: string[];
    initialDisplay?: number;
    className?: string;
    containerClassName?: string;
    sectionTitle?: string;
    sectionSubtitle?: string;
    badgeText?: string;
}

const Videos: React.FC<VideosProps> = ({
    videos,
    initialDisplay = 6,
    className = "",
    containerClassName = "",
    sectionTitle = "See AnswerThis in Action",
    sectionSubtitle = "Watch how AnswerThis transforms research workflows and enhances academic productivity",
    badgeText = "Watch & Learn"
}) => {
    const [showAll, setShowAll] = useState(false);
    const displayedVideos = showAll ? videos : videos.slice(0, initialDisplay);

    return (
        <div id="videos" className={className}>
            <section className={`py-16 px-4 sm:px-6 lg:px-8 ${containerClassName}`}>
                <div className="max-w-7xl mx-auto">
                    <div className="text-center mb-12">
                        <span className="inline-flex items-center rounded-md bg-brandMaroon/10 px-2 py-1 text-sm font-medium text-brandMaroon ring-1 ring-inset ring-brandMaroon/20 mb-4">
                            {badgeText}
                        </span>
                        <h2 className="text-3xl font-bold text-gray-900 mb-4">{sectionTitle}</h2>
                        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                            {sectionSubtitle}
                        </p>
                    </div>

                    <div className="relative">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {displayedVideos.map((url) => (
                                <div key={url} className="group relative">
                                    <div className="absolute -inset-0.5 bg-gradient-to-r from-brandMaroon to-purple-600 rounded-xl blur opacity-25 group-hover:opacity-75 transition duration-300" />
                                    <div className="relative aspect-video rounded-xl overflow-hidden bg-white shadow-lg">
                                        <ReactPlayer
                                            url={url}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {!showAll && videos.length > initialDisplay && (
                            <>
                                <div className="absolute bottom-0 left-0 right-0 h-60 mb-10 bg-gradient-to-t from-white to-transparent" />
                                <div className="flex justify-center">
                                    <Button
                                        onClick={() => setShowAll(true)}
                                        className="relative mt-10 bg-gradient-to-r from-brandMaroon to-purple-600 hover:from-darkMaroon hover:to-purple-700 text-white px-8 py-6 rounded-xl text-lg flex items-center gap-2 transition-all duration-300"
                                    >
                                        View More Videos <ArrowRight className="h-5 w-5" />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Videos;