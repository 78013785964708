import React, { createContext, useContext, useState } from 'react';
import CopyCitation, { CopyCitationProps } from '@/pages/Answer/copyCitation';
import { CITATION_STYLES, citationStyles } from '@/pages/Answer/citationUtils';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
} from "@/components/ui/select";
// Create the context
const CitationStyleContext = createContext<{
    globalStyle: citationStyles;
    setGlobalStyle: (style: citationStyles) => void;
}>({
    globalStyle: 'chicago',
    setGlobalStyle: () => { },
});

// Provider component
import { ReactNode } from 'react';
import { Quote } from 'lucide-react';

export const CitationStyleProvider = ({ children }: { children: ReactNode }) => {
    const [globalStyle, setGlobalStyle] = useState<citationStyles>('chicago');

    return (
        <CitationStyleContext.Provider value={{ globalStyle, setGlobalStyle }}>
            {children}
        </CitationStyleContext.Provider>
    );
};

// Custom hook for using the citation style
export const useGlobalCitationStyle = () => {
    const context = useContext(CitationStyleContext);
    if (!context) {
        throw new Error('useGlobalCitationStyle must be used within a CitationStyleProvider');
    }
    return context;
};

export const GlobalCitationStyleControl = ({ compactUi = false }: { compactUi?: boolean }) => {
    const { globalStyle, setGlobalStyle } = useGlobalCitationStyle();

    return (
        <div className="flex items-center">
            <Select
                value={globalStyle}
                onValueChange={(value) => setGlobalStyle(value as citationStyles)}
            >
                <SelectTrigger
                    className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition w-full sm:w-auto h-auto"
                >
                    <Quote className="w-4 h-4 mr-2" />
                    {compactUi ? (
                        <span className="text-sm">{globalStyle.toUpperCase()}</span>
                    ) : (
                        <span className="text-sm">Style: {globalStyle.toUpperCase()}</span>
                    )}
                </SelectTrigger>
                <SelectContent>
                    {CITATION_STYLES.map(style => (
                        <SelectItem key={style} value={style}>
                            {style.toUpperCase()}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
};

// Modified CopyCitation component that uses the global style
const GlobalCopyCitation: React.FC<Omit<CopyCitationProps, 'externalStyle' | 'onExternalStyleChange'>> = (props) => {
    const { globalStyle } = useGlobalCitationStyle();

    return <CopyCitation {...props} externalStyle={globalStyle} />;
};

export default GlobalCopyCitation;