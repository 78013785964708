import { initPlasmicLoader } from "@plasmicapp/loader-react";
import ShadcnFaq from "./CommonFaq";
import Videos from "./Homepage/Videos";
export const PLASMIC = initPlasmicLoader({
    projects: [
        {
            id: "7cq7fkBSvB2APnJjwsYu7H",  // ID of a project you are using
            token: "SjmOM30afPW0j6ynbqQspPFZVY0oZHhLdjKUS5lgy3TmsHQpxjFfZOMBoLLAMDjCRAAdIlVrBRTIfaRbsdSQ"  // API token for that project
        }
    ],

    preview: import.meta.env.PROD
        ? false
        : true,
})

PLASMIC.registerComponent(ShadcnFaq, {
    name: "ShadcnFaq",
    props: {
        faqs: {
            type: "object",
            defaultValue: [
                {
                    question: "What is your return policy?",
                    answer: "We offer a 30-day return policy for all unused items."
                },
                {
                    question: "How long does shipping take?",
                    answer: "Shipping typically takes 3-5 business days within the continental US."
                }
            ],
            description: "Array of FAQ items containing questions and answers"
        },
        headerClassName: {
            type: "string",
            description: "Custom class names for the FAQ headers"
        },
        contentClassName: {
            type: "string",
            description: "Custom class names for the FAQ content"
        },
        accordionClassName: {
            type: "string",
            description: "Custom class names for the accordion container"
        }
    },
    defaultStyles: {
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        padding: "1rem"
    }
});
PLASMIC.registerComponent(Videos, {
    name: "Videos",
    props: {
        videos: {
            type: "object",
            defaultValue: [
                "https://www.youtube.com/watch?v=example1",
                "https://www.youtube.com/watch?v=example2",
                "https://www.youtube.com/watch?v=example3"
            ],
            description: "Array of YouTube video URLs"
        },
        initialDisplay: {
            type: "number",
            defaultValue: 6,
            description: "Number of videos to show initially before 'View More'"
        },
        sectionTitle: {
            type: "string",
            defaultValue: "See AnswerThis in Action",
            description: "Main title for the videos section"
        },
        sectionSubtitle: {
            type: "string",
            defaultValue: "Watch how AnswerThis transforms research workflows and enhances academic productivity",
            description: "Subtitle text for the videos section"
        },
        badgeText: {
            type: "string",
            defaultValue: "Watch & Learn",
            description: "Text displayed in the badge above the title"
        }
    },
    defaultStyles: {
        width: "100%",
        maxWidth: "100%",
        margin: "0 auto"
    }
});