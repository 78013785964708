import { fetchPaperNetwork } from './api';
import { PaperCache } from './CitationMap';
import { MIN_NODE_SIZE, MAX_NODE_SIZE, MIN_YEAR, MAX_YEAR, LAYOUT_OPTIONS, EDGE_WEIGHT_COCITATION, EDGE_WEIGHT_COUPLING } from './graphConstants';
import { Paper } from './types';
import cytoscape from 'cytoscape';

// Utility functions
export const calculateCoupling = (paper1: Paper, paper2: Paper, cache: PaperCache): number => {
    const refs1 = cache[paper1.paperId]?.citing || new Set();
    const refs2 = cache[paper2.paperId]?.citing || new Set();
    const intersection = new Set([...refs1].filter(x => refs2.has(x)));
    return intersection.size / Math.sqrt(refs1.size * refs2.size) || 0;
};
export const calculateCocitation = (paper1: Paper, paper2: Paper, cache: PaperCache): number => {
    const citedBy1 = cache[paper1.paperId]?.citedBy || new Set();
    const citedBy2 = cache[paper2.paperId]?.citedBy || new Set();
    const intersection = new Set([...citedBy1].filter(x => citedBy2.has(x)));
    return intersection.size / Math.sqrt(citedBy1.size * citedBy2.size) || 0;
};
export const getNodeSize = (citations: number, maxCitations: number): number => {
    const normalizedCitations = Math.log10(citations + 1) / Math.log10(maxCitations + 1);
    return MIN_NODE_SIZE + normalizedCitations * (MAX_NODE_SIZE - MIN_NODE_SIZE);
};
export const getNodeColor = (year: number): string => {
    // Using a steeper curve (multiplying by 2) to amplify small differences
    const normalizedYear = (year - MIN_YEAR) / (MAX_YEAR - MIN_YEAR) * 3;
    // Starting from 20% lightness (darker) and going up to 70%
    return `hsl(344, 45%, ${20 + Math.min(normalizedYear * 50, 50)}%)`;
};
export const paperToSourceInfo = (paper: Paper) => ({
    id: paper.paperId,
    title: paper.title || '',
    author: paper.authors?.map(author => author.name).join(', ') || '',
    journal: paper.venue || paper.journal || '',
    date_of_publication: paper.year?.toString() || '',
    citations: paper.citationCount || 0,
    doi: paper.doi || '',
    summary: paper.abstract || '',
    url: paper.url || '',
    pdf_path: null,
    abstract: paper.abstract || null,
    sections: [],
});





export function handleAddPaperFunction(
    cyRef: React.MutableRefObject<cytoscape.Core | null>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    papersCache: React.MutableRefObject<PaperCache>,
    analyzeNetwork: (papers: Paper[]) => Promise<cytoscape.ElementDefinition[]>,
    setGraphPapers: React.Dispatch<React.SetStateAction<Paper[]>>,
    setOriginPapers: React.Dispatch<React.SetStateAction<Paper[]>>,
    onSuccess: (message: string) => void = console.log,
    onError: (message: string) => void = console.error
): (paper: Paper, asOrigin?: boolean) => Promise<void> {
    return async (paper: Paper, asOrigin: boolean = false) => {
        if (!cyRef.current) return;

        try {
            setIsLoading(true);

            // Fetch paper network
            const network = await fetchPaperNetwork(paper.paperId);

            // Initialize cache entry if it doesn't exist
            if (!papersCache.current[paper.paperId]) {
                papersCache.current[paper.paperId] = {
                    timestamp: Date.now(),
                    citing: new Set(),
                    citedBy: new Set()
                };
            }

            // Update cache with new network data
            network.references.forEach(ref => {
                if (!papersCache.current[ref.paperId]) {
                    papersCache.current[ref.paperId] = {
                        timestamp: Date.now(),
                        citing: new Set(),
                        citedBy: new Set()
                    };
                }
                papersCache.current[paper.paperId].citing.add(ref.paperId);
                papersCache.current[ref.paperId].citedBy.add(paper.paperId);
            });

            network.citations.forEach(cite => {
                if (!papersCache.current[cite.paperId]) {
                    papersCache.current[cite.paperId] = {
                        timestamp: Date.now(),
                        citing: new Set(),
                        citedBy: new Set()
                    };
                }
                papersCache.current[paper.paperId].citedBy.add(cite.paperId);
                papersCache.current[cite.paperId].citing.add(paper.paperId);
            });

            // Handle origin paper differently
            if (asOrigin) {
                // Instead of clearing the graph, merge the new network
                const allPapers = new Set([
                    paper,
                    ...network.citations,
                    ...network.references,
                    ...network.secondLevelCitations,
                    ...network.secondLevelReferences
                ]);

                // Analyze network including existing papers
                const elements = await analyzeNetwork(Array.from(allPapers));

                // Add new elements to the graph
                cyRef.current.add(elements);

                // Update origin papers state
                setOriginPapers(prev => {
                    const exists = prev.some(p => p.paperId === paper.paperId);
                    return exists ? prev : [...prev, paper];
                });
            } else {
                // For non-origin papers, add to the existing graph
                const elements = await analyzeNetwork([
                    paper,
                    ...network.citations,
                    ...network.references,
                    ...network.secondLevelCitations,
                    ...network.secondLevelReferences
                ]);
                cyRef.current.add(elements);
            }

            // Update graph papers
            setGraphPapers(prev => {
                // Create a map of existing papers by title for deduplication
                const existingByTitle = new Map<string, Paper>();
                prev.forEach(p => {
                    if (p.title) {
                        existingByTitle.set(p.title.toLowerCase(), p);
                    }
                });

                // Filter and deduplicate new papers
                [
                    paper,
                    ...network.citations,
                    ...network.references,
                    ...network.secondLevelCitations,
                    ...network.secondLevelReferences
                ].forEach(p => {
                    if (!p.title) return;
                    const lowerTitle = p.title.toLowerCase();
                    const existing = existingByTitle.get(lowerTitle);

                    // If paper doesn't exist yet, add it to map
                    if (!existing) {
                        existingByTitle.set(lowerTitle, p);
                        return;
                    }

                    // If paper exists but new one has more data, update it
                    const newCitationCount = p.citationCount ?? 0;
                    const existingCitationCount = existing.citationCount ?? 0;
                    if (newCitationCount > existingCitationCount ||
                        p.abstract ||
                        p.doi ||
                        (p.authors?.length ?? 0) > (existing.authors?.length ?? 0)) {
                        existingByTitle.set(lowerTitle, p);
                    }
                });

                // Return array of unique papers, preferring ones with more complete data
                return Array.from(existingByTitle.values());
            });

            // Apply layout
            cyRef.current.layout(LAYOUT_OPTIONS).run();
            cyRef.current.fit();

            onSuccess("Paper network loaded successfully");
        } catch (error) {
            console.error('Error adding paper:', error);
            onError("Failed to load paper network");
        } finally {
            setIsLoading(false);
        }
    };
}


export function analyzeNetworkFunction(papersCache: React.MutableRefObject<PaperCache>) {
    return async (papers: Paper[]): Promise<cytoscape.ElementDefinition[]> => {
        const elements: cytoscape.ElementDefinition[] = [];
        const paperMap = new Map<string, Paper>();
        const addedEdges = new Set<string>();

        // Pre-calculate all necessary maximum values
        const maxCitations = Math.max(...papers.map(p => p.citationCount || 0));

        // Calculate connection counts for each paper within the network
        const connectionCounts = new Map<string, number>();
        papers.forEach(paper => {
            if (!paper.paperId) return;
            paperMap.set(paper.paperId, paper);
            connectionCounts.set(paper.paperId, 0);
        });

        // Count direct connections (citations and references)
        papers.forEach(paper1 => {
            if (!paper1.paperId) return;
            papers.forEach(paper2 => {
                if (!paper2.paperId || paper1.paperId === paper2.paperId) return;

                const paper1Cache = papersCache.current[paper1.paperId];
                const paper2Cache = papersCache.current[paper2.paperId];

                if (paper1Cache?.citedBy?.has(paper2.paperId) ||
                    paper1Cache?.citing?.has(paper2.paperId) ||
                    paper2Cache?.citedBy?.has(paper1.paperId) ||
                    paper2Cache?.citing?.has(paper1.paperId)) {
                    connectionCounts.set(paper1.paperId, (connectionCounts.get(paper1.paperId) || 0) + 1);
                    connectionCounts.set(paper2.paperId, (connectionCounts.get(paper2.paperId) || 0) + 1);
                }
            });
        });

        // Calculate maximum connections after counting all connections
        const maxConnections = Math.max(...Array.from(connectionCounts.values()));

        // Helper function to create node with size based on connections
        function createNode(paper: Paper, isOrigin: boolean) {
            const connections = connectionCounts.get(paper.paperId!) || 0;
            const connectionSize = connections / maxConnections; // 0 to 1
            const citationSize = paper.citationCount ? Math.log(paper.citationCount + 1) / Math.log(maxCitations + 1) : 0;
            const size = Math.max(connectionSize * 0.7 + citationSize * 0.3, 0.2); // Minimum size of 0.2

            return {
                group: 'nodes' as const,
                data: {
                    id: paper.paperId,
                    label: `${paper.authors?.[0]?.name?.split(' ').pop() || ''} ${paper.year || ''}`,
                    title: paper.title,
                    size: getNodeSize(size * maxCitations, maxCitations),
                    color: getNodeColor(paper.year || MAX_YEAR),
                    paper,
                    type: isOrigin ? 'origin' : 'paper',
                    connections
                }
            };
        }



        // Add other nodes, prioritizing well-connected papers
        Array.from(paperMap.entries())
            .sort(([id1], [id2]) => {
                const connections1 = connectionCounts.get(id1) || 0;
                const connections2 = connectionCounts.get(id2) || 0;
                return connections2 - connections1;
            })
            .forEach(([, paper]) => {
                elements.push(createNode(paper, false));
            });

        // Helper function to add edge
        const addEdge = (source: string, target: string, weight: number, color: string) => {
            const edgeId = `${source}-${target}`;
            const reverseEdgeId = `${target}-${source}`;

            if (!addedEdges.has(edgeId) && !addedEdges.has(reverseEdgeId)) {
                const sourceExists = elements.some(el => el.group === 'nodes' && el.data.id === source);
                const targetExists = elements.some(el => el.group === 'nodes' && el.data.id === target);

                if (sourceExists && targetExists) {
                    elements.push({
                        group: 'edges' as const,
                        data: {
                            id: edgeId,
                            source,
                            target,
                            weight,
                            width: 1 + weight,
                            color
                        }
                    });
                    addedEdges.add(edgeId);
                }
            }
        };

        // Add all direct connections (citations and references)
        papers.forEach(paper1 => {
            if (!paper1.paperId) return;
            const paper1Node = elements.find(el => el.group === 'nodes' && el.data.id === paper1.paperId);
            if (!paper1Node) return;

            papers.forEach(paper2 => {
                if (!paper2.paperId || paper1.paperId === paper2.paperId) return;
                const paper2Node = elements.find(el => el.group === 'nodes' && el.data.id === paper2.paperId);
                if (!paper2Node) return;

                const paper1Cache = papersCache.current[paper1.paperId];
                if (paper1Cache?.citedBy?.has(paper2.paperId)) {
                    addEdge(paper2.paperId, paper1.paperId, 1, 'rgba(59, 130, 246, 0.8)'); // Citation
                }
                if (paper1Cache?.citing?.has(paper2.paperId)) {
                    addEdge(paper1.paperId, paper2.paperId, 1, 'rgba(239, 68, 68, 0.8)'); // Reference
                }
            });
        });

        // Add bibliographic coupling and co-citation edges only between well-connected papers
        const wellConnectedPapers = new Set(
            Array.from(connectionCounts.entries())
                .filter(([, count]) => count > 1) // Must have at least 2 connections
                .map(([id]) => id)
        );

        papers.forEach((paper1, i) => {
            if (!paper1.paperId || !wellConnectedPapers.has(paper1.paperId)) return;

            papers.slice(i + 1).forEach(paper2 => {
                if (!paper2.paperId || !wellConnectedPapers.has(paper2.paperId)) return;

                const coupling = calculateCoupling(paper1, paper2, papersCache.current);
                const cocitation = calculateCocitation(paper1, paper2, papersCache.current);
                const weight = coupling * EDGE_WEIGHT_COUPLING + cocitation * EDGE_WEIGHT_COCITATION;

                if (weight > 0.3) { // Higher threshold for indirect relationships
                    addEdge(paper1.paperId, paper2.paperId, weight, `rgba(148, 163, 184, ${Math.min(0.8, weight)})`);
                }
            });
        });

        return elements;
    };
}
export function similarPaperFinder(graphPapers: Paper[], papersCache: React.MutableRefObject<PaperCache>) {
    return (paperId: string) => {
        const similarityScores = new Map<string, number>();
        const paper = graphPapers.find(p => p.paperId === paperId);

        if (!paper) return [];

        graphPapers.forEach(otherPaper => {
            if (otherPaper.paperId === paperId) return;

            const coupling = calculateCoupling(paper, otherPaper, papersCache.current);
            const cocitation = calculateCocitation(paper, otherPaper, papersCache.current);
            const similarity = coupling * EDGE_WEIGHT_COUPLING + cocitation * EDGE_WEIGHT_COCITATION;

            if (similarity > 0.1) {
                similarityScores.set(otherPaper.paperId, similarity);
            }
        });

        return Array.from(similarityScores.entries())
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(([id]) => graphPapers.find(p => p.paperId === id)!)
            .filter(Boolean);
    };
}

export const calculateMostCited = (graphPapers: Paper[]): Paper[] => {
    return [...graphPapers]
        .filter(paper => paper?.citationCount != null)
        .sort((a, b) => (b.citationCount || 0) - (a.citationCount || 0))
        .slice(0, 5);
};

export const calculateMostConnected = (graphPapers: Paper[], cyRef: React.MutableRefObject<cytoscape.Core | null>): Paper[] => {
    if (!cyRef.current) return [];
    const cy = cyRef.current; // Store reference to avoid multiple null checks
    return [...graphPapers]
        .sort((a, b) => {
            if (!a?.paperId || !b?.paperId) return 0;
            const aEdges = cy.$(`edge[source = "${a.paperId}"], edge[target = "${a.paperId}"]`).length;
            const bEdges = cy.$(`edge[source = "${b.paperId}"], edge[target = "${b.paperId}"]`).length;
            return bEdges - aEdges;
        })
        .slice(0, 5);
};

export const calculateMostCommonAuthors = (graphPapers: Paper[]) => {
    const authorCount = new Map<string, { count: number; papers: Paper[] }>();

    graphPapers.forEach(paper => {
        paper.authors?.forEach(author => {
            if (!author.name) return;
            const current = authorCount.get(author.name) || { count: 0, papers: [] };
            current.count++;
            if (!current.papers.some(p => p.paperId === paper.paperId)) {
                current.papers.push(paper);
            }
            authorCount.set(author.name, current);
        });
    });

    return Array.from(authorCount.entries())
        .sort((a, b) => b[1].count - a[1].count)
        .slice(0, 5)
        .map(([name, data]) => ({
            name,
            count: data.count,
            papers: data.papers
                .sort((a, b) => (b.citationCount || 0) - (a.citationCount || 0))
                .slice(0, 3)
        }));
};
export function addNodesToGraph(
    cyRef: React.MutableRefObject<cytoscape.Core | null>,
    containerRef: React.MutableRefObject<HTMLDivElement | null>,
    papersCache: React.MutableRefObject<PaperCache>,
    graphPapers: Paper[],
    originPapers: Paper[],
    hideSingleConnections: boolean,
    maxPapers: number  // NEW
) {
    return () => {
        if (!cyRef.current || !containerRef.current) return;

        const cy = cyRef.current;
        const cache = papersCache.current;

        // Calculate connection counts
        const connectionCounts = new Map<string, number>();

        graphPapers.forEach(paper => {
            const citedBy = cache[paper.paperId]?.citedBy?.size || 0;
            const citing = cache[paper.paperId]?.citing?.size || 0;
            connectionCounts.set(paper.paperId, citedBy + citing);
        });

        const sortedPapers = [...graphPapers].sort((a, b) => {
            const aCount = connectionCounts.get(a.paperId) || 0;
            const bCount = connectionCounts.get(b.paperId) || 0;
            return bCount - aCount;
        });

        const visiblePapers = new Set<string>();

        // Always include origin papers
        originPapers.forEach(paper => {
            visiblePapers.add(paper.paperId);
        });

        let added = visiblePapers.size;
        for (const paper of sortedPapers) {
            if (added >= maxPapers) break;

            const connections = connectionCounts.get(paper.paperId) || 0;
            if (!visiblePapers.has(paper.paperId) && (!hideSingleConnections || connections > 1)) {
                visiblePapers.add(paper.paperId);
                added++;
            }
        }

        const maxConnections = Math.max(...Array.from(connectionCounts.values()));

        // Update node visibility and sizes
        cy.nodes().forEach(node => {
            const shouldHide = !visiblePapers.has(node.id());
            const connections = connectionCounts.get(node.id()) || 0;

            if (shouldHide) {
                node.style('display', 'none');
            } else {
                node.style('display', 'element');
                const size = getNodeSize(connections, maxConnections);
                node.style('width', size);
                node.style('height', size);
            }
        });

        // Hide edges connected to hidden nodes
        cy.edges().forEach(edge => {
            const sourceVisible = cy.getElementById(edge.source().id()).style('display') !== 'none';
            const targetVisible = cy.getElementById(edge.target().id()).style('display') !== 'none';
            edge.style('display', sourceVisible && targetVisible ? 'element' : 'none');
        });

        cy.layout(LAYOUT_OPTIONS).run();
    };
}


export function observeContainerResize(
    containerRef: React.MutableRefObject<HTMLDivElement | null>,
    cyRef: React.MutableRefObject<cytoscape.Core | null>,
) {
    return () => {
        if (!containerRef.current) return;

        const observer = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                if (cyRef.current) {
                    cyRef.current.resize();
                    cyRef.current.layout(LAYOUT_OPTIONS).run();
                    cyRef.current.layout(LAYOUT_OPTIONS).run();
                }
            });
        });

        observer.observe(containerRef.current);

        return () => observer.disconnect();
    };
}
