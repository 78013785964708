import { Copy, PlayCircle } from 'lucide-react';
import { WriterDocument } from './types';
import { toast } from 'sonner';
import { copyDocument } from './documentService';
import { guides } from './guides';
import { showDemoIframe } from './DocumentHistory';

export const HelpAndGuidesContent = ({ projectId, onDocumentCreated }: { projectId: string; onDocumentCreated: (doc: WriterDocument) => void; }) => {
    const handleImportGuide = async (guideId: string, guideName: string) => {
        try {
            const newDoc = await copyDocument(guideId, projectId);
            onDocumentCreated(newDoc);
            toast.success(`Imported ${guideName} guide`);
        } catch (error) {
            toast.error("Failed to import guide");
        }
    };

    const steps = [
        {
            title: "1. Create or Select a Document",
            description: "Start by creating a new document or selecting an existing one from the Documents tab."
        },
        {
            title: "2. Import or Generate Content",
            description: "Use the Assistant to import a review or generate an outline for your document."
        },
        {
            title: "3. Build Your Outline",
            description: "Use Generate Outline to create a structured outline for your document."
        },
        {
            title: "4. Library",
            description: "Use the Library tab to insert citations from your existing project library."
        },
        {
            title: "5. Transform to Answer",
            description: "Select sections of your outline and use AI Edit to expand them into full paragraphs, or use Full Outline to Answer for the entire document."
        },
        {
            title: "6. Fine-tune with AI",
            description: "Use AI Edit to refine specific sections or the entire content."
        }
    ];

    return (
        <div className="space-y-8 p-4">

            <div>
                <div className="flex justify-between items-center mb-4 gap-2">
                    <div>
                        <h2 className="text-xl font-semibold text-brandMaroon">Quick Starting Steps</h2>
                        <p className="text-sm text-gray-600">Follow these steps to create your document</p>
                    </div>
                    <button
                        className="flex items-center gap-2 px-2 py-1 rounded-lg bg-brandMaroon text-white hover:bg-brandMaroon/90 transition-colors"
                        onClick={() => showDemoIframe('https://demo.answerthis.io/embed/cm7mbj5en1w0cddumsngnkr1a?embed_v=2')}
                    >
                        <PlayCircle size={20} />
                        Show Visual Guide
                    </button>
                </div>
                <div className="space-y-4">
                    {steps.map((step, index) => (
                        <div key={index} className="p-4 rounded-lg border">
                            <h3 className="font-semibold text-lg text-brandMaroon">{step.title}</h3>
                            <p className="text-sm text-gray-600 mt-1">{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* Guides Section */}
            <div>
                <h2 className="text-xl font-semibold text-brandMaroon">Detailed Guides</h2>
                <p className="text-sm text-gray-600 mb-4">Import detailed guides to learn more</p>
                <div className="space-y-4">
                    {guides.map((guide) => (
                        <div key={guide.id} className="p-4 rounded-lg border hover:border-brandMaroon transition-colors">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h3 className="font-semibold text-lg">{guide.title}</h3>
                                </div>
                                <button
                                    onClick={() => handleImportGuide(guide.id, guide.title)}
                                    className="text-brandMaroon hover:text-brandMaroon/80 transition-colors"
                                    title="Import guide"
                                >
                                    <Copy size={20} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Getting Started Section */}
        </div>
    );
};
