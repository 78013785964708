
const EmptySearchState = () => {
    return (
        <div className="flex flex-col items-center justify-center text-center p-4 space-y-4 max-w-xs mx-auto">

            <div className="space-y-2">
                <h3 className="text-lg font-semibold text-gray-900">Start Your Research Graph</h3>
                <p className="text-sm text-gray-600 max-w-md">
                    Search for papers by title or paste a DOI to begin building your citation network.
                    Click add as origin for the relevant papers
                </p>
            </div>
            <div className="bg-gray-50 rounded-lg p-4 text-sm text-gray-600 max-w-md">
                <p className="font-medium mb-2">Quick Tips:</p>
                <ul className="list-disc list-inside space-y-1">
                    <li>Enter a paper title or DOI in the search bar, and select add as origin</li>
                    <li>Click nodes to expand and explore citations</li>
                    <li>Save interesting papers to your library</li>
                </ul>
            </div>
        </div>
    );
};

export default EmptySearchState;