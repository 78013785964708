import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'sonner';
import { serverUrl } from '../../authService';
import { useNavigate } from 'react-router-dom';

interface UserData {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    level: string;
    credits: number;
    chargeBeeSubscriptionId: string | null;
    stripe_subscription_id: string | null;
    madeAt: string | null;
    upgradedAt: string | null;
    verified: boolean;
    onboarding_completed: boolean;
}

interface NewUserData {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    level: string;
    credits: number;
}

const AdminPanel: React.FC = () => {
    const { isAdmin } = useAuth();
    const navigate = useNavigate();
    const [searchEmail, setSearchEmail] = useState<string>('');
    const [userData, setUserData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [stripeId, setStripeId] = useState<string>('');
    const [chargeBeeId, setChargeBeeId] = useState<string>('');
    const [level, setLevel] = useState<string>('');
    const [credits, setCredits] = useState<string>('');

    // New user form state
    const [newUser, setNewUser] = useState<NewUserData>({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        level: 'trial',
        credits: 5
    });
    const [creatingUser, setCreatingUser] = useState<boolean>(false);

    // Redirect if not admin
    React.useEffect(() => {
        if (isAdmin === false) {
            navigate('/');
        }
    }, [isAdmin, navigate]);

    const searchUser = async () => {
        if (!searchEmail) {
            toast.error('Please enter an email to search');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(
                `${serverUrl}/api/admin/find-user`,
                { email: searchEmail },
                { withCredentials: true }
            );

            if (response.data.user) {
                setUserData(response.data.user);
                setStripeId(response.data.user.stripe_subscription_id || '');
                setChargeBeeId(response.data.user.chargeBeeSubscriptionId || '');
                setLevel(response.data.user.level || '');
                setCredits(response.data.user.credits.toString());
            }
        } catch (error) {
            console.error('Error searching for user:', error);
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                toast.error('User not found');
            } else if (axios.isAxiosError(error) && error.response?.status === 403) {
                toast.error('Admin access required');
                navigate('/');
            } else {
                toast.error('Error searching for user');
            }
        } finally {
            setLoading(false);
        }
    };

    const upgradeUser = async () => {
        if (!userData) return;

        setLoading(true);
        try {
            await axios.post(
                `${serverUrl}/api/admin/upgrade-user`,
                { email: userData.email },
                { withCredentials: true }
            );

            toast.success('User upgraded to pro successfully');
            // Refresh user data
            searchUser();
        } catch (error) {
            console.error('Error upgrading user:', error);
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                toast.error('Admin access required');
                navigate('/');
            } else {
                toast.error('Error upgrading user');
            }
        } finally {
            setLoading(false);
        }
    };

    const updateUser = async () => {
        if (!userData) return;

        setLoading(true);
        try {
            await axios.post(
                `${serverUrl}/api/admin/update-user`,
                {
                    email: userData.email,
                    level,
                    stripe_subscription_id: stripeId || null,
                    chargeBeeSubscriptionId: chargeBeeId || null,
                },
                { withCredentials: true }
            );

            toast.success('User details updated successfully');
            // Refresh user data
            searchUser();
        } catch (error) {
            console.error('Error updating user:', error);
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                toast.error('Admin access required');
                navigate('/');
            } else {
                toast.error('Error updating user details');
            }
        } finally {
            setLoading(false);
        }
    };

    const updateCredits = async () => {
        if (!userData) return;

        setLoading(true);
        try {
            await axios.post(
                `${serverUrl}/api/admin/update-credits`,
                {
                    email: userData.email,
                    credits: parseInt(credits),
                },
                { withCredentials: true }
            );

            toast.success('User credits updated successfully');
            // Refresh user data
            searchUser();
        } catch (error) {
            console.error('Error updating credits:', error);
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                toast.error('Admin access required');
                navigate('/');
            } else {
                toast.error('Error updating user credits');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleNewUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewUser(prev => ({
            ...prev,
            [name]: name === 'credits' ? parseInt(value) : value
        }));
    };

    const createUser = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!newUser.email || !newUser.password || !newUser.firstName || !newUser.lastName) {
            toast.error('Please fill in all required fields');
            return;
        }

        setCreatingUser(true);
        try {
            await axios.post(
                `${serverUrl}/api/admin/create-user`,
                newUser,
                { withCredentials: true }
            );

            toast.success('User created successfully');

            // Store the created user's email
            const createdEmail = newUser.email;

            // Reset form
            setNewUser({
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                level: 'trial',
                credits: 5
            });

            // Find the newly created user
            setSearchEmail(createdEmail);

            // Search for the user directly using the email
            try {
                const response = await axios.post(
                    `${serverUrl}/api/admin/find-user`,
                    { email: createdEmail },
                    { withCredentials: true }
                );

                if (response.data.user) {
                    setUserData(response.data.user);
                    setStripeId(response.data.user.stripe_subscription_id || '');
                    setChargeBeeId(response.data.user.chargeBeeSubscriptionId || '');
                    setLevel(response.data.user.level || '');
                    setCredits(response.data.user.credits.toString());
                }
            } catch (searchError) {
                console.error('Error searching for new user:', searchError);
                // Don't show an error toast here as it would confuse the user
                // The user creation was successful, searching afterward is just a convenience
            }

        } catch (error) {
            console.error('Error creating user:', error);
            if (axios.isAxiosError(error) && error.response?.status === 400) {
                toast.error(error.response.data.error || 'Error creating user');
            } else if (axios.isAxiosError(error) && error.response?.status === 403) {
                toast.error('Admin access required');
                navigate('/');
            } else {
                toast.error('Error creating user');
            }
        } finally {
            setCreatingUser(false);
        }
    };

    if (!isAdmin) {
        return <div className="text-center p-8">Loading...</div>;
    }

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Admin Panel</h1>

            {/* Create New User Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4">Create New User</h2>
                <form onSubmit={createUser}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700 mb-1">Email*</label>
                            <input
                                type="email"
                                name="email"
                                value={newUser.email}
                                onChange={handleNewUserChange}
                                placeholder="user@example.com"
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-1">Password*</label>
                            <input
                                type="password"
                                name="password"
                                value={newUser.password}
                                onChange={handleNewUserChange}
                                placeholder="••••••••"
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-1">First Name*</label>
                            <input
                                type="text"
                                name="firstName"
                                value={newUser.firstName}
                                onChange={handleNewUserChange}
                                placeholder="John"
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-1">Last Name*</label>
                            <input
                                type="text"
                                name="lastName"
                                value={newUser.lastName}
                                onChange={handleNewUserChange}
                                placeholder="Doe"
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-1">User Level</label>
                            <select
                                name="level"
                                value={newUser.level}
                                onChange={handleNewUserChange}
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="trial">Trial</option>
                                <option value="paid">Paid</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-1">Credits</label>
                            <input
                                type="number"
                                name="credits"
                                value={newUser.credits}
                                onChange={handleNewUserChange}
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                min="0"
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={creatingUser}
                        className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50"
                    >
                        {creatingUser ? 'Creating...' : 'Create User'}
                    </button>
                </form>
            </div>

            {/* Find User Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4">Find User</h2>
                <div className="flex gap-3 mb-4">
                    <input
                        type="email"
                        value={searchEmail}
                        onChange={(e) => setSearchEmail(e.target.value)}
                        placeholder="Enter user email"
                        className="flex-1 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                        onClick={searchUser}
                        disabled={loading}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                </div>
            </div>

            {userData && (
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">User Information</h2>
                    <div className="grid grid-cols-2 gap-4 mb-6">
                        <div>
                            <p className="text-gray-600">Email</p>
                            <p className="font-medium">{userData.email}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Name</p>
                            <p className="font-medium">{`${userData.firstName} ${userData.lastName}`}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">User Level</p>
                            <p className="font-medium">{userData.level === 'paid' ? 'Pro' : 'Trial'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Credits</p>
                            <p className="font-medium">{userData.credits}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Stripe ID</p>
                            <p className="font-medium truncate">{userData.stripe_subscription_id || 'None'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">ChargeBee ID</p>
                            <p className="font-medium truncate">{userData.chargeBeeSubscriptionId || 'None'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Account Created</p>
                            <p className="font-medium">{userData.madeAt ? new Date(userData.madeAt).toLocaleDateString() : 'Unknown'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Upgraded At</p>
                            <p className="font-medium">{userData.upgradedAt ? new Date(userData.upgradedAt).toLocaleDateString() : 'Never'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Verified</p>
                            <p className="font-medium">{userData.verified ? 'Yes' : 'No'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Onboarding Completed</p>
                            <p className="font-medium">{userData.onboarding_completed ? 'Yes' : 'No'}</p>
                        </div>
                    </div>

                    <div className="border-t border-gray-200 pt-4 mt-4">
                        <h3 className="text-lg font-semibold mb-4">Quick Actions</h3>
                        <button
                            onClick={upgradeUser}
                            disabled={loading || userData.level === 'paid'}
                            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50 mr-3"
                        >
                            Upgrade to Pro
                        </button>
                    </div>

                    <div className="border-t border-gray-200 pt-4 mt-4">
                        <h3 className="text-lg font-semibold mb-4">Update User Details</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div>
                                <label className="block text-gray-700 mb-1">User Level</label>
                                <select
                                    value={level}
                                    onChange={(e) => setLevel(e.target.value)}
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    <option value="trial">Trial</option>
                                    <option value="paid">Paid</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-1">Credits</label>
                                <input
                                    type="number"
                                    value={credits}
                                    onChange={(e) => setCredits(e.target.value)}
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-1">Stripe Subscription ID</label>
                                <input
                                    type="text"
                                    value={stripeId}
                                    onChange={(e) => setStripeId(e.target.value)}
                                    placeholder="Stripe Subscription ID"
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-1">ChargeBee Subscription ID</label>
                                <input
                                    type="text"
                                    value={chargeBeeId}
                                    onChange={(e) => setChargeBeeId(e.target.value)}
                                    placeholder="ChargeBee Subscription ID"
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <button
                                onClick={updateUser}
                                disabled={loading}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                            >
                                Update User Details
                            </button>
                            <button
                                onClick={updateCredits}
                                disabled={loading}
                                className="px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700 disabled:opacity-50"
                            >
                                Update Credits
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPanel; 