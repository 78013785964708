import React, { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Spinner from "../Spinner";
import { useAuth } from "../../contexts/AuthContext";

interface ProtectedRouteProps {
  children: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  const { loggedIn, isLoading } = useAuth();

  if (isLoading) {
    return <Spinner />;
  }

  if (loggedIn === false) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;