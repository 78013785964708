import React from 'react';
import CitationMap from '../../components/CitationMap/CitationMap';
import { useParams } from 'react-router-dom';

const CitationMapPage: React.FC = () => {
  const { id: projectId } = useParams<{ id: string }>();

  return (
    
    <div className="flex-1 flex overflow-hidden">
      <CitationMap projectId={projectId || ''} />
    </div>
  );
};

export default CitationMapPage;