import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import { MessageSquare, FileUp, BarChart2, Library, Mail, PlayCircle, BookOpen, Search, Diamond, DiamondIcon, FileText, HelpCircle, ExternalLink } from 'lucide-react';
import { toast } from 'sonner';
import { handleChatClick, uploadPapersToProject } from '../../authService';
import SeoComp from '../../components/SeoComp';
import { useSidebar } from '../../contexts/sidebarContext';
import { useSearch } from '../../contexts/queryContext';
import LiteratureReview from './LiteratureReview';
import Typewriter from 'typewriter-effect';
// import BrandBanner from '../../components/banner/onBrand';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../../components/Spinner';
import { SearchBar } from '../../components/searchInput/SearchBar';
import { showDemoIframe } from '../../components/editor/DocumentHistory';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';

interface AdditionalResourcesProps {
    id: string;
    navigate: NavigateFunction;
}


const AdditionalResources: React.FC<AdditionalResourcesProps> = ({
    id,
    navigate,
}) => (
    <div className="grid md:grid-cols-2 gap-12">
        <div className="bg-white p-8 rounded-2xl shadow-lg">
            <h3 className="text-2xl font-bold mb-6 flex items-center text-gray-800">
                <PlayCircle className="mr-3 text-brandMaroon" size={28} />
                Tutorial Video
            </h3>
            <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
                <iframe
                    src="https://www.youtube.com/embed/AlUAdYt9vDc"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                ></iframe>
            </div>
        </div>
        <div className="bg-white p-8 rounded-2xl shadow-lg">
            <h3 className="text-2xl font-bold mb-6 flex items-center text-gray-800">
                <Mail className="mr-3 text-brandMaroon" size={28} />
                Contact Us
            </h3>
            <p className="mb-6 text-gray-600">Have questions or need assistance? We're here to help!</p>
            <button
                className="bg-brandMaroon text-white px-8 py-3 rounded-full inline-flex items-center hover:bg-red-700 transition-all duration-300 transform hover:scale-105 shadow-lg"
                onClick={() => navigate(`/${id}/contact-us`)}
            >
                <Mail className="mr-2" size={18} />
                Contact Support
            </button>
        </div>
    </div>
);


const FeatureCard = ({ title, description }: { title: string, description: string }) => (
    <div className="bg-white p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105">
        <h4 className="font-semibold text-lg mb-2 text-brandMaroon">{title}</h4>
        <p className="text-gray-600">{description}</p>
    </div>
);

const Features = ({ userLevel, navigate, id }: { userLevel: string, navigate: NavigateFunction, id: string }) => (
    <div className=" bg-brandMaroon rounded-3xl p-12 text-white shadow-2xl mb-20">
        <div className="max-w-5xl mx-auto text-center">
            <h3 className="text-4xl font-bold mb-6 text-white">
                {userLevel === 'paid' ? "Welcome Pro User" : "Elevate Your Research Game"}
            </h3>
            <p className="text-xl mb-10 text-gray-100">
                {userLevel === 'paid'
                    ? "Thank you for your support. Enjoy all the premium features of AnswerThis!"
                    : "Unlock the full potential of your research with our premium features."}
            </p>
            <div className="grid md:grid-cols-3 gap-8 mb-12">
                <FeatureCard
                    title="Unlimited Access"
                    description="Explore our entire database without limits"
                />
                <FeatureCard
                    title="Priority Support"
                    description="Get expert help whenever you need it"
                />
                <FeatureCard
                    title="Advanced Features"
                    description="Access cutting-edge research tools"
                />
            </div>
            {userLevel !== 'paid' && (
                <button className="bg-white text-brandMaroon px-10 py-4 rounded-full text-lg font-semibold hover:bg-gray-100 transition-all duration-300 transform hover:scale-105 shadow-lg"

                    onClick={() => navigate(`/${id}/pricing`)}
                >
                    Upgrade to Pro
                </button>
            )}
        </div>
    </div>
);

// New Tool Card component for Popular Tools and Best for Researchers sections
const ToolCard = ({
    icon: Icon,
    title,
    description,
    onClick,
    bgColor = "bg-white",
    iconBgColor = "bg-gray-50",
    iconColor = "text-brandMaroon",
    demoUrl
}: {
    icon: React.ElementType,
    title: string,
    description: string,
    onClick?: () => void,
    bgColor?: string,
    iconBgColor?: string,
    iconColor?: string,
    demoUrl?: string
}) => (
    <div className={`${bgColor} p-6 rounded-xl shadow-lg transition-all duration-300 hover:scale-105 relative`}>
        <div onClick={onClick} className={`flex flex-col items-center text-center ${onClick ? 'cursor-pointer' : ''}`}>
            <div className={`p-3 rounded-full ${iconBgColor} mb-4`}>
                <Icon className={`${iconColor}`} size={32} />
            </div>
            <h4 className="font-semibold text-lg mb-2 text-gray-800">{title}</h4>
            <p className="text-gray-600 text-sm">{description}</p>
        </div>
        {demoUrl && (
            <button
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                onClick={(e) => {
                    e.stopPropagation();
                    showDemoIframe(demoUrl);
                }}
            >
                <HelpCircle size={18} />
            </button>
        )}
    </div>
);

// Add a "Guides" link component
const GuidesLink: React.FC<{ id: string }> = ({ id }) => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => navigate(`/${id}/guides`)}
            className="flex items-center text-gray-600 hover:text-brandMaroon gap-1 text-sm mt-2"
        >
            <ExternalLink size={14} />
            <span>View interactive guides</span>
        </button>
    );
};

// Chat with PDF modal component
const ChatWithPDFModal: React.FC<{ isOpen: boolean; onClose: () => void; handleLibraryClick: () => void; isUploading: boolean; handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({
    isOpen,
    onClose,
    handleLibraryClick,
    isUploading,
    handleFileUpload
}) => {

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-lg">
                <DialogHeader>
                    <DialogTitle>Chat with PDF</DialogTitle>
                </DialogHeader>
                <div className="text-center p-4">
                    <MessageSquare className="mx-auto mb-6 text-brandMaroon" size={64} />
                    <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
                        Upload your documents and get instant answers to your questions. Our AI-powered chat feature makes navigating through your PDFs effortless.
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center sm:space-x-4 space-y-4 sm:space-y-0">
                        <label
                            htmlFor="chat-popup-file-upload"
                            className="cursor-pointer bg-brandMaroon text-white px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-brandMaroon-600 transition-colors duration-200 w-full sm:w-auto"
                        >
                            <FileUp className="mr-2" size={20} />
                            {isUploading ? 'Uploading...' : 'Upload PDF'}
                        </label>
                        <button
                            onClick={() => {
                                handleLibraryClick();
                                onClose();
                            }}
                            className="bg-gray-200 text-gray-800 px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-gray-300 transition-colors duration-200 w-full sm:w-auto"
                        >
                            <Library className="mr-2" size={20} />
                            Select from Library
                        </button>
                    </div>
                    <input
                        id="chat-popup-file-upload"
                        type="file"
                        multiple
                        accept=".pdf"
                        onChange={(e) => {
                            handleFileUpload(e);
                            onClose();
                        }}
                        className="hidden"
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

// Extract Data modal component
const ExtractDataModal: React.FC<{ isOpen: boolean; onClose: () => void; setShowExtractData: (show: boolean) => void; handleLibraryClick: () => void; isUploading: boolean; handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({
    isOpen,
    onClose,
    setShowExtractData,
    handleLibraryClick,
    isUploading,
    handleFileUpload
}) => {

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-lg">
                <DialogHeader>
                    <DialogTitle>Extract Data from PDFs</DialogTitle>
                </DialogHeader>
                <div className="text-center p-4">
                    <BarChart2 className="mx-auto mb-6 text-brandMaroon" size={64} />
                    <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
                        Automatically pull out key information and statistics from your documents. Save hours of manual work with our advanced data extraction tool.
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center sm:space-x-4 space-y-4 sm:space-y-0">
                        <label
                            htmlFor="extract-popup-file-upload"
                            className="cursor-pointer bg-brandMaroon text-white px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-brandMaroon-600 transition-colors duration-200 w-full sm:w-auto"
                        >
                            <FileUp className="mr-2" size={20} />
                            {isUploading ? 'Uploading...' : 'Upload PDF'}
                        </label>
                        <button onClick={() => {
                            setShowExtractData(true);
                            handleLibraryClick();
                            onClose();
                        }}
                            className="bg-gray-200 text-gray-800 px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-gray-300 transition-colors duration-200 w-full sm:w-auto"
                        >
                            <Library className="mr-2" size={20} />
                            Select from Library
                        </button>
                    </div>
                    <input
                        id="extract-popup-file-upload"
                        type="file"
                        multiple
                        accept=".pdf"
                        onChange={(e) => {
                            handleFileUpload(e);
                            onClose();
                        }}
                        className="hidden"
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

const HomeUpload: React.FC = () => {

    const [activeTab, setActiveTab] = useState<string>('review');
    const [query, setQuery] = useState<string>('');
    const { id } = useParams();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [showChatPopup, setShowChatPopup] = useState<boolean>(false);
    const [showExtractPopup, setShowExtractPopup] = useState<boolean>(false);
    const navigate = useNavigate();
    const { setSearchQuery, setShowExtractData } = useSearch();
    const { refreshSidebar } = useSidebar();
    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const { user, checkCredits, removeCredits, isLoading, togglePricingPopup } = useAuth();

    // Added to ensure handleTabChange is used
    useEffect(() => {
        // Default to review tab on component mount
        handleTabChange('review');
    }, []);

    if (isLoading || !user) {
        return <Spinner />;
    }

    const handleSearch = () => {
        setSearchQuery(query);
        // Navigate to SearchPapers page with the query
        navigate(`/${id}/ask-answerthis?tab=search`);
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const loadingToastId = toast.loading('Uploading papers...');
            const hasEnoughCredits = checkCredits(id!, files.length);
            if (!hasEnoughCredits) {
                return;
            }
            setIsUploading(true);

            try {
                const paperUpload = await uploadPapersToProject(
                    id!,
                    files,
                    (message) => {
                        removeCredits(files.length);
                        toast.dismiss(loadingToastId);
                        toast.success(message);
                        refreshSidebar();
                    },
                    (error) => {
                        toast.dismiss(loadingToastId);
                        toast.error(error);
                    },
                    navigate,
                    setIsUploading
                );

                if (paperUpload) {
                    if (activeTab === 'chat') {
                        await handleChatClick(
                            paperUpload.paperIds,
                            id!,
                            navigate,
                            (response) => { toast.success(response) },
                            (error) => { toast.error(error) },
                        );
                    } else {
                        setShowExtractData(true);
                        navigate(`/${id}/ask-answerthis?tab=library`);
                    }
                }
            } catch (error) {
                toast.dismiss(loadingToastId);
                if (error instanceof Error) {
                    toast.error(error.message);
                } else {
                    toast.error("An unexpected error occurred while uploading files");
                }
            } finally {
                setIsUploading(false);
            }
        }

    };
    const handleLibraryClick = () => {
        navigate(`/${id}/ask-answerthis?tab=library`);
    };
    return (
        <div>
            <SeoComp />
            {/* <BrandBanner sideText="New UI" bannerText="Go create a new literature review and check it out." /> */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">

                <HeadUploadTypewriter userLevel={user.level} togglePricingPopup={() => togglePricingPopup(id!)} />

                <div className="bg-white shadow-sm rounded-lg overflow-hidden mb-4">
                    <div className="">
                        {activeTab === 'review' && (
                            <div className="w-full max-w-full overflow-hidden">
                                <div className="relative">
                                    <LiteratureReview
                                        showOnlySearchBar={true}
                                        onQueryChange={setQuery}
                                        initialQuery={query}
                                    />

                                    <div className="absolute top-4 right-4 z-10 flex items-center space-x-2">

                                        <button
                                            onClick={() => showDemoIframe(DEMO_URLS.literatureReview)}
                                            className="text-gray-400 hover:text-gray-600 transition-colors duration-200 h-9 w-9 flex items-center justify-center"
                                        >
                                            <HelpCircle size={20} />
                                        </button>
                                    </div>
                                </div>

                                {/* Remove the TrySearchFor component call */}

                            </div>
                        )}

                        {activeTab === 'chat' && (
                            <div className="text-center p-6">
                                <MessageSquare className="mx-auto mb-6 text-brandMaroon" size={64} />
                                <h2 className="text-3xl font-bold mb-4 text-gray-800">Chat with your PDFs</h2>
                                <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
                                    Upload your documents and get instant answers to your questions. Our AI-powered chat feature makes navigating through your PDFs effortless.
                                </p>
                                <div className="flex flex-col sm:flex-row justify-center sm:space-x-4 space-y-4 sm:space-y-0">
                                    <label
                                        htmlFor="chat-file-upload"
                                        className="cursor-pointer bg-brandMaroon text-white px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-brandMaroon-600 transition-colors duration-200 w-full sm:w-auto"
                                    >
                                        <FileUp className="mr-2" size={20} />
                                        {isUploading ? 'Uploading...' : 'Upload PDF'}
                                    </label>
                                    <button
                                        onClick={handleLibraryClick}
                                        className="bg-gray-200 text-gray-800 px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-gray-300 transition-colors duration-200 w-full sm:w-auto"
                                    >
                                        <Library className="mr-2" size={20} />
                                        Select from Library
                                    </button>
                                </div>
                                <input
                                    id="chat-file-upload"
                                    type="file"
                                    multiple
                                    accept=".pdf"
                                    onChange={handleFileUpload}
                                    className="hidden"
                                />
                            </div>
                        )}

                        {activeTab === 'extract' && (
                            <div className="text-center p-6">
                                <BarChart2 className="mx-auto mb-6 text-brandMaroon" size={64} />
                                <h2 className="text-3xl font-bold mb-4 text-gray-800">Extract Data from PDFs</h2>
                                <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
                                    Automatically pull out key information and statistics from your documents. Save hours of manual work with our advanced data extraction tool.
                                </p>
                                <div className="flex flex-col sm:flex-row justify-center sm:space-x-4 space-y-4 sm:space-y-0">
                                    <label
                                        htmlFor="extract-file-upload"
                                        className="cursor-pointer bg-brandMaroon text-white px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-brandMaroon-600 transition-colors duration-200 w-full sm:w-auto"
                                    >
                                        <FileUp className="mr-2" size={20} />
                                        {isUploading ? 'Uploading...' : 'Upload PDF'}
                                    </label>
                                    <button onClick={() => {
                                        setShowExtractData(true);
                                        handleLibraryClick()
                                    }}
                                        className="bg-gray-200 text-gray-800 px-8 py-3 rounded-lg inline-flex items-center justify-center hover:bg-gray-300 transition-colors duration-200 w-full sm:w-auto"
                                    >
                                        <Library className="mr-2" size={20} />
                                        Select from Library
                                    </button>
                                </div>
                                <input
                                    id="extract-file-upload"
                                    type="file"
                                    multiple
                                    accept=".pdf"
                                    onChange={handleFileUpload}
                                    className="hidden"
                                />
                            </div>
                        )}

                        {activeTab === 'search' && (
                            <div className='mt-4 p-6 flex align-middle justify-center w-[90%] mx-auto flex-col gap-2'>
                                <SearchBar handleInputChange={(e) => setQuery(e.target.value)} handleSearch={handleSearch} query={query}></SearchBar>
                                <p className="text-gray-600 text-center">
                                    Access millions of research papers across various disciplines with our powerful search engine.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="p-4">
                    <div className="mb-12">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">Popular Tools</h2>
                            <div className="flex items-center gap-3">
                                <GuidesLink id={id!} />

                                {user.level !== 'paid' && (
                                    <button
                                        onClick={() => navigate(`/${id}/pricing`)}
                                        className="text-sm bg-white border border-brandMaroon text-brandMaroon px-4 py-2 rounded-lg hover:bg-gray-50 transition-all duration-300 flex items-center"
                                    >
                                        <Diamond className="mr-1" size={16} />
                                        Get Pro Access
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                            <ToolCard
                                icon={MessageSquare}
                                title="Chat with PDF"
                                description="Get all answers backed by citations. Chat with your research papers directly."
                                onClick={() => setShowChatPopup(true)}
                                bgColor="bg-white"
                                iconBgColor="bg-pink-50"
                                iconColor="text-pink-500"
                                demoUrl={DEMO_URLS.chatWithPaper}
                            />
                            <ToolCard
                                icon={FileText}
                                title="AI Writer"
                                description="Write new research papers. Assisted by AI."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=documents`)}
                                bgColor="bg-white"
                                iconBgColor="bg-blue-50"
                                iconColor="text-blue-500"
                                demoUrl={DEMO_URLS.editor}
                            />
                            <ToolCard
                                icon={BarChart2}
                                title="Extract Data"
                                description="Get summary, conclusions & findings from multiple PDFs."
                                onClick={() => setShowExtractPopup(true)}
                                bgColor="bg-white"
                                iconBgColor="bg-purple-50"
                                iconColor="text-purple-500"
                            />
                            <ToolCard
                                icon={Search}
                                title="Diagram Generator"
                                description="Create visual diagrams from your research data automatically."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=diagram`)}
                                bgColor="bg-white"
                                iconBgColor="bg-green-50"
                                iconColor="text-green-500"
                                demoUrl={DEMO_URLS.diagramGenerator}
                            />
                        </div>
                    </div>

                    <div className="mb-16 ">
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Best for Researchers</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            <ToolCard
                                icon={BookOpen}
                                title="Literature Review"
                                description="Discover new papers for your research."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=review`)}
                                bgColor="bg-white"
                                iconBgColor="bg-amber-50"
                                iconColor="text-amber-500"
                                demoUrl={DEMO_URLS.literatureReview}
                            />
                            <ToolCard
                                icon={Search}
                                title="Search Papers"
                                description="Discover topics from 285M research papers."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=search`)}
                                bgColor="bg-white"
                                iconBgColor="bg-cyan-50"
                                iconColor="text-cyan-500"
                                demoUrl={DEMO_URLS.searchPapers}
                            />
                            <ToolCard
                                icon={MessageSquare}
                                title="Citation Map"
                                description="Visualize citation networks and research connections."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=citation-map`)}
                                bgColor="bg-white"
                                iconBgColor="bg-indigo-50"
                                iconColor="text-indigo-500"
                                demoUrl={DEMO_URLS.citationMap}
                            />
                        </div>
                    </div>

                    <Features userLevel={user.level} navigate={navigate} id={id!} />
                    <AdditionalResources id={id!} navigate={navigate} />
                </div>
            </div>

            {/* Modals */}
            <ChatWithPDFModal
                isOpen={showChatPopup}
                onClose={() => setShowChatPopup(false)}
                handleLibraryClick={handleLibraryClick}
                isUploading={isUploading}
                handleFileUpload={handleFileUpload}
            />

            <ExtractDataModal
                isOpen={showExtractPopup}
                onClose={() => setShowExtractPopup(false)}
                setShowExtractData={setShowExtractData}
                handleLibraryClick={handleLibraryClick}
                isUploading={isUploading}
                handleFileUpload={handleFileUpload}
            />

        </div>
    );
};

export default HomeUpload;


function HeadUploadTypewriter({ userLevel, togglePricingPopup }: { userLevel: string, togglePricingPopup: () => void }) {
    return (
        <div className="text-center mb-5 relative">
            <h1 className="text-3xl md:text-5xl font-extrabold text-gray-900 mb-6 leading-tight">
                <span className="text-brandMaroon hidden md:block">
                    <Typewriter
                        options={{
                            strings: ['Comprehensive Literature Reviews', 'Research Gaps', 'Research Proposals', 'Project Reports'],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </span>
                <span className="text-brandMaroon block md:hidden">Comprehensive Literature Reviews</span>
                {" "}In Seconds
            </h1>
            <div className="mt-6">
                {userLevel === 'trial' ? (
                    <button
                        onClick={() => togglePricingPopup()}
                        className="bg-brandMaroon text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 shadow-lg inline-flex items-center"
                    >
                        <Diamond className="mr-2" size={20} />
                        Upgrade to Pro
                    </button>
                ) : (
                    <div className="inline-flex items-center  px-6 py-2 rounded-full">
                        <DiamondIcon className="text-brandMaroon mr-2" size={24} />
                        <span className="font-bold text-brandMaroon">Pro User</span>
                    </div>
                )}
            </div>
        </div>
    );
}

// Add demo URLs for each tool
const DEMO_URLS = {
    diagramGenerator: "https://app.supademo.com/embed/cm7nuad5c0f8ti0nhr4dgvykf?embed_v=2",
    citationMap: "https://app.supademo.com/embed/cm7nsxbvy0duoi0nhap527qke?embed_v=2",
    searchPapers: "https://app.supademo.com/embed/cm7nslxtd0dgti0nhdme9jun4?embed_v=2",
    library: "https://app.supademo.com/embed/cm7nsa1h30czmi0nh3rwlgnke?embed_v=2",
    chatWithPaper: "https://app.supademo.com/embed/cm7nyjj1z004khilgmzuz1dgj?embed_v=2",
    literatureReview: "https://app.supademo.com/embed/cm7nzuc4i00l7hilgfn3ynvy5?embed_v=2",
    editor: "https://app.supademo.com/embed/cm7mbj5en1w0cddumsngnkr1a?embed_v=2"
};
