import React, { createContext, useState, useContext } from 'react';

interface QueryContextType {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    showExtractData: boolean;
    setShowExtractData: (show: boolean) => void;
}

const QueryContext = createContext<QueryContextType | undefined>(undefined);

export const QueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showExtractData, setShowExtractData] = useState(false);

    return (
        <QueryContext.Provider value={{ searchQuery, setSearchQuery, showExtractData, setShowExtractData }}>
            {children}
        </QueryContext.Provider>
    );
};

export const useSearch = () => {
    const context = useContext(QueryContext);
    if (context === undefined) {
        throw new Error('useSearch must be used within a SearchProvider');
    }
    return context;
};