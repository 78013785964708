import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { SourceInfo } from '../../authService';
import CitationComponent from './CitationComponent';
import { citationStyles, getInTextCitation } from './citationUtils';
import { useGlobalCitationStyle } from '@/contexts/citationContext';
// import ProFeatureBlur from '@/components/ProFeatureBlur';

interface CitationManagerProps {
    sources: SourceInfo[];
    answer: string;
    onAnswerUpdate: (updatedAnswer: string) => void;
}

const CitationManager: React.FC<CitationManagerProps> = ({ sources, answer, onAnswerUpdate }) => {
    const [citationOrder, setCitationOrder] = useState<SourceInfo[]>(sources);
    const { globalStyle } = useGlobalCitationStyle();
    const previousStyleRef = useRef<citationStyles>(globalStyle);

    useEffect(() => {
        if (previousStyleRef.current !== globalStyle) {
            updateInTextCitations(citationOrder, globalStyle, previousStyleRef.current);
            previousStyleRef.current = globalStyle;
        }
    }, [globalStyle, citationOrder]);

    useEffect(() => {
        setCitationOrder(sources);
    }, [sources]);

    const updateInTextCitations = useCallback((newOrder: SourceInfo[], newStyle: citationStyles, oldStyle: citationStyles) => {
        // Create a mapping of old citations to new citations
        const citationMapping = new Map<string, string>();

        sources.forEach((source, oldIndex) => {
            const newIndex = newOrder.findIndex(s => s.id === source.id);
            const oldCitation = getInTextCitation(source, oldStyle, oldIndex);
            const newCitation = getInTextCitation(source, newStyle, newIndex);
            citationMapping.set(oldCitation, newCitation);
        });

        // Create a regex that matches all possible citations
        const allCitationsRegex = new RegExp(
            Array.from(citationMapping.keys())
                .map(citation => citation.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                .join('|'),
            'g'
        );

        // Replace all citations in one go
        const updatedAnswer = answer.replace(allCitationsRegex, (match) => {
            return citationMapping.get(match) || match;
        });

        onAnswerUpdate(updatedAnswer);
    }, [answer, sources, onAnswerUpdate]);

    const handleCitationOrderChange = useCallback((newOrder: SourceInfo[]) => {
        setCitationOrder(newOrder);
        updateInTextCitations(newOrder, globalStyle, globalStyle);
    }, [updateInTextCitations, globalStyle]);

    const memoizedCitationComponent = useMemo(() => (
        <CitationComponent
            sources={citationOrder}
            onCitationOrderChange={handleCitationOrderChange}
            currentStyle={globalStyle}
        />
    ), [citationOrder, handleCitationOrderChange, globalStyle]);

    return (
        <div className="mb-6">
            {memoizedCitationComponent}
        </div>
    );
};

export default React.memo(CitationManager);