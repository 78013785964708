import axios from "axios";
import { serverUrl } from "../../authService";
import QueueAdapter from "@/HelperUtils/QueueAdapter";
const queueAdapter = new QueueAdapter({
    taskBaseUrl: serverUrl,     // For SSE/status endpoints
    endpointBaseUrl: serverUrl, // For queued endpoint
    backupEndpointUrl: serverUrl      // For non-queued backup
});
export interface DiagramStyle {
    nodeColor: string;
    edgeColor: string;
    backgroundColor: string;
    actorColor?: string;
    messageColor?: string;
}

export interface DiagramType {
    title: string;
    description: string;
    orientation?: string[];
    defaultStyle: DiagramStyle;
}

export interface DiagramData {
    id: string;
    type: string;
    prompt: string;
    code: string;
    created_at: string;
    updated_at?: string;
    changes_requested?: string;
    version_history?: DiagramData[];
    mermaid_code?: string;
}

export interface DiagramResponse {
    error?: string;
    id?: string;
    type?: string;
    code?: string;
    version_history?: DiagramData[];
    message?: string;
    created_at?: string;
    prompt?: string;
    updated_at?: string;
    mermaid_code?: string;
}

export async function createDiagram(
    text: string,
    type: string,
    is_svg: boolean = false
): Promise<DiagramResponse> {
    try {
        const requestBody = {
            text,
            type,
            is_svg
        };
        const response = await queueAdapter.executeTask<DiagramResponse>(
            '/diagramsQ',  // Queued endpoint
            requestBody,
            '/diagrams'    // Backup endpoint
        );
        return response;
    } catch (error: any) {
        return { error: error.response?.data?.error || 'Failed to create diagram' };
    }
}

export async function updateDiagram(
    diagram_id: string,
    text: string
): Promise<DiagramResponse> {
    try {
        const requestBody = {
            text,
            diagram_id
        };
        const response = await queueAdapter.executeTask<DiagramResponse>(
            '/diagrams/updateQ',
            requestBody,
            '/diagrams/update'
        );
        return response;
    } catch (error: any) {
        return { error: error.response?.data?.error || 'Failed to update diagram' };
    }
}

export async function editDiagramCode(
    diagram_id: string,
    code: string
): Promise<DiagramResponse> {
    try {
        const requestBody = {
            diagram_id,
            code
        };
        const response = await queueAdapter.executeTask<DiagramResponse>(
            '/diagrams/editQ',
            requestBody,
            '/diagrams/edit'
        );
        return response;
    } catch (error: any) {
        return { error: error.response?.data?.error || 'Failed to update diagram code' };
    }
}

// These functions don't need queueing as they're read-only operations
export async function getAllDiagramsForUser(): Promise<DiagramData[]> {
    try {
        const response = await axios.post(`${serverUrl}/diagrams/viewAll`, {}, { withCredentials: true });
        return response.data.diagrams;
    } catch (error: any) {
        throw new Error(error.response?.data?.error || 'Failed to fetch diagrams with revisions');
    }
}

export async function getDiagram(diagram_id: string): Promise<DiagramResponse> {
    try {
        const response = await axios.post(`${serverUrl}/viewDiagramById`, { diagram_id }, { withCredentials: true });
        return response.data;
    } catch (error: any) {
        return { error: error.response?.data?.error || 'Failed to retrieve diagram' };
    }
}


export function releaseBlobUrl(url: string): void {
    if (url.startsWith('blob:')) {
        URL.revokeObjectURL(url);
    }
}