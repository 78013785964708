// Import React and necessary types
import React from 'react';
import vastSourcesImg from "/img/search_page.png";
import litReviewImg from "/img/scholarly_answer.png";
import tableImg from "/img/table_sources.png";
import { MagnifyingGlassCircleIcon, BookmarkSquareIcon, DocumentTextIcon, ShieldCheckIcon, GlobeAltIcon, SparklesIcon } from '@heroicons/react/24/outline'

import { FadeIn } from '../Animations';
import { FeatureCard, FeatureData } from '../Features/FeatureCard';


// FeaturesSection main component
const FeaturesSection: React.FC = () => {
    const features: FeatureData[] = [
        {
            badgeText: 'Uncover',
            title: 'The World’s Best Research Assistant',
            description: 'Simplify your research with comprehensive literature reviews. Request summaries of papers, receive paragraph-by-paragraph citations and access relevant sources.',
            imgSrc: vastSourcesImg, // Replace with actual image path
            altText: 'AnswerThis uses vast sources to find the best literature preview to make research easier.',
            features: [
                {
                    name: 'Intelligent Paper Search',
                    description: 'Utilize our advanced AI to find the most relevant papers for your research, saving hours of manual searching and sifting.',
                    icon: MagnifyingGlassCircleIcon,
                },
                {
                    name: 'Smart Library Organization',
                    description: 'Effortlessly organize your research papers into customized libraries, making it easy to manage and access your sources.',
                    icon: BookmarkSquareIcon,
                },
                {
                    name: 'Rapid Literature Reviews',
                    description: 'Generate comprehensive literature reviews with line-by-line citations in minutes, not days or weeks.',
                    icon: DocumentTextIcon,
                },
            ]

        },
        {
            badgeText: 'Accelerate',
            title: '200 Million Sources for Credible Research Data.',
            description: 'Our cutting-edge search delivers reliable, detailed, organised information in an easy-to-read table. No more endless scrolling of papers to find the right piece of information.',
            imgSrc: tableImg, // Replace with actual image path
            altText: 'AnswerThis provides detailed source table for all the user queries',
            reverse: true,
            features: [
                {
                    name: 'Expansive Research Database',
                    description: 'Access a wealth of knowledge with our database of over 200 million research papers, ensuring comprehensive coverage of your field.',
                    icon: GlobeAltIcon,
                },
                {
                    name: 'Secure and Private',
                    description: 'Your research data and queries are protected with state-of-the-art security measures, ensuring complete confidentiality.',
                    icon: ShieldCheckIcon,
                },
                {
                    name: 'Reliable AI Insights',
                    description: 'Our AI rarely hallucinates, providing you with accurate and dependable information for your research needs.',
                    icon: SparklesIcon,
                },
            ]
        },
        {
            badgeText: 'Refine',
            title: 'Specialised For Academics',
            description: 'Want to capture the essence of your theoretical framework? AnswerThis’ distinctive AI system is designed to produce scholarly answers that can go directly into your paper.',
            imgSrc: litReviewImg, // Replace with actual image path
            altText: 'AnswerThis provides the best literature review using published papers as sources.',
            features: [
                {
                    name: 'Comprehensive Reviews',
                    description: 'Generate thorough literature reviews that capture the essence of your research topic, complete with relevant theories and current findings.',
                    icon: DocumentTextIcon,
                },
                {
                    name: 'Precise Citations',
                    description: 'Every statement in your literature review is backed by accurate, line-by-line citations, ensuring academic integrity and ease of reference.',
                    icon: BookmarkSquareIcon,
                },
                {
                    name: 'Time-Saving Synthesis',
                    description: 'Let our AI synthesize information from multiple sources, allowing you to focus on analysis and advancing your research.',
                    icon: SparklesIcon,
                },
            ]
        }
    ];

    return (
        <div id="features">
            <div className="feature-cards">
                {features.map((feature, index) => (
                    <FadeIn>
                        <FeatureCard key={index} {...feature} />
                    </FadeIn>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;
