import { useState } from 'react';
import { Maximize2, ZoomIn, ZoomOut, Plus, Minus, Paintbrush, ChevronUp, ChevronDown, Settings } from 'lucide-react';

interface GraphControlsProps {
  onFitView: () => void;
  handleZoom: (factor: number) => void;
  handleNodeSizeChange: (factor: number) => void;
  handleNodeColorChange: () => void;
  maxPapers: number;
  handleMaxPapersChange: (value: number) => void;
}

export function GraphControls({
  onFitView,
  handleZoom,
  handleNodeSizeChange,
  handleNodeColorChange,
  maxPapers,
  handleMaxPapersChange
}: GraphControlsProps) {
  const [showControls, setShowControls] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);

  return (
    <div className="absolute top-4 right-4 flex flex-col gap-2">
      {/* Toggle Controls Button */}
      <button
        onClick={() => setShowControls(!showControls)}
        className="self-end flex items-center gap-2 bg-white rounded-lg shadow-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        {showControls ? 'Hide Controls' : 'Show Controls'}
        {showControls ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
      </button>

      {showControls && (
        <div className="bg-white rounded-lg shadow-md p-3 space-y-3 min-w-[200px] sm:min-w-[250px]">
          {/* Basic Controls */}
          <div className="space-y-2">
            <div className="flex justify-between gap-2">
              <button
                onClick={() => handleZoom(1.2)}
                className="flex-1 flex items-center justify-center py-2 px-3 rounded bg-gray-50 hover:bg-gray-100"
                title="Zoom in"
              >
                <ZoomIn className="w-4 h-4 text-gray-600" />
                <span className="ml-2 text-sm hidden sm:inline">Zoom In</span>
              </button>

              <button
                onClick={() => handleZoom(0.8)}
                className="flex-1 flex items-center justify-center py-2 px-3 rounded bg-gray-50 hover:bg-gray-100"
                title="Zoom out"
              >
                <ZoomOut className="w-4 h-4 text-gray-600" />
                <span className="ml-2 text-sm hidden sm:inline">Zoom Out</span>
              </button>

              <button
                onClick={onFitView}
                className="flex-1 flex items-center justify-center py-2 px-3 rounded bg-gray-50 hover:bg-gray-100"
                title="Fit to view"
              >
                <Maximize2 className="w-4 h-4 text-gray-600" />
                <span className="ml-2 text-sm hidden sm:inline">Fit</span>
              </button>
            </div>

            {/* Arrow Color Legend */}
            <div className="mt-4 pt-3 border-t border-gray-200">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Arrow Colors</h3>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <div className="w-8 h-0.5" style={{ backgroundColor: 'rgb(59, 130, 246)' }}></div>
                  <span className="text-sm text-gray-600">Citation (Paper A is cited by Paper B)</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-8 h-0.5" style={{ backgroundColor: 'rgb(239, 68, 68)' }}></div>
                  <span className="text-sm text-gray-600">Reference (Paper A references Paper B)</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-8 h-0.5" style={{ backgroundColor: 'rgb(148, 163, 184)' }}></div>
                  <span className="text-sm text-gray-600">Indirect Connection (Papers share references/citations)</span>
                </div>
              </div>
            </div>
          </div>

          {/* Advanced Settings Toggle */}
          <button
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="w-full flex items-center justify-between px-3 py-2 rounded bg-gray-50 hover:bg-gray-100 text-sm font-medium text-gray-700"
          >
            <div className="flex items-center gap-2">
              <Settings className="w-4 h-4" />
              Advanced Settings
            </div>
            {showAdvanced ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
          </button>

          {/* Advanced Settings Content */}
          {showAdvanced && (
            <div className="space-y-3 pt-2">
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => handleNodeSizeChange(1.1)}
                  className="flex items-center justify-center py-2 px-3 rounded bg-gray-50 hover:bg-gray-100"
                  title="Increase node size"
                >
                  <Plus className="w-4 h-4 text-gray-600" />
                  <span className="ml-2 text-sm">Size +</span>
                </button>

                <button
                  onClick={() => handleNodeSizeChange(0.9)}
                  className="flex items-center justify-center py-2 px-3 rounded bg-gray-50 hover:bg-gray-100"
                  title="Decrease node size"
                >
                  <Minus className="w-4 h-4 text-gray-600" />
                  <span className="ml-2 text-sm">Size -</span>
                </button>

                <button
                  onClick={handleNodeColorChange}
                  className="flex items-center justify-center py-2 px-3 rounded bg-gray-50 hover:bg-gray-100"
                  title="Change node color"
                >
                  <Paintbrush className="w-4 h-4 text-gray-600" />
                  <span className="ml-2 text-sm">Color</span>
                </button>
              </div>

              <div className="flex items-center gap-2">
                <label htmlFor="maxPapers" className="text-sm font-medium text-gray-700">
                  Max Papers:
                </label>
                <input
                  id="maxPapers"
                  type="number"
                  value={maxPapers}
                  onChange={(e) => handleMaxPapersChange(Number(e.target.value))}
                  className="flex-1 px-2 py-1 border border-gray-300 rounded text-sm"
                  min={1}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GraphControls;