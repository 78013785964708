import React from 'react';
import PaperItem from '../PaperList/PaperItem';
import { Paper } from './types';
import { paperToSourceInfo } from './utilsGraph';

interface PaperItemWithOriginProps {
    paper: Paper;
    isOriginPaper?: boolean;
    onSetAsOrigin?: (paper: Paper) => Promise<void>;
    onRemoveOrigin?: (paperId: string) => void;
    handleCheckboxChange: (paperId: string) => void;
    handleSavePaper: (paperId: string) => Promise<void>;
    isAddingPaper: { [key: string]: boolean };
    selectedPapersToSave: Set<string>;
    projectId: string;
}

const PaperItemWithOrigin = ({
    paper,
    isOriginPaper = false,
    onSetAsOrigin,
    onRemoveOrigin,
    handleCheckboxChange,
    handleSavePaper,
    isAddingPaper,
    selectedPapersToSave,
    projectId
}: PaperItemWithOriginProps) => {
    const [settingOrigin, setSettingOrigin] = React.useState(false);

    const handleSetAsOrigin = async () => {
        if (!onSetAsOrigin) return;
        setSettingOrigin(true);
        try {
            await onSetAsOrigin(paper);
        } finally {
            setSettingOrigin(false);
        }
    };

    const customButtons = React.useMemo(() => {
        const buttons = [];

        // Add "Set as origin" button if not origin paper
        if (!isOriginPaper && onSetAsOrigin) {
            buttons.push({
                name: "Set as Origin",
                onClick: () => handleSetAsOrigin()
            });
        }

        // Add "Remove origin" button if it is origin paper
        if (isOriginPaper && onRemoveOrigin) {
            buttons.push({
                name: "Remove Origin",
                onClick: () => onRemoveOrigin(paper.paperId)
            });
        }

        return buttons;
    }, [isOriginPaper, onSetAsOrigin, onRemoveOrigin, paper.paperId]);

    return (
        <div className="flex-1">
            <PaperItem
                paper={paperToSourceInfo(paper)}
                isLibrary={false}
                handleChat={() => { }}
                handleDelete={() => { }}
                handleSave={() => handleSavePaper(paper.paperId)}
                selectedSourceIds={Array.from(selectedPapersToSave)}
                handleCheckboxChange={() => handleCheckboxChange(paper.paperId)}
                customColumns={[]}
                customColumnData={{}}
                loadingColumns={{
                    save: isAddingPaper[paper.paperId],
                    origin: settingOrigin
                }}
                projectId={projectId}
                customButtons={customButtons}
                showCheckbox={false}
            />
        </div>
    );
};

export default PaperItemWithOrigin;