import "./AnswerThisChat.css";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import assistant from "/img/assistant.png";
import { sendMessageToChat, getPdfPath, IDocument, SourceInfo, Section, getSources } from "../../authService";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { toast } from "sonner";
import React from "react";
import PdfViewer from "../../components/PdfViewer";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import SeoComp from "../../components/SeoComp";
import SplitView from "../../components/SplitView";
import SlicedText from "../../components/slicedText";
import { fetchChats, FetchReviewResult } from "@/HelperUtils/AnswerFetcher";
import AnswerChatView from "@/components/AnswerChatView";


const AnswerThisChat = () => {
    const [message, setMessage] = useState<string>("");
    const bottomRef = useRef<HTMLDivElement | null>(null);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const { id, chatIndex } = useParams<{ id: string, chatIndex: string }>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [docs, setDocs] = useState<IDocument[]>([]);
    const [filteredDoc, setFilteredDoc] = useState<IDocument | null>(null);
    const [papertoggled, setPapertoggled] = useState<IDocument | null>(null);
    const [reviewData, setReviewData] = useState<FetchReviewResult | null>(null);
    const [sentRerun, setSetRerun] = useState<boolean>(false);
    const [shouldScroll, setShouldScroll] = useState<boolean>(false);

    const MemoizedPdfViewer = React.memo(PdfViewer);
    const [activeHighlight, setActiveHighlight] = useState<{
        sectionId: string;
        content: string;
    } | null>(null);

    const findSectionInSources = (id: string): { section: Section; source: SourceInfo } | null => {
        if (!reviewData) return null;

        for (const answer of reviewData.answers) {
            const sources = answer.answerObject.sources.result;
            for (const sourceId in sources) {
                const source = sources[sourceId];
                const section = source.sections.find(s => s.id === id);
                if (section) {
                    return { section, source };
                }
            }
        }
        return null;
    };

    const handleCitationClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const isLink = target.tagName === 'A' && target.getAttribute('href')?.startsWith('#');

        if (isLink) {
            e.preventDefault();
            const id = target.getAttribute('href')!.substring(1);
            const result = findSectionInSources(id);

            if (result) {
                const { section, source } = result;

                // Switch to the correct PDF if necessary
                const correspondingDoc = docs.find(doc =>
                    doc.uri === getPdfPath(source)
                );

                if (correspondingDoc) {
                    setFilteredDoc(correspondingDoc);
                    setPapertoggled(correspondingDoc);

                    // Set the active highlight with the section content
                    setActiveHighlight({
                        sectionId: section.id,
                        content: section.content
                    });

                    // Scroll the PDF viewer to the top to show the highlighted text banner
                    const pdfViewer = document.querySelector('.react-doc-viewer');
                    if (pdfViewer) {
                        pdfViewer.scrollTop = 0;
                    }
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleCitationClick);
        return () => {
            document.removeEventListener('click', handleCitationClick);
        };
    }, [reviewData, docs]);
    const fetchData = async () => {
        try {
            const result = await fetchChats(chatIndex!);
            const papers: { [key: string]: SourceInfo } = await getSources(chatIndex!);
            setReviewData(result);
            const allPdfPaths = Object.values(papers).map((source: SourceInfo) => ({
                title: source.title,
                pdf_path: source.pdf_path,
                sourceInfoObj: source,
            }));
            const validDocs = allPdfPaths
                .filter((paper) => paper.pdf_path && paper.pdf_path !== "None")
                .map((paper) => {
                    return {
                        uri: getPdfPath(paper.sourceInfoObj),
                        fileName: paper.title
                    };
                });

            setDocs(validDocs);
            setFilteredDoc(validDocs[0]);
            setPapertoggled(validDocs[0]);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (chatIndex) {
            console.log("Fetching data...");
            fetchData();
        }
    }, [chatIndex, sentRerun]);

    // Add useEffect to scroll to bottom when reviewData updates
    useEffect(() => {
        if (reviewData?.answers && reviewData.answers.length > 0 && shouldScroll) {
            scrollToBottom();
            setShouldScroll(false);
        }
    }, [reviewData, shouldScroll]);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };


    const chat = async (e: React.FormEvent) => {
        e.preventDefault();
        const inputMessage = inputRef.current?.value;
        if (!inputMessage) return;
        setIsTyping(true);
        scrollToBottom();
        try {
            await sendMessageToChat(inputMessage, chatIndex!);
            setShouldScroll(true);
            setSetRerun(!sentRerun)
            setIsTyping(false);
            inputRef.current!.value = ''; // Clear input after message is sent
        } catch (error) {
            console.error(error);
            toast.error("Error sending message");
            inputRef.current!.value = ''; // Clear input on error as well
        }
    };

    const handleTextSelection = async () => {
        const selectedText = window.getSelection()?.toString() || '';
        if (selectedText == '') {
            toast.error("Select text from PDF")
            return
        }
        setIsTyping(true);
        scrollToBottom();
        setMessage("");
        try {
            await sendMessageToChat("Explain the text here: " + selectedText, chatIndex!);
            setShouldScroll(true);
            setSetRerun(!sentRerun)
            setIsTyping(false);
        } catch (error) {
            toast.error("Error sending message");
            console.error(error);
        }
    };



    const truncateString = (str: string, num: number) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const handleToggleClick = (paper: IDocument) => {
        setPapertoggled(paper);
        setFilteredDoc(paper);
        console.log("handling this", [paper])
    };

    // Memoize the PdfViewer component to prevent unnecessary re-renders
    const memoizedPdfViewer = React.useMemo(() => (
        <MemoizedPdfViewer
            documents={docs}
            filteredDoc={filteredDoc}
            activeHighlight={activeHighlight}
        />
    ), [docs, filteredDoc, activeHighlight]);

    const handleAnswerUpdate = (updatedAnswer: string, answerIndex: number) => {
        if (!reviewData) return;

        setReviewData(prevData => {
            if (!prevData) return null;

            const updatedAnswers = [...prevData.answers];
            updatedAnswers[answerIndex] = {
                ...updatedAnswers[answerIndex],
                formattedAnswer: updatedAnswer,
                answerObject: {
                    ...updatedAnswers[answerIndex].answerObject,
                    answer: updatedAnswer
                }
            };

            return {
                ...prevData,
                answers: updatedAnswers
            };
        });
    };

    return (
        <>
            <SeoComp />
            <div className="h-screen-header-tabs md:mt-0">
                <SplitView
                    leftTitle="Document Viewer"
                    minimizeForMobile={true}
                    leftComponent={
                        <div className="flex p-4 flex-col h-full">
                            <div className="pt-[10px] px-4 md:flex md:flex-col md:justify-center">
                                <label className="text-[16px] font-medium text-brandMaroon w-full flex min-h-12">
                                    <div className="min-w-max">Selected paper:</div>
                                    <div className="font-bold text-black ml-4">
                                        {papertoggled ? <SlicedText text={papertoggled.fileName} maxLength={40} /> : ""}
                                    </div>
                                </label>
                                <div className="flex mt-3 items-center max-w-[100%] gap-4 flex-wrap">
                                    {docs.length > 1 && docs.map((paper, index) => (
                                        <div key={index} className="flex flex-col items-center mb-2 relative group min-w-max">
                                            <button
                                                onClick={() => handleToggleClick(paper)}
                                                className={`rounded-full p-2 ${papertoggled == paper ? "toggle-button-selected toggle-button" : "toggle-button"}`}
                                            >
                                                {truncateString(paper.fileName, 20)}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className="max-h-[65vh] lg:max-h-[72vh] overflow-y-scroll my-4">
                                    {memoizedPdfViewer}
                                </div>
                            </div>
                            <div className="flex flex-col justify-end items-end">
                                <button className="toggle-button mt-2 mb-4 max-w-max" onClick={handleTextSelection}>Explain selected text</button>
                            </div>
                        </div>
                    }
                    rightTitle="Chat"
                    rightComponent={
                        <ChatComponent
                            id={id!}
                            bottomRef={bottomRef}
                            chat={chat}
                            isTyping={isTyping}
                            message={message}
                            inputRef={inputRef}
                            chatIndex={chatIndex!}
                            reviewData={reviewData!}
                            handleAnswerUpdate={handleAnswerUpdate}
                        />
                    }
                />
            </div>
        </>
    );
}

export default AnswerThisChat;



interface ChatInputFormProps {
    chat: ChatProps['chat'];
    message: string;
    inputRef: React.RefObject<HTMLInputElement>;
}



function ChatInputForm({ chat, inputRef }: ChatInputFormProps) {
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                chat(e);
            }}
            className="flex items-center"
        >
            <input
                type="text"
                ref={inputRef}
                placeholder="Chat with the paper..."
                autoComplete="off"
                className="flex-grow px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-brandMaroon focus:border-transparent"
            />
            <button
                type="submit"
                className="px-4 py-2 bg-brandMaroon text-white rounded-r-lg hover:bg-brandMaroonDark transition-colors duration-200"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                </svg>
            </button>
        </form>
    );
}


interface ChatProps {
    id: string;
    bottomRef: React.MutableRefObject<HTMLDivElement | null>;
    chat: (e: React.FormEvent<Element>) => Promise<void>;
    isTyping: boolean;
    message: string;
    inputRef: React.RefObject<HTMLInputElement>;
    chatIndex: string;
    reviewData: FetchReviewResult;
    handleAnswerUpdate: (updatedAnswer: string, answerIndex: number) => void;
}
export const ChatComponent: React.FC<ChatProps> = ({
    id,
    bottomRef,
    chat,
    isTyping,
    message,
    inputRef,
    chatIndex,
    reviewData,
    handleAnswerUpdate
}) => {

    return (
        <div className="flex flex-col h-full overflow-hidden">
            <div className="flex-grow overflow-y-auto px-4">
                {reviewData?.answers.map((answer, index) => (
                    <AnswerChatView
                        id={id!}
                        answer={answer}
                        index={index}
                        chatIndex={chatIndex}
                        handleAnswerUpdate={handleAnswerUpdate}
                        key={index.toString()}
                    />
                ))}
                {isTyping && (
                    <div className="flex justify-start mb-4">
                        <div className="flex items-end max-w-[80%]">
                            <img src={assistant} alt="Assistant Icon" className="h-8 w-8 rounded-full" />
                            <div className="mx-2 p-3 bg-white text-gray-800 rounded-lg rounded-bl-none">
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={bottomRef}></div>
            </div>
            <div className="p-4 bg-white border-t border-gray-200">
                <ChatInputForm
                    chat={chat}
                    message={message}
                    inputRef={inputRef}
                />
            </div>
        </div>
    );
};
