import { SourceInfo } from '../../authService';
export const CITATION_STYLES = ['apa', 'mla', 'chicago', 'harvard', 'vancouver', 'ieee'] as const;
export type citationStyles = typeof CITATION_STYLES[number];

const toSentenceCase = (str: string): string => {
    if (!str) return '';
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
};

const ensureDOILink = (doi: string): string => {
    // If DOI is present, ensure it's in the form https://doi.org/xxxxx
    if (!doi) return '';
    return doi.startsWith('http') ? doi : `https://doi.org/${doi}`;
};

/**
 * Parse authors from a string.
 * Input: "John A. Smith, Jane B. Doe, Alex Johnson"
 * Output: Array of strings ["John A. Smith", "Jane B. Doe", "Alex Johnson"]
 */
const parseAuthors = (authorString: string): string[] => {
    if (!authorString) return [];
    return authorString
        .split(',')
        .map(a => a.trim())
        .filter(a => a.length > 0);
};

/**
 * Extract last name and initials from a full name.
 * E.g., "John Andrew Smith" -> { lastName: "Smith", initials: "J. A." }
 */
const getLastNameAndInitials = (fullName: string): { lastName: string; initials: string } => {
    const parts = fullName.split(/\s+/).filter(Boolean);
    if (parts.length === 0) return { lastName: '', initials: '' };
    const lastName = parts[parts.length - 1];
    const givenNames = parts.slice(0, -1);
    const initials = givenNames.map(g => g.charAt(0).toUpperCase() + '.').join(' ');
    return { lastName, initials };
};

/**
 * Format authors for APA style.
 * APA 7th edition:
 * - 1 author: "Last, F. M."
 * - 2 authors: "Last, F. M., & Last, F. M."
 * - 3–20 authors: list all with commas, and an ampersand before the last author
 * - >20 authors: list first 19 authors, then ellipses (...), then final author
 */
const formatAuthorAPA = (authorString: string): string => {
    const authors = parseAuthors(authorString);
    const count = authors.length;

    if (count === 0) return '';

    // Extract (Last, F. M.) format for each author
    const formattedAuthors = authors.map(author => {
        const { lastName, initials } = getLastNameAndInitials(author);
        return `${lastName}, ${initials}`.trim();
    });

    if (count === 1) {
        return formattedAuthors[0];
    } else if (count === 2) {
        return `${formattedAuthors[0]} & ${formattedAuthors[1]}`;
    } else if (count <= 20) {
        return formattedAuthors.slice(0, -1).join(', ') + ', & ' + formattedAuthors[formattedAuthors.length - 1];
    } else {
        // More than 20 authors:
        // First 19 authors, then ellipses, then last author
        return formattedAuthors.slice(0, 19).join(', ') + ', ... ' + formattedAuthors[formattedAuthors.length - 1];
    }
};

/**
 * Format authors for MLA style.
 * MLA 9th edition:
 * - 1 author: "Last, First M."
 * - 2 authors: "Last, First M., and First M. Last"
 * - 3+ authors: "Last, First M., et al."
 */
const formatAuthorMLA = (authorString: string): string => {
    const authors = parseAuthors(authorString);
    const count = authors.length;

    if (count === 0) return '';

    const formatOne = (author: string): string => {
        // "John A. Smith" -> "Smith, John A."
        const parts = author.split(/\s+/);
        if (parts.length === 1) return parts[0]; // single name
        const lastName = parts[parts.length - 1];
        const firstNames = parts.slice(0, -1).join(' ');
        return `${lastName}, ${firstNames}`;
    };

    if (count === 1) {
        return formatOne(authors[0]);
    } else if (count === 2) {
        // First: "Smith, John A." 
        // Second: "Jane B. Doe" -> "Jane B. Doe" (not inverted except first author)
        const firstAuthorFormatted = formatOne(authors[0]);
        const secondAuthor = authors[1];
        return `${firstAuthorFormatted}, and ${secondAuthor}`;
    } else {
        // First author inverted, then et al.
        const firstAuthorFormatted = formatOne(authors[0]);
        return `${firstAuthorFormatted}, et al.`;
    }
};

const formatAuthorVancouver = (authorString: string): string => {
    const authors = parseAuthors(authorString);
    const count = authors.length;

    if (count === 0) return '';

    const formatOne = (author: string): string => {
        const { lastName, initials } = getLastNameAndInitials(author);
        // Vancouver uses no periods and no spaces between initials
        const formattedInitials = initials.replace(/\.\s*/g, '').replace(/\s+/g, '');
        return `${lastName} ${formattedInitials}`;
    };

    const formattedAuthors = authors.map(formatOne);

    if (count <= 6) {
        return formattedAuthors.join(', ');
    } else {
        // First 6 authors + et al.
        return formattedAuthors.slice(0, 6).join(', ') + ', et al';
    }
};


/**
 * Format authors for Chicago (Author-Date) and Harvard styles.
 * We'll do a simplified version:
 * Chicago/Harvard generally: "Last, First M." and join multiple authors with commas,
 * and use "and" before the last author. For >10 authors, use et al.
 * 
 * Harvard and Chicago often have subtle differences, but we'll use a common simplified approach:
 */
const formatAuthorChicagoHarvard = (authorString: string): string => {
    const authors = parseAuthors(authorString);
    const count = authors.length;

    if (count === 0) return '';

    const formatOne = (author: string): string => {
        // "John Andrew Smith" -> "Smith, John A."
        const parts = author.split(/\s+/);
        if (parts.length === 1) return parts[0];
        const lastName = parts[parts.length - 1];
        const firstNames = parts.slice(0, -1).map(n => n[0].toUpperCase() + n.slice(1)).join(' ');
        return `${lastName}, ${firstNames}`;
    };

    const formattedAuthors = authors.map(a => formatOne(a));

    if (count <= 10) {
        if (count === 1) return formattedAuthors[0];
        if (count === 2) return `${formattedAuthors[0]} and ${formattedAuthors[1]}`;
        return formattedAuthors.slice(0, -1).join(', ') + ', and ' + formattedAuthors[formattedAuthors.length - 1];
    } else {
        // More than 10 authors:
        return formattedAuthors[0] + ', et al.';
    }
};
/**
 * Format authors for IEEE style.
 * IEEE style:
 * - Uses initials followed by last name (e.g., "J. A. Smith")
 * - For 1-6 authors, list all authors
 * - For >6 authors, list first author followed by "et al."
 */
const formatAuthorIEEE = (authorString: string): string => {
    const authors = parseAuthors(authorString);
    const count = authors.length;

    if (count === 0) return '';

    const formatOne = (author: string): string => {
        const { lastName, initials } = getLastNameAndInitials(author);
        // IEEE uses periods and spaces between initials
        return `${initials} ${lastName}`;
    };

    const formattedAuthors = authors.map(formatOne);

    if (count <= 6) {
        return formattedAuthors.join(', ');
    } else {
        // First author + et al.
        return `${formattedAuthors[0]} et al.`;
    }
};
const getLastName = (authorString: string): string => {
    const authors = parseAuthors(authorString);
    if (authors.length === 0) return '';
    const firstAuthor = authors[0];
    const parts = firstAuthor.split(' ');
    return parts[parts.length - 1];
};

const formatAuthor = (authorString: string, style: citationStyles): string => {
    switch (style) {
        case 'apa':
            return formatAuthorAPA(authorString);
        case 'mla':
            return formatAuthorMLA(authorString);
        case 'chicago':
        case 'harvard':
            return formatAuthorChicagoHarvard(authorString);
        case 'vancouver':
            return formatAuthorVancouver(authorString);
        case 'ieee':
            return formatAuthorIEEE(authorString);
        default:
            return authorString;
    }
};

export const formatCitation = (source: SourceInfo, style: citationStyles, index?: number): string => {
    const year = source.date_of_publication ? new Date(source.date_of_publication).getFullYear() : 'n.d.';
    const formattedAuthor = formatAuthor(source.author, style);
    const doi = ensureDOILink(source.doi || '');
    const journal = source.journal ? source.journal.trim() : '';
    const title = source.title ? source.title.trim() : '';
    const volume = '';
    const issue = '';
    const pages = '';

    const titleAPA = toSentenceCase(title); // APA requires sentence case for titles.
    switch (style) {
        case 'apa':
            // APA 7th:
            // Author(s). (Year). Title of article. Journal Title, volume(issue), pages. DOI
            // Journal Title and Volume are italicized in official APA, but we're using plain text:
            // If pages are available: "JournalTitle, volume(issue), pages."
            // If issue is present: "volume(issue)"
            // If no issue: "volume"
            // Example:
            // Smith, J. A., & Doe, R. (2020). A comprehensive study on neural networks. Journal of Advanced Computing, 12(3), 45–67. https://doi.org/10.xxxx
            return `${formattedAuthor} (${year}). ${titleAPA}${journal ? `. ${journal}` : ''}${volume ? `, ${volume}${issue ? `(${issue})` : ''}` : ''}${pages ? `, ${pages}` : ''}${doi ? `. ${doi}` : ''}`;

        case 'mla':
            // MLA 9th:
            // Author(s). "Title of Article." Journal Title, vol. Volume, no. Issue, Year, pp. Pages. DOI
            // If no volume/issue/pages, omit them.
            // Example:
            // Smith, John A. "A Comprehensive Study on Neural Networks." Journal of Advanced Computing, vol. 12, no. 3, 2020, pp. 45–67. https://doi.org/10.xxxx
            return `${formattedAuthor}. "${title}"${journal ? ` ${journal},` : ''}${volume ? ` vol. ${volume},` : ''}${issue ? ` no. ${issue},` : ''}${year !== 'n.d.' ? ` ${year},` : ''}${pages ? ` pp. ${pages}.` : '.'}${doi ? ` ${doi}` : ''}`;
        case 'vancouver':
            return `${formattedAuthor}. ${title}. ${journal}. ${year}${volume ? `;${volume}` : ''}${pages ? `:${pages}` : ''}.${doi ? ` ${doi}` : ''}`;
        case 'chicago':
            // Chicago Author-Date:
            // Author(s). Year. "Title of Article." Journal Title volume(issue): pages. DOI
            // Example:
            // Smith, John A., and Jane B. Doe. 2020. "A Comprehensive Study on Neural Networks." Journal of Advanced Computing 12(3): 45–67. https://doi.org/10.xxxx
            return `${formattedAuthor}. ${year}. "${title}". ${journal}${volume ? ` ${volume}` : ''}${issue ? `(${issue})` : ''}${pages ? `: ${pages}` : ''}.${doi ? ` ${doi}` : ''}`;

        case 'harvard':
            // Harvard style (Author-Date):
            // Author(s) (Year) 'Title of article', Journal Title, volume(issue), pages. Available at: DOI [Accessed date].
            // Example:
            // Smith, J. A., and Doe, J. (2020) 'A comprehensive study on neural networks', Journal of Advanced Computing, 12(3), pp. 45–67. Available at: https://doi.org/10.xxxx [Accessed 10 December 2024].
            return `${formattedAuthor} (${year}) '${title}', ${journal}${volume ? `, ${volume}${issue ? `(${issue})` : ''}` : ''}${pages ? `, pp. ${pages}` : ''}.` +
                (doi ? ` Available at: ${doi}` : '') +
                ` [Accessed ${new Date().toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })}].`;
        case 'ieee':
            // IEEE style:
            // [N] Author(s), "Title of article," Journal Title, vol. Volume, no. Issue, pp. Pages, Month Year. DOI
            // - Title is in quotation marks
            // - Journal name is italicized (but we're using plain text)
            // - Volume, issue, and page numbers have specific abbreviations
            // Example:
            // [1] J. A. Smith and R. B. Doe, "A comprehensive study on neural networks," Journal of Advanced Computing, vol. 12, no. 3, pp. 45-67, Mar. 2020. https://doi.org/10.xxxx
            return `${index !== undefined ? `[${index + 1}] ` : ''}${formattedAuthor}, "${title},"${journal ? ` ${journal},` : ''}${volume ? ` vol. ${volume},` : ''}${issue ? ` no. ${issue},` : ''}${pages ? ` pp. ${pages},` : ''} ${year}.${doi ? ` ${doi}` : ''}`;

        default:
            // A fallback, possibly numeric reference style:
            return index !== undefined
                ? `[${index + 1}] ${formattedAuthor}. ${title}. ${journal}${year ? `, ${year}` : ''}${doi ? `. ${doi}` : ''}`
                : `${formattedAuthor}. ${title}. ${journal}${year ? `, ${year}` : ''}${doi ? `. ${doi}` : ''}`;
    }
};

export const getInTextCitation = (source: SourceInfo, style: citationStyles, index?: number): string => {
    const year = source.date_of_publication ? new Date(source.date_of_publication).getFullYear() : 'n.d.';
    const lastName = getLastName(source.author);

    switch (style) {
        case 'ieee':
            // IEEE always uses numbered references [1]
            return index !== undefined ? `[${index + 1}]` : '[?]';
        case 'apa':
            // (LastName, Year)
            return `(${lastName}, ${year})`;
        case 'mla':
            // (LastName Year)
            return `(${lastName} ${year})`;
        case 'chicago':
            // Chicago Author-Date: (LastName Year)
            // If using footnotes with numeric references, might show [N].
            return index !== undefined ? `[${index + 1}]` : `(${lastName} ${year})`;
        case 'harvard':
            // (LastName, Year)
            return `(${lastName}, ${year})`;
        case 'vancouver':
            // Vancouver always uses numbered references [1]
            return index !== undefined ? `[${index + 1}]` : '[?]';
        default:
            return index !== undefined ? `[${index + 1}]` : `(${lastName}, ${year})`;
    }
};
