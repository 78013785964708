import React from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { buttonClass } from './Buttons/buttonClasses';

interface ProFeatureButtonProps {
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
    retainChildStyle?: boolean;
}

export function ProFeatureButton({ onClick, children, className = buttonClass, retainChildStyle = false }: ProFeatureButtonProps) {
    const { isPro } = useAuth();
    const navigate = useNavigate();
    const { id } = useParams();

    const handleClick = () => {
        if (isPro && onClick) {
            onClick();
        } else {
            navigate(`/${id}/pricing`);
        }
    };

    if (retainChildStyle) {
        return (
            <div onClick={handleClick} className="inline-flex items-center">
                {children}
                {!isPro && (
                    <span className="ml-2 px-2 py-0.5 text-xs rounded-sm bg-red-50 text-red-900 font-medium">
                        UPGRADE
                    </span>
                )}
            </div>
        );
    }

    return (
        <button onClick={handleClick} className={className}>
            {children}
            {!isPro && (
                <span className="ml-2 px-2 py-0.5 text-xs rounded-sm bg-red-50 text-red-900 font-medium">
                    UPGRADE
                </span>
            )}
        </button>
    );
} 