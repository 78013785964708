export const plans = {
    'Free Forever': {
        name: 'Free Forever',
        discretion: 'Get a feel for AnswerThis with our free plan.',
        price: {
            monthly: 0,
            annually: 0,
            annualWithOffer: "0",
            offer: "",
            annualLink: {
                regular: "",
            },
            monthlyLink: {
                regular: "",
            },
        },
        features: [
            '5 credits / month',
            'Limited to top 3 references',
            'Limited Line-by-line citations',
            'Limited AI writer',
            'Limited Paper Search',
            'Limited Library',
            'Limited Projects',
            '1 User'
        ],
        tooltip: [
            'Monthly credit allowance for generating answers',
            'Access to top 3 most relevant references for your queries',
            'Limited access to in-line citations',
            'Basic AI writing assistance with limitations',
            'Limited paper search functionality',
            'Basic library features with limitations',
            'Limited project organization features',
            'Single user access'
        ]
    },
    'Passionate researchers': {
        name: 'Passionate researchers',
        discretion: 'Advanced features for full time researchers.',
        price: {
            monthly: 35,
            annually: 35,
            annualWithOffer: "12",
            offer: "65% off",
            annualLink: {
                regular: "https://answerthis.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=AnswerThis-PRO-Plan-USD-Yearly&utm_source=cb-app-copy",
            },
            monthlyLink: {
                regular: "https://answerthis.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=AnswerThis-PRO-Plan-USD-Monthly&utm_source=cb-app-copy",
            },
        },
        features: [
            'Unlimited references',
            'Line-by-line citations',
            'Export Results',
            'Search for Papers',
            'AI writer',
            'Library',
            'Projects',
            '1 User'
        ],
        tooltip: [
            'Access to unlimited references for comprehensive research',
            'In-line citations for your literature reviews',
            'Export your answers as CSV, PDF',
            'Advanced paper search functionality',
            'Full AI writing capabilities',
            'Complete library features',
            'Organize your work under different projects',
            'Single user access'
        ]
    },

    'Student Discount': {
        name: 'Passionate researchers',
        discretion: 'Advanced features for full time researchers.',
        price: {
            monthly: 17,
            annually: 17,
            annualWithOffer: "8",
            offer: "65% off",
            annualLink: {
                regular: "https://answerthis.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=AnswerThis-Student-Discount-Offer-USD-Yearly&utm_source=cb-app-copy"
            },
            monthlyLink: {
                regular: "https://answerthis.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=AnswerThis-Student-Discount-Offer-USD-Monthly&utm_source=cb-app-copy"
            }
        },
        features: ['Unlimited references', 'Line-by-line citations', 'Export Results', 'Search for Papers ', 'AI writer', 'Library', 'Projects', '1 User'],
        tooltip: ['Use AnswerThis to get Literature reviews based on published papers with In-line citations', '', '', 'You can export the answer as CSV, PDF', 'Search for papers from our library', 'Let AI help with your writing', 'Maintain your own library for quicker access to research papers', 'Be more organized by compiling your work under different projects', '', '']
    },
    'Enterprise': {
        name: 'Enterprise',
        discretion: 'For Enterprises who wants to handle large traffic.',
        price: {
            monthly: "3,300+",
            annually: "3,300",
            annualWithOffer: "1,650+",
            offer: "50% off",
            annualLink: {
                regular: "",
            },
            monthlyLink: {
                regular: "",
            },


        },
        features: ['Unlimited references', 'Line-by-line citations', 'Export Results', 'Search for Papers ', 'AI writer', 'Library', 'Projects', 'Min 150 users, 11$ / User '],
        tooltip: ['Use AnswerThis to get Literature reviews based on published papers with In-line citations', '', '', 'You can export the answer as CSV, PDF', 'Search for papers from our library', 'Let AI help with your writing', 'Maintain your own library for quicker access to research papers', 'Be more organized by compiling your work under different projects', '', '']
    },
};

export const DEFAULT_PLAN = 'Passionate researchers';
export const DEFAULT_DURATION = 'monthly';