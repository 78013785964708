import React, { useState, useEffect } from 'react';
import {
    Settings,
    BookOpen,
    Search,
    Layout,
    Wand2,
    PenTool,
    Folders,
    HelpCircle,
    FileText
} from 'lucide-react';
import { WriterDocument } from './types';
import { useDocument } from './documentService';
import { toast } from 'sonner';
import ScrollableTabLayout from './ScrollableTabLayoutComponent';
import { SourceInfo } from '@/authService';
import { Editor } from '@tiptap/core';
import ChatInterface from './ChatAssistant';
import EditorLibraryModal from './EditorLibraryModal';
import SearchPapersComponent from './SearchPapers';
import { OutlineModal, OutlineToAnswerModal, UpdateSectionModal } from './EditorModals';
import { citationStyles } from '@/pages/Answer/citationUtils';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { DocumentSettings } from './DocumentSettings';
import { ContinueWritingModal } from './ContinueWritingModal';
import { HelpAndGuidesContent } from './HelpAndGuidesContent';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';

interface RecentsSidebarProps {
    projectId: string;
    activeDocument: WriterDocument | null;
    citationHandler: ((source: SourceInfo) => void) | null;
    editor: Editor;
    citationStyle: citationStyles;
    isMobile?: boolean;
}

const RecentsSidebar: React.FC<RecentsSidebarProps> = ({
    projectId,
    activeDocument,
    citationHandler,
    editor,
    citationStyle,
    isMobile = false
}) => {
    const [editTitle, setEditTitle] = useState(activeDocument?.title || '');
    const [editDescription, setEditDescription] = useState(activeDocument?.description || '');
    const [isPublic, setIsPublic] = useState(activeDocument?.is_public || false);
    const navigate = useNavigate();

    const { updateDocument } = useDocument(activeDocument?.id || '');

    useEffect(() => {
        if (activeDocument) {
            setEditTitle(activeDocument.title);
            setEditDescription(activeDocument.description);
            setIsPublic(activeDocument.is_public);
        }
    }, [activeDocument]);

    const handleUpdateDocument = async () => {
        if (!activeDocument) return;

        try {
            await updateDocument({
                title: editTitle,
                description: editDescription,
                is_public: isPublic,
                ...(activeDocument.content && { content: activeDocument.content }),
            });
            toast.success('Document updated');
        } catch (error) {
            console.error('Error updating document:', error);
            toast.error('Failed to update document');
        }
    };

    const handleViewAllDocuments = () => {
        navigate(`/${projectId}/ask-answerthis?tab=documents`);
    };

    // Mock function for HelpAndGuidesContent
    const handleDocumentCreated = (doc: WriterDocument) => {
        navigate(`/${projectId}/doc/${doc.id}`);
    };

    const tabs = [

        {
            value: "assistant",
            label: "Assistant",
            content: <ChatInterface editor={editor} />,
            icon: BookOpen,
            description: "Traditional AnswerThis Review"
        },
        {
            value: "generateOutline",
            label: "Generate Outline",
            content: <OutlineModal
                editor={editor}
                projectId={projectId}
                activeDocument={activeDocument}
                citationStyle={citationStyle}
            />,
            icon: Layout,
            description: "Create Outline For Topic"
        },
        {
            value: "outlineToAnswer",
            label: "Full Outline to Answer",
            content: <OutlineToAnswerModal
                editor={editor}
                activeDocument={activeDocument}
                citationStyle={citationStyle}
                projectId={projectId}
            />,
            icon: PenTool,
            description: "Convert Outline to Full Answer"
        },
        {
            value: "aiEdit",
            label: "AI Edit",
            content: <Tabs defaultValue="edit" className="w-full">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="edit">Edit Selection</TabsTrigger>
                    <TabsTrigger value="continue">Continue Writing</TabsTrigger>
                </TabsList>
                <TabsContent value="edit">
                    <UpdateSectionModal editor={editor} projectId={projectId!} citationStyle={citationStyle} />
                </TabsContent>
                <TabsContent value="continue">
                    <ContinueWritingModal editor={editor} projectId={projectId} citationStyle={citationStyle} />
                </TabsContent>
            </Tabs>,
            icon: Wand2,
            description: "Edit section using AI"
        },
        {
            value: "library",
            label: "Library",
            content: <EditorLibraryModal citationHandler={citationHandler} />,
            icon: Folders,
            description: "Add citations from your library"
        },
        {
            value: "searchPapers",
            label: "Search Papers",
            content: <SearchPapersComponent citationHandler={citationHandler} />,
            icon: Search,
            description: "Find new papers"
        },
        {
            value: "help",
            label: "Help & Guides",
            content: <HelpAndGuidesContent projectId={projectId} onDocumentCreated={handleDocumentCreated} />,
            icon: HelpCircle,
            description: "Learn how to use the editor and access guides"
        },
        {
            value: "settings",
            label: "Settings",
            content: <DocumentSettings
                activeDocument={activeDocument!}
                editDescription={editDescription}
                editTitle={editTitle}
                handleUpdateDocument={handleUpdateDocument}
                isPublic={isPublic}
                setEditDescription={setEditDescription}
                setEditTitle={setEditTitle}
                setIsPublic={setIsPublic}
            />,
            icon: Settings,
            description: "Configure document settings"
        },
        {
            value: "documentManager",
            label: "Documents",
            content: (
                <div className="p-4">
                    <Button
                        onClick={handleViewAllDocuments}
                        className="w-full mb-4 flex items-center justify-center gap-2"
                    >
                        <FileText size={16} />
                        View All Documents
                    </Button>
                </div>
            ),
            icon: FileText,
            description: "Manage your documents"
        },
    ];

    return (
        <div className={`h-full ${isMobile ? 'mobile-sidebar' : ''}`}>
            <ScrollableTabLayout
                tabs={tabs}
                defaultTab="generateOutline"
                isMobile={isMobile}
            />
        </div>
    );
};

export default RecentsSidebar;





