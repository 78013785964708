import React from 'react';

export const SkeletonLoader: React.FC = () => {
    const shimmerStyle = {
        backgroundImage: 'linear-gradient(to right, #e0e0e0 0%, #eaeaea 50%, #e0e0e0 100%)',
        backgroundSize: '200% 100%',
        animation: 'shimmer 1.5s infinite',
    };

    const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `;

    return (
        <>
            <style>{shimmerKeyframes}</style>
            <div className="space-y-4">
                <div className="h-8 rounded" style={shimmerStyle}></div>
                <div className="space-y-2">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="h-6 rounded" style={shimmerStyle}></div>
                    ))}
                </div>
            </div>
        </>
    );
};

