import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react'
import { contactUs, isIntercomAvailable } from '../../authService'
import { toast } from 'sonner'
import SeoComp from '@/components/SeoComp'
import { showNewMessage } from '@intercom/messenger-js-sdk'
import { useAuth } from '@/contexts/AuthContext'

const Contact: FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    })
    const [isContactForm, setIsContactForm] = useState<boolean>(true)
    const formRef = useRef<HTMLDivElement | null>(null)
    const { user, loggedIn } = useAuth()
    useEffect(() => {
        if (loggedIn && user) {
            setFormData(prev => ({
                ...prev,
                name: user.name || "",
                email: user.email || "",
            }))
        }
    }, [loggedIn, user])
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (isIntercomAvailable()) {
            // Try to use Intercom if available
            const message = `${formData.message}`
            showNewMessage(message)
            toast.success("Message opened in Intercom chat!")
        } else {
            await contactUs(formData.name, formData.email, formData.message)
            toast.success("Your message has been sent successfully!")
        }

        // Clear form in either case
        setFormData({
            name: "",
            email: "",
            message: "",
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleRequestClick = () => {
        setIsContactForm(false)
        formRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleContactClick = () => {
        setIsContactForm(true)
        formRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            <SeoComp />
            <div className="mb-20 md:mb-20 font-Manrope">
                <div
                    className='pb-40'
                    style={{
                        overflow: 'hidden',
                        backgroundImage: "url('/img/gradient-bg.png')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center bottom',
                    }}
                >
                    <div className="flex flex-col gap-4 justify-center items-center mx-auto pt-12 md:pt-10">
                        <h1 className="mb-4 font-Manrope text-3xl md:text-4xl lg:text-[40px] 2xl:text-[2.2vw] font-semibold text-brandMaroon">
                            Get in touch with us
                        </h1>
                        <p className='text-md p-3'>
                            Your opinion is valuable to us. Please feel free to get in touch with us for any queries or feedback.
                        </p>
                    </div>
                    <div className='flex flex-col md:flex-row justify-center gap-6 w-[80%] mx-auto md:w-full mt-10 text-[16px] md:text-[18px] 2xl:text-[1.1vw]'>
                        <ContactUsCard
                            handleContactClick={handleRequestClick}
                            title="Requests"
                            description="We'd love to know what new features you would like to see AnswerThis provide."
                            buttonText="Request Feature"
                        />
                        <ContactUsCard
                            handleContactClick={handleContactClick}
                            title="Contact Us"
                            description="Have a query or feedback? Contact us and we will get back to you as soon as possible."
                            buttonText="Contact Us"
                        />
                        <ContactUsCard
                            handleContactClick={() => window.open('mailto:ayush@answerthis.io')}
                            title="Media"
                            description="We love working with journalists, send us a note and our Comms Manager will be in touch. Check out our media page here too!"
                            buttonText="Mail Us"
                        />
                        <ContactUsCard
                            handleContactClick={() => window.open('https://ayushgarg.youcanbookme.com/')}
                            title="Meeting"
                            description="If you want to directly book a time with us, please feel free to do so. We are always happy to meet new people and discuss ideas."
                            buttonText="Book Meeting"
                        />
                    </div>
                </div>

                <div className="md:mt-0 flex justify-center w-[90%] md:w-[40%] mx-auto" id='form' ref={formRef}>
                    <form
                        onSubmit={handleSubmit}
                        className="w-full font-Manrope"
                    >
                        <h1 className="mb-8 font-Manrope text-md font-semibold text-brandMaroon text-center">
                            {isContactForm ? "Contact Us" : "Request feature"}
                        </h1>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full px-4 py-2 bg-brandMaroon bg-opacity-30 rounded-md ring-none outline-none focus:outline-none focus:ring-[2px] focus:ring-brandMaroon duration-300 group"
                                required
                                placeholder=""
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="email"
                                className="block text-gray-700 font-medium mb-2"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full px-4 py-2 bg-brandMaroon bg-opacity-30 rounded-md ring-none outline-none focus:outline-none focus:ring-[2px] focus:ring-brandMaroon duration-300"
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="message"
                                className="block text-gray-700 font-medium mb-2"
                            >
                                {isContactForm ? "Message" : "Describe features"}
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full px-4 py-2 bg-brandMaroon bg-opacity-30 rounded-md ring-none outline-none focus:outline-none focus:ring-[2px] focus:ring-brandMaroon duration-300 overflow-y-auto"
                                rows={4}
                                required
                            ></textarea>
                        </div>
                        <button
                            className="w-full hover:bg-white text-[17px] font-medium hover:text-brandMaroon border-brandMaroon border py-2 px-4 rounded-lg bg-brandMaroon text-white transition duration-300"
                            type='submit'
                        >
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

interface ContactUsCardProps {
    handleContactClick: () => void
    title: string
    description: string
    buttonText: string
}

function ContactUsCard({ handleContactClick, title, description, buttonText }: ContactUsCardProps) {
    return (
        <div className='w-full md:max-w-[300px] md:w-[30%] 2xl:max-w-[500px] border-[1.5px] border-brandMaroon px-6 py-4 rounded-lg group flex flex-col justify-between bg-white'>
            <div>
                <h2 className='text-md mb-1'>
                    {title}
                </h2>
                <div className='border-t-[1.5px] border-brandMaroon duration-300'></div>
                <p className='my-4 text-base'>
                    {description}
                </p>
            </div>
            <div className='text-brandMaroon border-b-[1.5px] border-dashed max-w-max border-brandMaroon hover:border-solid hover:cursor-pointer duration-300'
                onClick={handleContactClick}>
                <p className='text-base'>
                    {buttonText}
                </p>
            </div>
        </div>
    )
}

export default Contact