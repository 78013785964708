import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
// import DOMPurify from 'dompurify';
import { saveAs } from 'file-saver';

import {
    Download, ZoomIn, ZoomOut, RotateCcw, Loader2,
    Code, ArrowLeft, ArrowRight, Check, X, Move
} from 'lucide-react';
import {
    getDiagram, updateDiagram,
    editDiagramCode,
} from './api';
import type { DiagramData } from './api';
import { toast } from 'sonner';
import { useProcessing } from '@/contexts/processingContext';

interface EmbeddedDiagramProps {
    diagramId: string;
    onDiagramUpdate?: (newData: DiagramData) => void;
}

export const EmbeddedDiagram: React.FC<EmbeddedDiagramProps> = ({
    diagramId,
    onDiagramUpdate
}) => {
    const { addTask, removeTask } = useProcessing();

    const [displayId, setDisplayId] = useState(diagramId);
    const [diagram, setDiagram] = useState<DiagramData | null>(null);
    const [revisions, setRevisions] = useState<DiagramData[]>([]);
    const [currentRevisionIndex, setCurrentRevisionIndex] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [updateText, setUpdateText] = useState('');
    const [zoomLevel, setZoomLevel] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isEditingPrompt, setIsEditingPrompt] = useState(false);
    const [isEditingCode, setIsEditingCode] = useState(false);
    const [editedCode, setEditedCode] = useState('');
    const imageRef = useRef<HTMLDivElement>(null);

    // MAIN EFFECT: load diagram by displayId
    useEffect(() => {
        const loadDiagram = async () => {
            try {
                setLoading(true);
                const taskId = addTask('Loading diagram');
                const response = await getDiagram(displayId);
                removeTask(taskId);

                if ('error' in response) {
                    throw new Error(response.error);
                }
                const diagramData = response as DiagramData;
                console.log(diagramData);
                setDiagram(diagramData);
                setEditedCode(diagramData.mermaid_code || diagramData.code || '');
                setUpdateText(diagramData.changes_requested || diagramData.prompt);

                if (diagramData.version_history) {
                    setRevisions(diagramData.version_history);
                    setCurrentRevisionIndex(diagramData.version_history.length - 1);
                }

            } catch (error) {
                toast.error((error as Error).message);
            } finally {
                setLoading(false);
            }
        };
        loadDiagram();
    }, [displayId]);

    // If parent changes diagramId, reset displayId
    useEffect(() => {
        setDisplayId(diagramId);
    }, [diagramId]);

    const handleSwitchRevision = (index: number) => {
        const revision = revisions[index];
        setCurrentRevisionIndex(index);
        setDisplayId(revision.id);
    };

    const handleUpdateDiagram = async () => {
        if (!updateText.trim()) {
            toast.error('Please enter update instructions');
            return;
        }
        try {
            setLoading(true);
            const taskId = addTask('Updating diagram');
            const response = await updateDiagram(diagramId, updateText);
            removeTask(taskId);

            if ('error' in response) {
                throw new Error(response.error);
            }
            const newDiagram = response as DiagramData;
            setDisplayId(newDiagram.id);
            setIsEditingPrompt(false);
            onDiagramUpdate?.(newDiagram);
            toast.success('Diagram updated successfully');
        } catch (error) {
            toast.error((error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveCode = async () => {
        try {
            setLoading(true);
            const taskId = addTask('Saving code');
            const response = await editDiagramCode(displayId, editedCode);
            removeTask(taskId);

            if ('error' in response) {
                throw new Error(response.error);
            }

            const updatedDiagram = response as DiagramData;
            setDisplayId(updatedDiagram.id);
            setIsEditingCode(false);
            toast.success('Code updated successfully');
        } catch (error) {
            toast.error('Failed to update code');
        } finally {
            setLoading(false);
        }
    };

    // ====== DIAGRAM PAN LOGIC (for the entire diagram container) ======
    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.button === 0) {
            setIsDragging(true);
            setDragStart({
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            });
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - dragStart.x,
                y: e.clientY - dragStart.y,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleDownload = async () => {
        const taskId = addTask('Downloading diagram');

        try {
            if (diagram?.code) {
                // Attempt to parse SVG
                let parser = new DOMParser();
                let doc = parser.parseFromString(diagram.code, 'image/svg+xml');

                // Check parse errors
                const parserErrors = doc.getElementsByTagName('parsererror');
                if (parserErrors.length > 0) {
                    console.warn('SVG contains invalid XML. Attempting to fix by escaping ampersands...');
                    const escapedSvg = diagram.code.replace(
                        /&(?!(amp;|lt;|gt;|quot;|apos;))/g,
                        '&amp;'
                    );
                    parser = new DOMParser();
                    doc = parser.parseFromString(escapedSvg, 'image/svg+xml');
                }

                // Serialize
                const serializer = new XMLSerializer();
                const validSvgString = serializer.serializeToString(doc.documentElement);

                // Create Blob
                const blob = new Blob([validSvgString], { type: 'image/svg+xml' });
                saveAs(blob, `diagram-${diagramId}.svg`);
            } else {
                toast.error('No diagram code found');
            }
            toast.success('Diagram downloaded successfully');
        } catch (error) {
            console.error('Failed to download diagram:', error);
            toast.error('Failed to download diagram');
        } finally {
            removeTask(taskId);
        }
    };

    const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.25, 3));
    const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.25, 0.5));
    const handleResetZoom = () => {
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });
    };

    if (isEditingCode) {
        return (
            <div className="w-full max-w-4xl mx-auto space-y-4">
                <div className="flex justify-between items-center">
                    <Button
                        variant="ghost"
                        onClick={() => setIsEditingCode(false)}
                        className="text-sm"
                    >
                        <ArrowLeft className="h-4 w-4 mr-2" />
                        Back
                    </Button>
                    <div className="space-x-2">
                        <Button
                            variant="outline"
                            onClick={() => setEditedCode(diagram && diagram.mermaid_code || diagram && diagram.code || '')}
                            className="text-sm"
                        >
                            <X className="h-4 w-4 mr-2" />
                            Reset
                        </Button>
                        <Button
                            onClick={handleSaveCode}
                            disabled={loading}
                            className="bg-brandMaroon text-white hover:bg-brandMaroon/90"
                        >
                            <Check className="h-4 w-4 mr-2" />
                            Save Changes
                        </Button>
                    </div>
                </div>
                <Textarea
                    value={editedCode}
                    onChange={(e) => setEditedCode(e.target.value)}
                    className="font-mono h-[500px] resize-none"
                    placeholder="Enter diagram code..."
                />
            </div>
        );
    }

    return (
        <div className="w-full max-w-4xl mx-auto space-y-4 font-Manrope">
            {/* -- Top Section (Prompt + controls) -- */}
            <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex items-start gap-4">
                    <div className="flex-grow">
                        {isEditingPrompt ? (
                            <textarea
                                onChange={(e) => setUpdateText(e.target.value)}
                                className="w-full h-20 p-2 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-brandMaroon"
                                placeholder="Describe your changes..."
                            />
                        ) : (
                            <div className="text-lg font-medium">
                                {(loading && 'Loading') || diagram?.prompt || 'No prompt available'}
                            </div>
                        )}
                    </div>
                    <div className="flex items-center gap-2">
                        {isEditingPrompt ? (
                            <>
                                <Button
                                    variant="outline"
                                    onClick={() => setIsEditingPrompt(false)}
                                    className="text-sm"
                                >
                                    <X className="h-4 w-4" />
                                </Button>
                                <Button
                                    onClick={handleUpdateDiagram}
                                    disabled={loading || !updateText.trim()}
                                    className="bg-brandMaroon text-white hover:bg-brandMaroon/90"
                                >
                                    {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Check className="h-4 w-4" />}
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={() => setIsEditingPrompt(true)}
                                className="bg-brandMaroon text-white hover:bg-brandMaroon/90"
                            >
                                Update Content
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            {/* -- Revisions Strip -- */}
            {revisions.length > 0 && (
                <div className="flex items-center gap-2 px-2">
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleSwitchRevision(currentRevisionIndex - 1)}
                        disabled={currentRevisionIndex === 0}
                    >
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                    <div className="text-sm text-gray-500">
                        Revision {currentRevisionIndex + 1} of {revisions.length}
                    </div>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleSwitchRevision(currentRevisionIndex + 1)}
                        disabled={currentRevisionIndex === revisions.length - 1}
                    >
                        <ArrowRight className="h-4 w-4" />
                    </Button>
                </div>
            )}

            {/* -- Main Diagram View -- */}
            <div className="relative bg-white rounded-lg shadow-sm overflow-hidden text-black">
                <ScrollArea className="h-[500px] w-full">

                    <div
                        ref={imageRef}
                        className="relative w-full h-full cursor-move"
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        style={{ color: 'black' }}
                    >

                        <div
                            style={{
                                transform: `translate(${position.x}px, ${position.y}px) scale(${zoomLevel})`,
                                transition: isDragging ? 'none' : 'transform 0.2s',
                            }}
                            className="flex justify-center items-center min-h-[500px]"
                        >
                            {diagram && diagram.code && <div
                                style={{ width: '100%' }}
                                dangerouslySetInnerHTML={{
                                    __html: diagram.code,
                                }}
                            />
                            }

                        </div>

                    </div>
                </ScrollArea>

                {/* -- Controls -- */}
                <div className="absolute top-4 right-4 flex gap-2">
                    <div className="flex items-center gap-1 bg-white/90 backdrop-blur-sm rounded-lg shadow-sm p-1">
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => setIsEditingCode(true)}
                            title="Edit Code"
                            className="hover:bg-gray-100"
                        >
                            <Code className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleZoomOut}
                            disabled={zoomLevel <= 0.5}
                            className="hover:bg-gray-100"
                        >
                            <ZoomOut className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleResetZoom}
                            className="hover:bg-gray-100"
                        >
                            <RotateCcw className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleZoomIn}
                            disabled={zoomLevel >= 3}
                            className="hover:bg-gray-100"
                        >
                            <ZoomIn className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="hover:bg-gray-100"
                        >
                            <Move className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleDownload}
                            className="hover:bg-gray-100"
                        >
                            <Download className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default EmbeddedDiagram;
