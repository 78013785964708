import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { logInUserGoogle, handleRedirectMainPage, redirectToOriginalPage } from '../authService';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'sonner';
import { useAuth } from '../contexts/AuthContext';
// import { serverUrl } from '../authService'
const client_id = import.meta.env.VITE_GOOGLE_CLIENT_ID;
interface GoogleLoginComponentProps {
}

const GoogleLoginComponent: React.FC<GoogleLoginComponentProps> = () => {

    console.log(client_id);
    const navigate = useNavigate();
    const location = useLocation();
    const { refreshUserInfo } = useAuth();
    return <GoogleOAuthProvider clientId={client_id}>
        <GoogleLogin
            onSuccess={async credentialResponse => {
                await logInUserGoogle(credentialResponse.credential!,
                    async () => {

                        await refreshUserInfo();
                        if (location.state && location.state.from) {
                            await handleRedirectMainPage(navigate);
                        } else {
                            await redirectToOriginalPage(navigate, location.state);
                        }

                    },
                    (errorMessage) => toast.error(errorMessage))
            }}

            onError={() => {
                console.log('Login Failed');
            }}
            useOneTap


        /></GoogleOAuthProvider>
}

export default GoogleLoginComponent;