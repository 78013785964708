import { FC, useEffect } from 'react'
import { useState } from "react";
import "./Faq.css";
import { Link } from 'react-router-dom';
import { marked } from 'marked';
interface FaqProps {
    id: string | undefined;
}

const Faq: FC<FaqProps> = ({ id }) => {
    console.log(id)
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number | any>(null);

    const toggleAnswer = (index: number) => {
        if (selectedQuestionIndex === index) {
            setSelectedQuestionIndex(null);
        } else {
            setSelectedQuestionIndex(index);
        }
    };

    const [faqData, setFaqData] = useState<{ question: string; answer: string; htmlAnswer?: string }[]>([


        {
            question: "What are the benefits of billing annually?",
            answer: "Billing annually offers significant discounts over monthly billing."
        },
        {
            question: "Can I upgrade my plan at any time?",
            answer: "Yes, you can upgrade your plan at any time through your account settings. Upgrades are pro-rated, ensuring you only pay for what you use."
        },
        {
            question: "What happens if I exceed my plan's limits?",
            answer: "Passionate Researchers plan has unlimited access. Enterprise users can contact support for custom solutions."
        },

        {
            question: "Is there a free trial available to test AnswerThis?",
            answer: "Yes, we offer a free tier that includes 5 credits to try out our service without any charge."
        },
        {
            question: "What is the cancellation policy?",
            answer: "You can cancel your subscription at any time from your account settings. The cancellation will take effect at the end of your current billing cycle."
        },
        {
            question: "How secure is my payment information with AnswerThis?",
            answer: "We use industry-standard encryption and compliance measures to ensure your payment information is securely handled."
        },
    ]);

    useEffect(() => {
        // Convert markdown to HTML for each FAQ answer
        const updateFaqData = async () => {
            const updatedFaqData = await Promise.all(faqData.map(async (faq) => ({
                ...faq,
                htmlAnswer: await marked(faq.answer)
            })));
            setFaqData(updatedFaqData);
        };
        updateFaqData();
    }, []);
    return (
        <div id="faq" className="mt-5 md:mt-5 font-Manrope">
            <div className="rounded-xl bg-white md:flex w-full py-10">
                <div className="md:w-[40%] px-10 md:pl-20">
                    <h2 className="mb-4 text-2xl md:text-3xl lg:text-4xl font-semibold text-brandMaroon">
                        Frequently asked questions
                    </h2>
                    <p className="text-lg font-normal text-gray-900 mt-6">
                        Get more information about the product and the services we provide
                        by taking a look at the FAQs or feel free to <Link className=' border-b border-dashed hover:border-solid border-brandMaroon duration-300 mx-1 text-brandMaroon font-semibold' to={(id ? `/${id}/contact-us` : "/contact-us")}>
                            contact us.
                        </Link>
                    </p>
                </div>
                <div className="md:w-[60%] md:pl-10 mt-10 md:mt-0 text-[16px] md:text-[18px] lg:text-[1.3vw]">
                    <div className="space-y-4">
                        {faqData.map((item, index) => (
                            <div key={index} className="px-6 md:px-10">
                                <hr className="border-t-[1px] border-black"></hr>
                                <div
                                    className="flex justify-between px-2 md:px-4 mt-4 hover:cursor-pointer"
                                    onClick={() => toggleAnswer(index)}
                                >
                                    <h2 className="w-[90%] font-semibold  text-[18px]">
                                        {item.question}
                                    </h2>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className={`w-6 h-6 transition-transform duration-300 transform ${selectedQuestionIndex === index ? "rotate-45" : ""
                                            }`}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 4.5v15m7.5-7.5h-15"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={`faq-answer ${selectedQuestionIndex === index ? "open" : ""
                                        }`}
                                >
                                    <p
                                        className="my-2 text-[17px] font-medium mx-2 md:mx-4 md:border-l border-brandMaroon md:pl-4"
                                        dangerouslySetInnerHTML={{ __html: item.htmlAnswer! }}
                                    ></p>                                </div>
                            </div>
                        ))}
                        <hr className="border-t-[1px] border-black mx-6 md:mx-10"></hr>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq