import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { handleRedirectMainPage } from "../../authService";
import Spinner from "../Spinner";
import { useAuth } from "../../contexts/AuthContext";

interface ProtectedRouteProps {
  children: ReactElement;
}

const UnProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const { loggedIn } = useAuth();

  if (loggedIn === true) {
    handleRedirectMainPage(navigate);
  }
  if (loggedIn === null) {
    return <Spinner />;
  }
  if (loggedIn === false) {
    return children;
  }

};

export default UnProtectedRoute;
