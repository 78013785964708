// import { useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface Props {
  children: JSX.Element;
}

export const FadeIn = ({ children }: Props) => {
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ ease: "easeInOut", duration: 0.8 }}
      >
        {children}
      </motion.div>
    </div>
  );
};
