import React, { useRef, useEffect } from 'react';
import { SearchIcon } from 'lucide-react';

interface SearchBarProps {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleSearch: () => Promise<void> | void;
    query: string;
    placeholder?: string;
    submitIcon?: React.ReactNode;
    disabled?: boolean;
    compactUi?: boolean;
}

export function SearchBar({
    handleInputChange,
    handleSearch,
    query,
    placeholder = "Enter your search query",
    submitIcon = <SearchIcon className="text-white" size={20} />,
    disabled = false,
    compactUi = false
}: SearchBarProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const adjustHeight = () => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';

            const maxHeight = window.innerWidth <= 768
                ? window.innerHeight * 0.3
                : window.innerHeight * 0.15;

            const newHeight = Math.max(
                48,
                Math.min(inputRef.current.scrollHeight, maxHeight)
            );

            inputRef.current.style.height = `${newHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [query]);

    useEffect(() => {
        const handleResize = () => adjustHeight();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTextareaInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        handleInputChange(event);
        adjustHeight();
    };

    return (
        <form
            className="w-full"
            action="submit"
            onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
            }}
        >
            <div className="relative group">
                {/* Strong gradient glow effect */}
                <div className="absolute -inset-1 bg-gradient-to-r from-brandMaroon/50 via-purple-500/50 to-brandMaroon/50 rounded-2xl blur-lg opacity-70 group-hover:opacity-100 transition-opacity duration-200" />

                {/* Main search container with solid brandMaroon border */}
                <div className="relative flex items-center bg-white border-2 border-brandMaroon/50 rounded-2xl focus-within:ring-2 focus-within:ring-brandMaroon/50">
                    <textarea
                        ref={inputRef}
                        value={query}
                        onChange={handleTextareaInput}
                        placeholder={placeholder}
                        className="w-full px-6 focus:outline-none resize-none rounded-2xl text-gray-700 align-middle leading-normal bg-transparent py-3"
                        rows={1}
                        style={{
                            minHeight: '48px',
                            overflowY: query.split('\n').length > 1 ? 'auto' : 'hidden'
                        }}
                    />
                    <button
                        type="submit"
                        disabled={disabled}
                        className={`absolute right-2 top-1/2 -translate-y-1/2  bg-brandMaroon hover:bg-brandMaroon/90 rounded-full flex items-center justify-center transition-colors duration-200 ${compactUi ? 'h-8 w-8' : 'h-10 w-10'}`}
                    >
                        {submitIcon}
                    </button>
                </div>
            </div>
        </form>
    );
}

export default SearchBar;