import { useState, useEffect } from "react";
import "./SearchPapers.css";
import {
  SourceInfo,
  searchForPapers,
  Filters,
  defaultFiltersSearch
} from "../../authService";
import { useParams } from "react-router-dom";
import PaperList from "../../components/PaperList/PaperList";
import { toast } from "sonner";
import SeoComp from "../../components/SeoComp";
import FilterSidebar from "../../components/filters/FiltersSidebar";
import { useAddToLibrary } from "../../components/PaperList/useAddToLibrary";
import { SearchBar } from "../../components/searchInput/SearchBar";
import { useAuth } from "@/contexts/AuthContext";

function SearchPapers() {
  const { id } = useParams();
  const cacheKey = `searchPapers_${id}`;
  const { checkCredits, removeCredits } = useAuth();

  // Load initial state from localStorage if available
  const loadInitialState = () => {
    // const cached = localStorage.getItem(cacheKey);
    // if (cached) {
    //   const { query: cachedQuery, results, filters } = JSON.parse(cached);
    //   return {
    //     query: cachedQuery || "",
    //     searchResult: results || [],
    //     filters: filters || defaultFiltersSearch
    //   };
    // }
    return {
      query: "",
      searchResult: [],
      filters: defaultFiltersSearch
    };
  };

  const initialState = loadInitialState();
  const [query, setQuery] = useState<string>(initialState.query);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SourceInfo[]>(initialState.searchResult);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtersInfo, setFiltersInfo] = useState<Filters>(initialState.filters);
  const { selectedSourceIds, handleCheckboxChange, addToLibrary } = useAddToLibrary(id!);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    const stateToCache = {
      query,
      results: searchResult,
      filters: filtersInfo
    };
    localStorage.setItem(cacheKey, JSON.stringify(stateToCache));
  }, [query, searchResult, filtersInfo, id]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSearchQuery = async (query: string) => {
    try {
      setOpenFilters(false);
      setIsLoading(true);
      const papers = await searchForPapers(query, id!, filtersInfo);
      setSearchResult(papers);
      setIsLoading(false);
    } catch (error) {
      if (error instanceof Error && error.message !== "No input in search bar") {
        toast.error("Failed to perform search. Please try again.");
      }
    }
  };

  const handleSearch = async () => {
    const hasEnoughCredits = checkCredits(id!, 1);
    if (!hasEnoughCredits) {
      toast.error("You don't have enough credits to perform this action.");
      return;
    }
    if (query == "") {
      toast.error("Enter what you are looking for before hitting the search button !!");
      throw new Error("No input in search bar");
    }
    handleSearchQuery(query);
    await removeCredits(1);
  };

  return (
    <div>
      <SeoComp />
      <div className="mt-[5px] md:mt-0 w-full">
        <div className=" font-Manrope mt-0 md:mt-5">
          <div className="w-[100%] font-Manrope">
            <div className="px-6">
              <SearchBar
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleInputChange(event as React.ChangeEvent<HTMLInputElement>);
                }}
                handleSearch={handleSearch}
                query={query}
              />
            </div>

            <div className="mx-auto mt-3 px-6">
              <button
                className="border-[1.5px] border-black bg-brandMaroon py-1 px-4 rounded-lg font-medium text-white flex items-center"
                onClick={() => {
                  setOpenFilters((prev) => !prev);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                  />
                </svg>
                Filters
              </button>
              {openFilters && (
                <div className="">
                  <FilterSidebar
                    filters={filtersInfo}
                    setFilters={setFiltersInfo}
                    isOpen={openFilters}
                    onClose={() => setOpenFilters(false)}
                    onApply={handleSearch}
                    isSearchingPapers={true}
                    isSidebar={false}
                    showTopBar={false}
                  />
                </div>
              )}
            </div>
            {searchResult[0] == null && !isLoading ? (
              <div className=" font-Manrope text-[20px] md:text-[24px] mt-4 ml-6 ">
                {" "}
                Search in our vast database to get all the relevant papers
              </div>
            ) : (
              ""
            )}
            {isLoading ? (
              <div className="ml-6 mt-6 font-Manrope text-[22px] font-normal">
                Fetching the research papers for you . . . <div className="spinner"></div>
              </div>
            ) : (
              <>
                {searchResult[0] ? (
                  <>
                    <PaperList
                      papers={searchResult}
                      handleCheckboxChange={handleCheckboxChange}
                      isLoading={isLoading}
                      selectedSourceIds={selectedSourceIds}
                      emptyMessage=""
                      handleSave={addToLibrary}
                      id={id!}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchPapers;