import React, { useState, useEffect } from 'react';
import { X, ChevronDown, ChevronUp, PlusCircle, Building, GraduationCap, Globe, Settings, Zap } from 'lucide-react';
import { AnswerStyle, Filters } from '../../authService';
import { Info } from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { Slider } from "@/components/ui/slider";

interface FilterSidebarProps {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    isOpen: boolean;
    onClose: () => void;
    onApply: () => void;
    isSidebar?: boolean;
    showSubmitButton?: boolean;
    isSearchingPapers?: boolean;
    showTopBar?: boolean;
    showAnswerType?: boolean;
    showTurboMode?: boolean;
    showDoubleCheckCitations?: boolean;
    showCustomSections?: boolean;
}

interface FilterCheckboxProps {
    name: string;
    value: string;
    label: string;
    icon: React.ReactNode;
    checked: boolean | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const journalQualityLabels = ["Q1", "Q2", "Q3", "Q4", "All"];

const FilterSidebar: React.FC<FilterSidebarProps> = ({
    filters,
    setFilters,
    isOpen,
    onClose,
    onApply,
    isSidebar = true,
    showSubmitButton = true,
    isSearchingPapers = false,
    showTopBar = true,
    showAnswerType = true,
    showTurboMode = true,
    showDoubleCheckCitations = true,
    showCustomSections = true
}) => {
    const [expandedSections, setExpandedSections] = useState({
        answerStyle: true,
        citations: true,
        database: true,
        date: true,
        output: true,
        sections: true,
        doubleCheckCitations: true,
        isFastMode: true,
        journalQuality: true,
    });
    const [newSection, setNewSection] = useState<string>("");
    const [showResearchPapersSettings, setShowResearchPapersSettings] = useState(false);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'unset';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const toggleSection = (section: keyof typeof expandedSections) => {
        setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const checked = (e.target as HTMLInputElement).checked;

        if (name === "internetFilter") {
            setFilters(prev => {
                let newInternetFilter: ("all" | "gov" | "edu")[] = [...(prev.internetFilter || [])];
                if (value === "all") {
                    newInternetFilter = checked ? ["all"] : [];
                } else {
                    if (checked) {
                        newInternetFilter = newInternetFilter.filter(f => f !== "all");
                        newInternetFilter.push(value as "gov" | "edu");
                    } else {
                        newInternetFilter = newInternetFilter.filter(f => f !== value);
                    }
                    if (newInternetFilter.length === 0) {
                        newInternetFilter = ["all"];
                    }
                }
                return { ...prev, internetFilter: newInternetFilter };
            });
        } else if (name === "isFastMode") {
            setFilters(prev => ({ ...prev, isFastMode: checked }));
        } else {
            setFilters(prev => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };

    const handleAddSection = () => {
        if (newSection.trim()) {
            setFilters(prev => ({
                ...prev,
                customSections: [...(prev.customSections || []), newSection.trim()],
            }));
            setNewSection("");
        }
    };

    const handleRemoveSection = (index: number) => {
        setFilters(prev => ({
            ...prev,
            customSections: prev.customSections?.filter((_, i) => i !== index),
        }));
    };

    const handleSourceToggle = (source: string) => {
        setFilters(prev => {
            const newDatabase = prev.database?.includes(source)
                ? prev.database.filter(db => db !== source)
                : [...(prev.database || []), source];

            let newResearchPapersFilter = prev.researchPapersFilter;

            if (source === "research papers") {
                if (newDatabase.includes("research papers")) {
                    // If research papers is being added, select all subfilters
                    newResearchPapersFilter = ["semanticscholar", "openalex", "pubmed", "arxiv"];
                } else {
                    // If research papers is being removed, clear the subfilters
                    newResearchPapersFilter = [];
                }
            }

            return {
                ...prev,
                database: newDatabase,
                researchPapersFilter: newResearchPapersFilter
            };
        });
    };

    const handleAnswerStyleChange = (style: AnswerStyle) => {
        setFilters(prev => {
            // Create new filters object with all previous values
            const newFilters = { ...prev };

            // Update answer style
            newFilters.answerStyle = style;

            // Update database settings based on style
            if (style === 'ai_only') {
                newFilters.database = [];
                newFilters.researchPapersFilter = [];
            } else if (!newFilters.database?.length) {
                // Only set default database if current database is empty
                newFilters.database = ['research papers'];
                newFilters.researchPapersFilter = ['semanticscholar', 'openalex'];
            }

            return newFilters;
        });
    };

    const handleResearchPaperSubfilterToggle = (subfilter: "semanticscholar" | "openalex" | "pubmed" | "arxiv") => {
        console.log(subfilter);
        setFilters(prev => {
            const newResearchPapersFilter = prev.researchPapersFilter?.includes(subfilter)
                ? prev.researchPapersFilter.filter(f => f !== subfilter)
                : [...(prev.researchPapersFilter || []), subfilter];

            let newDatabase = [...(prev.database || [])];
            if (newResearchPapersFilter.length > 0 && !newDatabase.includes("research papers")) {
                newDatabase.push("research papers");
            } else if (newResearchPapersFilter.length === 0 && newDatabase.includes("research papers")) {
                newDatabase = newDatabase.filter(db => db !== "research papers");
            }

            return {
                ...prev,
                researchPapersFilter: newResearchPapersFilter,
                database: newDatabase
            };
        });
    };

    const handleJournalQualityChange = (value: number[]) => {
        const selectedQuality = value[0];
        if (selectedQuality === 5) {
            setFilters(prev => ({
                ...prev,
                journalQuality: ["Q1", "Q2", "Q3", "Q4"]
            }));
        } else {
            const selectedQualities = journalQualityLabels.slice(0, selectedQuality).filter(q => q !== "All");
            setFilters(prev => ({
                ...prev,
                journalQuality: selectedQualities
            }));
        }
    };

    const sourceOptions = [
        {
            category: "Research Papers",
            value: "research papers",
            subOptions: [
                { value: "semanticscholar", label: "Semantic Scholar" },
                { value: "openalex", label: "OpenAlex" },
                { value: "pubmed", label: "PubMed" },
                { value: "arxiv", label: "arXiv" },
            ]
        },
        {
            category: "Web",
            options: [
                { value: "internet", label: "Web Search" },
            ]
        },
        {
            category: "Other Sources",
            options: [
                { value: "patent", label: "Patents" },
                { value: "your library", label: "My Library", hideIfSearchingPapers: true },
            ]
        }
    ];

    const ResearchPapersSettings: React.FC = () => (
        <div className="mt-4 bg-gray-100 rounded-lg p-3">
            <p className="font-semibold mb-2 text-sm text-gray-700">Research Paper Sources:</p>
            <div className="flex flex-col gap-2">
                {sourceOptions[0]?.subOptions?.map((option) => (
                    <FilterCheckbox
                        key={option.value}
                        name="researchPaperSources"
                        value={option.value}
                        label={option.label}
                        icon={null}
                        checked={filters.researchPapersFilter?.includes(option.value as any) || false}
                        onChange={() => handleResearchPaperSubfilterToggle(option.value as any)}
                    />
                ))}
            </div>
        </div>
    );

    const InternetFilterSection: React.FC = () => (
        <div className="mt-4 bg-gray-100 rounded-lg p-3">
            <p className="font-semibold mb-2 text-sm text-gray-700">Internet Filter:</p>
            <div className="flex flex-col gap-2">
                <FilterCheckbox
                    name="internetFilter"
                    value="all"
                    label="All websites"
                    icon={<Globe size={16} />}
                    checked={filters.internetFilter?.includes("all") || !filters.internetFilter || filters.internetFilter.length === 0}
                    onChange={handleFilterChange}
                />
                <FilterCheckbox
                    name="internetFilter"
                    value="gov"
                    label=".gov (Government)"
                    icon={<Building size={16} />}
                    checked={filters.internetFilter?.includes("gov")}
                    onChange={handleFilterChange}
                />
                <FilterCheckbox
                    name="internetFilter"
                    value="edu"
                    label=".edu (Education)"
                    icon={<GraduationCap size={16} />}
                    checked={filters.internetFilter?.includes("edu")}
                    onChange={handleFilterChange}
                />
            </div>
        </div>
    );

    const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ name, value, label, icon, checked, onChange }) => (
        <label className="flex items-center space-x-2 cursor-pointer">
            <input
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className="hidden"
            />
            <div className={`w-5 h-5 border-2 rounded flex items-center justify-center ${checked ? 'bg-brandMaroon border-brandMaroon' : 'border-gray-400'}`}>
                {checked && <svg className="w-3 h-3 text-white fill-current" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z" /></svg>}
            </div>
            <div className="flex items-center space-x-2">
                {icon}
                <span className={`text-sm ${checked ? 'text-brandMaroon font-medium' : 'text-gray-700'}`}>{label}</span>
            </div>
        </label>
    );

    const content = <div className="flex flex-col h-full">
        {showTopBar && (
            <div className="flex justify-between items-center p-4 border-b">
                <h2 className="text-xl font-bold">Research Paper Filters</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X size={24} />
                </button>
            </div>)}

        <div className="flex-grow overflow-y-auto p-4 space-y-4">
            {/* Answer Style Section */}
            {!isSearchingPapers && showAnswerType && (
                <div className="border-b pb-2">
                    <button
                        className="flex justify-between items-center w-full text-left font-semibold py-2"
                        onClick={() => toggleSection('answerStyle')}
                    >
                        Answer Type
                        {expandedSections.answerStyle ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    {expandedSections.answerStyle && (
                        <div className="mt-4 space-y-3">
                            <TooltipProvider>
                                {[
                                    {
                                        value: 'literature_review',
                                        label: 'Structured Literature Review',
                                        tooltip: 'Strict literature review mode that provides structured, citation-rich answers'
                                    },
                                    {
                                        value: 'research_assistant',
                                        label: 'Dynamic Research Assistant',
                                        tooltip: 'Interactive mode with citations and research context useful for finding research gaps, research questions, etc.'
                                    },
                                    // {
                                    //     value: 'ai_only',
                                    //     label: 'AI Only (Very fast, no citations)',
                                    //     tooltip: 'Standard AI responses like ChatGPT, Claude, etc.'
                                    // }
                                ].map((option) => (
                                    <div key={option.value}>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div
                                                    className="flex items-center space-x-2 cursor-pointer"
                                                    onClick={() => handleAnswerStyleChange(option.value as AnswerStyle)}
                                                >
                                                    <input
                                                        type="radio"
                                                        checked={filters.answerStyle === option.value}
                                                        readOnly
                                                        className="w-4 h-4"
                                                    />
                                                    <label className="flex items-center">
                                                        {option.label}
                                                        <Info className="w-4 h-4 ml-2 text-gray-500" />
                                                    </label>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent className="max-w-sm">
                                                <p className="text-sm">{option.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </div>
                                ))}
                            </TooltipProvider>
                        </div>
                    )}
                </div>
            )}
            {/* Citation Count Section */}
            {filters.answerStyle !== 'ai_only' && (
                <div className="border-b pb-2">
                    <button
                        className="flex justify-between items-center w-full text-left font-semibold py-2"
                        onClick={() => toggleSection('citations')}
                    >
                        Minimum Citations
                        {expandedSections.citations ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    {expandedSections.citations && (
                        <div className="mt-2">
                            <input
                                type="range"
                                min="0"
                                max="20"
                                name="minCitationCount"
                                value={filters.minCitationCount}
                                onChange={handleFilterChange}
                                className="w-full"
                                style={{
                                    "--track-left-color": "#A53E5A",
                                    "--track-right-color": "#F9B0C2",
                                    "--track-fill-percentage": `${(((filters.minCitationCount as number) - 0) / (20 - 0)) * 100}%`,
                                } as React.CSSProperties} />
                            <div className="text-right">{filters.minCitationCount}</div>
                        </div>
                    )}
                </div>)}

            {/* Turbo Mode Section */}
            {!isSearchingPapers && filters.answerStyle !== 'ai_only' && showTurboMode && (
                <div className="border-b pb-2">
                    <button
                        className="flex justify-between items-center w-full text-left font-semibold py-2"
                        onClick={() => toggleSection('isFastMode')}
                    >
                        Turbo Mode
                        {expandedSections.isFastMode ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    {expandedSections.isFastMode && (
                        <div className="mt-2">
                            <FilterCheckbox
                                name="isFastMode"
                                value="isFastMode"
                                label="Enable Turbo Mode"
                                icon={<Zap size={16} />}
                                checked={filters.isFastMode}
                                onChange={handleFilterChange}
                            />
                            <p className="text-xs text-gray-500 mt-1">
                                Turbo Mode provides faster results but may reduce comprehensiveness.
                            </p>
                        </div>
                    )}
                </div>
            )}

            {/* Database Section */}
            {(filters.answerStyle !== 'ai_only') && (
                <div className="border-b pb-2">
                    <button
                        className="flex justify-between items-center w-full text-left font-semibold py-2"
                        onClick={() => toggleSection('database')}
                    >
                        Database
                        {expandedSections.database ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    <BadgeAlert text='Select internet as well for more sources' />

                    {expandedSections.database && (
                        <div className="mt-2 space-y-4">
                            {sourceOptions.map((category, index) => (
                                <div key={index} className="space-y-2">
                                    <h3 className="font-semibold text-sm text-gray-700">{category.category}</h3>
                                    <div className="flex flex-wrap gap-2">
                                        {category.value === "research papers" ? (
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() => handleSourceToggle(category.value)}
                                                    className={`px-3 py-1 rounded-full text-sm ${filters.database?.includes(category.value)
                                                        ? 'bg-brandMaroon text-white'
                                                        : 'bg-gray-200 text-gray-700'
                                                        }`}
                                                >
                                                    Research Papers
                                                </button>
                                                <button
                                                    onClick={() => setShowResearchPapersSettings(!showResearchPapersSettings)}
                                                    className="ml-2 text-gray-600 flex  items-center text-sm gap-2 hover:text-gray-800"
                                                >
                                                    <Settings size={16} />
                                                    Choose Databases
                                                </button>
                                            </div>
                                        ) : (
                                            category?.options?.map((option) => (
                                                (!isSearchingPapers || !option.hideIfSearchingPapers) && (
                                                    <button
                                                        key={option.value}
                                                        onClick={() => handleSourceToggle(option.value)}
                                                        className={`px-3 py-1 rounded-full text-sm ${filters.database?.includes(option.value)
                                                            ? 'bg-brandMaroon text-white'
                                                            : 'bg-gray-200 text-gray-700'
                                                            }`}
                                                    >
                                                        {option.label}
                                                    </button>
                                                )
                                            ))
                                        )}
                                    </div>
                                    {category.value === "research papers" && showResearchPapersSettings && (
                                        <ResearchPapersSettings />
                                    )}
                                </div>
                            ))}
                            {filters.database?.includes("internet") && (
                                <InternetFilterSection />
                            )}
                        </div>
                    )}
                </div>)}

            {/* Journal Quality Section */}
            <div className="border-b pb-4">
                <div className="flex items-center gap-2 mb-4">
                    <span className="font-medium">Journal quality</span>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info className="h-4 w-4 text-gray-400" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Journal quartile rankings:<br />
                                    Q1: Top 25% journals<br />
                                    Q2: Top 25-50%<br />
                                    Q3: Top 50-75%<br />
                                    Q4: Bottom 25%</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <div className="w-[200px]">
                    <Slider
                        defaultValue={[filters.journalQuality?.length ? (filters.journalQuality.length == 4 ? 5 : filters.journalQuality.length) : 5]}
                        max={5}
                        min={1}
                        step={1}
                        onValueChange={handleJournalQualityChange}
                        className="w-full bg-lightMaroon"
                    />
                    <div className="flex justify-between mt-3">
                        {journalQualityLabels.map((label) => (
                            <span key={label} className="text-sm text-gray-600" style={{ width: '20px', textAlign: 'center' }}>
                                {label}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            {/* Publication Date Section */}
            {filters.answerStyle !== 'ai_only' && (<div className="border-b pb-2">
                <button
                    className="flex justify-between items-center w-full text-left font-semibold py-2"
                    onClick={() => toggleSection('date')}
                >
                    Publication Date
                    {expandedSections.date ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </button>
                {expandedSections.date && (
                    <div className="mt-2 space-y-2">
                        <div>
                            <label htmlFor="startDate" className="block">Start Date:</label>
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={filters.startDate}
                                onChange={handleFilterChange}
                                className="w-full border rounded p-1" />
                        </div>
                        <div>
                            <label htmlFor="endDate" className="block">End Date:</label>
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={filters.endDate}
                                onChange={handleFilterChange}
                                className="w-full border rounded p-1" />
                        </div>
                    </div>
                )}
            </div>)}

            {/* Double Check Citations Option */}
            {!isSearchingPapers && filters.answerStyle !== 'ai_only' && showDoubleCheckCitations && (
                <div className="border-b pb-2">
                    <button
                        className="flex justify-between items-center w-full text-left font-semibold py-2"
                        onClick={() => toggleSection('doubleCheckCitations')}
                    >
                        Double Check Citations
                        {expandedSections.doubleCheckCitations ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    {expandedSections.doubleCheckCitations && (
                        <div className="mt-2">
                            <FilterCheckbox
                                name="doubleCheckCitations"
                                value="doubleCheckCitations"
                                label="Enable double-checking of citations"
                                icon={null}
                                checked={filters.doubleCheckCitations}
                                onChange={handleFilterChange}
                            />
                            <p className="text-xs text-gray-500 mt-1">
                                This option may increase processing time but improves citation accuracy.
                            </p>
                        </div>
                    )}
                </div>)}

            {/* Custom Sections */}
            {filters.answerStyle === "literature_review" && !isSearchingPapers && showCustomSections && (
                <div className="border-b pb-2">
                    <button
                        className="flex justify-between items-center w-full text-left font-semibold py-2"
                        onClick={() => toggleSection('sections')}
                    >
                        Custom Sections
                        {expandedSections.sections ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    {expandedSections.sections && (
                        <div className="mt-2">
                            <p className="text-sm text-gray-600 mb-2">Add your custom sections below. Leave blank for AI-generated sections.</p>
                            <div className="flex items-center mb-2">
                                <input
                                    type="text"
                                    value={newSection}
                                    onChange={(e) => setNewSection(e.target.value)}
                                    placeholder="Enter a section name"
                                    className="flex-grow mr-2 p-2 border rounded" />
                                <button
                                    onClick={handleAddSection}
                                    className="p-2 bg-brandMaroon text-white rounded hover:bg-brandMaroon-dark"
                                >
                                    <PlusCircle size={20} />
                                </button>
                            </div>
                            <ul className="list-disc pl-5">
                                {filters.customSections?.map((section, index) => (
                                    <li key={index} className="flex items-center justify-between mb-2">
                                        <span>{section}</span>
                                        <button
                                            onClick={() => handleRemoveSection(index)}
                                            className="ml-2 text-red-500 hover:text-red-700"
                                        >
                                            <X size={16} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>

        {showSubmitButton && (<div className="p-4 border-t">
            <button
                onClick={() => {
                    onApply();
                    onClose();
                }}
                className="w-full bg-brandMaroon text-white py-2 rounded hover:bg-brandMaroon-dark"
            >
                Submit Search
            </button>
        </div>)}
    </div>;

    if (isSidebar) {
        return (
            <>
                {isOpen && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-40 backdrop-blur-sm"
                        onClick={onClose}
                    ></div>
                )}
                <div
                    className={`fixed top-0 right-0 h-full w-full md:w-96 bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
                >
                    {content}
                </div>
            </>
        );
    } else {
        return (
            <div className="bg-white shadow-lg rounded-lg">
                {isOpen && content}
            </div>
        );
    }
};

export default FilterSidebar;

function BadgeAlert({ text }: { text: string }) {
    return (
        <span className="inline-flex mx-2 items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">{text}</span>
    )
}