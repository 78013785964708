import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { checkIsLoggedIn, logInUser, logOutUser, UserInfo, serverUrl } from '../authService';
import Intercom from '@intercom/messenger-js-sdk';
import axios from 'axios';
import { toast } from 'sonner';
import { usePostHog } from 'posthog-js/react'

declare global {
  interface Window {
    fpr: (event: string, data: { email: string }) => void;
  }
}

// Interface for the pricing popup state
interface PricingPopupState {
  isOpen: boolean;
  projectId: string | null;
}

interface AuthContextType {
  user: UserInfo | null;
  loggedIn: boolean | null;
  isPro: boolean;
  isAdmin: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshUserInfo: () => Promise<void>;
  isLoading: boolean;
  isOnboardingCompleted: boolean;
  addCredits: (amount: number) => Promise<void>;
  removeCredits: (amount: number) => Promise<void>;
  checkCredits: (projectId: string, requiredCredits?: number, options?: { setRedirect?: boolean }) => boolean;
  pricingPopupState: PricingPopupState;
  closePricingPopup: () => void;
  togglePricingPopup: (projectId: string | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Initialize with default values that don't block rendering
  const [user, setUser] = useState<UserInfo | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean | null>(false); // Default to false instead of null
  const [isPro, setIsPro] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const posthog = usePostHog()

  const [pricingPopupState, setPricingPopupState] = useState<PricingPopupState>({
    isOpen: false,
    projectId: null
  });

  const AuthCheck = async () => {
    try {
      const responseLoggedIn = await retrieveIsloggedInWithRetry();
      setLoggedIn(responseLoggedIn.logged_in);
      if (responseLoggedIn.logged_in && responseLoggedIn.user) {
        setUser(responseLoggedIn.user);
        setIsPro(responseLoggedIn.user.level === 'paid');
        setIsAdmin(responseLoggedIn.user.email === 'themoviechannel77@gmail.com');
        setIsOnboardingCompleted(responseLoggedIn.user.onboarding_completed);
        try {
          sendEmailToFirstPromoter(responseLoggedIn.user.email);
        }

        catch (error) {
          console.error('Error in sending email to first promoter:', error);
        }
        try {
          posthog?.identify(responseLoggedIn.user.email, {
            email: responseLoggedIn.user.email,
            level: responseLoggedIn.user.level,
          })
        } catch (error) {
          console.error('Error in PostHog:', error);
        }

        try {
          // Initialize Intercom
          Intercom({
            app_id: 'wt0o8urb',
            user_id: responseLoggedIn.user.email,
            name: responseLoggedIn.user.name,
            email: responseLoggedIn.user.email,
            hide_default_launcher: true,
          });
        }
        catch (error) {
          console.error('Error in Intercom:', error);
        }
      } else {
        setUser(null);
        setIsPro(false);
        setIsAdmin(false);
      }
    } catch (error) {
      setLoggedIn(false);
      setUser(null);
      setIsPro(false);
      setIsAdmin(false);
      console.error('Error in quick auth check:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Perform auth check after initial render
  useEffect(() => {
    // Start the auth check without blocking rendering
    AuthCheck();

    // Set a timeout to ensure isLoading is reset even if the auth check hangs
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5 second maximum waiting time

    return () => clearTimeout(timeoutId);
  }, []);

  const retrieveIsloggedInWithRetry = async () => {
    const MAX_RETRIES = 2;
    const RETRY_DELAY = 2000; // 2 seconds

    for (let attempt = 0; attempt <= MAX_RETRIES; attempt++) {
      try {
        const response = await checkIsLoggedIn();
        // If we get a valid response, return it immediately
        return response;
      } catch (error) {
        // If this is our last attempt, throw the error
        if (attempt === MAX_RETRIES) {
          throw error;
        }

        // Otherwise, wait before trying again
        console.log(`Auth check attempt ${attempt + 1} failed, retrying in ${RETRY_DELAY}ms...`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      }
    }

    // This should never be reached due to the throw above, but TypeScript needs it
    throw new Error('Authentication check failed after all retries');
  };

  const sendEmailToFirstPromoter = (email: string) => {
    window.fpr('referral', { email: email });
  };

  const refreshUserInfo = async () => {
    await AuthCheck();
  };

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await logInUser(
        email,
        password,
        () => {
          refreshUserInfo();
        },
        (error) => {
          console.error('Login error:', error);
          throw new Error(error);
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await logOutUser(
        () => {
          setUser(null);
          setLoggedIn(false);
          setIsPro(false);
          setIsAdmin(false);
          setIsOnboardingCompleted(false);
        },
        (error) => {
          console.error('Logout error:', error);
          throw new Error(error);
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addCredits = async (amount: number) => {
    if (!user) return;
    try {
      const response = await axios.post(
        `${serverUrl}/credits/add`,
        { amount },
        { withCredentials: true }
      );
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error('Error adding credits:', error);
      toast.error('Failed to add credits');
    }
  };

  const removeCredits = async (amount: number) => {
    if (!user) return;
    try {
      const response = await axios.post(
        `${serverUrl}/credits/remove`,
        { amount },
        { withCredentials: true }
      );
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error('Error removing credits:', error);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        toast.error('Insufficient credits');
        throw new Error('Insufficient credits');
      } else {
        toast.error('Failed to remove credits');
        throw error;
      }
    }
  };

  const checkCredits = (projectId: string, requiredCredits: number = 1, options?: { setRedirect?: boolean }) => {
    if (!user) return false;
    const currentCredits = user?.credits ?? 0;
    const hasEnoughCredits = isPro || currentCredits >= requiredCredits;
    const shouldRedirect = options?.setRedirect ?? true;

    if (!hasEnoughCredits && shouldRedirect) {
      toast.error(
        isPro
          ? "Error checking credits"
          : `You only have ${currentCredits} credits left. Please upgrade to continue.`
      );

      // Instead of redirecting, show the pricing popup directly
      setPricingPopupState({
        isOpen: true,
        projectId
      });
    }

    return hasEnoughCredits;
  };

  // Handle closing the pricing popup
  const closePricingPopup = () => {
    setPricingPopupState({
      isOpen: false,
      projectId: null
    });
  };

  const togglePricingPopup = (projectId: string | null) => {
    setPricingPopupState({
      isOpen: !pricingPopupState.isOpen,
      projectId: projectId
    });
  };

  return (
    <AuthContext.Provider value={{
      user,
      loggedIn,
      isPro,
      isAdmin,
      login,
      logout,
      refreshUserInfo,
      isLoading,
      isOnboardingCompleted,
      addCredits,
      removeCredits,
      checkCredits,
      pricingPopupState,
      closePricingPopup,
      togglePricingPopup
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};