import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { toast } from 'sonner';
import { performCursorInsertion } from './updateSectionHelper';
import { defaultFilters, defaultFiltersNoDatabase, Filters } from "@/authService";
import FilterSidebar from "../filters/FiltersSidebar";
import { Switch } from "@/components/ui/switch";
import { FileSearch } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { citationStyles } from '@/pages/Answer/citationUtils';
import { Editor } from '@tiptap/core';

export function ContinueWritingModal({ editor, projectId, citationStyle }: ContinueWritingModalProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [customPrompt, setCustomPrompt] = useState("Continue writing from this point, maintaining the same style and flow");
    const [useNewSources, setUseNewSources] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState<Filters>(defaultFilters);
    const [queryText, setQueryText] = useState("");
    const [cursorPreview, setCursorPreview] = useState<{ before: string; after: string; }>({ before: "", after: "" });
    const { checkCredits, removeCredits } = useAuth();
    useEffect(() => {
        const updateCursorPreview = () => {
            const { from } = editor.state.selection;
            const doc = editor.state.doc;

            // Get text before cursor (up to 150 characters)
            const beforeStart = Math.max(0, from - 150);
            const beforeText = doc.textBetween(beforeStart, from);

            // Get text after cursor (up to 150 characters)
            const afterEnd = Math.min(doc.content.size, from + 150);
            const afterText = doc.textBetween(from, afterEnd);

            setCursorPreview({
                before: beforeText,
                after: afterText
            });
        };

        editor.on('selectionUpdate', updateCursorPreview);
        updateCursorPreview(); // Initial update

        return () => {
            editor.off('selectionUpdate', updateCursorPreview);
        };
    }, [editor]);

    const handleContinueWriting = async () => {
        if (!projectId) {
            toast.error("Project ID is required");
            return;
        }

        if (!checkCredits(projectId, 1)) {
            return;
        }

        try {
            setIsLoading(true);
            await performCursorInsertion({
                editor,
                projectId,
                instruction: customPrompt,
                useNewSources,
                filters: useNewSources ? filters : defaultFiltersNoDatabase,
                citationStyle
            });


            await removeCredits(1);
            toast.success("Content generated successfully");

        } catch (error) {
            console.error('Error continuing writing:', error);
            toast.error('Failed to continue writing');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="space-y-4 py-4">
            {/* Cursor Position Banner */}
            <div className="p-3 rounded-md bg-blue-50 border border-blue-100">
                <div className="flex items-center gap-2 mb-1">
                    <span className="text-blue-700">📍</span>
                    <span className="font-medium text-sm">Writing from Cursor Position</span>
                </div>
                <p className="text-sm text-gray-600 mb-2">
                    Content will be inserted at your current cursor position. Place your cursor where you want the AI to continue writing.
                </p>
                <div className="mt-2 border border-blue-200 bg-white p-2 rounded-md">
                    <p className="text-xs text-blue-700 mb-1">Text around cursor:</p>
                    <div className="text-sm text-gray-700">
                        <span className="opacity-50">{cursorPreview.before}</span>
                        <span className="bg-blue-100 px-1">|</span>
                        <span className="opacity-50">{cursorPreview.after}</span>
                    </div>
                </div>
            </div>

            <div className="space-y-2">
                <Label>Custom Instruction (Optional)</Label>
                <Textarea
                    placeholder="Enter custom instruction for continuing the text..."
                    value={customPrompt}
                    onChange={(e) => setCustomPrompt(e.target.value)}
                    className="min-h-[100px]"
                    disabled={isLoading} />
            </div>

            <div className="space-y-2 border-t pt-4">
                <Label className="text-base font-medium">Source Selection</Label>
                <div className="bg-gray-50 p-3 rounded-md">
                    <div className="flex items-center gap-3">
                        <Switch
                            checked={useNewSources}
                            onCheckedChange={setUseNewSources}
                            id="source-mode" />
                        <div className="flex-1">
                            <Label htmlFor="source-mode" className="text-sm font-medium">
                                Would you like AnswerThis to search for new sources?
                            </Label>
                            <p className="text-xs text-gray-600">
                                {useNewSources
                                    ? "AnswerThis will search its database for additional relevant sources to enhance your content"
                                    : "AnswerThis will only use the sources already cited in your document"}
                            </p>
                        </div>
                    </div>
                </div>

                {useNewSources && (
                    <div className="space-y-2 mt-3">
                        <div className="space-y-2">
                            <Label>What should we search for?</Label>
                            <Input
                                placeholder="Enter the topic you'd like to find new sources about..."
                                value={queryText}
                                onChange={(e) => setQueryText(e.target.value)}
                                className="border-gray-300 focus:ring focus:ring-blue-300" />
                        </div>
                        <Button variant="outline" size="sm" onClick={() => setShowFilters(true)}>
                            <FileSearch className="h-4 w-4 mr-2" />
                            Customize Source Settings
                        </Button>
                    </div>
                )}
            </div>

            {showFilters && (
                <FilterSidebar
                    filters={filters}
                    setFilters={setFilters}
                    isOpen={showFilters}
                    onClose={() => setShowFilters(false)}
                    onApply={handleContinueWriting}
                    isSidebar={false}
                    showAnswerType={false}
                    showTurboMode={false}
                    showDoubleCheckCitations={false}
                    showCustomSections={false}
                    showSubmitButton={false} />
            )}

            <div className="flex justify-end space-x-2 pt-4">
                <Button variant="outline" onClick={() => {
                    setCustomPrompt("Continue writing from this point, maintaining the same style and flow");
                    setUseNewSources(false);
                    setShowFilters(false);
                    setQueryText("");
                    setFilters(defaultFilters);
                }} disabled={isLoading}>
                    Reset
                </Button>
                <Button
                    onClick={handleContinueWriting}
                    disabled={isLoading}
                    className="bg-[#A53E5A] hover:bg-[#8A3349] text-white"
                >
                    {isLoading ? "Generating..." : "Continue Writing"}
                </Button>
            </div>
        </div>
    );
} export interface ContinueWritingModalProps {
    editor: Editor;
    projectId: string;
    citationStyle: citationStyles;
}

