// Constants
export const MIN_NODE_SIZE = 50;
export const MAX_NODE_SIZE = 120;
export const MIN_YEAR = 1950;
export const MAX_YEAR = 2025;
export const EDGE_WEIGHT_COUPLING = 0.7;
export const EDGE_WEIGHT_COCITATION = 0.3;

// Layout Constants
export const LAYOUT_OPTIONS = {
    name: 'cola',
    animate: true,
    refresh: 1,
    maxSimulationTime: 2000,
    // Remove unnecessary constraints:
    // nodeSpacing: undefined,
    // edgeLength: undefined,
    padding: 20,
    randomize: false,
    fit: true, // Ensure the layout tries to fit the entire graph in the viewport
    avoidOverlap: true,
    convergenceThreshold: 0.01,
    ready: () => { },
    stop: (e: unknown) => {
        // After layout is done, fit the graph to the container
        // Type assertion for Cytoscape event
        const event = e as { cy: { fit: () => void } };
        event.cy.fit();
    }
};


// Graph Styles
export const graphStyles = [
    {
        selector: 'node',
        css: {
            'label': 'data(label)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': '#000',
            'background-color': 'data(color)',
            'border-width': 2,
            'border-color': '#fff',
            'text-outline-width': 0,        // Remove outline if you prefer cleaner text
            'text-outline-color': '#000',
            'font-size': '12px',            // Keep font size consistent
            // Remove or reduce min-zoomed-font-size
            // 'min-zoomed-font-size': 20,   // Remove this line
            'text-wrap': 'wrap',
            'text-max-width': '150px'
        }
    },
    {
        selector: 'edge',
        css: {
            'width': 'data(width)',
            'line-color': 'data(color)',
            'curve-style': 'straight',
            'target-arrow-shape': 'triangle',
            'target-arrow-color': 'data(color)',
            'opacity': 0.8
        }
    },
    {
        selector: '.faded',
        css: {
            'opacity': 0.2
        }
    },
    {
        selector: '.highlighted',
        css: {
            'border-width': 4,
            'border-color': '#ffd700'
        }
    }
];
