import { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import HeroSection from '@/components/marketing/HeroSection';
import StatsSection from '@/components/marketing/StatsSection';
import StepCard from '@/components/marketing/StepCard';
import ResourceCard from '@/components/marketing/ResourceCard';
import CommissionCalculator from '@/components/marketing/CommissionCalculator';
import {
    ArrowRight,
    CheckCircle2,
    XCircle,
    Users,
    Calculator,
    Instagram,
    Linkedin,
    Youtube,
    Facebook,
    MessageCircle,
    Download,
    FileText,
    ImageIcon,
    PenTool,
    Link as LinkIcon
} from 'lucide-react';

const AffiliatePage = ({ noPaddingTop = false }: { noPaddingTop?: boolean }) => {
    const [isYearly, setIsYearly] = useState(false);
    const baseCommission = isYearly ? 144 * 0.2 : 22 * 0.2;

    const quickStats = [
        { value: "20%", label: "Commission Rate" },
        { value: "∞", label: "No Referral Limit" },
        { value: "24/7", label: "24/7 Support" },
    ];

    return (
        <div className="w-full min-h-screen font-Manrope bg-white">
            <HeroSection
                badge="#1 AI Research Assistant"
                title={<>
                    Become an affiliate<br />
                    <span className="text-brandMaroon">and get paid for each referral</span><br />
                    to AnswerThis.io
                </>}
                noTopPadding={noPaddingTop}
                description="Join our affiliate program today and earn 20% referral bonus for every person you refer, each payment period.
                Perfect for educators, influencers, and research communities."
                primaryCTA={{
                    text: "Become an affiliate",
                    onClick: () => window.open('https://answerthis.firstpromoter.com/signup'),
                }}
                secondaryCTA={{
                    text: "Brand Collaborations",
                    onClick: () => window.open('mailto:outreach@answerthis.io'),
                }}
                variant="affiliate"
                image="/img/affiliate-earnings.png"
                backgroundPattern="grid"
            />

            {/* Quick stats */}
            <section className="py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <StatsSection stats={quickStats} className="pt-12" />
                </div>
            </section>

            {/* Steps Section */}
            <section className="py-24 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-gray-900">How it works</h2>
                        <p className="mt-4 text-lg text-gray-600">Follow these 3 simple steps & start earning today</p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        <StepCard
                            title="Sign-up"
                            description="Create your account and get your unique referral link instantly"
                            icon={<Users className="h-6 w-6 text-brandMaroon" />}
                            action={
                                <Button variant="link" className="text-brandMaroon p-0 hover:no-underline" onClick={() => window.open('https://answerthis.firstpromoter.com/signup')}>
                                    Take me there <ArrowRight className="ml-2 h-4 w-4" />
                                </Button>
                            }
                        />
                        <StepCard
                            title="Share your link"
                            description="Share with your network and help them discover AI-powered research"
                            icon={<LinkIcon className="h-6 w-6 text-brandMaroon" />}
                            preview={
                                <div className="flex gap-3 mb-6">
                                    {[Instagram, Linkedin, Youtube, Facebook, MessageCircle].map((Icon, i) => (
                                        <div key={i} className="w-10 h-10 bg-gray-50 rounded-lg flex items-center justify-center  transition-colors duration-200">
                                            <Icon className="h-5 w-5 text-brandMaroon" />
                                        </div>
                                    ))}
                                </div>
                            }
                        />
                        <StepCard
                            title="Get paid"
                            description="Earn 20% referral bonus for each referral, with no limits"
                            icon={<Calculator className="h-6 w-6 text-brandMaroon" />}
                            highlight={
                                <div className="mt-4 p-3 bg-brandMaroon/10 rounded-lg text-lg font-semibold text-brandMaroon">
                                    20% referral bonus
                                </div>
                            }
                        />
                    </div>
                </div>
            </section>

            {/* Assets Section */}
            <section className="py-24 bg-gray-50/50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-gray-900">Promotional Resources</h2>
                        <p className="mt-4 text-lg text-gray-600">Everything you need to promote AnswerThis effectively</p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        <ResourceCard
                            icon={<ImageIcon className="h-6 w-6 text-brandMaroon" />}
                            title="Brand Assets"
                            description="Download our official logos, banners, and visual materials."
                            buttonText="Download Assets"
                            buttonIcon={<Download className="h-4 w-4" />}
                            onClick={() => window.open('https://drive.google.com/drive/folders/1jlyaEJYguIDQwFOTlVAp3O8x-1euxljE?usp=sharing')}
                        />

                        <ResourceCard
                            icon={<FileText className="h-6 w-6 text-brandMaroon" />}
                            title="Email Templates"
                            description="Ready-to-use email templates for various audiences."
                            buttonText="Download All Templates"
                            onClick={() => window.open('https://drive.google.com/drive/folders/1AMO1r9zwr1WXQhjubqAp-quZwMJrV4QQ?usp=drive_links')}
                        // extraContent={
                        //     <div className="space-y-3 mb-6">
                        //         {['Research Groups', 'Academic Outreach'].map((template, i) => (
                        //             <div key={i} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                        //                 <span className="text-sm text-gray-600">{template}</span>
                        //                 <Button variant="ghost" size="sm" className="text-brandMaroon">
                        //                     Copy <Copy className="ml-2 h-4 w-4" />
                        //                 </Button>
                        //             </div>
                        //         ))}
                        //     </div>
                        // }
                        />

                        <ResourceCard
                            icon={<PenTool className="h-6 w-6 text-brandMaroon" />}
                            title="Social Media Kit"
                            description="Social media templates and post ideas."
                            buttonText="Download Social Kit"
                            onClick={() => window.open('https://drive.google.com/drive/folders/1pLtQ6P1TQOdtu5R9O3SkxDoEOfF-cMM1?usp=drive_link')}

                        />
                    </div>

                    {/* Special Notice */}
                    <div className="mt-12 p-6 bg-brandMaroon/5 rounded-xl border border-brandMaroon/10">
                        <p className="text-gray-600 text-center">
                            🎯 Are you an influencer with 10,000+ followers? Email <span className="text-brandMaroon font-semibold">outreach@answerthis.io</span> for
                            exclusive brand collaboration opportunities!
                        </p>
                    </div>
                </div>
            </section>

            {/* Calculator Section */}
            <section className="py-24 bg-white">
                <CommissionCalculator
                    isYearly={isYearly}
                    onToggle={setIsYearly}
                    baseCommission={baseCommission}
                />
            </section>

            {/* Policy Section */}
            <section className="py-24 bg-gray-50/50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-gray-900">Our Affiliate Policy</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            Help us maintain a high-quality affiliate program by following these guidelines
                        </p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-8">
                        <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                            <CardContent className="p-8">
                                <h3 className="flex items-center text-xl font-semibold text-green-600 mb-6">
                                    <CheckCircle2 className="h-6 w-6 mr-2" />
                                    Please do...
                                </h3>
                                <ul className="space-y-4">
                                    {[
                                        'Share your link with friends & family',
                                        'Share across Social Media Platforms',
                                        'Share your affiliate link as many times as you like',
                                        'Respect platform guidelines',
                                        'Get creative!'
                                    ].map((item, index) => (
                                        <li key={index} className="flex items-center text-gray-700">
                                            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>

                        <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                            <CardContent className="p-8">
                                <h3 className="flex items-center text-xl font-semibold text-red-600 mb-6">
                                    <XCircle className="h-6 w-6 mr-2" />
                                    Please do not...
                                </h3>
                                <ul className="space-y-4">
                                    {[
                                        'Use your affiliate link to create an account for yourself',
                                        'Abuse other people\'s boundaries when sharing your link',
                                        'Act on behalf of AnswerThis',
                                        'Run any form of paid ads with your referral link'
                                    ].map((item, index) => (
                                        <li key={index} className="flex items-center text-gray-700">
                                            <XCircle className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>
                    </div>

                    <div className="mt-8 p-6 bg-gray-100 rounded-xl">
                        <p className="text-gray-600 text-center">
                            ⚠️ Important note: AnswerThis reserves the right to change the Terms and Services of our Affiliate Program at any time.
                            If you fail to adhere to our affiliate policy, we hold the right to remove you from the program.
                        </p>
                    </div>
                </div>
            </section>

            {/* Final CTA */}
            <section className="py-24 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Card className="bg-gradient-to-r from-brandMaroon to-purple-700 text-white shadow-xl">
                        <CardContent className="p-12 text-center">
                            <h2 className="text-3xl font-bold mb-6">Ready to start earning?</h2>
                            <p className="text-lg mb-8 text-gray-100">Join our affiliate program and start earning today.</p>
                            <Button
                                onClick={() => window.open('https://answerthis.firstpromoter.com/signup')}
                                className="bg-white text-brandMaroon hover:bg-gray-50 px-8 py-6 rounded-xl text-lg font-medium"
                            >
                                Become an Affiliate <ArrowRight className="ml-2 h-5 w-5" />
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            </section>
        </div>
    );
};

export default AffiliatePage;