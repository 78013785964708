import React from 'react';

interface CustomLabelProps {
    htmlFor: string;
    children: React.ReactNode;
    className?: string;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ htmlFor, children, className = '' }) => {
    return (
        <label htmlFor={htmlFor} className={`text-sm font-medium ${className}`}>
            {children}
        </label>
    );
};

export default CustomLabel;