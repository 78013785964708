import { useState, useEffect } from 'react';
import { Editor } from '@tiptap/react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { ScrollArea } from '@/components/ui/scroll-area';
import { SourceInfo } from '@/authService';
import { ExternalLink, Calendar, Hash, FileText, Award } from 'lucide-react';

interface CitationPopupProps {
    citationId: string;
    citationContent: string;
    isOpen: boolean;
    onClose: () => void;
    editor?: Editor;
}

interface CitationDetails extends Partial<SourceInfo> {
    inlineText?: string;
    citedContent?: string;
    chunkId?: string;
}

export const CitationPopup = ({ citationId, citationContent, isOpen, onClose, editor }: CitationPopupProps) => {
    const [citationDetails, setCitationDetails] = useState<CitationDetails | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedDetails, setEditedDetails] = useState<CitationDetails | null>(null);

    useEffect(() => {
        if (isOpen && editor) {
            let details: CitationDetails | null = null;
            editor.state.doc.descendants((node) => {
                if (node.type.name === 'citation' && node.attrs.sourceInfo?.id === citationId) {
                    details = {
                        ...node.attrs.sourceInfo,
                        inlineText: node.attrs.displayText || citationContent,
                        citedContent: node.attrs.sectionContent || citationContent,
                        chunkId: node.attrs.chunkId
                    };
                    return false;
                }
            });
            setCitationDetails(details);
            setEditedDetails(details);
        }
    }, [isOpen, editor, citationId, citationContent]);

    const handleSave = () => {
        if (!editor || !editedDetails) return;

        const { state, view } = editor;
        let tr = state.tr;

        state.doc.descendants((node, pos) => {
            if (node.type.name === 'citation' && node.attrs.sourceInfo?.id === citationId) {
                const newAttrs = {
                    ...node.attrs,
                    sourceInfo: {
                        ...node.attrs.sourceInfo,
                        ...editedDetails
                    }
                };
                tr = tr.setNodeMarkup(pos, undefined, newAttrs);
            }
        });

        view.dispatch(tr);
        setIsEditing(false);
        onClose();
    };

    const formatDate = (dateString: string) => {
        try {
            return new Date(dateString).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        } catch (e) {
            return dateString;
        }
    };

    if (!citationDetails) return null;

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-4xl h-auto min-h-[50vh] max-h-[90vh] flex flex-col">
                <DialogHeader className='pl-4 pr-4'>
                    <DialogTitle className="flex justify-between items-center">
                        <span className="text-xl font-semibold">Citation Details</span>
                        <Button
                            variant="outline"
                            onClick={() => setIsEditing(!isEditing)}
                            size="sm"
                        >
                            {isEditing ? 'Cancel Edit' : 'Edit'}
                        </Button>
                    </DialogTitle>
                </DialogHeader>

                <ScrollArea className="pr-4 flex-1 overflow-y-auto">
                    {isEditing ? (
                        // Edit Mode
                        <div className="space-y-4 py-2">
                            <div className="grid gap-4">
                                <div>
                                    <Label htmlFor="title">Title</Label>
                                    <Input
                                        id="title"
                                        value={editedDetails?.title || ''}
                                        onChange={(e) => setEditedDetails(prev => ({
                                            ...prev,
                                            title: e.target.value
                                        }))}
                                        className="mt-1.5"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="author">Authors</Label>
                                    <Input
                                        id="author"
                                        value={editedDetails?.author || ''}
                                        onChange={(e) => setEditedDetails(prev => ({
                                            ...prev,
                                            author: e.target.value
                                        }))}
                                        className="mt-1.5"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="abstract">Abstract</Label>
                                    <Textarea
                                        id="abstract"
                                        value={editedDetails?.abstract || ''}
                                        onChange={(e) => setEditedDetails(prev => ({
                                            ...prev,
                                            abstract: e.target.value
                                        }))}
                                        className="mt-1.5 h-32"
                                    />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="journal">Journal</Label>
                                        <Input
                                            id="journal"
                                            value={editedDetails?.journal || ''}
                                            onChange={(e) => setEditedDetails(prev => ({
                                                ...prev,
                                                journal: e.target.value
                                            }))}
                                            className="mt-1.5"
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="doi">DOI</Label>
                                        <Input
                                            id="doi"
                                            value={editedDetails?.doi || ''}
                                            onChange={(e) => setEditedDetails(prev => ({
                                                ...prev,
                                                doi: e.target.value
                                            }))}
                                            className="mt-1.5"
                                        />
                                    </div>
                                </div>
                            </div>
                            <DialogFooter>
                                <Button
                                    variant="outline"
                                    onClick={() => {
                                        setIsEditing(false);
                                        setEditedDetails(citationDetails);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button onClick={handleSave}>
                                    Save Changes
                                </Button>
                            </DialogFooter>
                        </div>
                    ) : (
                        // View Mode
                        <div className="space-y-6 py-2">
                            <Card>
                                <CardHeader>
                                    <CardTitle>{citationDetails.title}</CardTitle>
                                    <CardDescription>
                                        <div className="flex flex-wrap gap-2 mt-2">
                                            <Badge variant="outline" className="flex items-center gap-1">
                                                <FileText className="w-3 h-3" />
                                                {citationDetails.journal}
                                            </Badge>
                                            <Badge variant="outline" className="flex items-center gap-1">
                                                <Calendar className="w-3 h-3" />
                                                {formatDate(citationDetails.date_of_publication || '')}
                                            </Badge>
                                            {citationDetails.citations !== undefined && (
                                                <Badge variant="outline" className="flex items-center gap-1">
                                                    <Award className="w-3 h-3" />
                                                    {citationDetails.citations} citations
                                                </Badge>
                                            )}
                                        </div>
                                    </CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-4">
                                    <div>
                                        <h4 className="text-sm font-medium">Authors</h4>
                                        <p className="text-sm text-muted-foreground mt-1">
                                            {citationDetails.author}
                                        </p>
                                    </div>

                                    <Separator />

                                    <div>
                                        <h4 className="text-sm font-medium">Abstract</h4>
                                        <p className="text-sm text-muted-foreground mt-1">
                                            {citationDetails.abstract || 'No abstract available'}
                                        </p>
                                    </div>

                                    {citationDetails?.citedContent && citationDetails.citedContent !== citationDetails.abstract && (
                                        <>
                                            <Separator />
                                            <div>
                                                <h4 className="text-sm font-medium">Retrieved Content</h4>
                                                <div className="text-sm text-muted-foreground mt-1">
                                                    <p className="whitespace-pre-wrap break-words">
                                                        {citationDetails.citedContent}
                                                    </p>
                                                    {citationDetails?.chunkId && (
                                                        <p className="text-xs text-muted-foreground mt-2">
                                                            Section ID: {citationDetails.chunkId}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <Separator />

                                    <div className="flex flex-wrap gap-4">
                                        {citationDetails.doi && (
                                            <a
                                                href={`https://doi.org/${citationDetails.doi}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center gap-1 text-sm text-blue-500 hover:underline"
                                            >
                                                <Hash className="w-4 h-4" />
                                                {citationDetails.doi}
                                            </a>
                                        )}
                                        {citationDetails.url && (
                                            <a
                                                href={citationDetails.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center gap-1 text-sm text-blue-500 hover:underline"
                                            >
                                                <ExternalLink className="w-4 h-4" />
                                                View Publication
                                            </a>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    )}
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};