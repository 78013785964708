import { UserProject } from "../authService"; export const sortProjects = (projects: UserProject[], activeId: string | null): UserProject[] => {
  return projects.sort((a, b) => {
    if (a.id === activeId) return -1;
    if (b.id === activeId) return 1;
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });
};

export const getActiveProject = (projects: UserProject[], activeId: string | null): UserProject | null => {
  return projects.find(project => String(project.id) === String(activeId)) || null;
};

export const sortAndFilterProjects = (projects: UserProject[], activeId: string | null, limit?: number): UserProject[] => {
  const sortedProjects = sortProjects(projects, activeId);

  if (activeId) {
    const activeProject = getActiveProject(sortedProjects, activeId);
    if (activeProject) {
      const filteredProjects = sortedProjects.filter(project => String(project.id) !== String(activeId));
      return [activeProject, ...(limit ? filteredProjects.slice(0, limit - 1) : filteredProjects)];
    }
  }

  return limit ? sortedProjects.slice(0, limit) : sortedProjects;
};