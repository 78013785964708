import heroImage from '/img/hero_answerthis.jpg'

interface HeroProps {
}

const Hero: React.FC<HeroProps> = () => {
    return (
        <div className="hero">
            <div className="main-container">
                <div className="main-content">
                    <h1 className="hero-h1">Your Research, Simplified</h1>
                    <p className="slogan">
                        Streamlining Your Study – upload, inquire, and get{" "}
                        <strong>comprehensive summaries </strong>
                        based directly from{" "}
                        <strong>verified research sources.</strong>{" "}
                    </p>
                    <a href="/signup">
                        <button className="join-button">Sign Up</button>
                    </a>
                </div>
                <img
                    className="hero-video"
                    src={heroImage}
                />
            </div>
        </div>
    );
};

export default Hero;