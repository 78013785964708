import { useState } from "react";
import { SearchBar } from "../../components/searchInput/SearchBar";

interface AskNewQuestionProps {
    compactUi?: boolean;
    handleNewQuestionSubmit: (question: string) => Promise<void>;
}

export function AskNewQuestion({
    handleNewQuestionSubmit,
    compactUi = false,

}: AskNewQuestionProps) {
    const [query, setQuery] = useState<string>('');
    return (
        <div className=" flex justify-center">
            <div className="w-[100%] md:w-[60%]">
                <SearchBar
                    compactUi={compactUi}
                    handleInputChange={(e) => setQuery(e.target.value)}
                    handleSearch={() => handleNewQuestionSubmit(query)}
                    query={query}

                    placeholder="Ask follow up question"
                />
            </div>
        </div>
    );
}