import React, { useEffect, useState } from 'react';
import { CloudLightningIcon } from 'lucide-react';
import { useSearch } from '../../contexts/queryContext';
import { buttonClass } from '../Buttons/buttonClasses';
interface CreateColumnComponentProps {
    onAddColumn: (columnName: string) => void;
}

export const CreateColumnComponent: React.FC<CreateColumnComponentProps> = ({ onAddColumn }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [columnName, setColumnName] = useState('');
    const predefinedColumns = [
        'Key Findings', 'Research Gaps', 'Methodology', 'Limitations',
        'Future Work', 'Contributions', 'Practical Implications'
    ];
    const { showExtractData, setShowExtractData } = useSearch();
    useEffect(() => {
        if (showExtractData) {
            setIsOpen(true);
            setShowExtractData(false);
        }
    }, [showExtractData]);

    const handleAddColumn = () => {
        if (columnName.trim()) {
            onAddColumn(columnName.trim());
            setColumnName('');
            setIsOpen(false);
        }
    };

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className={buttonClass}
            >
                <CloudLightningIcon className="mr-2" />
                Extract Data
            </button>

            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                            Add New Column
                        </h3>
                        <input
                            type="text"
                            value={columnName}
                            onChange={(e) => setColumnName(e.target.value)}
                            placeholder="Custom Instruction"
                            className="w-full px-3 py-2 border rounded-md mb-4"
                        />

                        <div className="mb-4">
                            <p className="text-sm text-gray-500 mb-2">Or choose from predefined options:</p>
                            <div className="flex flex-wrap gap-2">
                                {predefinedColumns.map((option) => (
                                    <button
                                        key={option}
                                        onClick={() => setColumnName(option)}
                                        className="px-2 py-1 text-sm bg-gray-200 rounded-md hover:bg-gray-300"
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddColumn}
                                className="px-4 py-2 text-sm font-medium text-white bg-brandMaroon rounded-md hover:bg-opacity-90 focus:outline-none"
                            >
                                Add Column
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

