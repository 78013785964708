import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleRedirectMainPage } from "../../authService";
import { NavigationList } from "../NavigationList";
import { useAuth } from "../../contexts/AuthContext";

function Header() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedIn, logout } = useAuth();

  useEffect(() => {
    const updateDimensions = () => setWindowWidth(window.innerWidth);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("resize", updateDimensions);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigation = (path: string, event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(path);
  };

  const handleLogoutClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to log out?")) {
      try {
        await logout();
        window.location.reload();
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };

  const AuthButtons = ({ isMobile = false }) => (
    <div className={isMobile ? "flex flex-col gap-4 w-full" : "flex items-center gap-4"}>
      {loggedIn ? (
        <>
          {!location.pathname.endsWith('ask-answerthis') && (
            <button
              onClick={() => {
                if (isMobile) setIsExpanded(false);
                handleRedirectMainPage(navigate);
              }}
              className="bg-[#A53E5A] text-white font-bold font-Manrope text-base px-6 py-3 rounded-lg hover:bg-[#8A3349] transition-colors w-full md:w-auto"
            >
              Go to App
            </button>
          )}
          <button
            onClick={(event) => {
              handleLogoutClick(event);
              if (isMobile) setIsExpanded(false);
            }}
            className="border border-gray-200 px-6 py-3 rounded-lg font-Manrope text-base hover:bg-gray-50 transition-colors w-full md:w-auto"
          >
            Log out
          </button>
        </>
      ) : (
        <>
          <button
            onClick={(event) => {
              if (isMobile) setIsExpanded(false);
              handleNavigation('/login', event);
            }}
            className="border bg-white border-gray-200 px-6 py-3 rounded-lg font-Manrope text-base hover:bg-gray-50 transition-colors w-full md:w-auto"
          >
            Sign in
          </button>
          <button
            onClick={(event) => {
              if (isMobile) setIsExpanded(false);
              handleNavigation('/signup', event);
            }}
            className="bg-[#A53E5A] text-white font-Manrope text-base px-6 py-3 rounded-lg hover:bg-[#8A3349] transition-colors w-full md:w-auto"
          >
            Sign Up
          </button>
        </>
      )}
    </div>
  );

  return (
    <>
      <header
        className={`mx-4 my-3 p-3 rounded-2xl flex justify-between items-center  ${isScrolled ? "border border-gray-200 bg-white/80 backdrop-blur-sm" : ""
          }`}
      >
        <a href="/" className="flex-shrink-0">
          <img
            src={windowWidth > 1200 ? "/img/logo.svg" : "/img/logos/answerthis_mobile_logo.png"}
            alt="AnswerThis logo"
            title="AnswerThis logo"
            className={`${windowWidth > 1200 ? "w-[268px] h-10" : "w-12 h-12"}`}
          />
        </a>

        <nav className="hidden md:block">
          <NavigationList
            location={location}
            navigate={navigate}
            isMobile={false}
          />
        </nav>

        <div className="flex items-center gap-4">
          <div className="hidden md:flex">
            <AuthButtons />
          </div>
          <button
            onClick={() => setIsExpanded((prev) => !prev)}
            className="block md:hidden p-2 hover:bg-gray-100 rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </header>

      {isExpanded && (
        <div className="fixed inset-x-0 top-[calc(10vh-1rem)] mx-4 p-6 bg-white rounded-2xl rounded-t-none shadow-xl z-20 border border-gray-100 border-t-0">
          <div className="flex flex-col gap-6">
            <NavigationList
              location={location}
              navigate={navigate}
              isMobile={true}
            />
            <AuthButtons isMobile={true} />
          </div>
        </div>
      )}
    </>
  );
}

export default Header;