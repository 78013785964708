import React, { useState, useEffect } from 'react';
import { BookOpen, Search, FileText, Folders, Home, List, Network, PaintBucket, Gem } from 'lucide-react';
import SearchPapers from "../SearchPapers/SearchPapers";
import YourLibrary from "../Library/YourLibrary";
import LiteratureReview from "./LiteratureReview";
import Projects from '../Projects/Projects';
import CitationMapPage from '../CitationMapPage/CitationMapPage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HomeUpload from './HomeUpload';
import ProtectedRoute from '../../components/routes/OnlyPro';
import { useAuth } from '../../contexts/AuthContext';
import DiagramGenerator from '../../components/diagram/diagramGenerator';
import DocumentHistory from '../../components/editor/DocumentHistory';
// import { useAuth } from '@/contexts/AuthContext';
interface Tab {
    id: string;
    label: string;
    component: React.ComponentType;
    icon: React.ElementType;
    isPro?: boolean;
}
interface TabButtonProps {
    tab: Tab;
    isActive: boolean;
    onClick: (id: string) => void;
    isMobile: boolean;
    isPricing?: boolean;
}
const TabButton: React.FC<TabButtonProps> = ({ tab, isActive, onClick, isMobile, isPricing }) => (
    <button
        onClick={() => onClick(tab.id)}
        className={`
            group relative flex items-center justify-center transition-colors duration-200
            ${isMobile
                ? 'flex-1 min-w-[22%] py-3 px-2 text-xs font-medium flex flex-col'
                : 'flex-1 py-4 px-4 text-sm font-medium flex items-center justify-center'
            }
            ${isPricing
                ? isMobile
                    ? 'text-white bg-gradient-to-r from-brandMaroon to-brandMaroon/90'
                    : 'text-white bg-brandMaroon hover:bg-brandMaroon/90 border-b-2 border-brandMaroon'
                : isActive
                    ? isMobile
                        ? 'text-white bg-gradient-to-r from-brandMaroon to-brandMaroon/90'
                        : 'text-brandMaroon border-b-2 border-brandMaroon'
                    : isMobile
                        ? 'text-gray-600 bg-gray-50 hover:bg-gray-100'
                        : 'text-gray-500 hover:text-gray-700 border-b-2 border-transparent'
            }
        `}
    >
        <tab.icon
            size={20}
            className={`
                ${isMobile ? 'mb-1' : 'mr-2'} 
                ${isActive || isPricing ? '' : 'text-gray-400'}
            `}
        />
        <span>{tab.label}</span>

    </button>
);




export const TabSearch: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { isPro, togglePricingPopup } = useAuth();
    const [activeTab, setActiveTab] = useState<string>('home');
    const location = useLocation();
    const navigate = useNavigate();
    const tabs: Tab[] = [
        { id: 'home', label: 'Home', component: HomeUpload, icon: Home, isPro: false },
        { id: 'review', label: 'Literature Review', component: LiteratureReview, icon: BookOpen, isPro: false },
        { id: 'documents', label: 'AI Writer', component: DocumentHistory, icon: FileText, isPro: true },

        { id: 'library', label: 'Library', component: YourLibrary, icon: Folders, isPro: true },
        { id: 'search', label: 'Search Papers', component: SearchPapers, icon: Search, isPro: true },
        { id: 'citations', label: 'Citation Map', component: CitationMapPage, icon: Network, isPro: true },
        { id: 'diagram', label: 'Diagram', component: DiagramGenerator, icon: PaintBucket, isPro: true },

        { id: 'project', label: 'Projects', component: Projects, icon: List, isPro: true },
    ];

    // Create a pricing "tab" for consistent styling
    const pricingTab: Tab = {
        id: 'pricing',
        label: 'See Pricing',
        component: () => null,
        icon: Gem,
        isPro: false
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabFromUrl = searchParams.get('tab');
        if (tabFromUrl && tabs.some(tab => tab.id === tabFromUrl)) {
            setActiveTab(tabFromUrl);
        } else {
            setActiveTab('home');
        }
    }, [location]);
    // useEffect(() => {
    //     if (!isOnboardingCompleted) {
    //         navigate(`/${id}/onboarding`);
    //     }
    // }, [isOnboardingCompleted]);

    const handleTabClick = (tabId: string) => {
        // Special case for pricing tab
        if (tabId === 'pricing') {
            togglePricingPopup(id || null);
            return;
        }

        setActiveTab(tabId);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', tabId);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    return (
        <div className="lib-body-wrap font-Manrope">

            <div className="flex">
                {/* Main content */}
                <div className="flex-1 transition-all duration-300">
                    <div className="w-full">
                        <div className="bg-white overflow-hidden">
                            {/* Mobile tabs */}
                            <div className="flex flex-wrap md:hidden" id="tabsSection">
                                {tabs.map((tab) => (
                                    <TabButton
                                        key={tab.id}
                                        tab={tab}
                                        isActive={activeTab === tab.id}
                                        onClick={handleTabClick}
                                        isMobile={true}
                                    />
                                ))}
                                {!isPro && (
                                    <TabButton
                                        key="pricing"
                                        tab={{ ...pricingTab, label: 'Pricing' }}
                                        isActive={false}
                                        onClick={handleTabClick}
                                        isMobile={true}
                                        isPricing={true}
                                    />
                                )}
                            </div>
                            {/* Desktop tabs */}
                            <div className="hidden md:block" id="tabsSectionDesktop">
                                <nav className="flex items-center" aria-label="Tabs">
                                    <div className="flex flex-1">
                                        {tabs.map((tab) => (
                                            <TabButton
                                                key={tab.id}
                                                tab={tab}
                                                isActive={activeTab === tab.id}
                                                onClick={handleTabClick}
                                                isMobile={false}
                                            />
                                        ))}
                                        {!isPro && (
                                            <TabButton
                                                key="pricing"
                                                tab={{ ...pricingTab, label: 'See Pricing' }}
                                                isActive={false}
                                                onClick={handleTabClick}
                                                isMobile={false}
                                                isPricing={true}
                                            />
                                        )}
                                    </div>
                                </nav>
                            </div>
                            <div className='md:px-4 mb-[50px]'>
                                {tabs.map((tab) => (
                                    activeTab === tab.id && (
                                        <div key={tab.id} className="transition-opacity duration-300">
                                            {tab.isPro ? (
                                                <ProtectedRoute id={id!}>
                                                    <tab.component />
                                                </ProtectedRoute>) :
                                                <tab.component />}
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabSearch;