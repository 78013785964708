import { useEffect, useRef, useState } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Slider } from "@/components/ui/slider"
import { toast } from "sonner"
import FilterSidebar from "../filters/FiltersSidebar"
import { Editor } from '@tiptap/react'
import { FileSearch } from 'lucide-react'
import { Switch } from "@/components/ui/switch"
import { defaultFilters, defaultFiltersNoDatabase, Filters } from "@/authService"
import { addOutline, outlineToAnswer, updateDocumentContent } from "./documentService"
import { WriterDocument } from "./types";
import { convertAnswerToEditorFormat, convertCitationsToChunkIds, convertEditorToAnswer } from './answerConversion';
import { DOMSerializer } from 'prosemirror-model';
import { AnswerData, answerObjectListToFormattedAnswer } from "@/HelperUtils/AnswerFetcher"
import { citationStyles } from "@/pages/Answer/citationUtils"
import { useAuth } from "@/contexts/AuthContext"

// Outline Generation Modal
interface OutlineModalProps {
    editor: Editor;
    projectId?: string;
    activeDocument?: WriterDocument | null;
    citationStyle: citationStyles;
}

interface PlacementButtonsProps {
    placement: "replace" | "before" | "after"
    setPlacement: (placement: "replace" | "before" | "after") => void
    hasSelection?: boolean
}

function PlacementButtons({ placement, setPlacement, hasSelection }: PlacementButtonsProps) {
    if (hasSelection) {
        return (
            <div className="flex flex-col space-y-2">
                <Button
                    variant={placement === "replace" ? "default" : "outline"}
                    onClick={() => setPlacement("replace")}
                    className="justify-start h-auto py-2"
                >
                    <div className="flex flex-col items-start">
                        <div className="font-medium">Replace selection</div>
                        <div className={`text-xs ${placement === "replace" ? "text-white/90" : "text-gray-600"}`}>
                            Replace only the highlighted text with new content
                        </div>
                    </div>
                </Button>
                <Button
                    variant={placement === "before" ? "default" : "outline"}
                    onClick={() => setPlacement("before")}
                    className="justify-start h-auto py-2"
                >
                    <div className="flex flex-col items-start">
                        <div className="font-medium">Insert before</div>
                        <div className={`text-xs ${placement === "before" ? "text-white/90" : "text-gray-600"}`}>
                            Add new content just before the highlighted text
                        </div>
                    </div>
                </Button>
                <Button
                    variant={placement === "after" ? "default" : "outline"}
                    onClick={() => setPlacement("after")}
                    className="justify-start h-auto py-2"
                >
                    <div className="flex flex-col items-start">
                        <div className="font-medium">Insert after</div>
                        <div className={`text-xs ${placement === "after" ? "text-white/90" : "text-gray-600"}`}>
                            Add new content just after the highlighted text
                        </div>
                    </div>
                </Button>
            </div>
        )
    }

    return (
        <div className="flex flex-col space-y-2">
            <Button
                variant={placement === "replace" ? "default" : "outline"}
                onClick={() => setPlacement("replace")}
                className="justify-start h-auto py-2"
            >
                <div className="flex flex-col items-start">
                    <div className="font-medium">Replace everything</div>
                    <div className={`text-xs ${placement === "replace" ? "text-white/90" : "text-gray-600"}`}>
                        Clear the document and add new content
                    </div>
                </div>
            </Button>
            <Button
                variant={placement === "before" ? "default" : "outline"}
                onClick={() => setPlacement("before")}
                className="justify-start h-auto py-2"
            >
                <div className="flex flex-col items-start">
                    <div className="font-medium">Add to beginning</div>
                    <div className={`text-xs ${placement === "before" ? "text-white/90" : "text-gray-600"}`}>
                        Insert new content at the start of document
                    </div>
                </div>
            </Button>
            <Button
                variant={placement === "after" ? "default" : "outline"}
                onClick={() => setPlacement("after")}
                className="justify-start h-auto py-2"
            >
                <div className="flex flex-col items-start">
                    <div className="font-medium">Add to end</div>
                    <div className={`text-xs ${placement === "after" ? "text-white/90" : "text-gray-600"}`}>
                        Insert new content at the end of document
                    </div>
                </div>
            </Button>
        </div>
    )
}

export function OutlineModal({
    editor,
    projectId,
    activeDocument,
    citationStyle
}: OutlineModalProps) {
    const [outlineLoader, setOutLineLoader] = useState<boolean>(false);
    const [topic, setTopic] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [headings, setHeadings] = useState(5);
    const [subheadings, setSubheadings] = useState(2);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState<Filters>(defaultFilters);
    const [generatedContent, setGeneratedContent] = useState<string | null>(null);
    const [placement, setPlacement] = useState<"replace" | "before" | "after">("replace");
    const { checkCredits, removeCredits } = useAuth();

    useEffect(() => {
        if (activeDocument?.title) {
            setTopic(activeDocument.title);
        }
    }, [activeDocument]);

    const handleGenerate = async () => {
        if (!projectId) {
            toast.error("Project ID is required");
            return;
        }

        if (!checkCredits(projectId, 1)) {
            return;
        }

        setOutLineLoader(true);
        setShowFilters(false);
        if (!topic) {
            toast.error("Please enter a topic");
            setOutLineLoader(false);
            return;
        }

        try {
            const content = await addOutline(topic, description, projectId, headings, subheadings, filters, editor, citationStyle);
            if (content) {
                await removeCredits(1);
                setGeneratedContent(content);
                toast.success("Outline generated successfully");
            } else {
                toast.error("Failed to generate outline");
            }
        } catch (error) {
            toast.error("Failed to generate outline");
        } finally {
            setOutLineLoader(false);
        }
    };

    const handleApply = () => {
        if (generatedContent) {
            // Clean up double newlines and extra spaces
            const cleanedContent = generatedContent.replace(/\n\s*\n/g, '\n').trim();
            if (placement === "replace") {
                editor.chain().focus().selectAll().deleteSelection().insertContent(cleanedContent).updateCitationNumbers().run();
            } else if (placement === "before") {
                editor.chain().focus().insertContentAt(0, cleanedContent).updateCitationNumbers().run();
            } else {
                editor.chain().focus().insertContentAt(editor.state.doc.content.size, cleanedContent).updateCitationNumbers().run();
            }
            toast.success('Added to editor');
            onClose();
        }
    };

    const onClose = () => {
        setTopic("");
        setDescription("");
        setHeadings(5);
        setSubheadings(2);
        setShowFilters(false);
        setOutLineLoader(false);
        setGeneratedContent(null);
        setPlacement("replace");
    }

    return (
        <div>
            <div className="overflow-auto">
                <div className="space-y-4 py-4">
                    <div className="space-y-2">
                        <Label>Topic</Label>
                        <Input
                            placeholder="Enter topic for outline"
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                            disabled={outlineLoader}
                        />
                    </div>

                    <div className="space-y-2">
                        <Label>Description (Optional)</Label>
                        <textarea
                            className="w-full min-h-[100px] p-2 border rounded-md resize-y"
                            placeholder="Add more details about what you want in the outline..."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={outlineLoader}
                        />
                    </div>

                    <div className="space-y-2">
                        <Label>Number of Headings: {headings}</Label>
                        <Slider
                            value={[headings]}
                            onValueChange={(value) => setHeadings(value[0])}
                            min={1}
                            max={20}
                            step={1}
                            disabled={outlineLoader}
                        />
                    </div>

                    <div className="space-y-2">
                        <Label>Subheadings per Heading: {subheadings}</Label>
                        <Slider
                            value={[subheadings]}
                            onValueChange={(value) => setSubheadings(value[0])}
                            min={0}
                            max={10}
                            step={1}
                            disabled={outlineLoader}
                        />
                    </div>

                    <Button
                        variant="ghost"
                        className="text-[#A53E5A] hover:text-[#A53E5A]"
                        onClick={() => setShowFilters(!showFilters)}
                        disabled={outlineLoader}
                    >
                        {showFilters ? 'Hide Source Options' : 'Configure Sources'}
                    </Button>

                    {showFilters && (
                        <div className="border rounded-lg p-4 bg-white">
                            <FilterSidebar
                                filters={filters}
                                setFilters={setFilters}
                                isOpen={true}
                                onClose={() => setShowFilters(false)}
                                isSidebar={false}
                                showSubmitButton={false}
                                onApply={handleGenerate}
                                showTopBar={true}
                                showAnswerType={false}
                                showTurboMode={false}
                                showDoubleCheckCitations={false}
                                showCustomSections={false}
                            />
                        </div>
                    )}

                    {generatedContent && (
                        <>
                            <div className="border p-4 rounded-md bg-gray-100 max-h-48 overflow-y-auto">
                                <Label>Generated Outline</Label>
                                <div dangerouslySetInnerHTML={{ __html: generatedContent }} />
                            </div>

                            <div className="space-y-2">
                                <Label>Where would you like to place the generated outline?</Label>
                                <PlacementButtons placement={placement} setPlacement={setPlacement} />
                            </div>
                        </>
                    )}

                    <div className="flex justify-end space-x-2 pt-4">
                        <Button variant="outline" onClick={onClose} disabled={outlineLoader}>
                            Cancel
                        </Button>
                        {!generatedContent ? (
                            <Button
                                disabled={outlineLoader}
                                onClick={handleGenerate}
                                className="bg-[#A53E5A] hover:bg-[#8A3349] text-white"
                            >
                                {outlineLoader ? 'Generating...' : 'Generate Outline'}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleApply}
                                className="bg-[#A53E5A] hover:bg-[#8A3349] text-white"
                            >
                                Apply Outline
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

// Outline to Answer Modal
export function OutlineToAnswerModal({
    editor,
    activeDocument,
    citationStyle,
    projectId
}: {
    editor: Editor
    activeDocument?: WriterDocument | null
    citationStyle: citationStyles
    projectId: string
}) {
    const [answerLoader, setAnswerLoader] = useState<boolean>(false);
    const [topic, setTopic] = useState(activeDocument?.title || "")
    const [placement, setPlacement] = useState<"replace" | "before" | "after">("replace")
    const [generatedContent, setGeneratedContent] = useState<string>("");
    const { checkCredits, removeCredits } = useAuth();

    useEffect(() => {
        if (activeDocument?.title) {
            setTopic(activeDocument.title)
        }
    }, [activeDocument])

    const hasOutlineContent = () => {
        const content = editor.getHTML();
        return content && content.trim() !== '';
    }

    const handleGenerate = async () => {
        if (!projectId) {
            toast.error("Project ID is required");
            return;
        }

        if (!checkCredits(projectId, 1)) {
            return;
        }

        if (!topic) {
            toast.error("Please enter a topic")
            return
        }

        if (!hasOutlineContent()) {
            toast.error("Please generate an outline first")
            return
        }

        try {
            setAnswerLoader(true);
            const content = await outlineToAnswer(topic, editor, citationStyle)
            if (content) {
                await removeCredits(1);
                setGeneratedContent(content);
                toast.success("Answer generated successfully")
            } else {
                toast.error("Failed to generate answer");
            }
        } catch (error) {
            toast.error("Failed to generate answer")
        } finally {
            setAnswerLoader(false);
        }
    }

    const handleApply = () => {
        if (generatedContent) {
            // Clean up double newlines and extra spaces
            const cleanedContent = generatedContent.replace(/\n\s*\n/g, '\n').trim();
            if (placement === "replace") {
                editor.chain().focus().selectAll().deleteSelection().insertContent(cleanedContent).updateCitationNumbers().run();
            } else if (placement === "before") {
                editor.chain().focus().insertContentAt(0, cleanedContent).updateCitationNumbers().run();
            } else {
                editor.chain().focus().insertContentAt(editor.state.doc.content.size, cleanedContent).updateCitationNumbers().run();
            }
            toast.success('Added to editor');
            onClose();
        }
    };

    const onClose = () => {
        setTopic("")
        setAnswerLoader(false)
        setPlacement("replace")
        setGeneratedContent("")
    }

    return (
        <div>
            <div>
                <div className="space-y-4 py-4">
                    <div className="text-gray-600 bg-gray-50 p-3 rounded-md mb-4">
                        <p className="font-medium mb-1">Before converting to an answer:</p>
                        <ul className="list-disc pl-4 text-sm space-y-1">
                            <li>Make sure you've either generated an outline using the "Generate Outline" tab</li>
                            <li>Or added your own outline content to the editor</li>
                        </ul>
                    </div>
                    {!hasOutlineContent() && (
                        <div className="text-yellow-600 bg-yellow-50 p-3 rounded-md mb-4 border border-yellow-200">
                            No outline content detected. Please add or generate an outline first.
                        </div>
                    )}
                    <div className="space-y-2">
                        <Label>Topic</Label>
                        <Input
                            placeholder="Enter topic for conversion"
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                            disabled={answerLoader}
                        />
                    </div>

                    {generatedContent && (
                        <>
                            <div className="border p-4 rounded-md bg-gray-100 max-h-48 overflow-y-auto">
                                <Label>Generated Answer</Label>
                                <div dangerouslySetInnerHTML={{ __html: generatedContent }} />
                            </div>

                            <div className="space-y-2">
                                <Label>Where would you like to place the generated answer?</Label>
                                <PlacementButtons placement={placement} setPlacement={setPlacement} />
                            </div>
                        </>
                    )}

                    <div className="flex justify-end space-x-2">
                        <Button variant="outline" onClick={onClose} disabled={answerLoader}>Cancel</Button>
                        {!generatedContent ? (
                            <Button
                                disabled={answerLoader || !hasOutlineContent()}
                                onClick={handleGenerate}
                                className={`${!hasOutlineContent() ? "opacity-50 cursor-not-allowed" : ""} ${answerLoader ? "cursor-wait" : ""}`}
                            >
                                {answerLoader ? 'Generating...' : 'Generate Answer'}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleApply}
                                className="bg-[#A53E5A] hover:bg-[#8A3349] text-white"
                            >
                                Apply Answer
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function UpdateSectionModal({
    editor,
    projectId,
    citationStyle
}: {
    editor: Editor
    projectId: string
    citationStyle: citationStyles
}) {
    const [sectionLoader, setSectionLoader] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState(defaultFilters)
    const [selectedOption, setSelectedOption] = useState("")
    const [customPrompt, setCustomPrompt] = useState("")
    const [useNewSources, setUseNewSources] = useState(false)
    const [queryText, setQueryText] = useState("")
    const [answerData, setAnswerData] = useState<AnswerData | null>(null)
    const [placement, setPlacement] = useState<"replace" | "before" | "after">("replace")
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [selectionPreview, setSelectionPreview] = useState<string>("")
    const { checkCredits, removeCredits } = useAuth();
    const hasSelection = editor?.state.selection?.from !== editor?.state.selection?.to

    // Add selection change listener
    useEffect(() => {
        if (editor) {
            const updateSelection = () => {
                const hasActiveSelection = editor.state.selection.from !== editor.state.selection.to;
                if (hasActiveSelection) {
                    const preview = getSelectedText();
                    setSelectionPreview(preview);
                } else {
                    setSelectionPreview("");
                }
            };

            editor.on('selectionUpdate', updateSelection);
            // Initial check
            updateSelection();

            return () => {
                editor.off('selectionUpdate', updateSelection);
            };
        }
    }, [editor]);

    const updateOptions = [
        { id: "rephrase", label: "Reword", instruction: "Reword this text for clarity", requiresSelection: true },
        { id: "abstract", label: "Summarize", instruction: "Summarize this section", requiresSelection: false },
        { id: "research_question", label: "Generate Questions", instruction: "Generate research questions", requiresSelection: false },
        { id: "custom", label: "Custom Prompt", instruction: "", requiresSelection: false }
    ]

    const handleUpdate = async () => {
        setSectionLoader(true);
        setShowFilters(false);
        setAnswerData(null) // Reset preview
        if (!selectedOption) {
            toast.error("Please select an option")
            setSectionLoader(false)
            return
        }

        const option = updateOptions.find(opt => opt.id === selectedOption)
        if (!option) return

        const instruction = option.id === "custom" ? customPrompt : option.instruction
        const selection = editor.state.selection

        if (option.requiresSelection && selection.from === selection.to) {
            toast.error("Please highlight text first")
            setSectionLoader(false)
            return
        }

        try {
            const rawAnswerText = convertCitationsToChunkIds(selectionPreview)
            checkCredits(projectId!, 1);
            const newEditorContent = await updateDocumentContent(
                queryText,
                instruction,
                convertEditorToAnswer(editor),
                rawAnswerText,
                projectId,
                useNewSources ? filters : defaultFiltersNoDatabase
            )
            await removeCredits(1);
            const formattedOutline = await answerObjectListToFormattedAnswer(newEditorContent)
            setAnswerData(formattedOutline.answers[0])

        } catch (error) {
            toast.error("Failed to generate preview")
        } finally {
            setSectionLoader(false)
        }
    }

    const applyUpdate = async () => {
        if (!answerData) {
            toast.error("No content available to apply")
            return
        }

        try {
            const convertedAnswerEditorFormat = await convertAnswerToEditorFormat(answerData, citationStyle)
            const cleanedContent = convertedAnswerEditorFormat.replace(/\n\s*\n/g, '\n').trim();
            if (placement === "replace") {
                editor.chain().focus().deleteSelection().insertContent(cleanedContent).updateCitationNumbers().run()
            } else if (placement === "before") {
                editor.chain().focus().insertContentAt(editor.state.selection.from, cleanedContent).updateCitationNumbers().run()
            } else {
                editor.chain().focus().insertContentAt(editor.state.selection.to, cleanedContent).updateCitationNumbers().run()
            }

            toast.success("Update applied successfully")
        } catch (error) {
            toast.error("Failed to apply changes")
        }
        resetState()
    }

    const resetState = () => {
        setSelectedOption("")
        setCustomPrompt("")
        setAnswerData(null)
        setUseNewSources(false)
        setQueryText("")
        setFilters(defaultFiltersNoDatabase)
        setPlacement("replace")
        setShowFilters(false)
        setSectionLoader(false)
    }

    useEffect(() => {
        getSelectedText()
    }, [selectedOption])
    return (
        <div className="overflow-auto font-Manrope p-4 bg-white shadow-lg rounded-md">
            <div className="space-y-4">
                {/* Selection Status Banner */}
                <div className={`p-3 rounded-md ${hasSelection ? 'bg-blue-50 border border-blue-100' : 'bg-gray-50 border border-gray-100'}`}>
                    <div className="flex items-center justify-between mb-2">
                        <span className="font-medium text-sm">
                            {hasSelection ? '✏️ Working with selected text' : '📄 Working with entire document'}
                        </span>
                        {hasSelection && (
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => {
                                    editor.commands.setTextSelection({ from: 0, to: 0 });
                                }}
                                className="text-blue-700 hover:text-blue-800 text-sm"
                            >
                                Clear Selection
                            </Button>
                        )}
                    </div>
                    <p className="text-sm text-gray-600">
                        {hasSelection
                            ? "Your changes will affect only the selected text. You can clear the selection to work with the entire document instead."
                            : "No text selected. Your changes will affect the entire document. Select specific text if you want to modify only a portion."}
                    </p>
                    {selectionPreview && (
                        <div className="mt-2 border border-blue-200 bg-white p-2 rounded-md">
                            <p className="text-xs text-blue-700 mb-1">Selected Text Preview:</p>
                            <div className="text-sm text-gray-700 italic max-h-20 overflow-y-auto">
                                <div dangerouslySetInnerHTML={{ __html: selectionPreview }} />
                            </div>
                        </div>
                    )}
                </div>

                {/* Modification Type */}
                <div>
                    <Label className="text-lg font-semibold">Select Modification Type</Label>
                    <div className="grid grid-cols-2 gap-2 mt-2">
                        {updateOptions.map(option => (
                            <Button
                                key={option.id}
                                variant={selectedOption === option.id ? "default" : "outline"}
                                onClick={() => setSelectedOption(option.id)}
                                className={`w-full ${option.requiresSelection && !hasSelection ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={option.requiresSelection && !hasSelection}
                            >
                                {option.label}
                                {option.requiresSelection && !hasSelection && (
                                    <span className="text-xs block text-gray-500">(requires selection)</span>
                                )}
                            </Button>
                        ))}
                    </div>
                </div>

                {/* Custom Prompt Input */}
                {selectedOption === "custom" && (
                    <div className="space-y-2">
                        <Label className="font-medium">Custom Instruction</Label>
                        <Input
                            ref={inputRef}
                            placeholder="Enter custom instruction..."
                            value={customPrompt}
                            onChange={(e) => setCustomPrompt(e.target.value)}
                            className="border-gray-300 focus:ring focus:ring-blue-300"
                        />
                    </div>
                )}

                {/* Source Selection & Filters */}
                <div className="space-y-2 border-t pt-4">
                    <Label className="text-base font-medium">Source Selection</Label>
                    <div className="bg-gray-50 p-3 rounded-md">
                        <div className="flex items-center gap-3">
                            <Switch
                                checked={useNewSources}
                                onCheckedChange={setUseNewSources}
                                id="source-mode"
                            />
                            <div className="flex-1">
                                <Label htmlFor="source-mode" className="text-sm font-medium">
                                    Would you like AnswerThis to search for new sources?
                                </Label>
                                <p className="text-xs text-gray-600">
                                    {useNewSources
                                        ? "AnswerThis will search its database for additional relevant sources to enhance your content"
                                        : "AnswerThis will only use the sources already cited in your document"}
                                </p>
                            </div>
                        </div>
                    </div>
                    {useNewSources && (
                        <div className="space-y-2 mt-3">
                            <div className="space-y-2">
                                <Label>What should we search for?</Label>
                                <Input
                                    placeholder="Enter the topic you'd like to find new sources about..."
                                    value={queryText}
                                    onChange={(e) => setQueryText(e.target.value)}
                                    className="border-gray-300 focus:ring focus:ring-blue-300"
                                />
                            </div>
                            <Button variant="outline" size="sm" onClick={() => setShowFilters(true)}>
                                <FileSearch className="h-4 w-4 mr-2" />
                                Customize Source Settings
                            </Button>
                        </div>
                    )}
                </div>

                {showFilters && (
                    <FilterSidebar
                        filters={filters}
                        setFilters={setFilters}
                        isOpen={showFilters}
                        onClose={() => setShowFilters(false)}
                        onApply={applyUpdate}
                        isSidebar={false}
                        showAnswerType={false}
                        showTurboMode={false}
                        showDoubleCheckCitations={false}
                        showCustomSections={false}
                        showSubmitButton={false}
                    />
                )}

                {/* Preview and Placement Options */}
                {answerData && (
                    <>
                        <div className="border p-4 rounded-md bg-gray-100 max-h-48 overflow-y-auto">
                            <Label>Preview of Changes</Label>
                            <div dangerouslySetInnerHTML={{ __html: answerData.formattedAnswer }} />
                        </div>

                        <div className="space-y-2">
                            <Label>Where would you like to place the changes?</Label>
                            <PlacementButtons
                                placement={placement}
                                setPlacement={setPlacement}
                                hasSelection={hasSelection}
                            />
                        </div>
                    </>
                )}

                <div className="flex justify-end space-x-2 pt-6">
                    <Button variant="outline" onClick={resetState}>Cancel</Button>
                    {!answerData && (
                        <Button
                            disabled={sectionLoader}
                            onClick={handleUpdate}
                            className="bg-[#A53E5A] hover:bg-[#8A3349] text-white"
                        >
                            {sectionLoader ? "Generating..." : "Generate"}
                        </Button>
                    )}
                    {answerData && (
                        <Button
                            onClick={applyUpdate}
                            className="bg-[#A53E5A] hover:bg-[#8A3349] text-white"
                        >
                            Apply Changes
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )

    function getSelectedText() {
        const selection = editor.state.selection
        const slice = editor.state.doc.slice(selection.from, selection.to)
        const serializer = DOMSerializer.fromSchema(editor.schema)
        const tempDiv = document.createElement("div")
        slice.content.forEach(node => {
            tempDiv.appendChild(serializer.serializeNode(node))
        })
        const selectedHtml = tempDiv.innerHTML
        setSelectionPreview(selectedHtml) // Store preview text
        return selectedHtml
    }
}
