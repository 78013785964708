import { BubbleMenu, Editor } from '@tiptap/react';
import { useRef } from 'react';
import { toast } from 'sonner';
import { updateSectionRephrase, updateSectionCustom, elongateOutline } from './updateSectionHelper';
import { Bold, Edit3, Expand, Italic, Loader, RotateCcw, Strikethrough } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { citationStyles } from '@/pages/Answer/citationUtils.tsx';

export function FloatingMenu({
    customPrompt, editor, projectId, setCustomPrompt, setUpdateLoading, updateLoading, citationStyle,
}: FloatingMenuProps) {
    // Store a reference to the custom prompt input.
    const inputRef = useRef<HTMLInputElement>(null);
    // Save the editor selection before the custom prompt is interacted with.
    const savedSelectionRef = useRef(editor.state.selection);
    const { checkCredits, removeCredits } = useAuth();

    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            className="bubble-menu-custom p-3 bg-white shadow-lg rounded-lg text-brandMaroon font-Manrope flex flex-wrap gap-3 w-full"
        >
            <div className="flex flex-row">
                {/* Basic Formatting Buttons */}
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={`bubble-btn p-2 rounded hover:bg-gray-100 ${editor.isActive('bold') ? 'bg-brandMaroon text-white' : ''}`}
                >
                    <Bold size={16} />
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={`bubble-btn p-2 rounded hover:bg-gray-100 ${editor.isActive('italic') ? 'bg-brandMaroon text-white' : ''}`}
                >
                    <Italic size={16} />
                </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={`bubble-btn p-2 rounded hover:bg-gray-100 ${editor.isActive('strike') ? 'bg-brandMaroon text-white' : ''}`}
                >
                    <Strikethrough size={16} />
                </button>

                {/* Update Section Buttons */}
                <button
                    disabled={updateLoading}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={async () => {
                        checkCredits(projectId!, 1);

                        setUpdateLoading(true);
                        try {
                            await updateSectionRephrase(editor, projectId!, citationStyle);
                            await removeCredits(1);
                            toast.success('Section updated successfully');
                        } catch (error: Error | unknown) {
                            toast.error(error instanceof Error ? error.message : 'Failed to update section');
                        } finally {
                            setUpdateLoading(false);
                        }
                    }}
                    className="bubble-btn flex items-center gap-1 p-2 rounded hover:bg-gray-100 disabled:opacity-50"
                >
                    {updateLoading ? <Loader className="animate-spin" size={16} /> : <RotateCcw size={16} />}
                    <span className="hidden sm:inline">Rephrase</span>
                </button>
                {/* <button
                disabled={updateLoading}
                onMouseDown={(e) => e.preventDefault()}
                onClick={async () => {
                    setUpdateLoading(true);
                    try {
                        await updateSectionElongate(editor, projectId!, citationStyle);
                        toast.success('Section updated successfully');
                    } catch (error: Error | unknown) {
                        toast.error(error instanceof Error ? error.message : 'Failed to update section');
                    } finally {
                        setUpdateLoading(false);
                    }
                }}
                className="bubble-btn flex items-center gap-1 p-2 rounded hover:bg-gray-100 disabled:opacity-50"
            >
                {updateLoading ? <Loader className="animate-spin" size={16} /> : <Expand size={16} />}
                <span className="hidden sm:inline">Elongate</span>
            </button> */}
                <button
                    disabled={updateLoading}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={async () => {
                        checkCredits(projectId!, 1);

                        setUpdateLoading(true);
                        try {
                            await elongateOutline(editor, projectId!, citationStyle);
                            await removeCredits(1);
                            toast.success('Section updated successfully');
                        } catch (error: Error | unknown) {
                            toast.error(error instanceof Error ? error.message : 'Failed to update section');
                        } finally {
                            setUpdateLoading(false);
                        }
                    }}
                    className="bubble-btn flex items-center gap-1 p-2 rounded hover:bg-gray-100 disabled:opacity-50"
                >
                    {updateLoading ? <Loader className="animate-spin" size={16} /> : <Expand size={16} />}
                    <span className="hidden sm:inline">Expand Outline</span>
                </button>
            </div>

            {/* Custom Prompt Input & Button */}
            <div className="flex items-center gap-2">
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Custom prompt"
                    value={customPrompt}
                    // onMouseDown prevents the default behavior (which would remove the selection)
                    // and saves the current editor selection.
                    onMouseDown={(e) => {
                        savedSelectionRef.current = editor.state.selection;
                        e.preventDefault();
                        // Use a timeout so that the input is focused after preventing the default blur.
                        setTimeout(() => {
                            inputRef.current?.focus();
                        }, 0);
                    }}
                    onChange={(e) => {
                        setCustomPrompt(e.target.value);
                        // Restore the previously saved editor selection so that the text remains selected.
                        editor.view.dispatch(editor.state.tr.setSelection(savedSelectionRef.current));
                    }}
                    className="border border-gray-300 rounded p-2 text-sm font-Manrope" />
                <button
                    disabled={updateLoading}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={async () => {
                        checkCredits(projectId!, 1);
                        setUpdateLoading(true);
                        try {
                            await updateSectionCustom(editor, projectId!, customPrompt, citationStyle);
                            await removeCredits(1);
                            toast.success('Section updated successfully');
                        } catch (error: Error | unknown) {
                            toast.error(error instanceof Error ? error.message : 'Failed to update section');
                        } finally {
                            setUpdateLoading(false);
                        }
                        setCustomPrompt('');
                    }}
                    className="bubble-btn flex items-center gap-1 p-2 rounded hover:bg-gray-100 disabled:opacity-50"
                >
                    {updateLoading ? <Loader className="animate-spin" size={16} /> : <Edit3 size={16} />}
                    <span className="hidden sm:inline">Enter</span>
                </button>
            </div>
        </BubbleMenu>
    );
} export interface FloatingMenuProps {
    customPrompt: string;
    editor: Editor;
    projectId: string | undefined;
    setCustomPrompt: React.Dispatch<React.SetStateAction<string>>;
    setUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
    updateLoading: boolean;
    citationStyle: citationStyles;
}

