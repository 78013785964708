import { FormEvent, useState } from "react";
import Popup from "reactjs-popup";
import { submitReview } from "../../authService";
import { toast } from "sonner";

interface AnswerReviewProps {
    queryHash: string;
}

export function AnswerReview({ queryHash }: AnswerReviewProps) {
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
    };

    const handleGoodReviewClick = async (): Promise<void> => {
        try {
            await submitReview(queryHash, "good");
            toast.success("Thank you for your feedback!");
        } catch (error) {
            toast.error("Failed to submit feedback.");
        }
    };

    const handleBadReviewClick = (): void => {
        setOpen(true);
    };

    const handleBadReviewSubmit = async (
        event: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const comment = formData.get("reviewResponse") as string;

        try {
            await submitReview(queryHash, "bad", comment);
            toast.success("Thank you for your feedback!");
            setOpen(false);
        } catch (error) {
            toast.error("Failed to submit feedback.");
        }
    };

    return (
        <div className="helpful-review">
            <p>Was this answer helpful?</p>
            <img
                className="thumb-up"
                src="/img/thumb-up.svg"
                alt="Thumbs Up Icon"
                onClick={handleGoodReviewClick}
            />
            <Popup open={open} onClose={closeModal} modal nested>

                <div className="popup">
                    <button
                        className="close-button"
                        onClick={closeModal}
                        aria-label="Close"
                    >
                        ×
                    </button>
                    <form onSubmit={handleBadReviewSubmit}>
                        <h3>Give us Feedback!</h3>
                        <label htmlFor="reviewResponse">
                            Let us know how we can be better!
                        </label>
                        <textarea
                            id="reviewResponse"
                            name="reviewResponse"
                            className="review-response"
                        ></textarea>
                        <button type="submit" className="submit-button">
                            Send
                        </button>
                        <button
                            type="button"
                            className="cancel-button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                    </form>
                </div>
            </Popup>
            <img
                className="thumb-down"
                src="/img/thumb-down.svg"
                alt="Thumbs Down Icon"
                onClick={handleBadReviewClick}
            />
        </div>
    );
}