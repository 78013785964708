import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import FloatingPricingPopup from './FloatingPricingPopup';

/**
 * This component acts as a container for the FloatingPricingPopup
 * It should be placed in the component tree where it has access to the Router context
 */
const PricingPopupContainer: React.FC = () => {
    const { pricingPopupState, closePricingPopup } = useAuth();

    if (!pricingPopupState.isOpen || !pricingPopupState.projectId) {
        return null;
    }

    return (
        <FloatingPricingPopup
            isOpen={pricingPopupState.isOpen}
            onClose={closePricingPopup}
            projectId={pricingPopupState.projectId}
        />
    );
};

export default PricingPopupContainer; 