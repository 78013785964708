import StarterKit from '@tiptap/starter-kit';
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import CodeBlock from "@tiptap/extension-code-block";
import HardBreak from "@tiptap/extension-hard-break";
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Color from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Placeholder from "@tiptap/extension-placeholder";
import Highlight from "@tiptap/extension-highlight";
import { Citation } from './extension/citation.tsx';
import Heading from '@tiptap/extension-heading';

export const CustomDocument = Document.extend({
    content: 'heading block*',
})
export const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    StarterKit.configure({
        document: false,
        bulletList: { keepMarks: true, keepAttributes: true, HTMLAttributes: { class: "list-disc" } },
        orderedList: { keepMarks: true, keepAttributes: true, HTMLAttributes: { class: "list-decimal" } },
        heading: {
            levels: [1, 2, 3, 4],
            HTMLAttributes: {
                class: 'font-bold my-4',
            }
        },
    }),
    CustomDocument,

    Placeholder.configure({
        emptyNodeClass: "is-editor-empty",
        placeholder: ({ node }) => {
            switch (node.type.name) {
                case "heading":
                    return `What's in your mind..`;
                case "detailsSummary":
                    return "Section title";
                case "codeBlock":
                    return "";
                default:
                    return "Start writing or press / to open the command menu";
            }
        },
        includeChildren: true,
    }),
    Document,
    Paragraph.configure({
        HTMLAttributes: {
            class: 'my-paragraph',
        },
    }),
    Text,
    CodeBlock,
    HardBreak,
    Table.configure({
        resizable: true,
        HTMLAttributes: {
            class: 'isolated-table',
        },
        allowTableNodeSelection: true,
    }),
    TableRow.configure({
        HTMLAttributes: {
            class: 'table-row',
        },
    }),
    TableHeader.configure({
        HTMLAttributes: {
            class: 'table-header',
        },
    }),
    TableCell.configure({
        HTMLAttributes: {
            class: 'table-cell',
        },
    }),
    Underline,
    TextAlign.configure({ types: ['heading', 'paragraph'] }),
    TextStyle,
    Link,
    Image.configure({
        HTMLAttributes: {
            class: 'max-w-full rounded-lg',
        },
        allowBase64: true,
        inline: false,
    }),
    Citation,
    Highlight.configure({
        multicolor: true,
    }),
];
extensions.push(
    Heading.configure({
        levels: [1, 2, 3, 4],
        HTMLAttributes: {
            class: 'font-bold my-4', // Adjust spacing and style
        },
    })
);
