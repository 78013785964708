import React from 'react';
import { WriterDocument } from './types';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
export interface DocumentSettingsProps {
    activeDocument: WriterDocument;
    editDescription: string;
    editTitle: string;
    handleUpdateDocument: () => Promise<void>;
    isPublic: boolean;
    setEditDescription: React.Dispatch<React.SetStateAction<string>>;
    setEditTitle: React.Dispatch<React.SetStateAction<string>>;
    setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentSettings = ({ activeDocument,
    editDescription,
    editTitle,
    handleUpdateDocument,
    isPublic,
    setEditDescription,
    setEditTitle,
    setIsPublic }: DocumentSettingsProps) => {
    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <label className="text-sm font-medium">Document Title</label>
                <Input
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                    className="h-10"
                    placeholder="Enter document title"
                />
            </div>

            <div className="space-y-2">
                <label className="text-sm font-medium">Description</label>
                <Textarea
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                    placeholder="Enter document description"
                    className="min-h-[100px]"
                />
            </div>

            <div className="space-y-2">
                <label className="text-sm font-medium">Visibility</label>
                <Select
                    value={isPublic ? "public" : "private"}
                    onValueChange={(value) => setIsPublic(value === "public")}
                >
                    <SelectTrigger>
                        <SelectValue placeholder="Select visibility" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="private">Private</SelectItem>
                        <SelectItem value="public">Public</SelectItem>
                    </SelectContent>
                </Select>
            </div>

            <Button
                className="w-full bg-brandMaroon hover:bg-brandMaroon/90"
                onClick={handleUpdateDocument}
            >
                Save Changes
            </Button>

            <div className="space-y-4 pt-4 border-t">
                <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Last updated</span>
                    <span className="text-sm">
                        {activeDocument?.updated_at ?
                            new Date(activeDocument.updated_at).toLocaleDateString() :
                            'N/A'}
                    </span>
                </div>
                <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Created</span>
                    <span className="text-sm">
                        {activeDocument?.created_at ?
                            new Date(activeDocument.created_at).toLocaleDateString() :
                            'N/A'}
                    </span>
                </div>
            </div>
        </div>
    );
}


