import React, { useState, useEffect } from 'react';
import './promptHelper.css';
import { toast } from 'sonner';
import { ChevronDownIcon, MousePointerClick } from 'lucide-react';
import ExpandableSection from './expandableSections/ExpandableSection';

interface PromptHelperProps {
    onSelectPrompt: (prompt: string) => void;
}

const PromptHelper: React.FC<PromptHelperProps> = ({ onSelectPrompt }) => {
    const [action, setAction] = useState<string>('');
    const [researchQuestion, setResearchQuestion] = useState<string>('');
    const [variables, setVariables] = useState<string>('');
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const actions: { [key: string]: string } = {
        findRelevantStudies: "Find relevant studies on",
        literatureReview: "Write a literature review on",
        quantitativeAnalysis: "Perform a quantitative analysis on",
        suggestQuestions: "Suggest research questions for",
        askFromPDFs: "Ask questions from PDFs related to",
        customSearch: "",
        researchGap: "Identify gaps in literature related to",
        researchOutline: "Generate a research outline on",
        researchProposal: "Generate a research proposal on",
        researchTopic: "Generate research topics on",
    };

    const handleCreatePrompt = (): void => {
        if (researchQuestion === '') {
            toast.error("Please enter a research question")
            // alert('Please enter a research question');
            return;
        }
        if (action === 'quantitativeAnalysis' && variables === '') {
            toast.error('Please enter variables to track')
            // alert('Please enter variables to track');
            return;
        }
        let prompt = "";
        switch (action) {
            case 'quantitativeAnalysis':
                prompt = `${actions[action]} topic: '${researchQuestion}' while tracking ${variables}`;
                break;
            default:
                prompt = `${actions[action]} ${researchQuestion}`;
        }
        setIsExpanded(false);

        onSelectPrompt(prompt);
    };

    useEffect(() => {
        // Reset researchQuestion and variables whenever action changes
        setResearchQuestion('');
        setVariables('');
    }, [action]);
    return (
        <div className="prompt-helper-container">
            <ExpandableSection
                title='Prompt Helper'
                isExpandedForce={isExpanded}
                onExpand={() => setIsExpanded(true)}
                titleClass="w-full flex items-center justify-between p-4 text-left font-semibold text-brandMaroon hover:bg-gray-100 transition-colors duration-200 cursor-pointer rounded-lg"
            >
                <div className="w-full bg-white rounded-lg ">
                    <div className="relative">
                        <select
                            value={action}
                            onChange={(e) => setAction(e.target.value)}
                            className="w-full p-2 pr-8 mt-2 mb-3 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
                        >
                            <option value="">Click to select a tool</option>
                            {Object.entries(actions).map(([key, value]) => (
                                <option key={key} value={key}>{value || key}</option>
                            ))}
                        </select>
                        <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
                    </div>

                    {action && (
                        <input
                            type="text"
                            placeholder="Type your Research Area"
                            value={researchQuestion}
                            onChange={(e) => setResearchQuestion(e.target.value)}
                            className="w-full p-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    )}
                    {action === 'quantitativeAnalysis' && (
                        <input
                            type="text"
                            placeholder="List variables to track"
                            value={variables}
                            onChange={(e) => setVariables(e.target.value)}
                            className="w-full p-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    )}

                    {action !== "" && (
                        <button
                            className="w-full mt-4 p-2 bg-[#A53E5A] text-white rounded-lg hover:bg-[#8e3249] transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#A53E5A]"
                            onClick={handleCreatePrompt}
                        >
                            Create
                        </button>
                    )}
                </div>
            </ExpandableSection>

            {/* Add a visual indicator when collapsed */}
            {!isExpanded && (
                <div className="flex items-center justify-center w-full p-2 text-sm text-gray-500 hover:text-brandMaroon cursor-pointer" onClick={() => setIsExpanded(true)}>
                    <MousePointerClick className="w-4 h-4 mr-1" />
                    <span>Click to open Prompt Helper</span>
                </div>
            )}
        </div>
    );
};

export default PromptHelper;
