import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

const LoadingSkeleton = () => {
    return (
        <div className="w-full h-full min-h-screen-header bg-white p-4 space-y-4">
            {/* Header Skeleton */}
            <div className="space-y-2">
                <Skeleton className="h-8 w-48 bg-lightMaroon/20" />
                <Skeleton className="h-4 w-64 bg-lightMaroon/20" />
            </div>

            {/* Main Content Skeleton */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
                {[...Array(19)].map((_, i) => (
                    <Card key={i} className="p-4 space-y-4">
                        <Skeleton className="h-32 w-full bg-lightMaroon/20" />

                    </Card>
                ))}
            </div>

            {/* Loading overlay with shimmer */}
            <div className="fixed inset-0 pointer-events-none">
                <div className="absolute inset-0 animate-[shimmer_2s_infinite]"
                    style={{
                        background: `linear-gradient(
                 90deg,
                 transparent 0%,
                 rgba(165, 62, 90, 0.05) 50%,
                 transparent 100%
               )`
                    }}
                />
            </div>
        </div>
    );
};

export default LoadingSkeleton;