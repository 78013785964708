import { Helmet } from 'react-helmet';


const SupportScript = () => (
  <Helmet>
    {/* <script>
      {`
      (function(d, w, c) {
        w.BrevoConversationsID = '65e953698a5514503862ac8d';
      w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
        };
      var s = d.createElement('script');
      s.async = true;
      s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'BrevoConversations');
    `}
    
    </script> */}
  </Helmet>
);

export default SupportScript;