import { DiagramType } from "./api";

// Group diagram types by category
export const DIAGRAM_CATEGORIES: Record<string, string[]> = {
    'All': ['flowchart', 'sequence', 'stateDiagram', 'gitGraph', 'classDiagram', 'erDiagram', 'journey', 'gantt', 'timeline', 'mindmap', 'pie', 'xyChart'],
    'Process & Flow': ['flowchart', 'sequence', 'stateDiagram', 'gitGraph'],
    'Structure': ['classDiagram', 'erDiagram'],
    'Planning': ['journey', 'gantt', 'timeline'],
    'Analysis': ['mindmap'],
    'Charts': ['pie', 'xyChart']
};
export const DIAGRAM_TYPES: Record<string, DiagramType> = {
    flowchart: {
        title: "Flowchart",
        description: "For process flows and workflows",
        orientation: ["TD", "LR"],
        defaultStyle: {
            nodeColor: "#bbf7d0",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    sequenceDiagram: {
        title: "Sequence Diagram",
        description: "For showing interactions between components over time",
        defaultStyle: {
            nodeColor: "#fef3c7",
            edgeColor: "#333333",
            backgroundColor: "#ffffff",
            actorColor: "#fef3c7",
            messageColor: "#333333"
        }
    },
    classDiagram: {
        title: "Class Diagram",
        description: "For showing object-oriented class structures",
        defaultStyle: {
            nodeColor: "#dbeafe",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    stateDiagram: {
        title: "State Diagram",
        description: "For showing state transitions and behavior",
        defaultStyle: {
            nodeColor: "#fae8ff",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    erDiagram: {
        title: "Entity Relationship Diagram",
        description: "For database schema and relationships",
        defaultStyle: {
            nodeColor: "#fee2e2",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    journey: {
        title: "User Journey",
        description: "For mapping user experiences and paths",
        defaultStyle: {
            nodeColor: "#d1fae5",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    gantt: {
        title: "Gantt Chart",
        description: "For project timelines and scheduling",
        defaultStyle: {
            nodeColor: "#fef3c7",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    pie: {
        title: "Pie Chart",
        description: "For showing proportional data distribution",
        defaultStyle: {
            nodeColor: "#ddd6fe",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    quadrantChart: {
        title: "Quadrant Chart",
        description: "For 2x2 matrix analysis",
        defaultStyle: {
            nodeColor: "#bfdbfe",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    requirementDiagram: {
        title: "Requirement Diagram",
        description: "For software requirements and dependencies",
        defaultStyle: {
            nodeColor: "#fecaca",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    gitGraph: {
        title: "Git Graph",
        description: "For visualizing Git workflows and branches",
        defaultStyle: {
            nodeColor: "#d1fae5",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    c4: {
        title: "C4 Diagram",
        description: "For software architecture visualization",
        defaultStyle: {
            nodeColor: "#e0e7ff",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    mindmap: {
        title: "Mind Map",
        description: "For hierarchical concept maps",
        defaultStyle: {
            nodeColor: "#dbeafe",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    timeline: {
        title: "Timeline",
        description: "For chronological event sequences",
        defaultStyle: {
            nodeColor: "#fce7f3",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },

    xyChart: {
        title: "XY Chart",
        description: "For plotting data points on a coordinate system",
        defaultStyle: {
            nodeColor: "#bfdbfe",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    block: {
        title: "Block Diagram",
        description: "For system components and their connections",
        defaultStyle: {
            nodeColor: "#fef3c7",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    },
    packet: {
        title: "Packet Diagram",
        description: "For network packet structures and flows",
        defaultStyle: {
            nodeColor: "#ddd6fe",
            edgeColor: "#333333",
            backgroundColor: "#ffffff"
        }
    }
};

// Example code snippets for each diagram type
export const DIAGRAM_EXAMPLES: Record<string, { code: string; }> = {
    flowchart: {
        code: `graph TD
    A[Start] --> B[Process]
    B --> C[End]`
    },
    sequenceDiagram: {
        code: `sequenceDiagram
    Alice->>John: Hello
    John-->>Alice: Hi`
    },
    classDiagram: {
        code: `classDiagram
    Animal <|-- Dog
    Animal : +makeSound()`
    },
    stateDiagram: {
        code: `stateDiagram-v2
    [*] --> Active
    Active --> [*]`
    },
    erDiagram: {
        code: `erDiagram
    USER ||--o{ ORDER : places`
    },
    journey: {
        code: `journey
    title My Journey
    section Go to work
      Make tea: 5: Me`
    },
    gantt: {
        code: `gantt
    title Timeline
    section Project
    Task :a1, 2024-01-01, 30d`
    },
    pie: {
        code: `pie title Market
    "A" : 40
    "B" : 60`
    },
    quadrantChart: {
        code: `quadrantChart
    title Portfolio
    x-axis Low --> High
    y-axis Low --> High`
    },
    mindmap: {
        code: `mindmap
    root((mindmap))
      Origins
      Long history`
    },

    xyChart: {
        code: `xychart-beta
    title "Data"
    x-axis [1, 2, 3]
    line [10, 20, 30]`
    },
    block: {
        code: `graph TD
    A[System] --> B[DB]`
    },
    packet: {
        code: `sequenceDiagram
    Client->>Server: SYN
    Server-->>Client: SYN-ACK
    Client->>Server: ACK`
    },
    gitGraph: {
        code: `gitGraph
    commit
    branch dev
    commit`
    },
    c4: {
        code: `C4Context
    title System Context
    System_Ext(sys, "System")`
    },
    requirementDiagram: {
        code: `requirementDiagram
    requirement test_req {
      id: 1
      text: Auth
    }`
    },
    timeline: {
        code: `timeline
    title History
    section 2024
      Event : details`
    }
};


