import React from 'react';
import { SourceInfo, AnswerObject, Filters } from '../authService';
import assistant from "/img/assistant.png";
import user_img from "/img/profile.png";
import AnswerView from './AnswerView';
import { AddToEditorButton } from './editor/addToEditor';
import { Editor } from '@tiptap/react'

interface AnswerViewProps {
    id: string;
    answer: {
        formattedAnswer: string;
        answerObject: AnswerObject;
        papersArraySorted: SourceInfo[];
    };
    chatIndex: string;
    index: number;
    handleAnswerUpdate: (updatedAnswer: string, answerIndex: number) => void;
    answerFilters?: Filters;
    compactUi?: boolean;
    editor?: Editor | null;
}

const AnswerChatView: React.FC<AnswerViewProps> = ({
    id,
    answer,
    index,
    chatIndex,
    handleAnswerUpdate,
    answerFilters,
    compactUi = false,
    editor = null,
}) => {

    return (
        <>
            <div className="flex justify-end mb-4">
                <div className="flex flex-row-reverse items-end max-w-[80%]">
                    <img
                        src={user_img}
                        alt="User"
                        className="h-8 w-8 rounded-full ml-2"
                    />
                    <div className="p-3 rounded-lg bg-brandMaroon text-white rounded-br-none">
                        <p>{answer.answerObject.query}</p>
                    </div>
                </div>
            </div>
            <div className="flex items-start mb-4 mt-4">
                <img src={assistant} alt="Assistant" className="w-8 h-8 rounded-full mr-2" />
                <div className="flex-1">
                    {editor && (<div className="flex justify-between items-start mb-2">
                        <AddToEditorButton answer={answer} editor={editor} />
                    </div>)}

                    <AnswerView
                        answer={answer}
                        queryHash={chatIndex || ""}
                        id={id}
                        onAnswerUpdate={(updatedAnswer: string) => handleAnswerUpdate(updatedAnswer, index)}
                        answerIndex={index}
                        answerFilters={answerFilters}
                        compactUi={compactUi}
                    />

                </div>
            </div>
        </>
    );
};

export default AnswerChatView;
