import React from 'react';
import { X, Check, Zap } from 'lucide-react';

interface ExitIntentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onUpgrade: () => void;
}

const ExitIntentModal: React.FC<ExitIntentModalProps> = ({
    isOpen,
    onClose,
    onUpgrade
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-[60] flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative animate-fadeIn">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                >
                    <X size={20} />
                </button>

                <div className="text-center mb-6">
                    <div className="w-16 h-16 bg-brandMaroon/10 rounded-full flex items-center justify-center mx-auto mb-4">
                        <Zap size={32} className="text-brandMaroon" />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">Wait! Don't miss out!</h3>
                    <p className="text-gray-600 mb-4">
                        <span className="font-semibold">Everyday hundreds of researchers are joining AnswerThis.</span> Don't get left behind in your research journey.
                    </p>

                    <div className="bg-gray-50 p-4 rounded-lg mb-6">
                        <p className="font-semibold text-gray-800 mb-2">Join researchers who are:</p>
                        <ul className="text-left text-gray-600 space-y-2">
                            <li className="flex items-start">
                                <Check size={20} className="text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                                <span>Completing literature reviews 65% faster</span>
                            </li>
                            <li className="flex items-start">
                                <Check size={20} className="text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                                <span>Saving 60+ hours on each research paper</span>
                            </li>
                            <li className="flex items-start">
                                <Check size={20} className="text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                                <span>Publishing more papers with better citations</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <button
                        onClick={onUpgrade}
                        className="w-full py-3 px-4 bg-brandMaroon text-white rounded-lg font-semibold hover:bg-brandMaroon/90 transition-colors"
                    >
                        Upgrade to Pro Now
                    </button>
                    <button
                        onClick={onClose}
                        className="w-full py-3 px-4 text-gray-500 rounded-lg font-medium hover:bg-gray-100 transition-colors"
                    >
                        No thanks, I prefer manual research
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExitIntentModal; 