import React from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'sonner';
import Header from './components/Homepage/Header.tsx';
import "./components/Sidebar.css";
import Footer from './components/Footer.tsx';

const HeaderLayout: React.FC = () => {
    return (
        <div className="min-h-screen relative">
            <div className="fixed top-0 left-0 right-0 z-50">
                <Header />
            </div>
            <div className="">
                <Toaster closeButton expand={false} richColors position="top-center" />
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default HeaderLayout;