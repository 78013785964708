import React, { useEffect, useState } from 'react';
import { X, Search, LogOut, User, ChevronLeft, Book, Plus, BookOpen, FileText, Folders, List, Headphones, Contact, Gem, ChevronRight, BookMarked, Network, PaintBucket } from 'lucide-react';
import {
    deleteQuery,
    RecentSearch,
} from '../../authService';
import { SkeletonLoader } from '../loaders/skeletal';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "/img/assistant.png";
import { toast } from 'sonner';
import { useSidebar } from '../../contexts/sidebarContext';
import { useAuth } from '../../contexts/AuthContext';
import SlicedText from '../slicedText';
import ExpandableSection from '../expandableSections/ExpandableSection';

interface SidebarProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    isMobile: boolean;
}

interface NavigationItem {
    id: string;
    label: string;
    icon: React.ElementType;
    path: string;
    isPro: boolean;
    action?: () => void;
    hidden?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ id, isOpen, onClose, isMobile }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setCurrentProjectId, chats, recentSearches, refreshSidebar, isLoading, currentProjectId } = useSidebar();
    const { user, isPro, logout, togglePricingPopup } = useAuth();
    const [activeTab, setActiveTab] = useState<string>('');
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [tooltipItem, setTooltipItem] = useState<string | null>(null);
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);
    const [collapseButtonHovered, setCollapseButtonHovered] = useState<boolean>(false);
    const [sidebarLocked, setSidebarLocked] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            setCurrentProjectId(id);
        }
    }, [id, setCurrentProjectId]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabFromUrl = searchParams.get('tab');
        if (tabFromUrl) {
            setActiveTab(tabFromUrl);
        } else {
            const currentPath = location.pathname.split('/').pop();
            if (currentPath) {
                setActiveTab(currentPath);
            } else {
                // Don't set a default active tab
                setActiveTab('');
            }
        }
    }, [location]);

    // When sidebar toggle state changes, dispatch an event to inform the layout
    useEffect(() => {
        // Only dispatch event for non-mobile views
        if (!isMobile) {
            document.dispatchEvent(new CustomEvent('sidebarToggle', {
                detail: { collapsed }
            }));
        }
    }, [collapsed, isMobile]);

    const handleCollapse = () => {
        setCollapsed(!collapsed);
        // Clear hover state when manually collapsing/expanding
        setIsHovering(false);
        setHoveredItem(null);

        // Temporarily lock the sidebar to prevent hover expansion right after manual collapse
        setSidebarLocked(true);
        setTimeout(() => {
            setSidebarLocked(false);
        }, 150); // Faster prevention time for snappier response

        if (isMobile && !collapsed) {
            onClose();
        }
    };

    // We're no longer using handleMouseEnter for the entire sidebar
    // Instead, we'll handle hover on individual navigation items

    const handleMouseLeave = () => {
        // When mouse leaves the sidebar completely, collapse it immediately if it was expanded by hover
        if (collapsed && isHovering && !isMobile) {
            setIsHovering(false);
            setHoveredItem(null);
            setTooltipItem(null);
        } else if (!collapseButtonHovered) {
            // Short delay to prevent sidebar flicker when moving between items - make it snappier
            setTimeout(() => {
                if (!collapseButtonHovered) {
                    setIsHovering(false);
                    setHoveredItem(null);
                }
                setTooltipItem(null);
            }, 20);
        }
    };

    const handleItemMouseEnter = (itemId: string) => {
        setTooltipItem(itemId);
        // Only expand sidebar on hover when in collapsed state, not mobile, and not locked
        // Don't expand on pricing icon hover
        if (collapsed && !isMobile && !sidebarLocked && itemId !== 'pricing') {
            setIsHovering(true);
            setHoveredItem(itemId);
        } else if (!collapsed) {
            // Just set the hovered item without expanding
            setHoveredItem(itemId);
        }
    };

    const handleItemMouseLeave = () => {
        setTooltipItem(null);
        // Clear the highlighted item when no longer hovering
        setTimeout(() => {
            // Only clear hovered item if we're not hovering on another item
            if (!sidebarLocked) {
                setHoveredItem(null);
            }
        }, 20); // Faster delay to prevent flicker
    };

    const handleCollapseButtonMouseEnter = () => {
        setCollapseButtonHovered(true);
        // Prevent hover expansion when hovering over collapse button
        setIsHovering(false);
        setSidebarLocked(true); // Lock sidebar while hovering over collapse button
    };

    const handleCollapseButtonMouseLeave = () => {
        setCollapseButtonHovered(false);
        setSidebarLocked(false); // Unlock sidebar when not hovering over collapse button
    };

    // On mobile, sidebar should always be expanded
    const isExpanded = isMobile ? true : (!collapsed || isHovering);

    const handleLogout = async () => {
        try {
            await logout();
            toast.success('Logged out successfully');
            navigate("/");
        } catch (error) {
            console.error("Error logging out:", error);
            toast.error("Failed to log out. Please try again.");
        }
    };

    const handleNavigation = (path: string, isProFeature: boolean) => {
        if (isProFeature && !isPro) {
            togglePricingPopup(currentProjectId);
            toast.error("This is a PRO feature. Upgrade to access!");
        } else {
            navigate(`/${id}/${path}`);
        }
        if (isMobile) {
            onClose();
        }
    };

    const handleRecentSearchClick = (search: RecentSearch) => {
        const route = search.type === "summary"
            ? `/${id}/answerthis/answer/summary/${search.query_hash}`
            : `/${id}/answerthis/answer/${search.query_hash}`;
        navigate(route);
        if (isMobile) {
            onClose();
        }
    };

    const handleDeleteSearch = async (queryHash: string) => {
        if (window.confirm("Are you sure you want to delete this search?")) {
            try {
                await deleteQuery(queryHash);
                refreshSidebar();
                toast.success("Search deleted successfully");
            } catch (error) {
                console.error("Error deleting search:", error);
                toast.error("Failed to delete search. Please try again.");
            }
        }
    };

    const handleChatClick = (chatIndex: string) => {
        navigate(`/${id}/answerthis/answer/chat/${chatIndex}`);
        if (isMobile) {
            onClose();
        }
    };

    const handleShowIntercom = () => {
        // Safely access Intercom if it exists
        type IntercomType = (command: string, ...args: unknown[]) => void;
        const intercomObj = window as unknown as { Intercom?: IntercomType };
        if (typeof intercomObj.Intercom === 'function') {
            intercomObj.Intercom('show');
        }
    };

    const navigationItems: NavigationItem[] = [
        { id: 'home', label: 'New', icon: Plus, path: 'ask-answerthis?tab=home', isPro: false },

        { id: 'review', label: 'Literature Review', icon: BookOpen, path: 'ask-answerthis?tab=review', isPro: false },
        { id: 'guides', label: 'Guides', icon: BookMarked, path: 'guides', isPro: false },
        { id: 'documents', label: 'AI Writer', icon: FileText, path: 'ask-answerthis?tab=documents', isPro: true },
        { id: 'library', label: 'Library', icon: Folders, path: 'ask-answerthis?tab=library', isPro: true },
        { id: 'search', label: 'Search', icon: Search, path: 'ask-answerthis?tab=search', isPro: true },
        { id: 'project', label: 'Projects', icon: List, path: 'ask-answerthis?tab=project', isPro: true },
        { id: 'citations', label: 'Citation Map', icon: Network, path: 'ask-answerthis?tab=citations', isPro: true },
        { id: 'diagram', label: 'Diagram', icon: PaintBucket, path: 'ask-answerthis?tab=diagram', isPro: true },
    ];

    const secondaryNavItems: NavigationItem[] = [
        { id: 'help', label: 'Support', icon: Headphones, path: 'help', isPro: false, action: handleShowIntercom },
        { id: 'profile', label: 'Profile', icon: User, path: 'profile', isPro: false },
        { id: 'contact-us', label: 'Contact Us', icon: Contact, path: 'contact-us', isPro: false },
    ];

    return (
        <div
            className="h-full"
            onMouseLeave={() => {
                // Immediately collapse when mouse completely leaves the sidebar
                if (collapsed && isHovering && !isMobile) {
                    setIsHovering(false);
                    setHoveredItem(null);
                    setTooltipItem(null);
                }
            }}
        >
            <div
                style={{ zIndex: 999 }}
                className={`fixed font-Manrope inset-y-0 left-0 bg-gradient-to-br from-white to-pink-50 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-lg transition-all duration-150 ease-out flex flex-col sidebar-container
                    ${isOpen ? 'translate-x-0' : '-translate-x-full'} 
                    ${isMobile ? 'md:translate-x-0' : ''} 
                    ${collapsed && !isHovering && !isMobile ? 'w-16' : 'w-72'}`}
                onMouseLeave={handleMouseLeave}
            >
                <div className="flex items-center justify-between p-4">
                    {isExpanded && <img src={logo} alt="Logo" className="h-8" />}
                    <div
                        className={`relative ${!isExpanded ? 'w-full flex justify-center' : 'ml-auto'}`}
                        onMouseEnter={handleCollapseButtonMouseEnter}
                        onMouseLeave={handleCollapseButtonMouseLeave}
                    >
                        <button
                            onClick={handleCollapse}
                            className="text-gray-500 hover:text-gray-700 hover:bg-gray-100 p-2 rounded-full z-50"
                        >
                            {!isExpanded ? <ChevronRight size={24} /> : <ChevronLeft size={24} />}
                        </button>
                    </div>
                </div>

                <div className="flex-grow flex flex-col overflow-y-auto">
                    {/* Main Navigation */}
                    <div className={`${!isExpanded ? 'px-2 py-4' : 'p-4'} border-b border-gray-200`}>
                        {navigationItems.map(item => (
                            <div
                                key={item.id}
                                className="relative mb-2"
                            >
                                <button
                                    onClick={() => item.action ? item.action() : handleNavigation(item.path, item.isPro)}
                                    className={`w-full flex items-center ${!isExpanded ? 'justify-center' : ''} rounded-lg transition-all duration-100
                                        ${activeTab === item.id ? 'bg-brandMaroon text-white' :
                                            hoveredItem === item.id ? 'bg-gray-100 text-gray-900' :
                                                'text-gray-700 hover:bg-gray-100'}
                                        p-3`}
                                    onMouseEnter={() => handleItemMouseEnter(item.id)}
                                    onMouseLeave={handleItemMouseLeave}
                                >
                                    <span className={`${!isExpanded ? 'flex justify-center w-6 h-6' : 'w-6 h-6 flex items-center justify-center'}`}>
                                        <item.icon
                                            size={20}
                                            className={`${activeTab === item.id ? 'text-white' :
                                                hoveredItem === item.id && !isExpanded ? 'text-gray-900' : ''}`}
                                        />
                                    </span>
                                    {isExpanded && <span className="ml-3">{item.label}</span>}

                                </button>
                                {/* Only show tooltips in collapsed view */}
                                {!isExpanded && tooltipItem === item.id && (
                                    <div className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap z-50">
                                        {item.label}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Main Content Sections - Show when expanded (including on hover) */}
                    {isExpanded && (
                        <div className="p-2 flex-grow">
                            {/* Recent Queries Section - Not expanded by default */}
                            <div >
                                <ExpandableSection title='Recent Queries' initiallyExpanded={false}>
                                    {isLoading ? (
                                        <SkeletonLoader />
                                    ) : (
                                        <div className="max-h-60 overflow-y-auto mt-2">
                                            {recentSearches.map((search) => (
                                                <div key={search.query_hash} className="flex items-center justify-between py-2 hover:bg-gray-200 cursor-pointer px-2 rounded mb-1">
                                                    <div className="flex-1 mr-2" onClick={() => handleRecentSearchClick(search)}>
                                                        <span className="font-normal text-sm block">
                                                            <SlicedText text={search.query_text} maxLength={24} />
                                                        </span>
                                                        <span className="text-xs text-gray-500">
                                                            {new Date(search.query_madeAt).toLocaleDateString()}
                                                        </span>
                                                        {search.type === "summary" && (
                                                            <span className="ml-2 px-2 py-1 bg-brandMaroon text-white text-xs rounded-full">
                                                                Summary
                                                            </span>
                                                        )}
                                                    </div>
                                                    <button
                                                        onClick={() => handleDeleteSearch(search.query_hash)}
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        <X size={16} />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ExpandableSection>
                            </div>

                            {/* Chat with Papers Section */}
                            <div className='mt-4'>
                                <ExpandableSection title='Chat with Papers' initiallyExpanded={false}>
                                    {isLoading ? (
                                        <SkeletonLoader />
                                    ) : (
                                        <div className="max-h-60 overflow-y-auto mt-2">
                                            {chats.map((chat) => (
                                                <div
                                                    key={chat.chatIndex}
                                                    onClick={() => handleChatClick(chat.chatIndex)}
                                                    className="py-2 px-2 hover:bg-gray-200 cursor-pointer rounded mb-1"
                                                >
                                                    <span className="font-normal text-sm block">
                                                        <SlicedText text={chat.lastUserMessage} maxLength={24} />
                                                    </span>
                                                    <span className="text-xs text-gray-500">
                                                        {new Date(chat.created_at).toLocaleDateString()}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ExpandableSection>
                            </div>
                        </div>
                    )}
                </div>

                {/* Compact Footer - Only show in expanded view */}
                <div className={`${!isExpanded ? 'py-4 px-2' : 'p-3'} bg-gray-100 border-t border-gray-200`}>
                    {/* Trial Status in a compact form */}
                    {!isPro && isExpanded && (
                        <div className="flex flex-col mb-3">
                            <div className="flex items-center justify-between bg-white p-3 rounded-lg shadow-sm mb-2">
                                <div className="flex items-center">
                                    <Book size={18} className="text-brandMaroon mr-2" />
                                    <span className="font-medium text-base">{user?.credits} credits left</span>
                                </div>
                            </div>
                            <button
                                onClick={() => togglePricingPopup(currentProjectId)}
                                className="w-full bg-brandMaroon text-white py-3 px-4 rounded-md hover:bg-opacity-90 transition-colors text-base font-medium"
                            >
                                See Pricing
                            </button>
                        </div>
                    )}

                    {/* Secondary Navigation in a row with tooltips - Only show in expanded view */}
                    {isExpanded && (
                        <div className="flex items-center justify-between mb-3">
                            {secondaryNavItems.map((item) => (
                                <div key={item.id} className="w-1/4 relative">
                                    <button
                                        onClick={() => item.action ? item.action() : handleNavigation(item.path, item.isPro)}
                                        className={`text-gray-600 hover:text-gray-800 p-3 rounded-full 
                                        ${hoveredItem === item.id ? 'bg-gray-200' : 'hover:bg-gray-200'} 
                                        mx-auto flex items-center justify-center w-10 h-10`}
                                        onMouseEnter={() => handleItemMouseEnter(item.id)}
                                        onMouseLeave={handleItemMouseLeave}
                                    >
                                        <span className="flex items-center justify-center w-6 h-6">
                                            <item.icon size={20} />
                                        </span>
                                    </button>
                                    {/* Show tooltips in both expanded and collapsed views */}
                                    {tooltipItem === item.id && (
                                        <div className={`absolute ${isExpanded ? 'left-1/2 -translate-x-1/2 -bottom-6' : 'left-full ml-2 top-1/2 -translate-y-1/2'} bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap z-50`}>
                                            {item.label}
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="w-1/4 relative">
                                <button
                                    onClick={handleLogout}
                                    className={`text-gray-600 hover:text-gray-800 p-3 rounded-full 
                                    ${hoveredItem === 'logout' ? 'bg-gray-200' : 'hover:bg-gray-200'} 
                                    mx-auto flex items-center justify-center w-10 h-10`}
                                    onMouseEnter={() => handleItemMouseEnter('logout')}
                                    onMouseLeave={handleItemMouseLeave}
                                >
                                    <span className="flex items-center justify-center w-6 h-6">
                                        <LogOut size={20} />
                                    </span>
                                </button>
                                {/* Show tooltips in both expanded and collapsed views */}
                                {tooltipItem === 'logout' && (
                                    <div className={`absolute ${isExpanded ? 'left-1/2 -translate-x-1/2 -bottom-6' : 'left-full ml-2 top-1/2 -translate-y-1/2'} bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap z-50`}>
                                        Log Out
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/* PRO Upgrade Button for Collapsed View */}
                    {!isPro && !isExpanded && (
                        <div className="flex justify-center relative">
                            <button
                                onClick={() => togglePricingPopup(currentProjectId)}
                                className={`bg-brandMaroon text-white p-3 rounded-full flex items-center justify-center w-10 h-10 ${hoveredItem === 'pricing' ? 'bg-opacity-90' : ''}`}
                                onMouseEnter={() => handleItemMouseEnter('pricing')}
                                onMouseLeave={handleItemMouseLeave}
                            >
                                <span className="flex items-center justify-center w-6 h-6">
                                    <Gem size={20} />
                                </span>
                            </button>
                            {/* Only show tooltip when sidebar is collapsed */}
                            {!isExpanded && tooltipItem === 'pricing' && (
                                <div className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap z-50">
                                    See Pricing
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

// Set sidebar to be expanded by default
const ExpandedSidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    return <Sidebar {...props} />;
};

export default ExpandedSidebar;
