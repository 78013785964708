import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BookOpen, Bookmark, PenTool, Book, LucideIcon, Sparkles } from 'lucide-react';

interface LoadingProps {
  question: string;
  extraLong?: boolean;
}

interface ResearchStep {
  icon: LucideIcon;
  text: string;
}

const literatureFacts: string[] = [
  "Did you know? The first novel is thought to be 'The Tale of Genji' written in the 11th century.",
  "Shakespeare's works contain 1,700 words that had never been used in writing before.",
  "The longest novel ever published is 'À la recherche du temps perdu' by Marcel Proust, with 9,609,000 characters.",
  "The first book ever printed was the Gutenberg Bible in 1455.",
  "Jorge Luis Borges, a famous Argentine writer, was also a librarian.",
];

const researchSteps: ResearchStep[] = [
  { icon: BookOpen, text: "Gathering sources" },
  { icon: Bookmark, text: "Analyzing key themes" },
  { icon: PenTool, text: "Synthesizing information" },
  { icon: Book, text: "Compiling result" },
];

const Loading: React.FC<LoadingProps> = ({ question, extraLong = false }) => {
  const [progress, setProgress] = useState<number>(0);
  const [currentFact, setCurrentFact] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showUberesque, setShowUberesque] = useState<boolean>(false);

  const calculateProgress = useCallback(() => {
    const totalDuration = 100000 + (extraLong ? 30000 : 0);
    const interval = 100;
    const incrementPerInterval = (100 / (totalDuration / interval)) * (0.5 + Math.random());
    return (prevProgress: number): number => Math.min(prevProgress + incrementPerInterval, 100);
  }, [extraLong]);

  useEffect(() => {
    const progressUpdater = calculateProgress();
    const timer = setInterval(() => {
      setProgress((prev) => {
        const newProgress = progressUpdater(prev);
        setCurrentStep(Math.floor(newProgress / 25));
        if (newProgress === 100) {
          clearInterval(timer);
          setTimeout(() => setShowUberesque(true), 1000);
        }
        return newProgress;
      });
    }, 100);

    const factTimer = setInterval(() => {
      setCurrentFact((prevFact) => (prevFact + 1) % literatureFacts.length);
    }, 6000);

    return () => {
      clearInterval(timer);
      clearInterval(factTimer);
    };
  }, [calculateProgress]);

  if (showUberesque) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen-header bg-white text-darkMaroon">
        <h2 className="text-4xl font-bold mb-8 text-brandMaroon animate-pulse">
          Just a moment...
        </h2>
        <div className="flex items-center mb-6">
          <Sparkles className="text-brandMaroon mr-2" size={24} />
          <p className="text-xl">We're adding some magic touches to your analysis.</p>
          <Sparkles className="text-brandMaroon ml-2" size={24} />
        </div>
        <p className="text-lg mb-8">
          We'll remind you via email once your processing is complete.
        </p>
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-brandMaroon to-transparent opacity-30 animate-gradient-x"></div>
          <div className="flex space-x-3 py-3 px-6 bg-white rounded-full shadow-lg">
            {[0, 1, 2].map((i) => (
              <div
                key={i}
                className="w-4 h-4 bg-brandMaroon rounded-full animate-bounce"
                style={{ animationDelay: `${i * 0.2}s` }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen-header text-darkMaroon">
      <div className="w-64 h-64 mb-8">
        <CircularProgressbar
          value={progress}
          text={`${Math.round(progress)}%`}
          styles={buildStyles({
            textColor: '#A53E5A',
            pathColor: '#A53E5A',
            trailColor: '#DBB2BD',
          })}
        />
      </div>

      <h2 className="text-2xl font-bold mb-4 text-brandMaroon">Analyzing: {question}</h2>

      <div className="flex justify-between w-full max-w-2xl mb-8">
        {researchSteps.map((step, index) => (
          <div key={index} className={`flex flex-col items-center ${index <= currentStep ? 'text-brandMaroon' : 'text-lightMaroon'}`}>
            <step.icon size={24} />
            <span className="text-sm mt-2">{step.text}</span>
          </div>
        ))}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h3 className="text-xl font-semibold mb-2 text-brandMaroon">Did you know?</h3>
        <p className="text-darkMaroon">{literatureFacts[currentFact]}</p>
      </div>

      <p className="mt-8 text-sm text-darkMaroon">You can close this window if you desire, as you will get an email notification once the processing is complete.</p>
    </div>
  );
};

export default Loading;