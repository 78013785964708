import React, { useRef, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendQuery,
  Filters,
  defaultFilters,
  UploadPdfResponse,
  RecentSearch,
} from "../../authService";
import FileUploadBar from "../../components/FileUploadBar";
import documentIcon from "/img/document-icon-gray.svg";
import PromptHelper from "../../components/promptHelper";
import { toast } from "sonner";
import { Search } from "lucide-react";

import SeoComp from "../../components/SeoComp";
import { useSidebar } from "../../contexts/sidebarContext";
import FilterSidebar from "../../components/filters/FiltersSidebar";
import UploadedFilesList from "./UploadFilesList";
import { useAuth } from "../../contexts/AuthContext";
import "./LiteratureReview.css";
import Loading from "../../components/Loading";
import { useContentHeight } from "../../utils/tabsHeightCalculator";
import { SearchBar } from "@/components/searchInput/SearchBar";
import RecentSearchesList from "../../components/MainUploadPage/RecentSearchesList";

interface LiteratureReviewProps {
  showOnlySearchBar?: boolean;
  onQueryChange?: (query: string) => void;
  initialQuery?: string;
}

interface TrySearchForProps {
  handleExampleQuestionClick: (question: string) => void;
}

function TrySearchFor({ handleExampleQuestionClick }: TrySearchForProps) {
  const exampleQuestions = [
    "How does climate change impact biodiversity?",
    "Why are aging Covid patients more susceptible to severe complications?",
    "How does social media affect the college selection process?",
    "What are the interesting theories about dark matter and dark energy?",
    "What is the significance of higher-dimensional algebra?"
  ];

  return (
    <div className="p-4 border-t border-gray-100">
      <div className="w-full">
        <p className="text-sm font-medium text-gray-700 mb-3">Try searching for:</p>

        <div className="flex flex-wrap gap-2 md:hidden">
          {exampleQuestions.map((question, index) => (
            <button
              key={index}
              className="flex-shrink-0 flex items-center px-3 py-1.5 
                        bg-white border border-gray-200 rounded-lg text-xs
                        hover:border-brandMaroon hover:text-brandMaroon
                        transition-colors duration-200 shadow-sm"
              onClick={() => handleExampleQuestionClick(question)}
            >
              <Search className="w-3 h-3 text-gray-500 mr-2 flex-shrink-0" />
              <span className="line-clamp-1">{question.split(' ').slice(0, 3).join(' ')}...</span>
            </button>
          ))}
        </div>

        <div className="hidden md:grid grid-cols-2 lg:grid-cols-3 gap-3">
          {exampleQuestions.map((question, index) => (
            <button
              key={index}
              className="text-left px-3 py-2 bg-white
                        border border-gray-200 rounded-lg text-xs
                        hover:border-brandMaroon hover:text-brandMaroon
                        transition-colors duration-200 shadow-sm
                        flex items-center group"
              onClick={() => handleExampleQuestionClick(question)}
            >
              <div className="p-1 rounded-full bg-gray-50 mr-2 group-hover:bg-pink-50">
                <Search className="w-3 h-3 text-gray-500 group-hover:text-brandMaroon flex-shrink-0" />
              </div>
              <span className="line-clamp-1">{question}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

const LiteratureReview: React.FC<LiteratureReviewProps> = ({
  showOnlySearchBar = false,
  onQueryChange,
  initialQuery = ""
}) => {
  const { id } = useParams();
  const { user, checkCredits, removeCredits } = useAuth();
  const { refreshSidebar, recentSearches } = useSidebar();
  const [contentHeight] = useContentHeight();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [getPapers, setGetPapers] = useState<boolean>(false);
  const [queryText, setQueryText] = useState<string>("");
  const [hasFreeUploads, setHasFreeUploads] = useState<boolean>(false);
  const [searchBarContext, setSearchBarContext] = useState<string>("");
  const [filtersInfo, setFiltersInfo] = useState<Filters>(defaultFilters);
  const navigate = useNavigate();

  const [isSendingQuery, setIsSendingQuery] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  useEffect(() => {
    setQueryText(initialQuery);
  }, [initialQuery]);

  useEffect(() => {
    adjustHeight();
  }, []);

  useEffect(() => {
    setContext();
  }, [user]);

  function promptHelp(value: string) {
    setQueryText(value);
    textareaRef.current?.focus();
  }

  const upload = useCallback(
    async (filesArray: File[]) => {
      if (filesArray.length === 0 && !getPapers) {
        toast.error("Please select files to upload before asking a query.");
        return;
      }

      const toastId = toast.loading("Sending query...");

      try {
        const hasEnoughCredits = checkCredits(id!, getPapers ? 1 : filesArray.length);
        if (!hasEnoughCredits) {
          toast.dismiss(toastId);
          return;
        }

        setIsSendingQuery(true);
        await sendQuery(
          id!,
          queryText,
          getPapers,
          filtersInfo,
          filesArray,
          async (response: UploadPdfResponse) => {
            console.log("Upload response:", response);
            await removeCredits(getPapers ? 1 : filesArray.length);
            setUploadedFiles([]);
            const queryHash = response.query_hash;
            refreshSidebar();
            toast.success("Query sent successfully", { id: toastId });
            navigate(`/${id}/answerthis/answer/${queryHash}`, {
              state: { question: queryText, filters: filtersInfo },
            });
          },
          (errorMessage: string) => {
            console.error(errorMessage);
            toast.error(errorMessage, { id: toastId });
          }
        );

        setIsSubmitDisabled(false);
      } catch (error) {
        setIsSendingQuery(false);
        toast.error(error instanceof Error ? error.message : "An error occurred", { id: toastId });
        setIsSubmitDisabled(false);
      }
    },
    [queryText, getPapers, filtersInfo, navigate, id, refreshSidebar, checkCredits, removeCredits]
  );

  const handleSubmit = () => {
    console.log("submitting");
    setIsSubmitDisabled(true);
    if (uploadedFiles.length > 0 && queryText.length > 0) {
      console.log("sending files here");
      upload(uploadedFiles);
    } else if (getPapers && queryText.length > 0) {
      console.log("sending query here");
      upload([]);
    } else if (!getPapers && uploadedFiles.length === 0) {
      if (!checkCredits(id!, 1)) {
        setShowFilters(false);
        setIsSubmitDisabled(false);
        return;
      }
    } else if (queryText.length === 0) {
      toast.error("You need to ask a question first");
      setShowFilters(false);
      setIsSubmitDisabled(false);
    }
  };

  const setContext = () => {
    if (user?.level === "trial" && (user?.credits ?? 0) <= 0) {
      setSearchBarContext(
        "No credits left, you need to upgrade to pro. It will also help us continue building answerthis."
      );
      setGetPapers(false);
      setHasFreeUploads(false);
    } else if (uploadedFiles.length > 0) {
      setSearchBarContext(
        `Answering question from: ${uploadedFiles.length} uploaded papers `
      );
      setGetPapers(false);
      setHasFreeUploads(true);
    } else {
      setSearchBarContext(
        ""
      );
      setGetPapers(true);
      setHasFreeUploads(true);
    }
  };

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "60px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQueryText(value);
    if (onQueryChange) {
      onQueryChange(value);
    }
    adjustHeight();
  };

  const handleShowFileUpload = () => {
    setShowFileUpload(!showFileUpload);
  };

  const handleShowFilters = () => {
    if (uploadedFiles.length > 0) {
      handleSubmit();
    } else {
      if (getPapers) {
        setShowFilters(!showFilters);
      } else {
        checkCredits(id!, 1);
      }
    }
  };

  const handleFileChange = (files: File[]) => {
    setUploadedFiles(files);
  };

  useEffect(() => {
    setContext();
    if (uploadedFiles.length > 0) {
      setGetPapers(false);
    } else {
      setGetPapers(true);
    }
  }, [uploadedFiles]);

  const handleExampleQuestionClick = (question: string) => {
    setQueryText(question);
    textareaRef.current?.focus();
  };

  const showFiltersSidebar = () => {
    setShowFilters(!showFilters);
  }

  const handleRemoveFile = (index: number) => {
    const newFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newFiles);
  };

  const handleRecentSearchClick = (search: RecentSearch) => {
    const route = search.type === "summary"
      ? `/${id}/answerthis/answer/summary/${search.query_hash}`
      : `/${id}/answerthis/answer/${search.query_hash}`;
    navigate(route);
  };

  const renderSearchBar = () => (
    <div className="px-4 py-3">
      <div className="select-where-from text-center mb-2">
        <p className={`${hasFreeUploads ? "has-free-uploads" : "has-no-free-uploads"}`}>
          {searchBarContext}
        </p>
      </div>
      <div className="flex justify-center mb-3">
        <div className="flex md:hidden justify-center w-full items-center p-2.5 mb-2.5 rounded-lg bg-brandMaroon shadow-md" onClick={handleShowFileUpload}>
          <p className="mr-2.5 font-bold text-white">Upload PDFs</p>
          <img src={documentIcon} alt="document icon" className="w-8 mx-1.5 filter  brightness-[1000%]" />
        </div>
      </div>
      <div className=" search-bar-and-icons  bg-white" id="searchbar">
        <SearchBar
          handleInputChange={handleTextareaChange}
          handleSearch={handleShowFilters}
          query={queryText}
          placeholder="Enter your search query"
          disabled={isSubmitDisabled}
        />
        <div className="help-icons-desktop">
          <p>Upload PDFs</p>
          <img
            src={documentIcon}
            alt="document icon"
            className="help-icon"
            onClick={handleShowFileUpload}
          />
        </div>
      </div>
      {uploadedFiles.length > 0 && (
        <div className="mt-3">
          <UploadedFilesList files={uploadedFiles} onRemoveFile={handleRemoveFile} />
        </div>
      )}
    </div>
  );

  // Show recent searches only in full view (when not embedded in HomeUpload)
  const shouldShowRecentSearches = !showOnlySearchBar;

  return (
    <>
      {isSendingQuery ? <Loading question="" /> : (
        <>
          <SeoComp />
          <div
            className={`max-w-7xl mx-auto ${showOnlySearchBar ? 'pt-2' : 'py-6'}`}
            style={{
              height: showOnlySearchBar ? "auto" : contentHeight,
              overflow: "hidden",
            }}
          >
            <div className="w-full h-full">
              <div className="w-full text-brandMaroon font-Manrope">
                {renderSearchBar()}
                <div className="mt-2">
                  <PromptHelper onSelectPrompt={promptHelp} />
                </div>
                <TrySearchFor handleExampleQuestionClick={handleExampleQuestionClick} />

                {/* Recent Searches Section - only show in full view */}
                {shouldShowRecentSearches && (
                  <RecentSearchesList
                    searches={recentSearches}
                    onSearchClick={handleRecentSearchClick}
                  />
                )}
              </div>
            </div>
          </div>
          <FilterSidebar
            isOpen={showFilters && getPapers}
            filters={filtersInfo}
            setFilters={setFiltersInfo}
            onClose={showFiltersSidebar}
            onApply={handleSubmit}
          />
          <FileUploadBar
            onFilesChange={handleFileChange}
            files={uploadedFiles}
            show={showFileUpload}
            reverseShow={handleShowFileUpload}
          />
        </>
      )}
    </>
  );
}

export default LiteratureReview;











