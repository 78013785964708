import { cn } from '@/lib/utils';

interface ButtonToggleProps {
    activeTab: "info" | "metrics" | "origins";
    setActiveTab: React.Dispatch<React.SetStateAction<"info" | "metrics" | "origins">>;
    originCount?: number;
}

export function ButtonToggle({ activeTab, setActiveTab, originCount = 0 }: ButtonToggleProps) {
    return (
        <div className="flex gap-2 mb-4">
            <button
                onClick={() => setActiveTab('info')}
                className={cn(
                    'flex-1 px-3 py-2 rounded-md text-sm font-medium transition-colors',
                    activeTab === 'info'
                        ? 'bg-brandMaroon text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                )}
            >
                Paper Info
            </button>
            <button
                onClick={() => setActiveTab('metrics')}
                className={cn(
                    'flex-1 px-3 py-2 rounded-md text-sm font-medium transition-colors',
                    activeTab === 'metrics'
                        ? 'bg-brandMaroon text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                )}
            >
                Metrics
            </button>
            <button
                onClick={() => setActiveTab('origins')}
                className={cn(
                    'flex-1 px-3 py-2 rounded-md text-sm font-medium transition-colors',
                    activeTab === 'origins'
                        ? 'bg-brandMaroon text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                )}
            >
                Origins ({originCount})
            </button>
        </div>
    );
}
