import { toast } from 'sonner';
import { createSuggestionsItems } from "@harshtalks/slash-tiptap";
import { performCursorInsertion } from './updateSectionHelper';
import { citationStyles } from '@/pages/Answer/citationUtils.tsx';

// Define slash command suggestions
export const createSlashCommands = (projectId: string, citationStyle: citationStyles, checkCredits: (projectId: string, requiredCredits?: number, options?: { setRedirect?: boolean; }) => boolean, removeCredits: (amount: number) => Promise<void>) => createSuggestionsItems([
    {
        title: "Continue writing",
        searchTerms: ["continue", "write", "more"],
        command: async ({ editor, range }) => {
            try {
                const loadingId = toast.loading('Continuing writing...');
                editor.chain().focus().deleteRange(range).run();
                checkCredits(projectId!, 1);
                await performCursorInsertion({
                    editor,
                    projectId,
                    instruction: 'Continue writing from this point, maintaining the same style and flow',
                    useNewSources: false,
                    citationStyle
                });
                await removeCredits(1);
                toast.success('Continuing writing...', { id: loadingId });
            } catch (error) {
                console.error('Error continuing writing:', error);
                toast.error('Failed to continue writing');
            }
        },
    },
    {
        title: "Bullet List",
        searchTerms: ["unordered", "point", "bullet"],
        command: async ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).toggleBulletList().run();
        },
    },
    {
        title: "Numbered List",
        searchTerms: ["ordered", "number", "list"],
        command: async ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).toggleOrderedList().run();
        },
    },
    {
        title: "Heading 1",
        searchTerms: ["h1", "title"],
        command: async ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setHeading({ level: 1 }).run();
        },
    },
    {
        title: "Heading 2",
        searchTerms: ["h2", "subtitle"],
        command: async ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setHeading({ level: 2 }).run();
        },
    },
    {
        title: "Heading 3",
        searchTerms: ["h3"],
        command: async ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setHeading({ level: 3 }).run();
        },
    },
]);
