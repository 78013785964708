import React, { useState, useEffect } from 'react';
import { Maximize2, Minimize2, ChevronDown, ChevronUp } from 'lucide-react';
import ReactDOM from 'react-dom';

interface FullScreenExpandableProps {
    children: React.ReactNode;
    title: string;
    initiallyExpanded?: boolean;
    minimizable?: boolean;
    respectSidebar?: boolean;
    compact?: boolean;
}

const FullScreenExpandable: React.FC<FullScreenExpandableProps> = ({
    children,
    title,
    initiallyExpanded = true,
    minimizable = false,
    respectSidebar = true,
    compact = true
}) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
    const [sidebarWidth, setSidebarWidth] = useState<number | null>(null);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (isFullScreen) {
            document.body.style.overflow = 'hidden';

            // When going fullscreen, measure the sidebar if we need to respect it
            if (respectSidebar) {
                // Find the sidebar element by a reliable selector
                const sidebarElement = document.querySelector('.sidebar-container');
                if (sidebarElement) {
                    setSidebarWidth(sidebarElement.getBoundingClientRect().width);
                } else {
                    // Fallback if we can't find the exact sidebar
                    setSidebarWidth(72); // Default minimal width (72px)
                }
            }
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isFullScreen, respectSidebar]);

    const content = (
        <div
            className={`${isFullScreen ?
                'fixed z-40 bg-white overflow-auto transition-all duration-200' :
                'relative'}`}
            style={
                isFullScreen ? {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: respectSidebar && sidebarWidth ? `${sidebarWidth}px` : 0
                } : {}
            }
        >
            <div className={`sticky top-0 flex justify-between items-center ${compact ? 'py-1 px-2' : 'p-2'}
                border-b border-brandMaroon/20 bg-white z-10 ${compact ? 'h-10' : ''}`}>
                <h2 className={`font-semibold text-brandMaroon ${compact ? 'text-md' : 'text-lg'}`}>{title}</h2>
                <div className="flex items-center space-x-1">
                    {minimizable && (
                        <button onClick={toggleExpanded} className="p-0.5 hover:bg-gray-100 rounded">
                            {isExpanded ? <ChevronUp size={compact ? 16 : 20} /> : <ChevronDown size={compact ? 16 : 20} />}
                        </button>
                    )}
                    <button onClick={toggleFullScreen} className="p-0.5 hover:bg-gray-100 rounded ml-1">
                        {isFullScreen ? <Minimize2 size={compact ? 16 : 20} /> : <Maximize2 size={compact ? 16 : 20} />}
                    </button>
                </div>
            </div>
            {(isExpanded || !minimizable) && (
                <div className={`${isFullScreen ? 'p-4' : 'p-0'}`}>
                    {children}
                </div>
            )}
        </div>
    );

    return isFullScreen
        ? ReactDOM.createPortal(content, document.body)
        : content;
};

export default FullScreenExpandable;
