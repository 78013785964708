import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import Spinner from "../Spinner";
import { useAuth } from "../../contexts/AuthContext";

interface ProtectedRouteProps {
  children: ReactElement;
  admin?: boolean;
  id?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, admin = false, id = false }) => {
  const { user } = useAuth();
  if (!user) {
    return <Spinner />;
  }
  const navigateTo = id ? `/${id}/pricing` : "/pricing";
  if (admin) {
    return user.email == 'themoviechannel77@gmail.com' ? children : <Navigate to={navigateTo} />;
  }
  return user.level == 'paid' ? children : <Navigate to={navigateTo} />;
};

export default ProtectedRoute;
