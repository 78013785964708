import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { UserInfo } from '@/authService';
interface RetentionAlertProps {
    user: UserInfo;
    onBookMeeting: () => void;
    litReviewMin?: number;
}
const RetentionAlert = ({ user, onBookMeeting }: RetentionAlertProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const checkEngagement = () => {
            if (user.level == 'paid') {
                setShow(true);
            }
        };

        checkEngagement();
    }, [user]);

    if (!show) return null;

    return (
        <Card className="w-full max-w-2xl mx-auto mb-6">
            <CardHeader>
                <CardTitle className="text-xl font-semibold text-brandMaroon">
                    Get the Most Out of Your Pro Subscription
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                {!user.onboarding_completed && (
                    <Alert className="bg-lightMaroon bg-opacity-10 border-brandMaroon">
                        <AlertTitle className="text-brandMaroon">Complete Your Onboarding</AlertTitle>
                        <AlertDescription>
                            Take 5 minutes to complete onboarding and unlock the full potential of our platform.
                        </AlertDescription>
                    </Alert>
                )}

                <div className="space-y-4">
                    <p className="text-gray-700">
                        Would you like personalized guidance on how to maximize your subscription?
                    </p>

                    <div className="flex flex-col sm:flex-row gap-3">
                        <Button
                            onClick={onBookMeeting}
                            className="bg-brandMaroon hover:bg-brandMaroon/90 text-white"
                        >
                            Book a 1:1 Strategy Session
                        </Button>

                        <Button
                            variant="outline"
                            className="border-brandMaroon text-brandMaroon hover:bg-brandMaroon/10"
                            onClick={() => setShow(false)}
                        >
                            I'll Explore on My Own
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default RetentionAlert;